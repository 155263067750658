import React, {useEffect, useState} from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid'
import {Popover} from "@headlessui/react";
import {usePopper} from "react-popper";
import {VirtualElement} from "@popperjs/core";

export enum TooltipPlacement {
  RIGHT = 'right',
  LEFT = 'left',
  TOP = 'top',
  BOTTOM = 'bottom',
  AUTO = 'auto',
}

type Props = {
  open: boolean
  text: string
  target: any
  index?: any
  placement?: TooltipPlacement
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const virtualReference: any = {
  getBoundingClientRect() {
    return {
      top: 100,
      left: 100,
      // bottom: 20,
      // right: 100,
      width: 90,
      height: 10,
    };
  },
};
const TootipPanel: React.FC<Props> = ({ open = false, text, target, placement}) => {
  const [on, setOn] = useState(open);
  let [referenceElement, setReferenceElement] = useState(target)
  let [popperElement, setPopperElement] = useState<any>()
  const [arrowElement, setArrowElement] = useState<any>(null);

  let { styles, attributes, state } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement }},
      { name: 'offset', options: { offset: [0, 15] }},
    ],
    placement: placement ? placement : 'auto',
  })

  // useEffect(() => {
  //     console.log(444, styles)
  // }, [styles.reference])

  useEffect(() => {
    setOn(open)
    // setReferenceElement(target.current.children[index])
    setReferenceElement(target.current)
  }, [open])

  const getArrowClass = (className: string = '') => {
    if(['top', 'top-start', 'top-end'].includes(className)) {
      // return "mb-3 after:content-[''] after:absolute after:top-full after:left-[var(--align-side)] after:border-x-transparent after:border-t-slate-700/90 after:border-x-8 after:border-t-8"
      return "after:content-[''] after:absolute top-full after:left-[var(--align-side)] after:border-x-transparent after:border-t-slate-700/90 after:border-x-8 after:border-t-8"
    } else if(['bottom', 'bottom-start', 'bottom-end'].includes(className)) {
      return "after:content-[''] after:absolute bottom-[var(--offset-opposite)] after:left-[var(--align-side)] after:border-x-transparent after:border-b-slate-700/90 after:border-x-8 after:border-b-8"
    } else if(['left', 'left-start', 'left-end'].includes(className)) {
      return "after:content-[''] after:absolute left-full after:top-[var(--align-side)] after:border-y-transparent after:border-l-slate-700/90 after:border-y-8 after:border-l-8"
    } else if(['right', 'right-start', 'right-end'].includes(className)) {
      return "after:content-[''] after:absolute right-[var(--offset-opposite)] after:top-[var(--align-side)] after:border-y-transparent after:border-r-slate-700/90 after:border-y-8 after:border-r-8"
    }
  }

  return (
    <Popover className="">
      {/*<Popover.Button*/}
      {/*  onMouseEnter={(event: any) => {event.target.click()}}*/}
      {/*  onMouseLeave={(event: any) => {event.target.click()}}*/}
      {/*  ref={setReferenceElement}>Solutions</Popover.Button>*/}
      <Popover.Panel
        static={on}
        // className=" z-10 left-5.5 bottom-2 -translate-x-2/4 rounded-md p-2 bg-slate-700/90 after:content-[''] after:absolute after:top-full after:left-2/4 after:border-x-transparent after:border-t-slate-700/90 after:border-x-8 after:border-t-8"
        // className=" z-10 rounded-md p-2 bg-slate-700/90 translate-x-2/4 after:content-[''] after:absolute after:top-full after:left-2/4 after:border-x-transparent after:border-t-slate-700/90 after:border-x-8 after:border-t-8"
        // className=" z-10 rounded-md p-2 bg-slate-700/90 after:content-[''] after:absolute after:bottom-full after:left-2/4 after:border-x-transparent after:border-b-slate-700/90 after:border-x-8 after:border-b-8"
        className={() => {
          return (
            classNames('max-w-[300px] z-50 rounded-md p-2 bg-slate-700/90')
          )
        }}
        ref={setPopperElement}
        // @ts-ignore
        style={{...styles.popper, '--align-side':'calc(50% - 8px)', '--offset-opposite':'calc(100% + 8px'}}
        {...attributes.popper}
      >
        <p className="text-white">{text}</p>
        <div ref={setArrowElement} className={ classNames(getArrowClass(attributes?.popper?.['data-popper-placement'])) } style={styles.arrow} />
      </Popover.Panel>
    </Popover>
  )
};

export default TootipPanel;