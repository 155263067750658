import * as React from 'react';
import {FormEvent, useMemo, useState} from 'react';
import {ArrowPathIcon, CheckIcon, ExclamationTriangleIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Input from "../../components/ui/Input/Input";
import {useSetNewPasswordRecoveryMutation} from "../../api/auth";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";
import {setCurrentUser} from "../../store/reducers/AuthSlice";
import {useAppDispatch} from "../../hooks/redux";

type Props = {
  children?: React.ReactNode
  param?: string
};

const useRecoveryError = (error: FetchBaseQueryError | SerializedError | undefined | any): { message: string; details: string[] } | null => {
  const memoizedError = useMemo(() => {
    if (error !== undefined && 'status' in error) {
      try {
        const result: { message: string; details: { property: string; message: string; }[] } = error.data;

        return {
          message: result.message,
          details: result.details?.map(({ message }) => message) ?? [],
        };
      } catch {
        return { message: 'Unknown error', details: [] };
      }

    }

    return null;
  }, [error]);

  return memoizedError;
};

const SetNewPasswordRecoveryPage: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params, setParams] = useSearchParams();
  const [setNewPasswordRecovery, {data, reset, isError, isLoading, error, isSuccess}] = useSetNewPasswordRecoveryMutation()
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPassword2, setNewPassword2] = useState<string>('')
  const key = params.get('key') ?? '';
  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setNewPasswordRecovery({ newPassword, key });
  };

  const recoveryError = useRecoveryError(error);

  const onUnderstandButtonClick = () => {
    if (data !== undefined) {
      let userData = {
        _id: data.Employee._id,
        accessToken: data.accessToken,
        memberTenants: data.memberTenants,
        Tenant: data.Tenant,
        login: data.login,
      }

      dispatch(setCurrentUser(userData));
      navigate('/products');
    }
  };

  return (
    <div className="flex flex-col items-center min-w-[320px] min-h-screen p-4 bg-slate-50 sm:pt-8 sm:pb-6 md:px-8 md:pt-12">
      <h1 className="flex flex-col items-center gap-1 sm:gap-4 md:gap-6">
        <span className="text-2xl text-slate-500 font-medium sm:text-3xl md:text-5xl"><span className="text-sky-500 italic uppercase underline">v</span>tovare</span>
        <span className="text-lg text-slate-700 font-bold sm:text-2xl md:text-3xl">Новый пароль</span>
      </h1>

      <main className="max-w-xs shadow rounded-lg w-full mt-2 bg-white sm:max-w-sm sm:mt-6 md:max-w-md md:mt-8">
        <form className="flex flex-col gap-4 text-sm text-slate-700 p-6 md:gap-6 md:p-10" onSubmit={onSubmit}>
            <Input label='Новый пароль' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type={'password'}/>
            <Input label={'Подтвердите пароль'} value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)} type={'password'} errorText={'Пароли не совпадают'} isError={!!(newPassword && newPassword2 && newPassword!==newPassword2)} />

            <div>
              <button
                type="submit"
                className="flex justify-center w-full rounded-md bg-sky-600 px-3 py-2 text-sm font-medium text-white hover:bg-sky-500 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:text-base md:px-6 md:py-3"
                disabled={!(newPassword && newPassword2 && newPassword === newPassword2)}
              >
              <span className="relative">
                Сохранить пароль
                {isLoading && <ArrowPathIcon className="animate-spin absolute top-0.5 -right-7 w-6 h-6"/>}
              </span>
              </button>
            </div>

            <div className="text-right">
              <Link to="/login" className="text-sky-600 hover:text-sky-500">
                Войти
              </Link>
            </div>
          </form>
      </main>

      <div className="text-xs text-slate-600 text-center mt-auto sm:text-sm md:flex md:flex-row-reverse md:justify-between md:w-full md:pt-8">
        <p>
          {/*<span>Русский</span>*/}
          {/*<span className="mx-4">English</span>*/}
          {/*<span>Все языки</span>*/}
        </p>

        <p className="mt-2 sm:mt-4 md:mt-0">Vtovare &copy; 2023</p>
      </div>

      {recoveryError && (
        <div className="fixed inset-x-0 top-0 p-4 flex gap-3 items-start bg-rose-50">
          <ExclamationTriangleIcon className="w-5 h-5 text-rose-400" />
          <div className="grow text-sm">
            <p className="font-medium text-rose-800">{recoveryError.message}</p>
            {!!recoveryError.details.length && (
              <ul className="list-disc list-inside text-rose-700">
                {recoveryError.details.map((message) => <li key={message}>{message}</li>)}
              </ul>
            )}
          </div>
          <button
            type="button"
            className="rounded-md p-1.5 text-rose-500 hover:bg-rose-100 focus:bg-rose-50 focus:ring-2 focus:ring-rose-100"
            onClick={reset}
          >
            <span className="sr-only">Закрыть</span>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      )}

      {isSuccess && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-slate-700/25">
          <div className="rounded-2xl m-4 p-6 max-w-sm bg-white text-center relative">
            <button
              type="button"
              className="absolute top-4 right-4 text-slate-500 hover:text-rose-500 focus:text-rose-500"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">Закрыть</span>
              <XMarkIcon className="w-6 h-6" />
            </button>
            <div className="rounded-full w-12 mx-auto p-3 bg-emerald-50">
              <CheckIcon className="w-6 h-6 text-emerald-500" />
            </div>

            <p className="text-slate-900 text-lg font-medium mt-5">Пароль успешно изменён</p>

            <p className="text-slate-500 text-sm">По нажатию кнопки Вы будете перенаправлены в панель управления</p>

            <button
              type="button"
              className="flex justify-center w-full rounded-md bg-sky-600 mt-4 px-3 py-2 text-sm font-medium text-white hover:bg-sky-500 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:text-base md:px-6 md:py-3"
              onClick={onUnderstandButtonClick}
            >
              Перейти в ПУ
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default SetNewPasswordRecoveryPage;