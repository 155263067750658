import * as React from 'react';
// import {useAppDispatch, useAppSelector} from "hooks/redux";
import {Fragment, useEffect, useRef, useState} from "react";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
// import {fetchClient} from "store/reducers/clients/ClientThunk";
import { Menu, Transition } from '@headlessui/react'
import {useAppSelector} from "../../../hooks/redux";
import {usePopper} from "react-popper";
import {useContextMenu} from "../../../hooks/contextMenu";
import {NavLink} from "react-router-dom";

export enum SideMenu { Left='left', Right='right' };
type Props = { children?: React.ReactNode };
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const ContextMenu: React.FC<Props> = (props: Props) => {
  const {} = props;
  const { items, data, target, side } = useAppSelector(state => state.contextMenu);
  const contextMenu = useContextMenu();
  const [open2, setOpen2] = useState(false);

  let [referenceElement, setReferenceElement] = useState(target)
  let [popperElement, setPopperElement] = useState<any>()
  let { styles, attributes, state } = usePopper(referenceElement, popperElement, {
    modifiers: [
      // { name: 'offset', options: { offset: [0, 25] }, enabled: true, effect: },
    ],
    strategy: 'fixed',
    placement: "bottom-start"
  })

  const click = () => {
    console.log(444, 'click');
    contextMenu.close();
  }

  useEffect(() => {
    console.log(222, items, data, target )
    if(items && items.length) {
      setOpen2(true)
      setReferenceElement(target)
    }

    window.requestAnimationFrame(() => {
      document.addEventListener('click', click, {once: true});
    })
  }, [])

  const [a, setA] = useState<any>()

  useEffect(() => {
    if(a) {
      console.log(5, a)
      a.focus()
    }

  }, [a])

  // useEffect(() => {
  //   // setOn(open)
  //   setReferenceElement(target)
  //   console.log(3, target)
  //
  // }, [open2])

  return (
    <>
      <Menu
        as="div"
        className="relative absolute text-left z-50"
        ref={setPopperElement}
        style={{...styles.popper}}
      >

        {/*<div>*/}
        {/*  <Menu.Button className="flex items-center rounded-full text-slate-500 hover:text-slate-600">*/}
        {/*    <span className="sr-only">Open options</span>*/}
        {/*    {children}*/}
        {/*  </Menu.Button>*/}
        {/*</div>*/}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={open2}
          appear={true}
        >
          <Menu.Items ref={setA} static className={classNames("absolute z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 outline-none", side == 'left' ? 'right-0' : '')}>
            {items && items.map((group, index) => (
              <div key={index} className="py-1">
                {group && group.map((item, index2) => (
                  <Fragment key={index2}>
                    <Menu.Item>
                      {({ active }) => {
                        return (
                          item.href ?
                              <NavLink
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100 text-slate-900' : 'text-slate-700',
                                  'block px-4 py-2 text-sm'
                                )}
                                onClick={(e) => { item?.onClick && item.onClick(data) }}
                              >
                                {item.text}
                              </NavLink> :
                              <a
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-100 text-slate-900' : 'text-slate-700',
                                  'block px-4 py-2 text-sm'
                                )}
                                onClick={(e) => { item?.onClick && item.onClick(data) }}
                              >
                                {item.text}
                              </a>
                        )
                      }}
                    </Menu.Item>
                  </Fragment>
                ))}
              </div>
            ))}

            {/*<div className="py-1">*/}
            {/*  <form method="POST" action="#">*/}
            {/*    <Menu.Item>*/}
            {/*      {({ active }) => (*/}
            {/*        <button*/}
            {/*          type="submit"*/}
            {/*          className={classNames(*/}
            {/*            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',*/}
            {/*            'block w-full px-4 py-2 text-left text-sm'*/}
            {/*          )}*/}
            {/*        >*/}
            {/*          Удалить*/}
            {/*        </button>*/}
            {/*      )}*/}
            {/*    </Menu.Item>*/}
            {/*  </form>*/}
            {/*</div>*/}
          </Menu.Items>
        </Transition>

      </Menu>
    </>
  );
};

export default ContextMenu;