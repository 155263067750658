import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {IProduct} from "../models/IProduct";

export type ProductsRequest = {
  page?: number
  size: number | string | undefined
  sort: string | undefined
  last: string
  last_sort: string
  filterText: string
  cat: string
  isPool: boolean
  isOnline: boolean
  onlyRoot: boolean
  cache_id: string //
}

export type ProductsResponse = {
  data: IProduct[];
  meta: any
}

export type AddProductRequest = {
  title_rus: string;
  number: string;
  Brand: string
  Categories: string[]
  isPool: boolean
  isOnline: boolean
  price_sale: number
  price_purchase: number
  qty: number
  images: string[],
  description: string
}

export interface ListResponse<T> {
  data: T[],
  meta: {
    page: number,
    perPage: number,
    totalElements: number
    hasNextPage: boolean
  }
}

export const productApi = createApi({
  reducerPath: 'productApi',
  tagTypes: ['Products', 'ProductsFilters'],
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/product` }),
  endpoints: (builder) => ({
    getProducts: builder.query<ListResponse<IProduct>, ProductsRequest>({
      query: (params) => ({
          url: `/`,
          method: 'POST',
          body: params,
          credentials: 'include',
      }),
      serializeQueryArgs: ({queryArgs, endpointDefinition, endpointName}) => {
        console.log(44444, queryArgs, endpointDefinition, endpointName)
        // если включить этот блок, то полученный кеш будет распространяться на все подписки, исключая различия в параметрах
        return endpointName + queryArgs?.cache_id
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { last, filterText } }) => {
        // if((filterText || cat) && !last) { // filterText = 'term'  last = ''
        if(!last) { // last = ''
          console.log('----------новый массив для фильтра', filterText)
          currentCache.data = newItems.data
          currentCache.meta = newItems.meta
        }
        else {
          console.log(99999999999999)
          currentCache.data.push(...newItems.data)
          currentCache.meta = newItems.meta
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }: any) {
        console.log(9999999)
        return currentArg !== previousArg
      },
      // providesTags: ['Products'],
      // providesTags: (result) =>
      //   result
      //     ? [
      //       ...result.data.map(({ _id }) => {console.log(1, _id); return { type: 'Products' as const, id: _id }}),
      //       { type: 'Products', id: 'PARTIAL-LIST' },
      //     ]
      //     : [{ type: 'Products', id: 'PARTIAL-LIST' }],
      // providesTags: (result) => result ? []
    }),
    getSelectedProducts: builder.query<void, { selected: any, params: any }>({
      query: (body) => ({
        url: `/get-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
    }),
    getProduct: builder.query<IProduct, {id: string}>({
      query: ({id}) => ({
        url: `/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      providesTags: ['Products']
    }),
    addProduct: builder.mutation<IProduct, AddProductRequest>({
      query: (body) => ({
        url: '/create',
        method: 'POST',
        body,
        credentials: 'include',
      }),
      // invalidatesTags: ['Products']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    copyProduct: builder.mutation<IProduct, AddProductRequest>({
      query: (body) => ({
        url: '/copy',
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),
    updateProduct: builder.mutation<IProduct, { id: string, body: AddProductRequest }>({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
      // invalidatesTags: ['Products'],
      // invalidatesTags: (result, error, arg) => {
      //   if(error) return []
      //   return ['Products']
      // },
    }),
    delProduct: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      // invalidatesTags: ['Products']
    }),
    delProducts: builder.mutation<void, { selected: any, params: any }>({
      query: (body) => ({
        url: `/del-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['Products']
    }),
    getProductsFilters: builder.query<any, any>({
      query: (params) => ({
        url: `/products-filters`,
        method: 'POST',
        body: params,
        credentials: 'include',
      }),
      providesTags: ['ProductsFilters']
    }),
    downloadXls: builder.mutation({
      queryFn: async (params: {selected: any, params: any}, api, extraOptions, baseQuery) => {
        const result: any = await baseQuery({
          url: `/get-xls`,
          method: 'POST',
          body: params,
          credentials: 'include',
          responseHandler: ((response) => response.blob())
        })
        let disposition = result.meta.response.headers.get('content-disposition')
        disposition = disposition.match('filename\\*?=[\'"]?(?:UTF-\\d[\'"]*)?([^;\\r\\n"\']*)[\'"]?;?')
        let fileName = disposition[1]
        const hiddenElement = document.createElement('a')
        const url = window.URL || window.webkitURL
        const blob = url.createObjectURL(result.data)
        hiddenElement.href = blob
        hiddenElement.target = '_blank'
        hiddenElement.download = fileName
        hiddenElement.click()
        return { data: null }
      }
    }),
    uploadProductsStep1: builder.mutation<{rows: any[], file: string}, FormData>({
      query: (data) => ({
        url: `/upload-step-1`,
        method: 'POST',
        body: data,
        credentials: 'include',
      })
    }),
    uploadProductsStep2: builder.mutation<{all: number, updated: number, updated_zero: number, inserted: number, errors: any}, {map: {}, file: string, Brand: string, fromRow: number, createMode: string, updateMode: string, isPriceSaleCalc: boolean, markupPricePurchase: string, fixedPricePurchase: string, roundPriceSale: string}>({
      query: (data) => ({
        url: `/upload-step-2`,
        method: 'POST',
        body: data,
        credentials: 'include',
      })
    }),
    moveToCategory: builder.mutation<IProduct, { selected: any, params: any, newCat: string, mode: string }>({
      query: (body) => ({
        url: `/move-to-category`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
    }),
    moveApplicability: builder.mutation<IProduct, { selected: any, params: any, newApp: string, mode: string }>({
      query: (body) => ({
        url: `/move-applicability`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
    }),
    setImg: builder.mutation<{fileName: string}[], FormData>({
      query: (data) => ({
        url: `/set-img`,
        method: 'POST',
        body: data,
        credentials: 'include',
      })
    }),
    poolProducts: builder.mutation<void, { selected: any, isPool: boolean, params: any }>({
      query: (body) => ({
        url: `/pool-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['Products']
    }),
    onlineProducts: builder.mutation<void, { selected: any, isOnline: boolean, params: any }>({
      query: (body) => ({
        url: `/online-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['Products']
    }),
    updateReplacement: builder.mutation<void, any>({
      query: ({ id, body }) => ({
        url: `/update-replacement/${id}`,
        method: 'PUT',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['Products']
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductQuery,
  useLazyGetProductQuery,
  useAddProductMutation,
  useCopyProductMutation,
  useUpdateProductMutation,
  useDelProductMutation,
  useDelProductsMutation,
  useLazyGetProductsFiltersQuery,
  useGetProductsFiltersQuery,
  useDownloadXlsMutation,
  useUploadProductsStep1Mutation,
  useUploadProductsStep2Mutation,
  useMoveToCategoryMutation,
  useMoveApplicabilityMutation,
  useSetImgMutation,
  usePoolProductsMutation,
  useOnlineProductsMutation,
  useLazyGetSelectedProductsQuery,
  useUpdateReplacementMutation,
} = productApi;