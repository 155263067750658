import {useAppDispatch} from "./redux";
import {closeTooltip, showTooltip} from "../store/reducers/TooltipSlice";
import {TooltipPlacement} from "../components/ui/tooltips/TooltipPanel";


export const useTooltip = () => {
  const dispatch = useAppDispatch();
  const show = (text: string | any, target: any, placement?: TooltipPlacement) => {
    // остановился здесь! нужно добавить параметр placement
    console.log('useTooltip', target, text)
    const data = {
      text,
      target,
      placement,
    };
    dispatch(showTooltip(data))
    // return new Promise<any>((resolve, reject) => {
    //   const data = {
    //     component,
    //     resolve,
    //     reject
    //   };
    //   dispatch(showDialog(data));
    // })
  }
  const close = (id: string) => {
    dispatch(closeTooltip(id))
    // dispatch(unmountDialog(dialog));
  }

  // const accept = (id: any, accept: any) => {
  //   console.log('accept1', id)
  //   const dialog = dialogs.find(item => item.id === id);
  //   if(dialog) dialog.accept3 = accept;
  // }

  return {
    show,
    close
  }
}