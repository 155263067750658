import React from 'react'
import {useAppSelector} from "hooks/redux";
import DialogWrap, {EnumDialogWidth} from "./DialogWrap";
import {useDialog} from "hooks/dialog";

export type DialogServiceType = {
    accept: any
    decline: any
    acceptHandler: any
    declineHandler: any
    acceptButton: any
    declineButton: any
    setIcon: any
    setTitle: any
    setWidth: any
}

/*
Функции:
1. Вставлять любой компонент в окно
2. Окно генерирует свои события:
    - Ок - клик по кнопке Ок
    - Cancel - клик по кнопке Cancel
    - Cancel - клик по крестику в углу
    - Cancel - клик за пределами окна
3. Компонент внутри окна должен мочь сгенерировать Ок и Cancel
4. Во внутреннем компоненте можно отслеживать события Ок и Cancel (если возвращать false, то действие отменяется,
    можно возвращать Promise - окно должно ждать ответа и отображать на кнопках прелоадер)
5.
 */
const Dialogs: React.FC = () => {
    const { dialogs } = useAppSelector(state => state.dialogs);
    const dialog = useDialog();

    const deleteDialog = (dialog2: any) => {
        dialog.unmount(dialog2);
    }

    return (
        <>
            {dialogs?.length ? dialogs.map((dialog: any) => {

                  const dialogComponent = (accept: any, decline: any, acceptHandler: any, declineHandler: any, acceptButton: any, declineButton: any, setIcon: any, setTitle: any, setWidth: any) => React.createElement(
                    dialog.component.type,
                    {...dialog.component.props, _dialog: { accept, decline, acceptHandler, declineHandler, acceptButton, declineButton, setIcon, setTitle, setWidth }}
                  )

                return (
                  <DialogWrap
                    key={dialog.id}
                    unmount={deleteDialog}
                    dialog={dialog}
                    width={dialog.component.props?.width ? dialog.component.props?.width : EnumDialogWidth.MAXW2XL}
                  >

                      {dialogComponent}

                  </DialogWrap>
                )
            }
            ) : ''}
        </>
    )
};

export default Dialogs;