import {createAsyncThunk} from "@reduxjs/toolkit";
import {get, post} from "../../../api";
import {ISettingsABCP} from "../../../models/ISettingsABCP";
// import Timeout from "await-timeout";


export const loadClientOrdersFromABCP = createAsyncThunk(
    'abcp/loadClientOrdersFromABCP',
    async (_, thunkAPI) => {
      try {
        const response = await get(`${process.env.REACT_APP_URL_API}/abcp-order`)
        console.log('response result', response);
        return response;
      } catch (e: any) {
        return thunkAPI.rejectWithValue('Ошибка загрузки заказов из ABCP')
      }
    }
)

export const fetchABCPSettings = createAsyncThunk(
    'abcp/fetchABCPSettings',
    async (_, thunkAPI) => {
        try {
            const response: ISettingsABCP = await get(`${process.env.REACT_APP_URL_API}/abcp-settings`)
            return response;
        } catch (e: any) {
            return thunkAPI.rejectWithValue('Ошибка загрузки')
        }

    }
)

export const saveABCPSettings = createAsyncThunk(
    'abcp/saveABCPSettings',
    async ({ host, userLogin, userPsw, dateLoad, canLoad }: { host: string, userLogin: string, userPsw: string, dateLoad: number, canLoad: boolean }, thunkAPI) => {
        try {
            const response = await post(`${process.env.REACT_APP_URL_API}/abcp-settings`, {
                host,
                userLogin,
                userPsw,
                dateLoad,
                canLoad
            })
            return response;
        } catch (e: any) {
            return thunkAPI.rejectWithValue('Ошибка загрузки')
        }

    }
)

export const fetchResults = createAsyncThunk(
    'abcp/fetchResults',
    async (_, thunkAPI) => {
        try {
            // await Timeout.set(2000);
            // const response = await axios.get<IOrder[]>(${process.env.REACT_APP_URL_API}/abcp-order/get', {
            //   headers: {'Authorization': `Bearer ${state?.auth?.accessToken}`},
            // })
            const response = await get(`${process.env.REACT_APP_URL_API}/abcp-load-result`)
            return response;
        } catch (e: any) {
            return thunkAPI.rejectWithValue('Ошибка загрузки')
        }

    }
)
export const fetchResultShort = createAsyncThunk(
    'abcp/fetchResultShort',
    async (_, thunkAPI) => {
        try {
            const response = await get(`${process.env.REACT_APP_URL_API}/abcp-load-result/short`)
            return response;
        } catch (e: any) {
            return thunkAPI.rejectWithValue('Ошибка загрузки')
        }

    }
)

export const fetchOrders = createAsyncThunk(
    'abcp/fetchOrders',
    async (params: any = {}, thunkAPI) => {
        try {
            const { last } = params;
            // await Timeout.set(2000);
            let response;
            if(last) {
                response = await get(`${process.env.REACT_APP_URL_API}/abcp-order/get?last=${last}`)
            } else {
                response = await get(`${process.env.REACT_APP_URL_API}/abcp-order/get`)
            }

            console.log('response', response);
            return response;
        } catch (e: any) {
            return thunkAPI.rejectWithValue('Ошибка загрузки')
        }

    }
)

export const fetchOrder = createAsyncThunk(
    'abcp/fetchOrder',
    async (id:string|undefined, thunkAPI) => {
      try {
        // await Timeout.set(2000);
        // const response = await axios.get<IOrder[]>(${process.env.REACT_APP_URL_API}/abcp-order/get', {
        //   headers: {'Authorization': `Bearer ${state?.auth?.accessToken}`},
        // })
        const response = await get(`${process.env.REACT_APP_URL_API}/abcp-order/get/${id}`)
        return response;
      } catch (e: any) {
        return thunkAPI.rejectWithValue('Ошибка загрузки')
      }

    }
)