import * as React from 'react';
import {Row} from "@tanstack/react-table";

type Props = {

};

const CellPreloader: React.FC<Props> = (props: Props) => {
  const {  } = props;

  return (
    <div className="h-full py-2">
      {/*<div className={''}>*/}
        <div className={'w-full h-full inline-block bg-white bg-[length:1em_1em] text-[30px] animate-stripe'}
             {...{style: {
               // minHeight: 48,
                 // width: '100%',
                 // height: '12px',
                 // display: 'inline-block',
                 // backgroundColor: '#FFF',
                 backgroundImage: 'linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent)',
                 // backgroundImage: 'repeating-linear-gradient(-45deg, white 0 10%, black 10% 20%)',
                 // fontSize: '30px',
                 // backgroundSize: '1em 1em',
                 // boxSizing: 'border-box',
                 // animation: 'stripe 1s linear infinite',
               }}}
        ></div>
      {/*</div>*/}

    </div>
  );
};

export default CellPreloader;