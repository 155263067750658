import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';

interface ISlide {
  id?: string
  // title?: string;
  // text?: string
  // onOk?: () => (boolean | void)
  // onCancel?: () => void
  component?: any
  resolve?: any
  accept3?: any
  acceptHandler?: any
  data?: any
  buttonAccept?: any
}

interface SlidesState {
  slides: ISlide[]
}

const initialState: SlidesState = {
  slides: []
}

export const slideSlice = createSlice({
  name: 'slide',
  initialState,
  reducers: {
    showSlide(state, action: PayloadAction<ISlide>) {
      // state.slides.push({title: action.payload.title, text: action.payload.text });
      // console.log('showSlide', state.slides, action.payload)
      const newSlide = action.payload;
      newSlide.id = uuidv4();
      // console.log(222, newSlide)
      state.slides = [...state.slides, newSlide]
    },
    unmountSlide(state, action: PayloadAction<ISlide>) {
      const slides = state.slides.filter( slide => slide.id !== action.payload.id);
      state.slides = [...slides]
    },
    // setData(state, action: PayloadAction<any>) {
    //   const slide = state.slides.find(item => item.id === action.payload.id);
    //   const slides = state.slides.filter(item => item.id !== action.payload.id);
    //   if(slide) {
    //     slide.data = action.payload.data;
    //     state.slides = [...slides, slide]
    //   }
    // }
  },
  extraReducers: {}
})

export const { showSlide, unmountSlide } = slideSlice.actions;
export default slideSlice.reducer;