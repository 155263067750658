import React, {useEffect, useState} from 'react';
import Input from "../components/ui/Input/Input";
import Button from "../components/ui/buttons/Button";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {deleteCustomer, fetchCustomer} from "../store/reducers/customers/CustomerThunk";
import {useNavigate, useParams} from "react-router-dom";
import AlertSuccess from "../components/ui/AlertSuccess";
import {useDialog} from "../hooks/dialog";
import {useNotification} from "../hooks/notification";
import NotificationSuccess from "../components/ui/notifications/tmpl/NotificationSuccess";
import {useAddCustomerMutation} from "../api/customer-api";
import DialogQuestionDanger from "../components/ui/dialogs/tmpl/DialogQuestionDanger";

export const CustomerPageBreadcrumb: React.FC = (props: any) => {
  const { data, isLoading} = useAppSelector(state => state.customers.one)
  const { id } = props?.match?.params;
  const name = id == 'new' ? 'Создание' : (!isLoading && `${data?.lastName} ${data?.firstName}`)
  return (
    <>
      { name }
    </>
  )
}
export const CustomerPageTitle: React.FC = (props: any) => {
  const { data, isLoading } = useAppSelector(state => state.customers.one)
  const { id } = props?.match?.params;
  const name = id == 'new' ? 'Создание нового пользователя' : (!isLoading ? `${data?.lastName} ${data?.firstName}` : '')
  return (
    <>
      { name ? name : <span className={'opacity-25'}>Загрузка...</span> }
    </>
  )
}

const CustomerPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading, error} = useAppSelector( state => state.customers.one);
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const { id } = useParams();
  const [alert, setAlert] = useState('');
  const dialog = useDialog();
  const notify = useNotification();
  const [addCustomer] = useAddCustomerMutation();

  useEffect(() => {
    if(id != 'new') {
      dispatch(fetchCustomer({id})).then(
        (response) => {
          setfirstName(response.payload.firstName)
          setlastName(response.payload.lastName);
        }
      );
    }

  }, [])
  const save = () => {
    if(id === 'new') {
      addCustomer({ firstName: firstName, lastName: lastName }).unwrap()
      //   .then((a: any) => {
      //   console.log(444, a)
      //   setAlert('Клиент успешно создан');
      //   navigate(`/customers/${a.data._id}`);
      // })
    }
    // return new Promise<void>((resolve, reject) => {
    //   if(id === 'new') {
    //     dispatch(createCustomer({ firstName, lastName })).then(
    //       (result) => {
    //         console.log('444', result)
    //         if(result.meta.requestStatus === 'rejected') reject()
    //         setAlert('Клиент успешно создан')
    //         resolve()
    //         navigate(`/customers/${result.payload._id}`);
    //       },
    //     );
    //   } else {
    //     dispatch(updateCustomer({ id, firstName, lastName })).then(
    //       (result: any) => {
    //         if(result.meta.requestStatus === 'rejected') reject()
    //         resolve()
    //       },
    //     );
    //   }
    // })

  }
  const remove = () => {
      dialog.show(<DialogQuestionDanger title='Внимание' text='Вы действительно хотите удалить клиента?' />).then(
        () => {
          dispatch(deleteCustomer({id})).then(
            (result) => {
              if(result.meta.requestStatus !== 'rejected') {
                navigate(`/customers`);
                setTimeout(() => {
                  notify.show(<NotificationSuccess text={'Клиент удалён'} />)
                }, 300)
              }
            }
          )
        }
      )
  }
  return (
      <div className='max-w-sm'>
        {alert && (<AlertSuccess text={alert} />) }
        { error &&
           <div className='text-sm text-red-500 py-2 px-0'>
             {error}
           </div>
        }

        <div className="grid grid-cols-1 gap-3">
          <div className="">
            <Input label='Имя' value={firstName} onChange={(e) => setfirstName(e.target.value)}/>
          </div>
          <div className="">
            <Input label='Фамилия' value={lastName} onChange={(e) => setlastName(e.target.value)}/>
          </div>
        </div>
        <div className="mt-4 flex gap-4 items-center justify-end">
          <Button onClick={save} text='Сохранить' isLoading={false}/>
          <Button onClick={remove} text='Удалить' isLoading={false}/>
        </div>
      </div>
  );
};

export default CustomerPage;