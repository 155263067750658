import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {EnumDialogIcon} from "../DialogWrap";
import {ClientError} from "../../../../api/util/fetchBaseQueryWithReauth";
import AlertError from "../../AlertError";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";
import {DialogServiceType} from "../Dialogs";
import SelectFlexHeight from "../../select/selectFlexHeight/SelectFlexHeight";
import {useGetProductCategoriesQuery} from "../../../../api/productCategory";
import {useMoveToCategoryMutation} from "../../../../api/product";

type Props = {
  _dialog?: DialogServiceType
  selected: { all: boolean, count: number, include: any[], exclude: any[] }
  params: any
}

const useErrorValidation = (error1: FetchBaseQueryError | SerializedError | undefined, error2?: FetchBaseQueryError | SerializedError | undefined): {} | null => {
  const memoizedError = useMemo(() => {
    const error = error1 || error2
    if (error !== undefined && 'status' in error) {
      try {
        const result: { message: string; details: { property: string; message: string; }[] } = error.data as any
        return result.details
      } catch(e) {
        console.log(e)
        return { message: 'Unknown error', details: [] };
      }

    }

    return null;
  }, [error1, error2]);

  return memoizedError;
};

const DialogCategorySelection: React.FC<Props> = ({ _dialog , selected, params}) => {
  const {data: productCategories} = useGetProductCategoriesQuery()
  const [moveToCategory, {isSuccess, isError, isLoading, error}] = useMoveToCategoryMutation()
  const [categorySelected, setCategorySelected] = useState<any>();
  const [mode, setMode] = useState<string>('')
  const errorValidation: any = useErrorValidation(error);
  const onOk = useCallback((): Promise<void> => {
    console.log('Ok', categorySelected)
    return new Promise((resolve, reject) => {
      moveToCategory({ selected, params, newCat: categorySelected?._id, mode }).then((result: any) => {
        if(result?.error) {
          reject() // окно не закрывается, ошибка на кнопке
        } else {
          resolve()
          _dialog?.accept()
        }

      })

    })
  }, [categorySelected, mode])

  useEffect(() => {
    // console.log('onOk change', tenantName)

    _dialog?.acceptHandler(onOk)
    // _dialog?.declineHandler(onCancel)

    // acceptButton() // стандартная кнопка
    // acceptButton('Создать') // стандартная кнопка с нашим названием
    // acceptButton(<Button text={'Создать3'} />) // пользовательская кнопка: можно использовать все props Button, кроме onClick
    // acceptButton(false) // без кнопки

    if(mode === 'add') {
      _dialog?.acceptButton('Добавить')
    }

    if(mode === 'move') {
      _dialog?.acceptButton('Переместить')
    }

    if(mode === '') {
      _dialog?.acceptButton(false) // стандартная кнопка с нашим названием
    }

    _dialog?.setTitle('Добавление/перемещение выбранных товаров в категорию')
    _dialog?.setIcon(EnumDialogIcon.FORM)
  }, [onOk])

  const isClientError = (error: any): error is ClientError => 'data' in error


  const treeToList2 = (tree: any, parentName?: string) => {
    const a = tree.map((item: any) => {
      const {subCategories, ...rest} = item;
      if(parentName) {
        rest.categoryName_rus = `${parentName} - ${rest.categoryName_rus}`
      }

      // исключаем из списка категории, которые лежат ниже текущей - idProductCategory
      // if(subCategories.length > 0 && item._id === idProductCategory) {
      //   return []
      // }
      // исключаем из списка текущую категорию - idProductCategory
      // if(item._id === idProductCategory) {
      //   return []
      // }

      if(subCategories.length > 0) {
        // return [...treeToList2(subCategories, rest.categoryName_rus)] // не отображаем родительские категории, только конечные
        return [rest, ...treeToList2(subCategories, rest.categoryName_rus)]
      } else {
        return [rest, ...treeToList2(subCategories, rest.categoryName_rus)]
      }
    })

    // добавить пункт Все в начало: не используется - нужно очищать select при выборе Все
    if(!parentName) {
      a.unshift([{_id: '', categoryName_rus: 'Все'}])
    }

    return a.flat()
  }

  const [cats, setCats] = useState()
  useEffect(() => {
    if(productCategories?.data) {
      const list = treeToList2(productCategories.data).map((item: any) => ({option: item}))
      setCats(list)
    }
  }, [productCategories]);

  const plans = [
    { id: 'add', name: 'Добавить', description: 'Товары дополнительно добавятся в выбранную категорию' },
    { id: 'move', name: 'Переместить', description: 'Товары переместятся из текущих категорий в выбранную' },
  ]

  return (
    <div className={'w-full'}>
      <div className={'mb-3'}>
        {Array.isArray(errorValidation) && errorValidation.filter((e: any) => e.property === 'mode').length ?
          <AlertError text={errorValidation.filter((e: any) => e.property === 'mode').map((item: any) => item.message)} />
          :
          error && isClientError(error) ? (error.status != 400) && (<AlertError text={error.data.message} />) : ''
        }
      </div>

      <div className={'mt-7'}>
        <SelectFlexHeight
          label='Выберите категорию'
          data={cats}
          optionKey='categoryName_rus'
          onChange={setCategorySelected}
          initStatus={'fulfilled'}
          isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'newCat') : false}
          errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'newCat')?.message : ''}
        />

        <fieldset className={'mt-7 mb-10'}>
          <legend className="sr-only">Plan</legend>
          <div className="space-y-5">
            {plans.map((plan) => (
              <div key={plan.id} className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id={plan.id}
                    aria-describedby={`${plan.id}-description`}
                    name="mode"
                    type="radio"
                    // defaultChecked={plan.id === 'small'}
                    className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600 cursor-pointer"
                    onChange={(e) => setMode(e.target.id)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor={plan.id} className="font-medium text-gray-900 cursor-pointer">
                    {plan.name}
                  </label>
                  <p id={`${plan.id}-description`} className="text-gray-500">
                    {plan.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>

    </div>
  );
};

export default DialogCategorySelection;