import React, {Fragment, useRef, useState} from 'react';
import { useRoutes } from "react-router-dom";
import {useAppSelector} from "../hooks/redux";
import RouterProtected from "../pages/routerProtected";
import RouterPublic from "../pages/routerPublic";
import {useTooltip} from "../hooks/tootip";

const AppRouter = () => {
  // localStorage.getItem('accessToken', user.data.accessToken);
  const user = useAppSelector((state) => state.auth)
  let auth = false;
  if(user?.data.userId) {
      auth = true;
  }
  let routes: any = auth ? RouterProtected : RouterPublic
  let element = useRoutes(routes);

  const target = useRef<any>()
  const [on, setOn] = useState(false);
  const tooltip = useTooltip();

  return (
        <Fragment>
          {/*<div ref={target}*/}
          {/*     onMouseEnter={ (event: any) => { tooltip.show('sjdf kjh;lksjdl;fkjhlkjhlkjhlkjhlkjhk asd;lkfja;ldskjf ;alkd ;lakjd ;lkajd;lfjkadsl j;lakjd f;alkjd f;lakjsd f;lakjd ;flakjd ;flkaj;lkdjf ;alksdjf ;alkdjsf;kh jj', event.currentTarget) } }*/}
          {/*     onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }*/}
          {/*     className='border w-[100px] m-40'>111</div>*/}
          { element }
        </Fragment>
    );
};

export default AppRouter;