import * as React from 'react';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useContext} from "react";
import {AppContext} from "../../App";

type Props = {
  children?: React.ReactNode
  param?: string
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const SettingsPage: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  const {children, param: name = 'по-умолчанию'} = props;
  const { pathname } = useLocation();
  const {settings} = useContext(AppContext)

  return (
    <>
      <div className="h-full flex w-full items-start gap-x-12">
        <aside className="sticky top-20 hidden min-h-[30rem] w-80 shrink-0 p-3 lg:block bg-white drop-shadow-lg rounded-lg space-y-5">
          <div>
            <div className='flex space-x-3 items-center text-slate-400'>
              <div>Категории</div>
            </div>
            <div className='mt-3'>
              <div className='text-slate-700'>
                <div className='group/category flex items-center space-x-3'>
                  <NavLink to={'/settings/tenant'} className={classNames('/settings/tenant' === pathname ? 'text-red-500': '')}>Организация</NavLink>
                </div>
                {settings?.view1 && <div className='group/category flex items-center space-x-3'>
                  <NavLink to={'/settings/abcp'} className={classNames('/settings/abcp' === pathname ? 'text-red-500': '')} >ABCP</NavLink>
                </div>}
              </div>
            </div>
          </div>
        </aside>


        {children}
      </div>
    </>
  );
};

export default SettingsPage;