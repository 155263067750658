import * as React from 'react';
import {Fragment, useEffect, useRef, useState} from 'react';
import {Dialog, Disclosure, Menu, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {NavLink, useLocation} from "react-router-dom";
import {
  AdjustmentsHorizontalIcon, ArrowRightOnRectangleIcon, ArrowTopRightOnSquareIcon,
  BookOpenIcon,
  BuildingStorefrontIcon,
  CalculatorIcon,
  ChatBubbleLeftEllipsisIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Cog8ToothIcon,
  CubeIcon,
  DocumentTextIcon,
  HomeModernIcon,
  PlusIcon,
  PresentationChartLineIcon,
  RectangleGroupIcon, ShoppingBagIcon,
  TruckIcon,
  UsersIcon
} from "@heroicons/react/20/solid";
import {useTooltip} from "../hooks/tootip";
import {useAppSelector} from "../hooks/redux";

type Props = {
  sidebarOpen?: boolean
  onClose?: (sidebarOpen: boolean) => void
  sidebarMini: boolean
  onMini?: (sidebarMini: boolean) => void

};

const navigation1 = [
  // { name: 'Аналитика', href: '/', icon: RectangleGroupIcon, current: false },
  // { name: 'Клиенты', href: '/customers', icon: UsersIcon, chevron: true, count: true, current: true },
  { name: 'Товары', href: '/products', icon: BuildingStorefrontIcon, filter: true, current: true },
  { name: 'Бренды', href: '/brands', icon: ShoppingBagIcon, filter: true, current: true },
  { name: 'Сотрудники', href: '/employees', icon: UsersIcon, filter: true, current: true },
  // { name: 'Склады', href: '/warehouses', icon: CubeIcon, chevron: true, current: true },
  // { name: 'Поставщики', href: '/suppliers', icon: TruckIcon, current: true },
  // { name: 'Производители', href: '/brands', icon: HomeModernIcon, current: true },

  // { name: 'Заказы клиентов', href: '/doc-customer-orders', icon: DocumentIcon, current: true },
  // { name: 'Заказы поставщикам', href: '/doc-orders-to-suppliers', icon: DocumentIcon, current: true },
  // { name: 'Поступление товаров', href: '/doc-products-receipt', icon: DocumentIcon, current: true },
  // { name: 'Размещение по ячейкам', href: '/doc-cell-placement', icon: DocumentIcon, current: true },
  // { name: 'Реализация товара', href: '/doc-release-products', icon: DocumentIcon, current: true },
  // { name: 'Отбор из ячеек', href: '/doc-selections-from-cells', icon: DocumentIcon, current: true },
  // { name: 'Отказы поставщиков', href: '/doc-refusals-suppliers', icon: DocumentIcon, current: true },
  // { name: 'Отказы клиентов', href: '/doc-refusals-customers', icon: DocumentIcon, current: true },
  // { name: 'Возвраты поставщикам', href: '/doc-returns-to-suppliers', icon: DocumentIcon, current: true },
  // { name: 'Возвраты от клиентов', href: '/doc-returns-from-customers', icon: DocumentIcon, current: true },
  // { name: 'Арендаторы', href: '/tenants', icon: FolderIcon, current: true },
  // { name: 'Сотрудники', href: '/employees', icon: FolderIcon, current: true },
  // { name: 'Синонимы брендов', href: '/brand-synonyms', icon: FolderIcon, current: true },
  // { name: 'Ячейки хранения', href: '/warehouse-cells', icon: FolderIcon, current: true },
]

const navigation2 = [
  { name: 'Документы', href: '/doc-customer-orders', icon: DocumentTextIcon, current: false, chevron: true },
  { name: 'Отчеты', href: '/doc-orders-to-suppliers', icon: PresentationChartLineIcon, current: false, chevron: true },
  { name: 'Финансы', href: '/doc-products-receipt', icon: CalculatorIcon, current: false, chevron: true },
  { name: 'Настройки', href: '/', icon: Cog8ToothIcon, current: false, chevron: true,
    children: [
      { name: 'Об организации', href: '/warehouse-cells' },
      { name: 'Оплата', href: '/brand-synonyms', badge: true },
      { name: 'Магазины', href: '/tenants', plus: true },
      { name: 'Сотрудники', href: '/employees', plus: true },
    ],
  },
]

const navigation3 = [
  { name: 'Тех. поддержка', href: '/doc-returns-from-customers', icon: ChatBubbleLeftEllipsisIcon, current: false, disable: true },
  { name: 'Справка', href: '/doc-returns-to-suppliers', icon: BookOpenIcon, current: false, disable: false },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
const userNavigation = [
  // { name: 'Профиль', href: '/profile' },
  { name: 'Настройки', href: '/settings/tenant' },
  { name: `Выйти`, href: '/logout', icon: ArrowRightOnRectangleIcon },
]
const Sidebar: React.FC<Props> = (props: Props) => {
  const { sidebarOpen = false, onClose = ()=>{}, sidebarMini = false, onMini = ()=>{} } = props;
  const { pathname } = useLocation();
  const [pop, setPop] = useState('');
  const target = useRef<any>()
  const tooltip = useTooltip();
  const user = useAppSelector((state) => state.auth)
  const [tenant, setTenant] = useState(user.data.Tenant)
  const [link, setLink] = useState({ href: '', name: '' })

  // const popover = usePopover();


  useEffect(() => {
    let href: string = process.env.REACT_APP_URL_PUBLIC ?? ''
    href = href.replace('#','id'+tenant.publicId)
    let domain: string = process.env.REACT_APP_URL_PUBLIC_DOMAIN ?? ''
    domain = 'id'+tenant.publicId+'.'+domain
    // domain = 'Публичная ссылка'
    setLink({ href, name: domain })
  }, [tenant])


  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => onClose(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src={`${process.env.REACT_APP_URL}/5@2x.png`}
                    alt="vtovare.ru"
                  />
                  <div className={'text-gray-300 ml-3'}>{user.data.login}</div>
                </div>

                <div className="mt-5 h-0 flex-1 overflow-y-auto">

                  <nav className="space-y-1 px-2">
                    {navigation1.map((item: any) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) => {
                          return classNames(
                            isActive && item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )}
                        }
                        onClick={() => onClose(false)}
                        aria-current={item.current ? 'page' : undefined}

                      >
                        <item.icon
                          className={classNames(
                            item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>

                  <nav className="hidden mt-5 h-0 flex-initial space-y-3 px-4 pt-0 pb-3">
                    {navigation1.map((item, index) => {
                      let current = item.href === pathname;
                      return (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) => {
                            return classNames(
                              current
                                ? "bg-sky-50 text-sky-600 ring-2 ring-[#E0F2FE] ring-inset"
                                : 'text-slate-600 hover:bg-sky-50 hover:text-sky-600',
                              'group flex justify-between px-2 py-2 font-normal text-base rounded-md'
                            )
                          }
                          }
                          onClick={() => onClose(false)}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <div className='flex items-center'>
                            <item.icon
                              className={classNames(
                                current ? 'text-sky-600' : 'text-slate-600 group-hover:text-sky-600',
                                'mr-3.5 shrink-0 h-4 w-4'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </div>
                          {/*<div className='flex items-center space-x-3'>*/}
                          {/*  {!item.count || <div className='rounded-full bg-emerald-500 px-2 text-xs leading-5 text-white'>+7</div> }*/}
                          {/*  {!item.filter || <div className='h-5 w-5 text-slate-600'>{<AdjustmentsHorizontalIcon />}</div> }*/}
                          {/*  {!item.chevron || <div className='h-5 w-5'>{<ChevronRightIcon />}</div> }*/}
                          {/*</div>*/}


                        </NavLink>
                      )}
                    )}
                  </nav>

                  <nav className="space-y-1 px-2 mt-6">
                    {userNavigation.map((item) => (
                      <div key={item.name}>

                        <NavLink
                          key={item.href}
                          to={item.href}
                          className={classNames(
                            item.href == pathname ?
                              'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )}
                          onClick={() => onClose(false)}
                        >
                          {item.name}

                          {item?.icon ? <item.icon
                            className={classNames(
                              item.href == pathname ? 'text-white' : 'text-gray-300',
                              'ml-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          /> : ''}

                        </NavLink>

                      </div>
                    ))}
                  </nav>


                  {/*Public link*/}
                  <div className='space-y-1 px-4 mt-12 text-gray-300' >
                    <div className={'text-xs'}>Публичная ссылка</div>
                    <a className={'flex items-center gap-3'} href={link.href} target='_blank'>
                      {link.name}
                      <ArrowTopRightOnSquareIcon className={'w-5 h-5'} />
                    </a>
                  </div>


                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden'>
        {sidebarMini ?
          <div className="hidden md:fixed md:inset-y-0 md:flex md:w-16 md:flex-col">
            <div className="flex min-h-0 flex-1 flex-col bg-white drop-shadow-lg">
              <div className="flex h-16 flex-shrink-0 items-center justify-between bg-white pl-2 border-b">
                <NavLink key='toStart' to="/" className='flex'>
                  <img
                    className="h-8 w-8"
                    src={`${process.env.REACT_APP_URL}/logo-vtovare.jpg`}
                    alt=""
                  />
                </NavLink>
                <div className='border-y border-l w-6 h-8 flex items-center justify-start rounded-l-md hover:border-slate-300 cursor-pointer' onClick={() => onMini(false)}>
                  <ChevronRightIcon className='w-5 h-5 text-slate-500' />
                </div>
              </div>
              <div className="flex flex-1 flex-col items-center overflow-y-auto scrollbar-none">
                <div className='flex-shrink-0 w-10 pt-4 pb-2 uppercase text-slate-300 text-xs text-ellipsis overflow-x-hidden'>меню</div>
                <nav className="space-y-3 justify-center pt-0 pb-3" ref={target}>
                  {navigation1.map((item: any, index) => {
                    let current = item.href === pathname;
                    return (
                      <Fragment key={item.href}>
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) => {
                            return classNames(
                              current
                                ? "bg-sky-50 text-sky-600 ring-2 ring-[#E0F2FE] ring-inset"
                                : 'text-slate-600 hover:bg-sky-50 hover:text-sky-600',
                              'w-10 h-10 group flex justify-center item-center font-normal text-base rounded-md relative'
                            )}
                          }
                          aria-current={item.current ? 'page' : undefined}
                          // onMouseEnter={ (event: any) => { tooltip.show((<span className="text-red-500">{item.name}</span>), event.currentTarget) } }
                          onMouseEnter={ (event: any) => { tooltip.show(item.name, event.currentTarget) } }
                          onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
                          onClick={ (event: any) => { tooltip.close(event.currentTarget) } }
                        >
                          <div className='flex items-center'>
                            <item.icon
                              className={classNames(
                                current ? 'text-sky-600' : 'text-slate-600 group-hover:text-sky-600',
                                'shrink-0 h-4 w-4'
                              )}
                              aria-hidden="true"
                            />
                          </div>
                        </NavLink>

                      </Fragment>


                    )}
                  )}
                </nav>

                <div className='flex-shrink-0 w-10 pt-4 pb-2 uppercase text-slate-300 text-xs text-ellipsis overflow-x-hidden'>Организация</div>
                <nav className="space-y-3 justify-center pt-0 pb-3">
                  {navigation2.map((item, index) => {
                    let current = item.href === pathname;
                    let a: any = null
                    return (
                      !item.children ? (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) => {
                            return classNames(
                              current
                                ? "bg-sky-50 text-sky-600 ring-2 ring-[#E0F2FE] ring-inset"
                                : 'text-slate-600 hover:bg-sky-50 hover:text-sky-600',
                              'w-10 h-10 group flex justify-center item-center font-normal text-base rounded-md'
                            )
                          }
                          }
                          aria-current={item.current ? 'page' : undefined}
                          onMouseEnter={ (event: any) => { tooltip.show(item.name, event.currentTarget) } }
                          onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
                          onClick={ (event: any) => { tooltip.close(event.currentTarget) } }
                        >
                          <div className='flex items-center'>
                            <item.icon
                              className={classNames(
                                current ? 'text-sky-600' : 'text-slate-600 group-hover:text-sky-600',
                                'shrink-0 h-4 w-4'
                              )}
                              aria-hidden="true"
                            />

                          </div>


                        </NavLink>
                      ) : (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) => {
                            return classNames(
                              current
                                ? "bg-sky-50 text-sky-600 ring-2 ring-[#E0F2FE] ring-inset"
                                : 'text-slate-600 hover:bg-sky-50 hover:text-sky-600',
                              'w-10 h-10 group flex justify-center item-center font-normal text-base rounded-md'
                            )
                          }
                          }
                          aria-current={item.current ? 'page' : undefined}
                          // onMouseEnter={ (event: any) => {
                          //   const ct = event.currentTarget
                          //   const ctc = event.currentTarget.getBoundingClientRect()
                            // popover.show(<SidebarSubmenu navigation={item.children} pathname={pathname} targetElement={ct} />, event.currentTarget).then((ref)=>{
                            //   a = ref;
                            //
                            //   const c = ref?.getBoundingClientRect();
                            //   const handler = ({ clientX, clientY }: any) => {
                            //     console.log(555, clientX, clientY, c.y, c.height)
                            //     // popover слева
                            //     const leftSide = clientX < (ctc.x + ctc.width);
                            //     if(leftSide) { // сторона кнопки (триггера)
                            //       if(clientX < ctc.x) {
                            //         popover.close(ct)
                            //         document.removeEventListener('mousemove', handler)
                            //       }
                            //       if(clientY > (ctc.y + ctc.height) || clientY < ctc.y) {
                            //         popover.close(ct)
                            //         document.removeEventListener('mousemove', handler)
                            //       }
                            //     } else { // сторона popover
                            //       if(clientX > (c.x + c.width)) {
                            //         popover.close(ct)
                            //         document.removeEventListener('mousemove', handler)
                            //       }
                            //       if( clientY > (c.y + c.height) || clientY < c.y ) {
                            //         popover.close(ct)
                            //         document.removeEventListener('mousemove', handler)
                            //       }
                            //     }
                            //   }
                            //
                            //   document.addEventListener('mousemove', handler)
                            //
                            // }) } }
                          // onMouseLeave={ (event: any) => {
                          //
                          //   console.log(1, event.target, event.currentTarget, event.relatedTarget, a?.current);
                          //   // if(event.relatedTarget !== a?.current) {
                          //   //   popover.close(event.currentTarget);
                          //   // }
                          //
                          // } }
                          // onClick={ (event: any) => { popover.close(event.currentTarget) } }
                        >
                          <div className='flex items-center'>
                            <item.icon
                              className={classNames(
                                current ? 'text-sky-600' : 'text-slate-600 group-hover:text-sky-600',
                                'shrink-0 h-4 w-4'
                              )}
                              aria-hidden="true"
                            />

                          </div>


                        </NavLink>
                      )

                    )}
                  )}
                </nav>

                <div className='flex-shrink-0 w-10 pt-4 pb-2 uppercase text-slate-300 text-xs text-ellipsis overflow-x-hidden'>Помощь</div>
                <nav className="space-y-3 justify-center pt-0 pb-3">
                  {navigation3.map((item, index) => {
                    let current = item.href === pathname;
                    return (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) => {
                          return classNames(
                            current
                              ? "bg-sky-50 text-sky-600 ring-2 ring-[#E0F2FE] ring-inset"
                              : 'text-slate-600 hover:bg-sky-50 hover:text-sky-600',
                            'w-10 h-10 group flex justify-center item-center font-normal text-base rounded-md'
                          )
                        }
                        }
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <div className='flex items-center'>
                          <item.icon
                            className={classNames(
                              current ? 'text-sky-600' : 'text-slate-600 group-hover:text-sky-600',
                              'shrink-0 h-4 w-4'
                            )}
                            aria-hidden="true"
                          />

                        </div>


                      </NavLink>
                    )}
                  )}
                </nav>
              </div>
            </div>
          </div>
          :
          <div className="hidden md:fixed md:inset-y-0 md:flex md:w-80 md:flex-col">
            <div className="flex min-h-0 flex-1 flex-col bg-white drop-shadow-lg">
              <div className="flex h-16 flex-shrink-0 items-center justify-between bg-white pl-4 border-b">
                <NavLink key='toStart' to="/" className='flex'>
                  <img
                    className="h-8 w-8"
                    src={`${process.env.REACT_APP_URL}/logo-vtovare.jpg`}
                    alt=""
                  />
                  <span className='text-sky-500 text-3xl ml-0.5'>tovare</span>
                </NavLink>
                <div className='border-y border-l w-6 h-8 flex items-center justify-start rounded-l-md hover:border-slate-300 cursor-pointer' onClick={() => onMini(true)}>
                  <ChevronLeftIcon className='w-5 h-5 text-slate-500' />
                </div>
              </div>
              <div className="flex flex-1 flex-col overflow-y-auto">
                <div className='px-4 pt-4 pb-2 uppercase text-slate-300 text-xs'>Главное меню</div>
                <nav className="flex-initial space-y-3 px-4 pt-0 pb-3">
                  {navigation1.map((item, index) => {
                    let current = item.href === pathname;
                    return (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) => {
                          return classNames(
                            current
                              ? "bg-sky-50 text-sky-600 ring-2 ring-[#E0F2FE] ring-inset"
                              : 'text-slate-600 hover:bg-sky-50 hover:text-sky-600',
                            'group flex justify-between px-2 py-2 font-normal text-base rounded-md'
                          )
                        }
                        }
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <div className='flex items-center'>
                          <item.icon
                            className={classNames(
                              current ? 'text-sky-600' : 'text-slate-600 group-hover:text-sky-600',
                              'mr-3.5 shrink-0 h-4 w-4'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>
                        {/*<div className='flex items-center space-x-3'>*/}
                        {/*  {!item.count || <div className='rounded-full bg-emerald-500 px-2 text-xs leading-5 text-white'>+7</div> }*/}
                        {/*  {!item.filter || <div className='h-5 w-5 text-slate-600'>{<AdjustmentsHorizontalIcon />}</div> }*/}
                        {/*  {!item.chevron || <div className='h-5 w-5'>{<ChevronRightIcon />}</div> }*/}
                        {/*</div>*/}


                      </NavLink>
                    )}
                  )}
                </nav>

                <div className='px-4 pt-4 pb-2 uppercase text-slate-300 text-xs'>Организация</div>
                <nav className="flex-initial space-y-3 px-4 pt-0 pb-3">

                  {navigation2.map((item: any, index) => {
                    let current = item.href === pathname;
                    return (
                      !item.children ? (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) => {
                            return classNames(
                              current
                                ? "bg-sky-50 text-sky-600 ring-2 ring-[#E0F2FE] ring-inset"
                                : 'text-slate-600 hover:bg-sky-50 hover:text-sky-600',
                              'group flex justify-between px-2 py-2 text-base rounded-md'
                            )
                          }
                          }
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <div className='flex items-center'>
                            <item.icon
                              className={classNames(
                                current ? 'text-sky-600' : 'text-slate-600 group-hover:text-sky-600',
                                'mr-3.5 shrink-0 h-4 w-4'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </div>
                          <div className='flex items-center space-x-3'>
                            {!item.count || <div className='rounded-full bg-emerald-500 px-2 text-xs leading-5 text-white'>+7</div> }

                            {!item.filter || <AdjustmentsHorizontalIcon className='h-4 w-4' /> }
                            {!item.chevron || <ChevronRightIcon className='h-5 w-5' /> }

                          </div>
                        </NavLink> ) : (




                        <>
                          <Disclosure as="div" key={item.name} className="space-y-1">
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    item.children.find((subItem: any) => subItem.href === pathname)
                                      ? 'text-sky-600'
                                      : 'text-slate-600',
                                    'group w-full flex items-center px-2 py-2 text-left rounded-md hover:bg-sky-50 hover:text-sky-600'
                                  )}
                                >

                                  <item.icon
                                    className={classNames(
                                      item.children.find((subItem: any) => subItem.href === pathname)
                                        ? 'text-sky-600'
                                        : 'text-slate-600',
                                      'mr-3.5 shrink-0 h-4 w-4 group-hover:text-sky-600'
                                    )}
                                    aria-hidden="true"
                                  />

                                  <span className="flex-1">{item.name}</span>

                                  <ChevronRightIcon
                                    className={classNames(
                                      open ? 'rotate-90' : '',
                                      'ml-3 h-5 w-5 flex-shrink-0 text-slate-600'
                                    )}
                                    viewBox="0 0 20 20"
                                    aria-hidden="true" />
                                </Disclosure.Button>

                                <Disclosure.Panel className="space-y-0.5 border-l border-slate-300 ml-4">
                                  {item.children.map((subItem: any) => (
                                    <Disclosure.Button
                                      key={subItem.name}
                                      as="div"
                                      // href={subItem.href}
                                      className="ml-4"
                                    >
                                      <NavLink
                                        key={subItem.name}
                                        to={subItem.href}
                                        className={({ isActive }) => {
                                          return classNames(
                                            subItem.href === pathname
                                              ? "bg-sky-50 text-sky-600"
                                              : 'text-slate-600',
                                            'group flex space-x-3 w-full items-center rounded-md py-2 pl-2 pr-2 hover:bg-sky-50 hover:text-sky-600'
                                          )
                                        }
                                        }
                                        aria-current={item.current ? 'page' : undefined}
                                      >
                                        <span className='flex-1'>{subItem.name}</span>

                                        {!subItem.badge || <div className='rounded-full bg-orange-500 px-2 text-xs leading-5 text-white'>Free</div> }
                                        {!subItem.plus || <PlusIcon className='h-5 w-5 flex-shrink-0 text-slate-600' /> }

                                      </NavLink>

                                    </Disclosure.Button>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </>

                      )

                    )}
                  )}

                </nav>

                <div className='px-4 pt-4 pb-2 uppercase text-slate-300 text-xs'>Помощь</div>
                <nav className="flex-initial space-y-3 px-4 pt-0 pb-3">
                  {navigation3.map((item: any, index) => {
                    let current = item.href === pathname;
                    return (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) => {
                          if(item.disable) {
                            return 'bg-slate-50 text-slate-400 group flex justify-between px-2 py-2 font-normal text-base rounded-md pointer-events-none'
                          }
                          return classNames(
                            current
                              ? "bg-sky-50 text-sky-600 ring-2 ring-[#E0F2FE] ring-inset"
                              : 'text-slate-600 hover:bg-sky-50 hover:text-sky-600',
                            'group flex justify-between px-2 py-2 font-normal text-base rounded-md'
                          )
                        }
                        }
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <div className='flex items-center'>
                          {item.disable ?
                            <item.icon
                              className={'text-slate-400 mr-3.5 shrink-0 h-4 w-4'}
                              aria-hidden="true"
                            />
                            :
                            <item.icon
                              className={classNames(
                                current ? 'text-sky-600' : 'text-slate-600 group-hover:text-sky-600',
                                'mr-3.5 shrink-0 h-4 w-4'
                              )}
                              aria-hidden="true"
                            />
                          }

                          {item.name}
                        </div>
                        <div className='flex items-center space-x-3'>
                          {!item.count || <div className='rounded-full bg-emerald-500 px-2 text-xs leading-5 text-white '>+7</div> }

                          {!item.filter || <div className='h-5 w-5'>{<AdjustmentsHorizontalIcon />}</div> }
                          {!item.chevron || <div className='h-5 w-5'>{<ChevronRightIcon />}</div> }

                        </div>


                      </NavLink>
                    )}
                  )}
                </nav>
              </div>
            </div>
          </div>
        }
      </div>

    </>
  );
};

export default Sidebar;