import React, {useContext, useEffect, useState} from 'react'
import './../index.css'
import {createColumnHelper,} from '@tanstack/react-table'
import {IOrder} from "../../models/IOrder";
import {useNavigate} from "react-router-dom";
import {useGetCustomerOrders} from "../../hooks/useGetCustomerOrders";
import {MainContext} from "../../components/Main";
import TableConstantMultiColumns from "../../components/ui/table/tmpl/TableConstantMultiColumns";
import {ColumnsWidth} from "../../components/ui/table/Table";
import {fetchResultShort, loadClientOrdersFromABCP} from "../../store/reducers/abcp/AbcpThunk";
import {fetchOrders} from "../../store/reducers/UserSlice";
import {useAppDispatch} from "../../hooks/redux";
import {
    useGetOrdersFromABCPMutation,
    useLazyGetABCPOrdersQuery,
    useLazyGetLoadOrdersResultQuery,
} from "../../api/abcp";
import {useGetCursor} from "../../hooks/useGetCursor";
import Button from "../../components/ui/buttons/Button";
import {DateTime} from "luxon";


const columnHelper = createColumnHelper<IOrder>()
const columns = [
    columnHelper.accessor('_id', {
        cell: info => info.getValue(),
        size: 270,
    }),
    columnHelper.accessor(row => row.number, {
        id: 'number',
        cell: info => <i>{info.getValue()}</i>,
        header: () => <span>Номер</span>,
        size: 200,
        minSize: 100,
        maxSize: 200,
        meta: { width: ColumnsWidth.MAX_CONTENT }
    }),
    columnHelper.accessor('date', {
        header: () => 'Дата',
        cell: info => info.renderValue(),
        minSize: 50,
        size: 200,
    }),
    columnHelper.accessor('userName', {
        header: () => <span>Пользователь</span>,
        // size: 200,
        meta: { width: ColumnsWidth.STRETCH, },
    }),
    columnHelper.accessor('sum', {
        header: 'Сумма',
        cell: info => `${info.getValue()} ₽`,
        size: 200,
        // maxSize: 300,
        // minSize: 100
    }),
]


const ABCPOrdersPage: React.FC = () => {
    const navigate = useNavigate();
    // const {data: allRows, isLoading: isFetchingNextPage, hasNextPage} = useAppSelector(state => state.abcp.orders)
    const dispatch = useAppDispatch();
    // const {data:result, isLoading:isLoadShort} = useAppSelector(state => state.abcp.moreResultShort)
    // const {error:errorABCPLoad, isLoading:isLoadABCP} = useAppSelector(state => state.abcp.loadResult)

    const [sort, setSort] = useState<string>('date:desc')
    const [params, setParams] = useState<any>()
    const [sortOption, setSortOption] = useState<any>([
        {option: {_id: 'id:desc', value: 'По дате создания'}},
        {option: {_id: 'price_sale:asc', value: 'Дешевле'}},
        {option: {_id: 'price_sale:desc', value: 'Дороже'}},
        {option: {_id: 'qty:asc', value: 'Наличие мало'}},
        {option: {_id: 'qty:desc', value: 'Наличие много'}},
        {option: {_id: 'title_rus:asc', value: 'Наименование (А-Я)'}},
        {option: {_id: 'title_rus:desc', value: 'Наименование (Я-А)'}},
    ])

    // const {appRef} = useContext(AppContext)
    // const [getCustomerOrders, {data: orders, isFetching, isLoading, isError: isError3, error: error3}] = useGetCustomerOrders()
    const [getABCPOrder, obj, lastArgs] = useLazyGetABCPOrdersQuery()
    const [getCustomerOrders, {data: orders, isFetching, isLoading, isError: isError3, error: error3}] = useGetCursor(getABCPOrder, obj)

    const [getOrdersFromABCPServer, {isLoading:isLoadingFromABCP}] = useGetOrdersFromABCPMutation()
    const [getLoadOrdersResult, {data:result, isLoading:isLoadShort}] = useLazyGetLoadOrdersResultQuery()

    const getCustomerOrderGap = (params: any) => {
        // if(filterText) params.filterText = filterText
        // if(cat) params.cat = cat;
        console.log('gap', params)
        // setParams(params)
        return getCustomerOrders({ ...params, sort })
    }

    useEffect(() => {
        console.log('setParams2', params, { sort })
        setParams({ sort })
        getLoadOrdersResult({size: 5})
    }, [ sort ])

    const getOrdersFromABCP = () => {
        getOrdersFromABCPServer()
    }


    // const getData:any = ({last}:any) => {
    //     dispatch(fetchOrders({last}))
    // }
    // useEffect(() => {
    //     dispatch(fetchResultShort());
    // }, [])
    // const loadFromABCP = () => {
    //     console.log('loadFromABCP')
    //     // return dispatch(loadClientOrdersFromABCP())
    //     return new Promise<void>((resolve, reject) => {
    //         dispatch(loadClientOrdersFromABCP()).then((result) => {
    //             if(result.meta.requestStatus === 'rejected') {
    //                 reject()
    //             } else {
    //                 resolve()
    //                 // dispatch(fetchOrders({last:null}));
    //                 // dispatch(fetchResultShort());
    //             }
    //
    //         });
    //     })
    // }
    const toOrder = (order: any) => {
        console.log('toOrder')
        // navigate(`/orders-client/${order.original._id}`)
        navigate(`${order.original._id}`)
    }

    const {main, title, content} = useContext(MainContext)

    return (
            <>
                <div className="">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <div className='text-gray-700 text-xs'>
                                <div className='font-semibold'>Последние загрузки:</div>
                                { isLoadShort ?
                                  <svg className="animate-spin h-4 w-4 mr-2 -mt-0.5 self-center" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" fillOpacity="0%" stroke="currentColor" strokeWidth="4"></circle>
                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  : <div>
                                      {result?.map( (item:any) => (
                                        <div key={item._id}>
                                            <div>- {DateTime.fromISO(item.start).toFormat('dd.MM.yyyy HH:mm:ss')} | Обработано заказов: {item.countOrder} (обновлено {item.countUpdateOrder}, создано {item.countCreateOrder})</div>
                                        </div>
                                      ))}
                                  </div>
                                }

                            </div>
                            {/*<NavLink to={'/more-result-load-abcp'} className='text-gray-700 text-xs underline'>Ещё</NavLink>*/}

                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            {/*{errorABCPLoad}*/}
                            <Button onClick={getOrdersFromABCP} text='Загрузить из ABCP' isLoading={isLoadingFromABCP} />
                        </div>
                    </div>

                    {/*<div className=" shadow ring-1 ring-black ring-opacity-5 md:rounded-lg w-full  h-[1500px]">*/}
                    {/*    <Table*/}
                    {/*        columns={columns}*/}
                    {/*        data={allRows}*/}
                    {/*        isLoading={isFetchingNextPage}*/}
                    {/*        hasNextPage={hasNextPage}*/}
                    {/*        getData={getData}*/}
                    {/*        onClickRow={toOrder}*/}
                    {/*        lastId='date'*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                <div className="flex flex-col justify-start border border-red-500"
                     {...{
                         style: {
                             height: `calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 82px)`,
                         },
                     }}>

                    <TableConstantMultiColumns
                      columns={columns}
                      data={orders.data}
                      isLoading={isLoading}
                      isFetching={isFetching}
                      hasNextPage={isLoading ? isLoading : orders?.meta.hasNextPage}
                      getData={getCustomerOrderGap}
                      // // tableContextMenu={tableContextMenu}
                      // // rowContextMenu={rowContextMenu}
                      // meta={ {cat, filter2, filterText, totalElements: products?.meta.totalElements, onlyRoot, sort} }
                      params={params}
                      // isError={isError3}
                      // error={error3}
                      // // isCheckbox={true}
                      // parentRef2={appRef}
                      // parentRefExt={appRef}
                      // actionGroup={<ActionGroup1
                      //   onChangeCategory={changeTheCategoryOfSelected}
                      //   onDelete={deleteProducts}
                      //   onExport={getXls}
                      //   onPool={onPoolSelected}
                      //   onOnline={onOnlineSelected}
                      // />}
                      // onClickRow={(info) => clickProduct(info)}
                    />

                </div>





                {/*{Outlet}*/}
            </>

    )
}

export default ABCPOrdersPage;