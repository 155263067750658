import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import Input from "../../Input/Input";
import {useAddBrandMutation, useLazyGetBrandQuery, useUpdateBrandMutation} from "../../../../api/brand";
import {SlideServiceType} from "../Slides";

type Props = {
  idBrand: string

  _slide?: SlideServiceType
};

const BrandSlide: React.FC<Props> = (props: Props) => {
  const {idBrand, _slide} = props;
  const [getBrand, {data, isFetching}] = useLazyGetBrandQuery();
  const [ updateBrand ] = useUpdateBrandMutation();
  const [ addBrand ] = useAddBrandMutation();
  const refInput = useRef<any>()

  const [name, setName] = useState<string>('');

  const onOk = useCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if(data?._id) {
        updateBrand({id: data._id, body: { name: name } }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        );
      } else if(idBrand === 'new') {
        addBrand({ name: name }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        )
      }
    })

  }, [data, name])

  useEffect(() => {
    _slide?.acceptHandler(onOk) // устанавливаем обработчик Ok (Save) в Slide
    if(idBrand === 'new') {
      _slide?.acceptButton('Создать')
    } else {
      _slide?.acceptButton('Сохранить')
    }
  }, [onOk])

  useEffect(() => {
    if(idBrand !== 'new') {
      getBrand({id: idBrand})
    }

  }, [])

  useEffect(() => {
    if(idBrand !== 'new') {
      // title in header
      const title = `${data?.name}`
      _slide?.titleFn(title);
      _slide?.subTitleFn('Бренд')

      setName(data?.name ?? '')
    } else {
      // title in header
      _slide?.titleFn('Новый бренд');
      _slide?.subTitleFn('Бренд')
    }
  }, [data]);

  // фокус на наименовании
  useEffect(() => {
    if(refInput.current && idBrand === 'new') {
      refInput.current.focus()
    }
  }, [refInput.current])

  return (
    <>
      {isFetching ? 'Загрузка...'
      :
        <form className="flex flex-col gap-6 text-sm text-slate-700 " /*onSubmit={onSubmitClient}*/>

          <Input label='Наименование'
                 value={name}
                 onChange={(e) => setName(e.target.value)}
                 ref={refInput} />


        </form>
      }

    </>
  );
};

export default BrandSlide;