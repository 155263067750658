import React, {useContext, useDeferredValue, useEffect, useState} from 'react';
import {createColumnHelper} from "@tanstack/react-table";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {Link, useNavigate} from "react-router-dom";
import {useNotification} from "../../hooks/notification";
import {useSlide} from "../../hooks/slide";
import {useContextMenu} from "../../hooks/contextMenu";
import {
  productApi,
  useAddProductMutation,
  useDelProductMutation,
  useDelProductsMutation,
  useGetProductsQuery, useLazyGetProductsQuery
} from "../../api/product";
import {IProduct} from "../../models/IProduct";
import Table3 from "../../components/ui/table3/Table3";
import Input from "../../components/ui/Input/Input";
import {cleanProducts} from "../../store/reducers/products/ProductSlice";
import {MainContext} from "../../components/Main";
import ProductSlide from "../../components/ui/slide/tmpl/ProductSlide";
import {data} from "autoprefixer";
import {useDebounce} from "../../hooks/useDebounce";
import {useGetProducts} from "../../hooks/useGetProducts";
import {useGetBrands} from "../../hooks/useGetBrands";
import {IBrand} from "../../models/IBrand";
import BrandSlide from "../../components/ui/slide/tmpl/BrandSlide";
import {useDelBrandMutation} from "../../api/brand";
import Button from "../../components/ui/buttons/Button";
import SelectConstantHeight from "../../components/ui/select/selectConstantHeight/SelectConstantHeight";
import NotificationError from "../../components/ui/notifications/tmpl/NotificationError";
import NotificationSuccess from "../../components/ui/notifications/tmpl/NotificationSuccess";
import TableConstantMultiColumns from "components/ui/table/tmpl/TableConstantMultiColumns";
import TableFlexOneColumn from "../../components/ui/table/tmpl/TableFlexOneColumn";
import {AppContext} from "../../App";
import {ColumnsWidth} from "../../components/ui/table/Table";



const BrandsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const [getBrands, {data: products, isFetching, isLoading, isError: isError3, error: error3}] = useGetBrands()
  const [params, setParams] = useState<any>()
  const notification = useNotification();

  const [addProduct] = useAddProductMutation();
  const [delBrand] = useDelBrandMutation();
  const [delProducts] = useDelProductsMutation();
  const notify = useNotification();
  const slides = useSlide();
  const contextMenu = useContextMenu();
  const tableContextMenu = [
    [
      {text: 'Добавить', onClick:(info: any) => { create() }},
      // {text: 'Удалить', onClick:(info: any) => { create() }}
    ],
    [{text: 'Обновить', onClick:(info: any) => { dispatch(productApi.util.invalidateTags(['Products']));}}]
  ]
  const rowContextMenu = [
    // [{text: 'Редактировать'}, {text: 'Печать'}],
    [{text: 'Удалить', onClick:({ row, meta}: any) => {
        delBrand(row.original._id).then(
        (r: any) => {
          if(r?.error) {
            console.log(r.error.data.message)
            notification.show(<NotificationError text={r.error.data.message} />)
          } else {
            setParams({})
            notification.show(<NotificationSuccess text={'Бренд удалён'} />)
          }
        }
      )
    }}]
  ]

  const clickBrand = (e: any, info: any) => {
    e.preventDefault();
    slides.show(<BrandSlide idBrand={info.row.original._id} />).then(
      (result) => {
        console.log(4, 'close slide', result)
        getBrands({filter, last: ''})
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const columnHelper = createColumnHelper<IBrand>()
  const columns = [
    // columnHelper.accessor('_id', {
    //   cell: info => <Link to="/" onClick={(e) => clickBrand(e, info)} className="text-sky-600 hover:text-sky-500">{info.getValue()}</Link>,
    //   size: 100,
    // }),
    columnHelper.accessor('name', {
      header: 'Наименование',
      cell: info => <Link to="/" onClick={(e) => clickBrand(e, info)} className="text-sky-600 hover:text-sky-500 border-b border-dashed border-sky-600 hover:border-sky-500">{info.getValue()}</Link>,
      // minSize: 1670,
      minSize: 950,
      meta: {
        width: ColumnsWidth.STRETCH
      },
    }),
  ]

  const create = () => {
    slides.show(<BrandSlide idBrand={'new'} />).then(
      (result) => {
        getBrands({filter, last: ''})
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const update = () => {
    // dispatch(fetchCustomers({}));
    // delProduct({ firstName: 'df', lastName: '222' }).unwrap()
  }

  const toCustomer = (a: any) => {
    console.log('a', a);
    navigate(`/customers/${a.original._id}`);
  }

  const {main, title, content} = useContext(MainContext)

  useEffect(() => {
    console.log(111, main)
    console.log(222, title)
    console.log(333, content)
  }, [main])

  useEffect(() => {
    console.log('setParams1')
    setParams({ })
  }, [])
  const {appRef} = useContext(AppContext)
  return (
    <>
      {/*<div className={'flex flex-col'}>*/}
        <div className="flex flex-col justify-start"
             {...{
               style: {
                 height: `calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 82px)`,
               },
             }}>
          <div className={'flex justify-between gap-x-3 mb-2'}>
            <div>
              <Button text={'Создать бренд'} style={'SECONDARY'} onClick={() => create()} />
            </div>

          </div>
          <div className={'overflow-auto'}>
            {/*123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />*/}
            <TableConstantMultiColumns
              columns={columns}
              data={products.data}
              isLoading={isLoading}
              isFetching={isFetching}
              hasNextPage={isLoading ? isLoading : products?.meta.hasNextPage}
              getData={getBrands}
              tableContextMenu={tableContextMenu}
              rowContextMenu={rowContextMenu}
              params={params}
              isError={isError3}
              error={error3}
              meta={ {sort: 'id:desc', totalElements: products?.meta.totalElements} }
              isRowNumber={true}
              isCheckbox={true}
              // parentRefExt={appRef}
              // height={`calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 82px)`}
            />
          </div>


        </div>
      {/*</div>*/}

    </>
  )
}

export default BrandsPage;