import {AsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICustomer} from "models/ICustomer";
import {ListResponse, productApi, ProductsResponse} from "../../../api/product";
import {IProduct} from "../../../models/IProduct";
import {ILogin} from "../../../models/ILogin";
import {authSlice} from "../AuthSlice";

interface ProductListState {
  data: IProduct[],
  meta: {}
}

const initialState: ProductListState = {
  data: [],
  meta: {}
}



export const ProductSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    cleanProducts(state) {
      // state.data = []
      // state.meta = {}
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher<ReturnType<AsyncThunk<ListResponse<IProduct>, unknown, {}>['fulfilled']>>(
      (action) => productApi.endpoints.getProducts.matchFulfilled(action),
      (state, { payload , type, meta}) => {
        // console.log(222, state.data, payload, type, meta)
        // state.data = [...state.data, ...payload.data];
        // state.meta = payload.meta
      },
    );

    builder.addMatcher<ReturnType<AsyncThunk<ListResponse<IProduct>, unknown, {}>['fulfilled']>>(
      (action) => productApi.endpoints.updateProduct.matchFulfilled(action),
      (state, { payload , type, meta}) => {
        console.log(888, state.data, payload, type, meta)
        // state.data = [...state.data, ...payload.data];
        // state.meta = {...payload.meta, last: ''}
      },
    );

  }
})
export const { cleanProducts } = ProductSlice.actions;
export default ProductSlice.reducer;