import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Input from "../../Input/Input";
import Button from "../../buttons/Button";
import SelectFlexHeight from "components/ui/select/selectFlexHeight/SelectFlexHeight";
import {SlideServiceType} from "../Slides";
import {
  useAddProductApplicabilityMutation,
  useDelProductApplicabilityMutation,
  useGetProductApplicabilityQuery,
  useLazyGetProductApplicabilityOneQuery,
  useUpdateProductApplicabilityMutation
} from "../../../../api/product-applicability-api";

type Props = {
  idProductApplicability: string

  _slide?: SlideServiceType
};

const ProductApplicabilitySlide: React.FC<Props> = (props: Props) => {
  const {idProductApplicability, _slide} = props;
  // const {data, isFetching} = useGetProductQuery({id: idProduct});
  const [getProductApplicabilityOne, {data, isFetching, status}] = useLazyGetProductApplicabilityOneQuery();
  const [ updateProductApplicability ] = useUpdateProductApplicabilityMutation();
  const [ addProductApplicability ] = useAddProductApplicabilityMutation();
  const [ delProductApplicability ] = useDelProductApplicabilityMutation()
  const [name, setName] = useState<string>('');
  const [applicabilityParent, setApplicabilityParent] = useState<any>();
  const [description_employee, setDescription_employee] = useState<string>('')


  const onOk = useCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if(data?._id) {
        return updateProductApplicability({id: data._id, body: { applicabilityName_rus: name, ProductApplicabilityParent: applicabilityParent?._id, description_employee } }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        );
      } else if(idProductApplicability === 'new') {
        return addProductApplicability({ applicabilityName_rus: name, ProductApplicabilityParent: applicabilityParent?._id, description_employee }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        )
      }
    })

  }, [data, name, applicabilityParent, description_employee])

  useEffect(() => {
    _slide?.acceptHandler(onOk) // устанавливаем обработчик Ok (Save) в Slide
    if(idProductApplicability === 'new') {
      _slide?.acceptButton('Создать')
    } else {
      _slide?.acceptButton('Сохранить')
    }
  }, [onOk])

  useEffect(() => {
    if(idProductApplicability !== 'new') {
      getProductApplicabilityOne({id: idProductApplicability})
    }

  }, [])

  useEffect(() => {
    if(idProductApplicability !== 'new') {
      // title in header
      const title = `${data?.applicabilityName_rus}`
      _slide?.titleFn(title);
      _slide?.subTitleFn('Применимость товара')

      setName(data?.applicabilityName_rus ?? '')
      setDescription_employee(data?.description_employee ?? '')
    } else {
      // title in header
      _slide?.titleFn('Новая применимость товаров');
      _slide?.subTitleFn('Применимость товара')
    }
  }, [data]);

  const delApplicability = () => {
    delProductApplicability(idProductApplicability).then(
      (r: any) => {
        if(r?.error) {
          _slide?.errorTextFn(r.error.data.message)
        } else {
          _slide?.accept()
        }
      }
    )
  }

  const {data: productApplicability, isFetching: isFetchingSelect} = useGetProductApplicabilityQuery()

  const treeToList2 = (tree: any, parentName?: string) => {
    const a = tree.map((item: any) => {
      const {subApplicability, ...rest} = item;
      if(parentName) {
        rest.applicabilityName_rus = `${parentName} - ${rest.applicabilityName_rus}`
      }

      // исключаем из списка категории, которые лежат ниже текущей - idProductApplicability
      if(subApplicability.length > 0 && item._id === idProductApplicability) {
        return []
      }
      // исключаем из списка текущую категорию - idProductApplicability
      if(item._id === idProductApplicability) {
        return []
      }

      if(subApplicability.length > 0) {
        // return [...treeToList2(subApplicability, rest.applicabilityName_rus)] // не отображаем родительские категории, только конечные
        return [rest, ...treeToList2(subApplicability, rest.applicabilityName_rus)]
      } else {
        return [rest, ...treeToList2(subApplicability, rest.applicabilityName_rus)]
      }
    })

    // добавить пункт Все в начало: не используется - нужно очищать select при выборе Все
    if(!parentName) {
      a.unshift([{_id: '', applicabilityName_rus: 'Все'}])
    }

    return a.flat()
  }

  const [apps, setApps] = useState()
  useEffect(() => {
    if(productApplicability?.data && (data?.ProductApplicabilityParent || idProductApplicability === 'new')) {
      const list = treeToList2(productApplicability.data).map((item: any) => ({option: item}))
      setApps(list)
    }
  }, [productApplicability, data]);

  return (
    <>
      {isFetching ? 'Загрузка...'
      :
        <form className="flex flex-col gap-6 text-sm text-slate-700 " /*onSubmit={onSubmitClient}*/>

          <Input label='Наименование применимости'
                 value={name}
                 onChange={(e) => setName(e.target.value)} />

          <SelectFlexHeight
            label='Родительская применимость'
            data={apps}
            optionKey='applicabilityName_rus'
            initSelected={data?.ProductApplicabilityParent}
            onChange={setApplicabilityParent}
            initStatus={idProductApplicability==='new' ? 'fulfilled' : status}
          />

          <div>
            <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
              Примечание для сотрудников
            </label>
            <div className="mt-1">
            <textarea
              rows={4}
              name="description"
              id="description"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              value={description_employee}
              // defaultValue={''}
              onChange={ (value) => setDescription_employee(value.target.value) }
            />
            </div>
          </div>

        </form>
      }

      <div className='mt-10'>
        <Button text='Удалить' onClick={delApplicability} style='DANGER' />
      </div>
    </>
  );
};

export default ProductApplicabilitySlide;