// import {useAppDispatch, useAppSelector} from "hooks/redux";
import * as React from 'react';
import {Fragment, useContext, useEffect, useState} from 'react';
import {
  docOurOrdersApi,
  useCreateDocOurOrderMutation, useDelDocOurOrdersMutation,
  useLazyGetDocOurOrderQuery, useMoveCancelDocOurOrdersMutation, useMoveDocOurOrdersMutation,
  useUpdateDocOurOrderMutation,
} from "../../api/doc-our-orders-api";
import Button from "../../components/ui/buttons/Button";
import TableConstantMultiColumns from "../../components/ui/table/tmpl/TableConstantMultiColumns";
import {createColumnHelper} from "@tanstack/react-table";
import {ColumnsWidth} from "../../components/ui/table/Table";
import {MainContext} from "../../components/Main";
import {IProduct} from "../../models/IProduct";
import {useDialog} from "../../hooks/dialog";
import DialogAddProducts from "../../components/ui/dialogs/tmpl/DialogAddProducts";
import {EnumDialogWidth} from "../../components/ui/dialogs/DialogWrap";
// import {fetchClient} from "store/reducers/clients/ClientThunk";
import {v4 as uuidv4} from 'uuid';
import {formatNumber, formatSumSeparator} from "../../utils/helpers";
import InputQtyCell from "../../components/ui/Input/tmpl/InputQtyCell";
import InputPriceCell from "../../components/ui/Input/tmpl/InputPriceCell";
import ActionGroupOrder from "../../components/ui/table3/action-groups/tmpl/ActionGroupOrder";
import {useLazyGetSelectedProductsQuery} from "../../api/product";
import {isArray} from "lodash";
import {Link, useNavigate, useParams} from "react-router-dom";
import ProductSlide from "../../components/ui/slide/tmpl/ProductSlide";
import {useSlide} from "../../hooks/slide";
import {useAppSelector} from "../../hooks/redux";
import {$CombinedState} from "redux";
import {store} from "../../index";
import {
  IDocOurOrder,
  IDocOurOrderPositionRequest,
  IDocOurOrderPositionResponse,
  IDocOurOrderPositionTable
} from "../../models/IDocOurOrder";
import {CheckIcon, EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {EllipsisHorizontalIcon, Square2StackIcon} from "@heroicons/react/24/outline";
import DialogSelectSuppliers from "../../components/ui/dialogs/tmpl/DialogSelectSuppliers";
import {DateTime} from "luxon";
import NotificationError from "../../components/ui/notifications/tmpl/NotificationError";
import NotificationSuccess from "../../components/ui/notifications/tmpl/NotificationSuccess";
import {LockClosedIcon} from "@heroicons/react/24/solid";
import Input from "../../components/ui/Input/Input";

type Props = {
  children?: React.ReactNode
  param?: string
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const InputQty: React.FC<{info: any, setPositionsList: any, setPositionsData: any, setChangeData: any}> = ({info, setPositionsList, setPositionsData, setChangeData}) => {
  const [qty, setQty] = useState<{ value: number, format: string}>({ value: info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id).qty, format: info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id).qty})
  return (
    <Fragment>
      <InputQtyCell key={info.row.original._id}
                 value={qty.format}
                    disabled={info.row.original.move}
                 onChange={(a) => {
                   console.log(1, a.target.value)
                   setQty({value: formatNumber(a.target.value, 0).number, format: a.target.value})
                 }}
                 onIncrement={
                   () => {
                     console.log('onIncrement', info.table.options.meta.positions)
                     const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
                     setQty({value: qty.value+1, format: formatNumber(qty.value+1, 0).string})
                     item.qty = qty.value+1
                     // setPositionsList(() => [...info.table.options.meta.positions])
                     setPositionsData(() => [...info.table.options.meta.positions])
                     setChangeData({_id: info.row.original._id})
                   }}
                 onDecrement={() => {
                   console.log('onDecrement', qty)
                   const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
                   // item.qty = item.qty-1
                   // setPositions(() => [...info.table.options.meta.positions])
                   setQty({value: qty.value-1, format: formatNumber(qty.value-1, 0).string})
                   item.qty = qty.value-1
                   // setPositionsList(() => [...info.table.options.meta.positions])
                   setPositionsData(() => [...info.table.options.meta.positions])
                   setChangeData({_id: info.row.original._id})
                 }}
                 onClick={() => console.log(info.table.options.meta)}
                  onEnter={() => {
                    const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
                    item.qty = qty.value
                    setQty({value: qty.value, format: formatNumber(qty.format, 2).string})
                    // setPositionsList(() => [...info.table.options.meta.positions])
                    setPositionsData(() => [...info.table.options.meta.positions])
                    setChangeData({_id: info.row.original._id})
                  }}
                 onBlur={(a) => {
                   console.log('onBlur', a.target.value, info.table.options.meta.positions);
                   const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
                   item.qty = formatNumber(a.target.value, 0).number
                   setQty({value: formatNumber(a.target.value, 0).number, format: formatNumber(a.target.value, 0).string})
                   setPositionsData(() => [...info.table.options.meta.positions])
                   setChangeData({_id: info.row.original._id})
                 }} />
    </Fragment>)
}

const InputPrice: React.FC<{info: any, setPositionsList: any, setPositionsData: any, setChangeData: any}> = ({info, setPositionsList, setPositionsData, setChangeData}) => {
  const [price, setPrice] = useState<{ value: number, format: string}>(
    {
      value: info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id).price_purchase,
      format: formatNumber(info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id).price_purchase, 2).string
    })
  useEffect(() => {
    // console.log('useEffect InputPrice')
  }, [])

  const aaa = () => {

  }
  return (
    <Fragment>
      <InputPriceCell key={info.row.original._id}
                    value={price.format}
                      disabled={info.row.original.move}
                    onChange={(a) => {
                      // console.log(1, a.target.value)
                      setPrice({value: formatNumber(a.target.value, 2).number, format: a.target.value})
                    }}
                    onClick={() => console.log('onClick')}
                    onFocus={(e) => console.log('onFocus')}
                    onEnter={() => {
                      const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
                      item.price_purchase = price.value
                      setPrice({value: price.value, format: formatNumber(price.format, 2).string})
                      // setPositionsList(() => [...info.table.options.meta.positions])
                      setPositionsData(() => [...info.table.options.meta.positions])
                      setChangeData({_id: info.row.original._id})
                    }}
                    onBlur={(a) => {
                      console.log('onBlur', a.target.value, info.table.options.meta.positions);
                      const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
                      item.price_purchase = price.value
                      setPrice({value: price.value, format: formatNumber(price.format, 2).string})
                      setPositionsData(() => [...info.table.options.meta.positions])
                      setChangeData({_id: info.row.original._id})
                    }} />
    </Fragment>)
}

const PriceSum: React.FC<{info: any}> = ({info}) => {
  const [price, setPrice] = useState<{ value: number, format: string}>({ value: info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id).price_purchase, format: info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id).price_purchase})
  const price2 = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id).price_purchase

  return (
    <Fragment key={info.row.original._id}>
      {formatSumSeparator(formatNumber(info.row.original.price_purchase * info.row.original.qty, 2).string)} ₽
    </Fragment>)
}

const PriceSumFooter: React.FC<{info: any, setChangeData: any}> = ({info, setChangeData}) => {
  const [sum, setSum] = useState<any>()

  useEffect(() => {
    // console.log(777, info)
    const sum = info.table.options.meta.positions.reduce((previousValue: number, currentValue: any) => {
      return previousValue + currentValue.price_purchase * currentValue.qty
    }, 0)
    setSum(`Итого: ${formatNumber(sum, 2).string} ₽`)
  }, [info.table.options.meta.positions])

  useEffect(() => {
    setChangeData({_id: 'footer'})
  }, [])
  return (
    <Fragment key={info.header.id+'_footer'}>
      {`Итого: ${formatSumSeparator(formatNumber(sum, 2).string)} ₽`}
    </Fragment>)
}

const SupplierSelection: React.FC<{info: any, setPositionsData: any, setPositionsList: any, setChangeData: any}> = ({info, setPositionsData, setPositionsList, setChangeData}) => {
  const dialog = useDialog()
  const [name, setName] = useState<string>(info.row.original.supplier_name)
  const selectSupplier = () => {
    dialog.show(<DialogSelectSuppliers width={EnumDialogWidth.MAXW7XL} />).then(
      (result) => {
        // notify.show(<NotificationSuccess text={'Организация успешно создана'} />)
        // onChangeTenant(result.Tenant)
        console.log('add supplier', result)
        if(result?.selected?.include && result?.selected?.include.length > 0) {
          // const pos = result.selected.include.map( (item: any) => ({ _id: uuidv4(), product_id: item._id, brandName: item.brandName, number: item.number, title_rus: item.title_rus, price_purchase: 0, qty: 1 }))
          const pos = result.selected.include

          // setPositionsList((p) => [...p, ...pos])
          // setPositionsData((p: any) => [...p, ...pos])

          const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
          console.log(111222, item)
          item.supplier_name = pos[0].name
          item.supplier_id = pos[0]._id
          setName(pos[0].name)
          // setPrice({value: price.value, format: formatNumber(price.format, 2).string})
          // setPositionsList(() => [...info.table.options.meta.positions])
          setPositionsData(() => [...info.table.options.meta.positions])
          setChangeData({_id: info.row.original._id})
        } else {
          // getSelectedProducts(result).then((res: any) => {
          //   // console.log(11111, res.data)
          //   if(res.data && isArray(res.data)) {
          //     const pos = res.data.map( (item: any) => ({ _id: uuidv4(), product_id: item._id, brandName: item.brandName, number: item.number, title_rus: item.title_rus, price_purchase: 0, qty: 1 }))
          //     // setPositionsList((p) => [...p, ...pos])
          //     setPositionsData((p) => [...p, ...pos])
          //   }
          // })
        }
      }
    )
  }

  return (
    <Fragment key={info.row.original._id}>
      <div className={'flex justify-between w-full'}>
        <div className={'flex items-center w-full'}>{name}</div>
        <div className='flex justify-center items-center w-[30px] h-[30px] hover:text-sky-600 hover:border hover:border-sky-200 hover:rounded cursor-pointer' onClick={selectSupplier} >
          {info.row.original.move || <EllipsisHorizontalIcon className="h-5 w-5 " aria-hidden="true" />}
        </div>
      </div>
    </Fragment>)
}

export const DocOurOrderPageBreadcrumb: React.FC = (props: any) => {
  const [getOurOrder, {data: order, isFetching}] = useLazyGetDocOurOrderQuery()
  const { id } = props?.match?.params;
  useEffect(() => {
    if(id && id !== 'new') {
      getOurOrder({id})
    }
  },[])
  return (
    <>
      { id !== 'new' && order?.date && `Наш заказ № ${order?.number} от ${DateTime.fromISO(order.date).toFormat('dd.MM.yyyy HH:mm:ss')}` }
      { id === 'new' && `Новый наш заказ` }
    </>
  )
}
export const DocOurOrderPageTitle: React.FC = (props: any) => {
  const a = useAppSelector(state => state.docOurOrdersApi)
  const [getOurOrder, {data: order, isFetching}] = useLazyGetDocOurOrderQuery()
  const { id } = props?.match?.params;

  // const result2 = docOurOrdersApi.endpoints?.getDocOurOrder.useQuerySubscription({id})

  const caption = '1'
  useEffect(() => {
    const state = store.getState();
    // const result = docOurOrdersApi.endpoints?.getDocOurOrder.select({id})(state)
    // const { data, status, error } = result
    console.log('id', id)
    if(id && id !== 'new') {
      getOurOrder({id})
    }
  },[])

  return (
    <>
      { id !== 'new' && order?.date &&
        <div className={'flex items-center gap-2'}>
          <span>Наш заказ № {order?.number} от {DateTime.fromISO(order.date).toFormat('dd.MM.yyyy HH:mm:ss')}</span>
          {order?.move === 1 && <LockClosedIcon className={'h-5 w-5 text-green-600/70'} />}
          {order?.move === 2 && <LockClosedIcon className={'h-5 w-5 text-amber-600/70'} />}
          {props.isSave ?
            <span className={'text-xs text-green-600 self-start'}>Сохранён</span>
            : <span className={'text-xs text-amber-600 self-start'}>Не сохранен</span> }

        </div>
      }
      { id === 'new' && `Новый наш заказ` }
    </>
  )
}

const InputNote: React.FC<{info: any, setPositionsData: any}> = ({info, setPositionsData}) => {
  const [note, setNote] = useState<string>(info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id).note || '')

  useEffect(() => {
    console.log(111, info.table.options.meta)
  }, [])
  return (
    <Fragment>
      <Input key={info.row.original._id}
             value={note}
             onChange={(a) => {
               setNote(a.target.value)
             }}
             onBlur={(a) => {
               const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
               item.note = note
               setPositionsData(() => [...info.table.options.meta.positions])
             }}
             onClean={() => {
               setNote('')
             }}
      />
    </Fragment>)
}
const NumberCell2xl: React.FC<any> = ({info}) => {
  const [clipboard, setClipboard] = useState<boolean>(false)
  useEffect(() => {
    if(clipboard) {
      setTimeout(() => {
        setClipboard(false)
      }, 1000)
    }
  }, [clipboard])
  return (
    <div className={'flex items-center gap-2'}>
      {info.row.original.number}
      {clipboard ?
        <CheckIcon className={'h-4 w-4 text-green-600'} />
        :
        <Square2StackIcon className={'h-4 w-4 text-slate-400 hover:text-slate-700'} onClick={e => {e.stopPropagation(); navigator.clipboard.writeText(info.row.original.number); setClipboard(true)}} />
      }
    </div>

  )
}

const DocOurOrder: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const {children, param: name = 'по-умолчанию'} = props;
  const [createDocOurOrder, { isLoading: isLoadingCreate }] = useCreateDocOurOrderMutation()
  const [updateDocOurOrder, { isLoading: isLoadingUpdate }] = useUpdateDocOurOrderMutation()
  const [delDocs, {}] = useDelDocOurOrdersMutation();
  const [moveDocs, {}] = useMoveDocOurOrdersMutation();
  const [moveCancelDocs, {}] = useMoveCancelDocOurOrdersMutation();
  const [positionsList, setPositionsList] = useState<any[]>([])
  const [positionsData, setPositionsData] = useState<any[]>([])
  const {main, title, content, setTitleData} = useContext(MainContext)
  const dialog = useDialog()
  const [getSelectedProducts] = useLazyGetSelectedProductsQuery()
  const { id } = useParams();
  const [getOurOrder, {data: order, isFetching}] = useLazyGetDocOurOrderQuery()
  const slides = useSlide();
  const [changeData, setChangeData] = useState<any>()
  const [isSave, setIsSave] = useState<boolean>(true)
  const [positionsInit, setPositionsInit] = useState<any[]>([])
  const [note, setNote] = useState<string>('')

  useEffect(() => {
    console.log('id', id, order)
    if(id && id !== 'new') {
      getOurOrder({id})
    }
  },[])

  useEffect(() => {
    console.log('order', order)
    if(order) {
      const pos = order.positions.map( (item: IDocOurOrderPositionResponse) => ({ _id: item._id, product_id: item.product_id, brandName: item.brandName, number: item.number, title_rus: item.title_rus, supplier_id: item.supplier_id, supplier_name: item.supplier_name, price_purchase: item.price_purchase, qty: item.qty, move: item.move, note: item.note }))
      setPositionsList((p) => [...pos])
      setPositionsData((p) => [...pos])
      setPositionsInit((p) => [...pos.map( item => ({...item}) )])
      setNote(order.note)
    }

  }, [order])

  const clickProduct = (info: any) => {
    console.log('row2', info)
    const meta = info.table.options.meta
    slides.show(<ProductSlide idProduct={info.row.original.product_id} />).then(
      (result) => {
        // setParams({ cat: meta?.cat, ...meta?.filter2, filterText: meta?.filterText, onlyRoot: meta?.onlyRoot, sort: meta?.sort })
        // updateCategories(meta.cat, meta?.filter2, meta?.filterText, meta?.onlyRoot)
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const columnHelper = createColumnHelper<IDocOurOrderPositionTable & {price_sum: string}>()
  const columns = [
    columnHelper.accessor('brandName', {
      header: () => 'Бренд',
      // cell: info => info.renderValue(),
      minSize: 50,
      // size: 200,
    }),
    columnHelper.accessor('number', {
      header: () => 'Артикул',
      cell: info => <NumberCell2xl info={info} />,
      minSize: 50,
      size: 200,
    }),
    columnHelper.accessor('title_rus', {
      header: () => <span>Товар</span>,
      cell: info => <Link to="/" onClick={(e) => {e.preventDefault(); e.stopPropagation(); clickProduct(info)}} className="text-sky-600 hover:text-sky-500 border-b border-dashed border-sky-600 hover:border-sky-500">{info.getValue() || 'не задано'}</Link>,
      minSize: 300,
      meta: { width: ColumnsWidth.STRETCH, },
    }),
    columnHelper.accessor('supplier_name', {
      header: () => <span>Поставщик</span>,
      cell: info => <SupplierSelection info={info} setPositionsData={setPositionsData} setPositionsList={setPositionsList} setChangeData={setChangeData} /> ,
      minSize: 150,
    }),
    columnHelper.accessor('price_purchase', {
      header: 'Цена закупочная',
      cell: info => <div className={''}><InputPrice info={info} setPositionsList={setPositionsList} setPositionsData={setPositionsData} setChangeData={setChangeData}/> </div>,
      // size: 200,
      maxSize: 150,
      // minSize: 100
      meta: {
        side: 'justify-end'
      }
    }),
    columnHelper.accessor('qty', {
      header: 'Кол-во',
      cell: (info: any) => <div className={''}><InputQty info={info} setPositionsList={setPositionsList} setPositionsData={setPositionsData} setChangeData={setChangeData}/> </div>,
      // size: 200,
      maxSize: 150,
      // minSize: 100
    }),
    columnHelper.accessor('price_sum', {
      header: 'Сумма',
      // cell: info => <div className={'flex items-center justify-end h-7 w-full border rounded-sm hover:border hover:cursor-text'}>{info.getValue()} ₽</div>,
      cell: info => <PriceSum info={info} />,
      // size: 200,
      // maxSize: 300,
      minSize: 120,
      meta: {
        side: 'justify-end',
      },
      footer: info => <PriceSumFooter info={info} setChangeData={setChangeData} />
    }),
    columnHelper.accessor('note', {
      header: 'Примечание',
      cell: info => <InputNote info={info} setPositionsData={setPositionsData} />,
      // size: 200,
      maxSize: 150,
      // minSize: 100
    }),
  ]

  const create = () => {
    const pos = positionsData.map((item: any): IDocOurOrderPositionRequest => ({_id: item._id, product_id: item.product_id, supplier_id: item.supplier_id, qty: item.qty, price_purchase: item.price_purchase, delivery_time: '', move: item.move, note: item.note}))
    if(id === 'new') {
      const pos = positionsData.map((item: any): IDocOurOrderPositionRequest => ({product_id: item.product_id, supplier_id: item.supplier_id, qty: item.qty, price_purchase: item.price_purchase, delivery_time: '', move: item.move, note: item.note}))
      createDocOurOrder({ number: '1', date: '2', positions: pos, note }).then(
        (res) => {
          console.log(1233333333, res)
          if('error' in res) {
            console.log('Error', res.error)
          }
          if('data' in res) {
            navigate(`/doc-our-orders/${res.data._id}`);
          }
        }
      )
    } else {
      const pos = positionsData.map((item: any): IDocOurOrderPositionRequest => ({_id: item._id, product_id: item.product_id, supplier_id: item.supplier_id, qty: item.qty, price_purchase: item.price_purchase, delivery_time: '', move: item.move, note: item.note}))
      if(id) {
        updateDocOurOrder({ id, data: {positions: pos, note} })
      }
    }
  }

  const addProduct = () => {
    // setPositions((p) => [...p, {number: 1000, title_rus: '123', price_sale: 3400 }])
    dialog.show(<DialogAddProducts width={EnumDialogWidth.MAXW7XL} />).then(
      (result) => {
        // notify.show(<NotificationSuccess text={'Организация успешно создана'} />)
        // onChangeTenant(result.Tenant)
        console.log('add product', result)
        if(result?.selected?.include && result?.selected?.include.length > 0) {
          const pos = result.selected.include.map( (item: any) => ({ _id: uuidv4(), product_id: item._id, brandName: item.brandName, number: item.number, title_rus: item.title_rus, supplier_name: '', price_purchase: 0, qty: 1 }))

          setPositionsList((p) => [...p, ...pos])
          setPositionsData((p) => [...p, ...pos])
        } else {
          getSelectedProducts(result).then((res: any) => {
            // console.log(11111, res.data)
            if(res.data && isArray(res.data)) {
              const pos = res.data.map( (item: any) => ({ _id: uuidv4(), product_id: item._id, brandName: item.brandName, number: item.number, title_rus: item.title_rus, supplier_name: '', price_purchase: 0, qty: 1 }))
              setPositionsList((p) => [...p, ...pos])
              setPositionsData((p) => [...p, ...pos])
            }
          })
        }
      }
    )
  }

  const onDelete = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    console.log('onDelete', selected)
    const anyMove = positionsList.filter((item: any) => item?.move)
    if(selected.all) {
      if(anyMove.length) {
        const newPos = positionsData.filter((item: any) => item?.move)
        setPositionsList([...newPos])
        setPositionsData([...newPos])
      } else {
        setPositionsList([])
        setPositionsData([])
      }
    } else {
      if(selected.include.length) {
        const newPos = positionsData.filter((item: any) => !selected.include.some(item2 => item2._id === item._id) || item?.move)
        setPositionsList([...newPos])
        setPositionsData([...newPos])
      } else if(selected.exclude.length) {
        const newPos = positionsData.filter((item: any) => selected.exclude.some(item2 => item2._id === item._id) || item?.move)
        setPositionsList([...newPos])
        setPositionsData([...newPos])
      }
    }
    if(cleanSelected) cleanSelected()
  }

  const onMove = () => {
    if(id) {
      return moveDocs({id})
    }
  }

  const onMoveCancel = () => {
    if(id) {
      return moveCancelDocs({id})
    }
  }

  useEffect(() => {
    if(order) {
      const posOld = positionsInit
      const posNew = positionsData
      console.log('change', posOld, posNew)
      let isChange = false

      isChange = positionsInit.length !== positionsData.length // если изменилось кол-во строк

      // если изменились данные в строках
      positionsInit.forEach(item => {
        const posNew = positionsData.find(item2 => item2._id === item._id)
        if(posNew) {
          console.log(555, item.qty, posNew.qty)
          for(const key in posNew) {
            if(posNew[key] != item[key]) {
              isChange = true
            }
          }
        }
      })

      //примечание
      if(note != order.note) {
        isChange = true
      }

      setIsSave(!isChange)
      setTitleData({isSave: !isChange})
    } else {
      if(id && id === 'new') {
        setIsSave(false)
      }
    }



  }, [positionsData, note])

  return (
    <>
      <div className={'flex gap-3 mb-2'}>
        <Button text={'Сохранить'} onClick={create} isDisabled={isSave} style={'SUCCESS'} isLoading={isLoadingCreate || isLoadingUpdate} />
        {id === 'new' || <Button text={'Провести'} onClick={onMove} isDisabled={!!(order && order.move == 1) || !isSave} style={'TERTIARY'} iconResolve={false} /> }
        {id === 'new' || <Button text={'Отменить проведение'} onClick={onMoveCancel} isDisabled={!!(order && order.move == 0) || !isSave} style={'TERTIARY'} iconResolve={false} /> }
        <Button text={'Добавить товар'} onClick={addProduct} isDisabled={!!(order && order.move == 1)} style={'TERTIARY'} />
      </div>


      <div className="flex flex-col justify-start"
           {...{
             style: {
               height: `calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 82px)`,
             },
           }}>

        <TableConstantMultiColumns
          columns={columns}
          data={positionsList}
          // isLoading={isLoading}
          // isFetching={isFetching}
          // hasNextPage={isLoading ? isLoading : orders?.meta.hasNextPage}
          // getData={getCustomerOrderGap}
          // // tableContextMenu={tableContextMenu}
          // // rowContextMenu={rowContextMenu}
          meta={ {positions: positionsData, totalElements: positionsList.length, changeData} }
          // params={params}
          // isError={isError3}
          // error={error3}
          isCheckbox={true}
          isRowNumber={true}
          // parentRef2={appRef}
          // parentRefExt={appRef}
          actionGroup={<ActionGroupOrder
            onDelete={onDelete}
          />}
          // onClickRow={(info) => clickProduct(info)}
          isFooter={true}
        />

      </div>

      <div>
        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
          Примечание для сотрудников
        </label>
        <div className="mt-1">
            <textarea
              rows={4}
              name="description"
              id="description"
              className="block w-96 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              value={note}
              // defaultValue={''}
              onChange={ (value) => setNote(value.target.value) }
            />
        </div>
      </div>
    </>
  );
};

export default DocOurOrder;