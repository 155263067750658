import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {useTooltip} from "../../../hooks/tootip";
import TooltipPanel from "./TooltipPanel";

const Tooltips: React.FC = () => {
  const { tooltips } = useAppSelector(state => state.tooltips);
  const [wrap, setWrap] = useState<any>()

  const rootRef = useCallback((node: any) => {
    // срабатывает, когда элемент монтируется и размонтируется (удаляется) из DOM
    if(node) {
      // переносим tooltips в body, чтобы они корректно отображались в слайдах и т.д. (которые вставляются непосредственно в body)
      document.body.append(node)
      setWrap(node)
    }
  }, [])

  useEffect(() => {
    // затем удаляем эти tooltips из DOM
    if(tooltips.length === 0) {
      !!wrap && wrap.remove()
    }
  }, [tooltips.length])

  return (
    <>
      {!!tooltips?.length && tooltips.map((tooltip: any) => {
        // console.log(333, tooltip)
        return (
          <div key={tooltip.id}>
            <div ref={rootRef}>
              <TooltipPanel open={true} text={tooltip.text} target={tooltip.target} placement={tooltip.placement} />
            </div>
          </div>

        )
      })}
    </>
  )
};

export default Tooltips;