import * as React from 'react';
// import {useAppDispatch, useAppSelector} from "hooks/redux";
import {useEffect} from "react";
import {ChevronRightIcon, HomeIcon} from "@heroicons/react/20/solid";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import {NavLink} from "react-router-dom";
import {useAppSelector} from "../hooks/redux";
import RouterProtected from "../pages/routerProtected";
import RouterPublic from "../pages/routerPublic";
import {Simulate} from "react-dom/test-utils";
// import {fetchClient} from "store/reducers/customers/ClientThunk";

type Props = {
  children?: React.ReactNode
  routes?: any
};

const Breadcrumbs: React.FC<Props> = (props: Props) => {
  // const {children, routes = []} = props;

  const user = useAppSelector((state) => state.auth)
  let auth = false;
  if(user?.data.userId) {
    auth = true;
  }
  let routes: any = auth ? RouterProtected : RouterPublic
  const breadcrumbs: any = useBreadcrumbs(routes);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-0">
        <li>
          <div>
            <NavLink to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Главная</span>
            </NavLink>
          </div>
        </li>
        {breadcrumbs.slice(1).map(({ breadcrumb, match }: any, index: any) => (
          <li key={match.pathname}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <NavLink
                to={match.pathname}
                className="ml-0 text-sm font-medium text-gray-500 hover:text-gray-700"
                // aria-current={page.current ? 'page' : undefined}
              >
                {breadcrumb}
              </NavLink>
              {/*{index !== breadcrumbs.length - 1 && '/'}*/}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;