import {createAsyncThunk} from "@reduxjs/toolkit";
import {del, get, post, put} from "../../../api";
// import Timeout from "await-timeout";

export const fetchCustomers = createAsyncThunk(
    'customers/fetchCustomers',
    async (params: any = {}, thunkAPI) => {
        try {
            const { last } = params;
            // await Timeout.set(2000);
            let response;
            if(last) {
                response = await get(`${process.env.REACT_APP_URL_API}/customer/?last=${last}`)
            } else {
                response = await get(`${process.env.REACT_APP_URL_API}/customer/`)
            }

            console.log('response', response);
            return response;
        } catch (e: any) {
            return thunkAPI.rejectWithValue('Ошибка загрузки')
        }

    }
)

export const fetchCustomer = createAsyncThunk(
    'customers/fetchCustomer',
    async (params: any = {}, thunkAPI) => {
      try {
        const response = await get(`${process.env.REACT_APP_URL_API}/customer/${params.id}`);
        console.log('response Customer', response)
        return response;
      } catch (e: any) {
        return thunkAPI.rejectWithValue('Ошибка загрузки');
      }
    }
)

export const createCustomer = createAsyncThunk(
    'customers/createCustomer',
    async (params: any, thunkAPI) => {
      try {
        const data = params;
        const response = await post(`${process.env.REACT_APP_URL_API}/customer/`, data);

        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue('Ошибка')
      }
    }
)

export const updateCustomer = createAsyncThunk(
    'customers/updateCustomer',
    async (params: any, thunkAPI) => {
      try {
        const data = params;
        const response = await put(`${process.env.REACT_APP_URL_API}/customer/${data.id}`, data);

        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue('Ошибка')
      }
    }
)

export const deleteCustomer = createAsyncThunk(
  'customers/deleteCustomer',
  async (params: any, thunkAPI) => {
    try {
      const data = params;
      const response = await del(`${process.env.REACT_APP_URL_API}/customer/${data.id}`);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue('Ошибка')
    }
  }
)