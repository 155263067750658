import * as React from 'react';
import {Cell, flexRender, Row} from "@tanstack/react-table";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {useContextMenu} from "../../../../../hooks/contextMenu";
import {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from "react";

type Props = {
  rowData: Row<any>
  cell: Cell<any, unknown> | undefined
  tableContextMenu: any
  rowContextMenu: any
  meta: any
  ref: any
  widthBodyRef: any
  widthHeaderRef: any
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CellRowContextMenu: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { rowData, cell, tableContextMenu, rowContextMenu, meta, widthBodyRef, widthHeaderRef } = props;
  const contextMenu = useContextMenu()
  const ref2 = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<any>({})

  useLayoutEffect(() => {
    if(cell) {
      // console.log('useLayoutEffect', cell?.column.id, ref2.current?.offsetWidth, cell?.getValue())
      const id = cell.column.id
      const width = ref2.current?.offsetWidth ? ref2.current?.offsetWidth : 0
      if(widthBodyRef.current[id]) {
        if(widthBodyRef.current[id] < width) {
          // console.log('useLayoutEffect CellSimple', widthBodyRef.current[id], width)
          widthBodyRef.current = {...widthBodyRef.current, [id]: width }
        }
      } else {
        widthBodyRef.current = { ...widthBodyRef.current, [id]: width }
      }
    }

    const h = cell && widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
    const b = cell && widthBodyRef.current[cell.column.id]
    const s = h > b ? h : b
    // console.log('useEffect', cell.column.id, widthBodyRef.current, ext)
    setStyle({
      minWidth: s,
      maxWidth: s
    })
  },[])

  useEffect(() => {
    if(cell) {
      const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
      const b = widthBodyRef.current[cell.column.id]
      const s = h > b ? h : b
      setStyle({
        minWidth: s,
        maxWidth: s
      })
    }
  }, [widthBodyRef.current])

  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle', cell?.column.id, ref2.current?.offsetWidth)
    return {
      offsetWidth: ref2.current?.offsetWidth,
    } as HTMLDivElement | any
  }, [])

  return (
    <>
      {rowContextMenu && cell ?
          <div ref={ref2} key={cell.id}
               className='flex justify-center items-center h-full text-slate-600 px-3'
               {...{
                 style: {
                   ...style
                 }
               }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='flex justify-center items-center w-[30px] h-[30px] hover:text-sky-600 hover:border hover:border-sky-200 hover:rounded cursor-pointer' onClick={(e) => contextMenu.show({ items: rowContextMenu, dataOnClick: { row: rowData ? rowData : null, meta: meta } }, e.currentTarget)}>
              <EllipsisVerticalIcon className="h-5 w-5 " aria-hidden="true" />
            </div>
          </div>
        :
        tableContextMenu && <div ref={ref} {...{
          style: {
            width: 60,
            minWidth: 60,
            maxWidth:60
          }
        }}></div>
      }
    </>
  );
})

export default CellRowContextMenu;