import * as React from 'react';
import {Cell, Row} from "@tanstack/react-table";
import {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from "react";

type Props = {
  rowData: Row<any>
  cell: Cell<any, unknown> | undefined
  selected: any
  setSelect: any
  ref: any
  widthBodyRef?: any
  widthHeaderRef?: any
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CellCheckbox: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { rowData, cell, selected, setSelect, widthBodyRef, widthHeaderRef} = props;
  const ref2 = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<any>({})

  useLayoutEffect(() => {
    if(cell) {
      // console.log('useLayoutEffect', cell?.column.id, ref2.current?.offsetWidth, cell?.getValue())
      const id = cell.column.id
      const width = ref2.current?.offsetWidth ? ref2.current?.offsetWidth : 0
      if(widthBodyRef.current[id]) {
        if(widthBodyRef.current[id] < width) {
          // console.log('useLayoutEffect CellSimple', widthBodyRef.current[id], width)
          widthBodyRef.current = {...widthBodyRef.current, [id]: width }
        }
      } else {
        widthBodyRef.current = { ...widthBodyRef.current, [id]: width }
      }
    }
    const h = cell && widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
    const b = cell && widthBodyRef.current[cell.column.id]
    const s = h > b ? h : b
    // console.log('useEffect', cell.column.id, widthBodyRef.current, ext)
    setStyle({
      minWidth: s,
      maxWidth: s
    })
  },[])

  useEffect(() => {
    if(cell) {
      const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
      const b = widthBodyRef.current[cell.column.id]
      const s = h > b ? h : b
      // console.log('useEffect', cell.column.id, widthBodyRef.current, ext)
      setStyle({
        minWidth: s,
        maxWidth: s
      })
    }
  }, [widthBodyRef.current])

  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle', cell?.column.id, ref2.current?.offsetWidth)
    return {
      offsetWidth: ref2.current?.offsetWidth,
    } as HTMLDivElement | any
  }, [])

  return (
    <>

      {cell &&
        <div ref={ref2} key={cell.id}
             className='flex py-1 justify-start items-center h-full'
             {...{
               style: {
                 ...style
               }
             }}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            name="remember-me"
            id="remember-me"
            disabled={rowData.original.row_disabled}
            className={classNames("h-4 w-4 rounded border-slate-400 text-sky-600 hover:border-sky-500 focus:ring-sky-300",
              'disabled:hover:border-slate-200 disabled:border-slate-200'
            )}
            checked={ !!selected.include.find((item: any) => item._id === rowData.original._id) || (selected.all && !rowData.original?.move) || (selected.exclude.length && !selected.exclude.find((item: any) => item._id === rowData?.original?._id) && !rowData.original?.move) }
            // disabled={isLoading || isError}
            onChange={(event) => {setSelect(event.target.checked, rowData.original)}}
          />
        </div>
      }
    </>
  );
})

export default CellCheckbox;