import * as React from 'react';
import {forwardRef, Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {flexRender, Table} from "@tanstack/react-table";
import {ColumnsWidth} from "../Table";
import FooterCellStretch from "./cells/FooterCellStretch";
import FooterCellCheckbox from "./cells/FooterCellCheckbox";
import FooterCellRowNumber from "./cells/FooterCellRowNumber";
import FooterCellRowContextMenu from "./cells/FooterCellRowContextMenu";
import FooterCellSeparator from "./cells/FooterCellSeparator";
import FooterCellSimple from "./cells/FooterCellSimple";

type Props = {
  table: Table<any>
  // headerRef: any
  selected: any
  setSelectAll: any
  scrollWidth: any
  widthHeaderRef?: any
  widthBodyRef?: any
  widthFooterRef?: any
  ref?: any
  data: any
  cellWidth?: any
  setCellWidth?: any
  minWidthTrRef?: any
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const TableFooter: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const {table, selected, setSelectAll, scrollWidth, widthHeaderRef, widthBodyRef, data, widthFooterRef, cellWidth, setCellWidth, minWidthTrRef} = props

  const footers = useMemo(() => {
    const footerGroup = table.getFooterGroups()
    return footerGroup.flatMap(group => group.headers.filter((item: any) => item.id!=='checkbox' && item.id!=='menu' && item.id!=='rowNumber'))
  }, [])

  // const footerGroup = table.getFooterGroups()
  // const footers = footerGroup.flatMap(group => group.headers.filter((item: any) => item.id!=='checkbox' && item.id!=='menu' && item.id!=='rowNumber'))


  const colStretchIndex = useMemo(() => footers.findIndex((item: any) => item.column.columnDef.meta?.width === ColumnsWidth.STRETCH), [])
  const colStretch = useMemo(() => footers[colStretchIndex], [])
  const colRowNumber = useMemo(() => table.getFooterGroups()[0].headers.find((item: any) => item.id==='rowNumber'), [])
  const colCheckbox = useMemo(() => table.getFooterGroups()[0].headers.find((item: any) => item.id==='checkbox'), [])
  const colMenu = useMemo(() => table.getFooterGroups()[0].headers.find((item: any) => item.id==='menu'), [])
  const colsUntilStretch = useMemo(() => colStretchIndex > -1 ? footers.slice(0, colStretchIndex) : [], [])
  const colsAfterStretch = useMemo(() => colStretchIndex > -1 ? footers.slice(colStretchIndex+1, footers.length) : [], [])

  const [minWidthLeft, setMinWidthLeft] = useState(0)
  const [minWidthRight, setMinWidthRight] = useState(0)
  // const minWidthHeader = minWidthLeft + (colStretch.column.columnDef.minSize || 300) + 4 + minWidthRight + 2 // +1 - border right col stretch, +2 - both borders header

  const rowNumberRef = useRef<any>(null);
  const checkboxRef = useRef<any>(null);
  const untilRef = useRef<any>({});
  const afterRef = useRef<any>({});
  const contextMenuRef = useRef<any>(null);
  const separatorsLeftRef = useRef<any>(0);
  const separatorsRightRef = useRef<any>(0);
  separatorsLeftRef.current = 0
  separatorsRightRef.current = 0


  useEffect(() => {
    // console.log(111222, widthHeaderRef.current, widthBodyRef.current, widthFooterRef.current)
    let leftSum = 0
    let rightSum = 0
    const left = colsUntilStretch.map(item => item.column.id)
    const right = colsAfterStretch.map(item => item.column.id)
    let countCellSimple = 0
    for (let key of Object.keys(widthHeaderRef.current)) {
      const h = widthHeaderRef.current[key] ? widthHeaderRef.current[key] : 0
      const b = widthBodyRef.current[key] ? widthBodyRef.current[key] : 0
      const f = widthFooterRef.current[key] ? widthFooterRef.current[key] : 0
      const max = Math.max(h, b, f)
      if(['rowNumber', 'checkbox'].includes(key)) {
        leftSum += max
      } else if([...left].includes(key)) {
        leftSum += max+1
        countCellSimple += 1
      }
      else if([...right].includes(key)) {
        // console.log('separatorsRightRef', separatorsRightRef)
        rightSum += max+1
        countCellSimple += 1
      }
      else if(['menu'].includes(key)) {
        rightSum += max
      }
    }

    setMinWidthLeft(leftSum+separatorsLeftRef.current)
    setMinWidthRight(rightSum+separatorsRightRef.current)
  }, [cellWidth])

  // useEffect(() => {
  //   console.log('data23', data)
  // }, [data])

  return (
    <>
      <div /*thead*/
        ref={ref}
        className={classNames("flex flex-1 justify-between h-8 border border-slate-200 rounded-md bg-slate-50 opacity-100 z-10 after:content-[''] after:h-3 after:absolute after:-bottom-3 after:inset-x-0 after:bg-white", `after:min-w-[var(--alter-width)]`)}
        // @ts-ignore
        style={{minWidth: minWidthTrRef.current, '--alter-width': `${minWidthTrRef.current}px`}}
      >

        {colStretch &&
          <>
            {/* footer left */}
            <div className={classNames('flex items-center overflow-hidden', 0 ? 'border border-red-500' : '')} {...{style: {minWidth: minWidthLeft}}} >

              {colRowNumber && <FooterCellRowNumber ref={rowNumberRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} cell={colRowNumber} />}

              {/* footer checkbox */}
              {colCheckbox && <FooterCellCheckbox ref={checkboxRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} cell={colCheckbox} selected={selected} setSelectAll={setSelectAll} />}

              {colsUntilStretch.map(header =>
                <Fragment key={header.id}>
                  <FooterCellSimple data={data} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} widthFooterRef={widthFooterRef} cell={header} ref={(el: any) => untilRef.current[header.id] = el} />
                  <FooterCellSeparator ref={(el: number) => separatorsLeftRef.current += el}/>
                </Fragment>
              )}
            </div>


            {/* footer stretch */}
            <div className={'flex flex-1 items-center'} {...{style: {minWidth: (colStretch.column.columnDef.minSize || 300) + 0}}}>
              <FooterCellStretch cell={colStretch} />
            </div>

            <div className={'flex items-center'}>
              <FooterCellSeparator isHidden={colsAfterStretch.length == 0 && !colMenu}/>
            </div>

            {/* footer right */}
            <div className={classNames('flex items-center ', 0 ? 'border border-red-500' : '')}
                 {...{ style: {
                     minWidth: minWidthRight
                 }}}
            >

              {colsAfterStretch.map((footer, index) => {
                // console.log(4444, footer, index+1, colsAfterStretch.length)
                // return <HeaderCellSimple key={header.id} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} cell={header} ref={(el: any) => afterRef.current[header.id] = el} />
                return <Fragment key={footer.id}>
                  <FooterCellSimple cellWidth={cellWidth} setCellWidth={setCellWidth} data={data} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} widthFooterRef={widthFooterRef} cell={footer} ref={(el: any) => afterRef.current[footer.id] = el} />
                  {index+1 < colsAfterStretch.length ? <FooterCellSeparator ref={(el: number) => separatorsRightRef.current += el} isHidden={colsAfterStretch.length == index+1 && !colMenu}/> : ''}
                </Fragment>
              })}

              {/* header menu */}
              {colMenu ?
                <FooterCellRowContextMenu ref={contextMenuRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} cell={colMenu} scrollWidth={scrollWidth} />
              :
                ''
              }
            </div>

          </>
        }

      </div>
      <div className={'min-w-[12px]'}></div>
    </>
  );
})

export default TableFooter;