import * as React from 'react';
import {Fragment, useEffect} from 'react';
import {flexRender, Header} from "@tanstack/react-table";

type Props = {
  // cell: Header<any, any>
  cell: any
  selected: any
  setSelectAll: any
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const HeaderCellStretch: React.FC<Props> = (props: Props) => {
  const { cell, selected, setSelectAll } = props;
  useEffect(() => {
    // console.log('cell1', cell, cell.column.columnDef.header)
  }, [cell])
  return (
    <Fragment>
      <div
        {...{
          colSpan: cell.colSpan,
          className: 'flex grow text-sm px-4 py-3 items-center justify-start h-full text-slate-600 overflow-hidden',
        }}
      >
          <span className='whitespace-nowrap text-ellipsis overflow-hidden'>
            {typeof cell.column.columnDef.header === 'function' ?
              <cell.column.columnDef.header { ...{...cell.getContext(), setSelectedAll2:(checked: boolean) => setSelectAll(checked), checkedAll2:selected?.all } }/>
              :
              cell.isPlaceholder ? null : flexRender(cell.column.columnDef.header, cell.getContext())
            }
            {/*{cell.isPlaceholder ? null : flexRender(cell.column.columnDef.header, cell.getContext())}*/}
            {/*{<cell.column.columnDef.header { ...{...cell.getContext(), setSelectedAll2:(checked: boolean) => setSelectAll(checked), checkedAll2:selected?.all } }/>}*/}
          </span>
      </div>
    </Fragment>
  );
};

export default HeaderCellStretch;