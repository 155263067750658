import React, {forwardRef, useId, useState} from 'react';
type Props = {
  label?: any
  placeholder?: string
  onChange?: (value: any) => void
  onClick?: () => void
  ref?: any
  isError?: boolean
  errorText?: string
  helpText?: string
  type?: string
  id?: string
  checked?: boolean
  disabled?: boolean
  className?: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Checkbox: React.FC<Props> = forwardRef<HTMLInputElement, Props>((
  { label,
    onChange,
    placeholder,
    onClick ,
    isError,
    errorText,
    helpText,
    type,
    id ,
    checked,
    disabled = false,
    className = ''
  }, ref) => {

  const id2 = useId();

  return (
      <div className={classNames('', className)}>
        <div className={'flex items-center relative'}>

          <input
            id={id || id2}
            name={id || id2}
            type="checkbox"
            className={classNames("h-4 w-4 rounded border-slate-400 text-sky-600 hover:border-sky-500 focus:ring-sky-300 disabled:text-slate-400 disabled:border-slate-300 disabled:hover:border-slate-300",
              disabled ? '' : 'cursor-pointer')}
            checked={checked}
            // disabled={isLoading || isError}
            onChange={(event) => onChange ? onChange(event.target.checked) : null}
            ref={ ref }
            disabled={disabled}
            onClick={(e) => e.stopPropagation()}
          />
          {label ?
            <label htmlFor={id || id2} className={classNames("ml-3", disabled ? 'text-slate-400' : 'cursor-pointer')}>{ label }</label>
            :
            '' }
          <label htmlFor={id || id2}
                 className={classNames('absolute', label ? '-top-0 -left-1 w-6 h-6' : '-top-1.5 -left-1.5 w-7 h-7',
                   disabled ? '' : 'cursor-pointer',
                   // 'border border-red-500 hover:bg-amber-600/10'
                 )}
                 onClick={(e) => e.stopPropagation()}></label>
        </div>
      </div>
  );
});

export default Checkbox;
