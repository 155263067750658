import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Input from "../../Input/Input";
import {
  useAddProductCategoryMutation,
  useDelProductCategoryMutation,
  useGetProductCategoriesQuery,
  useLazyGetProductCategoryQuery,
  useUpdateProductCategoryMutation
} from "../../../../api/productCategory";
import Button from "../../buttons/Button";
import {useNotification} from "../../../../hooks/notification";
import SelectFlexHeight from "components/ui/select/selectFlexHeight/SelectFlexHeight";
import {IProductCategory} from "../../../../models/IProductCategory";
import {SlideServiceType} from "../Slides";

type Props = {
  idProductCategory: string

  _slide?: SlideServiceType
};

const ProductCategorySlide: React.FC<Props> = (props: Props) => {
  const {idProductCategory, _slide} = props;
  // const {data, isFetching} = useGetProductQuery({id: idProduct});
  const [getProductCategory, {data, isFetching, isLoading: isLoadingProductCategory, status}] = useLazyGetProductCategoryQuery();
  const [ updateProductCategory ] = useUpdateProductCategoryMutation();
  const [ addProductCategory ] = useAddProductCategoryMutation();
  const [ delProductCategory ] = useDelProductCategoryMutation()
  const notification = useNotification();
  const [name, setName] = useState<string>('');
  const [categoryParent, setCategoryParent] = useState<any>();

  const onOk = useCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if(data?._id) {
        return updateProductCategory({id: data._id, body: { categoryName_rus: name, ProductCategoryParent: categoryParent?._id } }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        );
      } else if(idProductCategory === 'new') {
        return addProductCategory({ categoryName_rus: name, ProductCategoryParent: categoryParent?._id }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        )
      }
    })

  }, [data, name, categoryParent])

  useEffect(() => {
    _slide?.acceptHandler(onOk) // устанавливаем обработчик Ok (Save) в Slide
    if(idProductCategory === 'new') {
      _slide?.acceptButton('Создать')
    } else {
      _slide?.acceptButton('Сохранить')
    }
  }, [onOk])

  useEffect(() => {
    if(idProductCategory !== 'new') {
      getProductCategory({id: idProductCategory})
    }

  }, [])

  useEffect(() => {
    if(idProductCategory !== 'new') {
      // title in header
      const title = `${data?.categoryName_rus}`
      _slide?.titleFn(title);
      _slide?.subTitleFn('Категория товара')

      setName(data?.categoryName_rus ?? '')
    } else {
      // title in header
      _slide?.titleFn('Новая категория товаров');
      _slide?.subTitleFn('Категория товара')
    }
  }, [data]);

  const delCategory = () => {
    delProductCategory(idProductCategory).then(
      (r: any) => {
        if(r?.error) {
          _slide?.errorTextFn(r.error.data.message)
        } else {
          _slide?.accept()
        }
      }
    )
  }

  const {data: productCategories, isFetching: isFetchingSelect, isLoading} = useGetProductCategoriesQuery()

  const treeToList2 = (tree: any, parentName?: string) => {
    const a = tree.map((item: any) => {
      const {subCategories, ...rest} = item;
      if(parentName) {
        rest.categoryName_rus = `${parentName} - ${rest.categoryName_rus}`
      }

      // исключаем из списка категории, которые лежат ниже текущей - idProductCategory
      if(subCategories.length > 0 && item._id === idProductCategory) {
        return []
      }
      // исключаем из списка текущую категорию - idProductCategory
      if(item._id === idProductCategory) {
        return []
      }

      if(subCategories.length > 0) {
        // return [...treeToList2(subCategories, rest.categoryName_rus)] // не отображаем родительские категории, только конечные
        return [rest, ...treeToList2(subCategories, rest.categoryName_rus)]
      } else {
        return [rest, ...treeToList2(subCategories, rest.categoryName_rus)]
      }
    })

    // добавить пункт Все в начало: не используется - нужно очищать select при выборе Все
    if(!parentName) {
      a.unshift([{_id: '', categoryName_rus: 'Все'}])
    }

    return a.flat()
  }

  const [cats, setCats] = useState()
  useEffect(() => {
    if(productCategories?.data && (data?.ProductCategoryParent || idProductCategory === 'new')) {
      const list = treeToList2(productCategories.data).map((item: any) => ({option: item}))
      setCats(list)
    }
  }, [productCategories, data]);

  return (
    <>
      {isFetching ? 'Загрузка...'
      :
        <form className="flex flex-col gap-6 text-sm text-slate-700 " /*onSubmit={onSubmitClient}*/>

          <Input label='Наименование категории'
                 value={name}
                 onChange={(e) => setName(e.target.value)} />

          <SelectFlexHeight
            label='Родительская категория'
            data={cats}
            optionKey='categoryName_rus'
            initSelected={data?.ProductCategoryParent}
            onChange={setCategoryParent}
            initStatus={idProductCategory==='new' ? 'fulfilled' : status}
          />


        </form>
      }

      <div className='mt-10'>
        <Button text='Удалить' onClick={delCategory} style='DANGER' />
      </div>
    </>
  );
};

export default ProductCategorySlide;