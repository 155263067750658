import * as React from 'react';
import {Cell, flexRender, Row} from "@tanstack/react-table";
import {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from "react";

type Props = {
  cell: Cell<any, any>
  widthBodyRef: any
  widthHeaderRef: any
  widthFooterRef: any
  ref: any
  data?: any
  cellWidth?: any
  setCellWidth?: any
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CellSimple: React.FC<Props> = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { cell , widthBodyRef, widthHeaderRef, widthFooterRef, data, cellWidth, setCellWidth} = props;
  const ref2 = useRef<HTMLDivElement>(null)
  const init2 = useRef<boolean>(true)
  const [style, setStyle] = useState<any>({})
  const [init, setInit] = useState<boolean>(false)

  // const cellRef = useRef<HTMLDivElement>(null)
  // useEffect(() => {
  //   // console.log(7, cell.column.id, cellRef.current?.clientWidth, widthRef)
  //   const w = widthRef.current
  //   const clientWidth = ref.current?.clientWidth
  //   if(clientWidth)
  //     widthRef.current = {...w, [cell.column.id]: w[cell.column.id] ? (w[cell.column.id] < ref.current?.clientWidth ? ref.current?.clientWidth : w[cell.column.id]) : ref.current?.clientWidth}
  //
  //   // cell.column.columnDef.size = cellRef.current?.clientWidth
  // }, [ref.current?.clientWidth]);
  // console.log(9, ref)
  // const minWidth = useMemo(() => {
  //   const minDef = cell.column.columnDef.minSize
  //   const ext = widthRef.current[cell.column.id]
  //   return ext
  //   // if(minDef && minDef > ext) {
  //   //   return minDef
  //   // } else {
  //   //   return ext
  //   // }
  // }, [widthRef.current])

  useLayoutEffect(() => {
   // console.log('монтирование cell', cell.column.id)
  }, [])

  useLayoutEffect(() => {
    // if(cell.column.id == 'brandName') {

    // }
    if(init || init2.current) {
      // console.log('useLayoutEffect cell', cell.column.id, init, 'offset', ref2.current?.offsetWidth )
      const id = cell.column.id
      const offsetWidth = ref2.current?.offsetWidth ? ref2.current?.offsetWidth : 0
      const minWidth = cell.column.columnDef.minSize || 50 // 50 - никогда не должно сработать, настраивается в useTable defaultColumn
      const maxWidth = cell.column.columnDef.maxSize || 400 // 400 - никогда не должно сработать

      let width = Math.max(offsetWidth, minWidth)
      width = Math.min(width, maxWidth)

      if(widthBodyRef.current[id]) {
        // console.log('useLayoutEffect CellSimple', widthBodyRef.current[id], width)
        if(width > widthBodyRef.current[id]) {
          widthBodyRef.current = { ...widthBodyRef.current, [id]: width }
        } else {
          // глючит!
          // уменьшаем только если уменьшение больше 20%
          // const percent = (widthBodyRef.current[id] * 100 / width) - 100
          // console.log('percent', percent, widthBodyRef.current[id], width)
          // if(percent >= 20 && !init2.current) {
            // widthBodyRef.current = { ...widthBodyRef.current, [id]: width }
          // }

        }
      } else {
        widthBodyRef.current = { ...widthBodyRef.current, [id]: width }
      }

      const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
      const b = widthBodyRef.current[cell.column.id] ? widthBodyRef.current[cell.column.id] : 0
      const f = widthFooterRef && widthFooterRef.current[cell.column.id] ? widthFooterRef.current[cell.column.id] : 0
      const max = Math.max(h, b, f)

      if(max !== style?.minWidth) {
        setStyle({
          minWidth: max,
          maxWidth: max
        })
        // console.log('setCellWidth cell', max, style?.minWidth)
        if(setCellWidth) setCellWidth({width: max})
      }

      if(init) setInit(false)
    }


  },[init])

  useLayoutEffect(() => {
    // if(cell.column.id === 'price_sum')

    if(init2.current) {
      init2.current = false
    } else {

      if(data._id === cell.row.original._id) {
        // console.log('setInit cell', 'data', data, 'cell', cell.row.original._id)
        setInit(true)

      }
    }

  }, [data])

  useEffect(() => {
    const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
    const b = widthBodyRef.current[cell.column.id] ? widthBodyRef.current[cell.column.id] : 0
    const f = widthFooterRef && widthFooterRef.current[cell.column.id] ? widthFooterRef.current[cell.column.id] : 0
    const max = Math.max(h, b, f)
    // console.log(222, 'useEffect', cell.column.id, max, h, b, f, 'minWidth', style?.minWidth)
    if(max !== style?.minWidth) {
      setStyle({
        minWidth: max,
        maxWidth: max
      })
    }
  }, [cellWidth])

  // раскрываем внешнему ref только нужные методы
  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle', cell.column.id, ref2.current?.offsetWidth)
    return {
      offsetWidth: ref2.current?.offsetWidth,
    } as HTMLDivElement | any
  }, [])

  return (
    <>
        {/*<div ref={ref2}*/}
        {/*  {...{*/}
        {/*    style: {*/}
        {/*      ...style*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  className={classNames("flex px-4 items-center h-full text-slate-600",*/}
        {/*    // 'border border-black',*/}
        {/*    )}>*/}
        {/*  <span className={classNames('text-sm w-full h-full flex items-center',*/}
        {/*    style?.minWidth ? 'line-clamp-2 break-words' : 'whitespace-nowrap',*/}
        {/*    cell.column.columnDef.meta?.side ? cell.column.columnDef.meta?.side : 'justify-start')}>*/}
        {/*    {flexRender(cell.column.columnDef.cell, { ...{...cell.getContext(), cellRef:111 } })}*/}
        {/*    /!*{widthRef.current[cell.column.id]}{flexRender(cell.column.columnDef.cell, cell.getContext())}*!/*/}

        {/*    /!*{<cell.column.columnDef.cell { ...{...cell.getContext(), cellRef:cellRef } }/>}*!/*/}
        {/*  </span>*/}
        {/*</div>*/}
      <div ref={ref2}
           {...{
             className: classNames(
               '',
               0 ? 'border' : '',
               ' px-4 text-sm ',
               (init || init2.current) ? 'inline-block whitespace-nowrap' : 'flex items-center h-full w-full line-clamp-2',
               cell.column.columnDef.meta?.side ? cell.column.columnDef.meta?.side : 'justify-start',
             ),
             style: (init || init2.current) ? {} : {minWidth: style.minWidth+1, maxWidth: style.maxWidth+1},
           }}
      >
        {flexRender(cell.column.columnDef.cell, { ...{...cell.getContext(), cellRef:111 } })}
      </div>
    </>
  );
})

export default CellSimple;