import {FetchBaseQueryArgs} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {authApi} from "../auth";
import {logOut} from "../../store/reducers/AuthSlice";

export type ClientError = {
  status: number
  data: {
    message: string,
    details: {
      property: string,
      message: string
    }[]
  }

}

// Запрос на сервер с обновлением access-token
// Источник: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
export const fetchBaseQueryWithReauth = (options: FetchBaseQueryArgs) => {
  options = {
    prepareHeaders: (headers, { getState }) => {
      const state: any = getState()
      headers.set('Authorization', `Bearer ${state?.auth?.data?.accessToken}`)
      return headers;
    },
    ...options
  }
  const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError | ClientError> = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery(options)(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
      const resultRefresh = await api.dispatch(authApi.endpoints.refreshToken.initiate(void(0), { subscribe: false, forceRefetch: true }));
      if (resultRefresh.isSuccess) {
        // retry the initial query
        result = await fetchBaseQuery(options)(args, api, extraOptions)
      } else {
        api.dispatch(logOut())
      }
    }

    return result
  }

  return baseQueryWithReauth
}