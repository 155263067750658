import {store} from "../index";
import {useAppDispatch, useAppSelector} from "./redux";
import {unmountDialog, showDialog} from "../store/reducers/DialogSlice";


export const useDialog = () => {
  const dispatch = useAppDispatch();
  const show = (component: any) => {
    return new Promise<any>((resolve, reject) => {
      const data = {
        component,
        resolve,
        reject
      };
      dispatch(showDialog(data));
    })
  }
  const unmount = (dialog: any) => {
    dispatch(unmountDialog(dialog));
  }

  // const accept = (id: any, accept: any) => {
  //   console.log('accept1', id)
  //   const dialog = dialogs.find(item => item.id === id);
  //   if(dialog) dialog.accept3 = accept;
  // }

  return {
    show,
    unmount
  }
}