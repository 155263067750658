import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {IDocCustomerOrder, IDocCustomerOrderPositionRequest} from "../models/IDocCustomerOrder";

export type ProductsRequest = {
  page?: number
  size: number | string | undefined
  sort: string | undefined
  last: string
  last_sort: string
  filterText: string
  cat: string
  isPool: boolean
  isOnline: boolean
  onlyRoot: boolean
}

export type DocCustomerOrdersResponse = {
  data: IDocCustomerOrder[];
  meta: any
}

// export interface Position extends IDocCustomerOrderPosition {
//   product_id: string
//   supplier_id: string
//   qty: string
//   price_purchase: string
//   delivery_time: string
// }

export type newDocCustomerOrderRequest = {
  number: string
  date: string
  customer_id: string
  positions: IDocCustomerOrderPositionRequest[]
  note: string
}

export type updateDocCustomerOrderRequest = {
  customer_id: string
  positions: IDocCustomerOrderPositionRequest[]
  note: string
}

export interface ListResponse<T> {
  data: T[],
  meta: {
    page: number
    perPage: number
    totalElements: number
    hasNextPage: boolean
  }
}

export const docCustomerOrdersApi= createApi({
  reducerPath: 'docCustomerOrdersApi',
  tagTypes: ['DocCustomerOrdersApi', 'CustomerOrder'],
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/doc-customer-orders` }),
  endpoints: (builder) => ({
    getDocCustomerOrders: builder.query<ListResponse<any>, any>({
      query: (params: any) => ({
        url: `/`,
        method: 'POST',
        body: params,
        credentials: 'include',
      }),
      serializeQueryArgs: ({queryArgs, endpointDefinition, endpointName}) => {
        // console.log(555, queryArgs, endpointDefinition, endpointName)
        return endpointName
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { page, size, sort, last, filterText, cat, isPool, isOnline } }) => {
        // if((filterText || cat) && !last) { // filterText = 'term'  last = ''
        if(!last) { // last = ''
          console.log('----------новый массив для фильтра', filterText)
          currentCache.data = newItems.data
          currentCache.meta = newItems.meta
        }
          // else if(!filterText && !last) { // filterText = ''  last = ''
          //   console.log('----------новый массив')
          //   currentCache.data = newItems.data
          //   currentCache.meta = newItems.meta
        // }
        else {
          currentCache.data.push(...newItems.data)
          currentCache.meta = newItems.meta
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }: any) {
        console.log(9999999)
        return currentArg !== previousArg
      },
      // providesTags: ['Products'],
      // providesTags: (result) =>
      //   result
      //     ? [
      //       ...result.data.map(({ _id }) => {console.log(1, _id); return { type: 'Products' as const, id: _id }}),
      //       { type: 'Products', id: 'PARTIAL-LIST' },
      //     ]
      //     : [{ type: 'Products', id: 'PARTIAL-LIST' }],
      // providesTags: (result) => result ? []
    }),
    createDocCustomerOrder: builder.mutation<IDocCustomerOrder, newDocCustomerOrderRequest>({
      query: (body) => ({
        url: '/create',
        method: 'POST',
        body,
        credentials: 'include',
      }),
      // invalidatesTags: ['Products']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    updateDocCustomerOrder: builder.mutation<IDocCustomerOrder, { id: string, data: updateDocCustomerOrderRequest }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['CustomerOrder']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    getDocCustomerOrder: builder.query<IDocCustomerOrder, {id: string}>({
      query: ({id}) => ({
        url: `/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      providesTags: ['CustomerOrder']
    }),
    delDocCustomerOrders: builder.mutation<void, { selected: any, params: any }>({
      query: (body) => ({
        url: `/del-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['DocCustomerOrdersApi']
    }),
    moveDocCustomerOrders: builder.mutation<void, { selected: any, params: any } | { id: string }>({
      query: (body) => ({
        url: `/move-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['CustomerOrder']
    }),
    moveCancelDocCustomerOrders: builder.mutation<void, { selected: any, params: any } | { id: string }>({
      query: (body) => ({
        url: `/move-cancel-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['CustomerOrder']
    }),
  }),
});

export const {
  useCreateDocCustomerOrderMutation,
  useGetDocCustomerOrdersQuery,
  useLazyGetDocCustomerOrdersQuery,
  useLazyGetDocCustomerOrderQuery,
  useUpdateDocCustomerOrderMutation,
  useDelDocCustomerOrdersMutation,
  useMoveDocCustomerOrdersMutation,
  useMoveCancelDocCustomerOrdersMutation,
} = docCustomerOrdersApi;