import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import type { AsyncThunk } from '@reduxjs/toolkit';

import {ILogin} from "../../models/ILogin";
import {get} from "../../api";

import { authApi, LoginResponse } from '../../api/auth';

interface AuthState {
    isLoading: boolean;
    error: string | null;
    data: {
        tenant: string;
        userId: string;
        roles?: string[];
        accessToken: string
        memberTenants: {_id: string, name: string}[]
        Tenant: any
        login: string
    }

}

const initialState: AuthState = {
    isLoading: false,
    error: null,
    data: {
        tenant: '',
        userId: '',
        roles: [],
        accessToken: '',
        memberTenants: [],
        Tenant: null,
        login: ''
    }

}

export const logoutToServer = createAsyncThunk(
    'auth/logoutToServer',
    async ({ idUser }: any, { rejectWithValue, dispatch, getState }) => {
        try {
            await get(`${process.env.REACT_APP_URL_API}/auth/log-out`);
            // localStorage.setItem('accessToken', '');
            dispatch(logOut())
        } catch (e) {
            return rejectWithValue('Ошибка загрузки')
        }

    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser(state, action: PayloadAction<ILogin>) {
            console.log('setCurrentUser', action.payload)
            state.data.userId = action.payload._id;
            state.data.accessToken = action.payload.accessToken;
            state.data.memberTenants = action.payload.memberTenants;
            state.data.Tenant = action.payload.Tenant;
            state.data.login = action.payload.login

        },
        logOut(state) {
            state.data.userId = '';
            state.data.accessToken = '';
            state.data.memberTenants = [];
            state.data.Tenant = null;
            state.data.login = ''

        }
    },
  extraReducers: (builder) => {
    builder.addMatcher<ReturnType<AsyncThunk<LoginResponse, unknown, {}>['fulfilled']>>(
      (action) => authApi.endpoints.login.matchFulfilled(action) || authApi.endpoints.refreshToken.matchFulfilled(action),

      (state, action: any) => {
        state.data.userId = action.payload.Employee._id;
        state.data.accessToken = action.payload.accessToken;
        state.data.memberTenants = action.payload.memberTenants
        state.data.Tenant = action.payload.Tenant
        state.data.login = action.payload.login
      },
    );
  },
});

export const { setCurrentUser, logOut } = authSlice.actions;
export default authSlice.reducer;