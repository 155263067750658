import * as React from 'react';
import {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import {flexRender, Header} from "@tanstack/react-table";

type Props = {
  cell: Header<any, any> | undefined
  scrollWidth: any
  ref?: any
  widthHeaderRef: any
  widthBodyRef: any
}

const HeaderCellRowContextMenu: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { cell, scrollWidth, widthHeaderRef, widthBodyRef} = props;
  const ref2 = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<any>({})

  useLayoutEffect(() => {

    // console.log('useLayoutEffect HeaderCellRowContextMenu', )
    if(cell) {
      // console.log('useLayoutEffect', cell, ref2.current?.offsetWidth)
      const id = cell.id
      const offsetWidth = ref2.current?.offsetWidth ? ref2.current?.offsetWidth : 0
      const minWidth = cell.column.columnDef.minSize || 50 // 50 - никогда не должно сработать, настраивается в useTable defaultColumn
      const maxWidth = cell.column.columnDef.maxSize || 400 // 400 - никогда не должно сработать

      let width = offsetWidth < minWidth ? minWidth : offsetWidth
      width = width > maxWidth ? maxWidth : width


      if(widthHeaderRef.current[id]) {
        widthHeaderRef.current = {...widthHeaderRef.current, [id]: widthHeaderRef.current[id] > width ? widthHeaderRef.current[id] : width }
      } else {
        widthHeaderRef.current = { ...widthHeaderRef.current, [id]: width }
      }
    }

  },[])

  useEffect(() => {
    if(cell) {
      const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
      const b = widthBodyRef.current[cell.column.id] ? widthBodyRef.current[cell.column.id] : 0
      const s = h > b ? h : b
      setStyle({
        minWidth: s,
        maxWidth: s
      })
    }
  }, [widthHeaderRef.current, widthBodyRef.current])

  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle', cell?.column.id, ref2.current?.offsetWidth)
    return {
      clientWidth: ref2.current?.clientWidth,
      offsetWidth: ref2.current?.offsetWidth,
    } as HTMLDivElement | any
  }, [])

  return (
    <>

      { cell ? <div ref={ref2} className='flex items-center justify-center h-full text-slate-600  '
             {...{style: {
                 // width: header.getSize() + scrollWidth*2, // *2, т.к. ширина делится пополам
                 // minWidth: (Number(header.column.columnDef.minSize) ? Number(header.column.columnDef.minSize) : 0) + scrollWidth*2,
                 // maxWidth: (Number(header.column.columnDef.maxSize) ? Number(header.column.columnDef.maxSize) : 0) + scrollWidth*2,
                 ...style
               }}}>
          {cell.isPlaceholder
            ? null
            : flexRender(
              cell.column.columnDef.header,
              cell.getContext()
            )}
        </div>

      :
        <>
          {/* header компенсатор ширины скролла */}
          <div
            ref={ref}
            {...{style: {
                width: scrollWidth,
                minWidth: scrollWidth,
                maxWidth: scrollWidth,
              }}}></div>
        </> }
    </>
  );
})

export default HeaderCellRowContextMenu;