import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {IProductCategory} from "../models/IProductCategory";

export type AddProductCategoryRequest = {
  categoryName_rus: string;
  ProductCategoryParent?: string;
}

export interface ListResponse<T> {
  data: T[],
  meta: {
    totalElements: number
  }
}

export const productCategoryApi = createApi({
  reducerPath: 'productCategoryApi',
  tagTypes: ['ProductCategory'],
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/product-category` }),
  endpoints: (builder) => ({
    getProductCategories: builder.query<ListResponse<IProductCategory>, void>({
      query: () => ({
          url: `/`,
          method: 'GET',
          credentials: 'include',
      }),
      providesTags: ['ProductCategory'],
    }),
    getProductCategory: builder.query<IProductCategory, {id: string}>({
      query: ({id}) => ({
        url: `/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      // providesTags: ['Brands']
    }),
    addProductCategory: builder.mutation<IProductCategory, AddProductCategoryRequest>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['ProductCategory']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    updateProductCategory: builder.mutation<IProductCategory, { id: string, body: AddProductCategoryRequest }>({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['ProductCategory']
    }),
    delProductCategory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['ProductCategory']
    }),
  }),
});

export const {
  useGetProductCategoryQuery,
  useGetProductCategoriesQuery,
  useLazyGetProductCategoryQuery,
  useLazyGetProductCategoriesQuery,
  useAddProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDelProductCategoryMutation,
} = productCategoryApi;