import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {ICustomer} from "../models/ICustomer";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";

export type CustomersRequest = {
  page?: number
  size: number | string | undefined
  sort: string | undefined
  last: string
  filter: string
}

export type CustomersResponse = {
  data: ICustomer[];
  meta: any
}

export type AddCustomerRequest = {
  firstName: string;
  lastName: string;
}

export interface ListResponse<T> {
  data: T[],
  meta: {
    page: number,
    perPage: number,
    totalElements: number
    hasNextPage: boolean
  }
}

export const customerApi = createApi({
  reducerPath: 'customerApi',
  tagTypes: ['Customers'],
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/customer` }),
  endpoints: (builder) => ({
    getCustomers: builder.query<ListResponse<ICustomer>, CustomersRequest>({
      query: ({page, size, sort, last, filter}) => ({
        url: `/`,
        method: 'GET',
        params: { page: page, size, sort, last, filter },
        credentials: 'include',
      }),
      serializeQueryArgs: ({queryArgs, endpointDefinition, endpointName}) => {
        // console.log(555, queryArgs, endpointDefinition, endpointName)
        return endpointName
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { page, size, sort, last, filter } }) => {
        if(!last) { // filter = 'term'  last = ''
          console.log('----------новый массив для фильтра', filter)
          currentCache.data = newItems.data
          currentCache.meta = newItems.meta
        } else {
          currentCache.data.push(...newItems.data)
          currentCache.meta = newItems.meta
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }: any) {
        // console.log(444, currentArg !== previousArg, currentArg, previousArg)
        return currentArg !== previousArg
      },
      providesTags: ['Customers'],
      // providesTags: (result) =>
      //   result
      //     ? [
      //       ...result.data.map(({ _id }) => {console.log(1, _id); return { type: 'Brands' as const, id: _id }}),
      //       { type: 'Brands', id: 'PARTIAL-LIST' },
      //     ]
      //     : [{ type: 'Brands', id: 'PARTIAL-LIST' }],
      // providesTags: (result) => result ? []
    }),
    getCustomer: builder.query<ICustomer, {id: string}>({
      query: ({id}) => ({
        url: `/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
    }),
    addCustomer: builder.mutation<ICustomer, AddCustomerRequest>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['Customers']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    updateCustomer: builder.mutation<ICustomer, { id: string, body: AddCustomerRequest }>({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
    }),
    delCustomer: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['Customers']
    }),
    delCustomers: builder.mutation<void, string[]>({
      query: (ids) => ({
        url: `/del-arr`,
        method: 'POST',
        body: ids,
        credentials: 'include',
      }),
      invalidatesTags: ['Customers']
    })
  }),
});

export const {
  useLazyGetCustomersQuery,
  useLazyGetCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDelCustomerMutation,
  useDelCustomersMutation
} = customerApi;