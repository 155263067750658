import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {XMarkIcon} from "@heroicons/react/20/solid";
import Input, {InputProps} from "../Input";
import {formatCurrencyView, formatNumber, formatNumberToServer} from "../../../../utils/helpers";
import {DateTime} from "luxon";
import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";
import {getYear, getMonth} from "date-fns";
import range from "lodash/range";

type InputNumberProps = {
  value: Date
  onChange?: (e: any) => void
  label?: string
}

registerLocale('ru', ru)
const DataPicker: React.FC<InputNumberProps> = forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {
  const [startDate, setStartDate] = useState(new Date());
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  return (
    <DatePicker
      locale="ru"
      dateFormat="dd.MM.yyyy HH:mm:ss"
      customInput={<Input label={props?.label ? props.label : ''} />}
      selected={props.value}
      onChange={(date: Date) => props.onChange ? props.onChange(date) : null}
      showTimeSelect
      timeFormat="p"
      timeIntervals={15}
      renderCustomHeader={({
                             date,
                             changeYear,
                             changeMonth,
                             decreaseMonth,
                             increaseMonth,
                             prevMonthButtonDisabled,
                             nextMonthButtonDisabled,
                           }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value as unknown as number)}
          >
            {years.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
    />
  );
});

export default DataPicker;
