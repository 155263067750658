// import {useAppDispatch, useAppSelector} from "hooks/redux";
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Button from "../../components/ui/buttons/Button";
import TableConstantMultiColumns from "../../components/ui/table/tmpl/TableConstantMultiColumns";
import {createColumnHelper} from "@tanstack/react-table";
import {MainContext} from "../../components/Main";
import {useDialog} from "../../hooks/dialog";
// import {fetchClient} from "store/reducers/clients/ClientThunk";
import {formatCurrencyView, formatSumSeparator} from "../../utils/helpers";
import ActionGroupOrder from "../../components/ui/table3/action-groups/tmpl/ActionGroupOrder";
import {useLazyGetSelectedProductsQuery} from "../../api/product";
import {useNavigate} from "react-router-dom";
import {useSlide} from "../../hooks/slide";
import {useGetCursor} from "../../hooks/useGetCursor";
import {DateTime} from "luxon";
import NotificationError from "../../components/ui/notifications/tmpl/NotificationError";
import NotificationSuccess from "../../components/ui/notifications/tmpl/NotificationSuccess";
import {useNotification} from "../../hooks/notification";
import DialogQuestionDanger from "../../components/ui/dialogs/tmpl/DialogQuestionDanger";
import {IDocSupplierOrder} from "../../models/IDocSupplierOrder";
import {
  useDelDocSupplierOrdersMutation,
  useLazyGetDocSupplierOrdersQuery,
  useMoveCancelDocSupplierOrdersMutation,
  useMoveDocSupplierOrdersMutation
} from "../../api/doc-supplier-orders-api";
import {ColumnsWidth} from "../../components/ui/table/Table";

type Props = {
  children?: React.ReactNode
  param?: string
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const DocSupplierOrders: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const notification = useNotification();
  const {children, param: name = 'по-умолчанию'} = props;
  const [positionsList, setPositionsList] = useState<any[]>([])
  const [positionsData, setPositionsData] = useState<any[]>([])
  const {main, title, content} = useContext(MainContext)
  const dialog = useDialog()
  const [getSelectedProducts] = useLazyGetSelectedProductsQuery()
  const slides = useSlide();
  const [changeData, setChangeData] = useState<any>()

  const [sort, setSort] = useState<string>('date:desc')
  const [params, setParams] = useState<any>()

  const [getOrder, obj, lastArgs] = useLazyGetDocSupplierOrdersQuery()
  const [getSupplierOrders, {data: orders, isFetching, isLoading, isError: isError3, error: error3}] = useGetCursor(getOrder, obj)

  const [delDocs, {}] = useDelDocSupplierOrdersMutation();
  const [moveDocs, {}] = useMoveDocSupplierOrdersMutation();
  const [moveCancelDocs, {}] = useMoveCancelDocSupplierOrdersMutation();

  const getSupplierOrderGap = (params: any) => {
    // if(filterText) params.filterText = filterText
    // if(cat) params.cat = cat;
    console.log('gap', params)
    // setParams(params)
    return getSupplierOrders({ ...params, sort })
  }
  useEffect(() => {
    console.log('setParams2', params, { sort })
    setParams({ sort })
  }, [ sort ])


  const columnHelper = createColumnHelper<IDocSupplierOrder & {price_sum: string}>()
  const columns = [
    columnHelper.accessor('number', {
      header: () => 'Номер',
      // cell: info => <span className={'text-sky-600'}>{info.renderValue()}</span>,
      minSize: 50,
      // size: 200,
      meta: {side: 'justify-center'}
    }),
    columnHelper.accessor('date', {
      header: () => 'Дата',
      cell: info => {
        const date = info.renderValue()
        if(date) {
          return DateTime.fromISO(date).toFormat('dd.MM.yyyy HH:mm:ss')
        }
      },
      minSize: 50,
      size: 200,
    }),
    columnHelper.accessor('supplier_name', {
      header: () => 'Поставщик',
      // cell: ,
      minSize: 50,
      size: 200,
      meta: { width: ColumnsWidth.STRETCH, },
    }),
    // columnHelper.accessor('countPositionsSuppliers', {
    //   header: () => 'Поставщики',
    //   cell: info => {
    //     const suppliers = info.renderValue()
    //     if(Array.isArray(suppliers)) {
    //       return suppliers.map(item => `${item.supplierName || 'Без поставщика'} (${item.count})`).join(', ')
    //     }
    //   },
    //   minSize: 50,
    //   size: 200,
    //   meta: { width: ColumnsWidth.STRETCH, },
    // }),
    columnHelper.accessor('countPositions', {
      header: () => 'Кол-во строк',
      cell: info => info.renderValue(),
      minSize: 50,
      size: 200,
      meta: {side: 'justify-center'}

    }),
    columnHelper.accessor('sumPositions', {
      header: () => 'Сумма',
      cell: info => info.getValue() ? formatSumSeparator(formatCurrencyView(info.getValue())) + ' ₽' : '0 ₽',
      minSize: 50,
      size: 200,
      meta: {side: 'justify-end'}
    }),
    columnHelper.accessor('note', {
      header: 'Примечание',
      cell: (info: any) => info.renderValue(),
      // size: 200,
      maxSize: 150,
      // minSize: 100
    }),
  ]

  const create = () => {
    navigate('/doc-supplier-orders/new')
  }

  const clickSupplierOrder = (info: any) => {
    console.log('123', info)
    navigate(`/doc-supplier-orders/${info.row.original._id}`)
  }

  const onDelete = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    dialog.show(<DialogQuestionDanger title='Внимание' text='Вы действительно хотите удалить выбранные документы?' buttonTextOk={'Удалить'}/>).then(
      () => {
        delDocs({selected, params}).then(
          (r: any) => {
            // setParams({ cat, ...filter2, filterText, onlyRoot, sort })

            if(r?.error) {
              console.log(r.error.data.details)
              if(r.error.data.details == 1) {
                setParams({ sort })
              }
              notification.show(<NotificationError text={r.error.data.message} />)
            } else {
              setParams({ sort })
              notification.show(<NotificationSuccess text={'Документы удалены'} />)
            }

          }
        )
      }
    )

  }

  const onMove = (selected: { all: boolean, count: number, include: any[], exclude: any[] }) => {
    moveDocs({selected, params}).then(
      (r: any) => {
        // setParams({ cat, ...filter2, filterText, onlyRoot, sort })

        if(r?.error) {
          console.log(r.error.data.details)
          if(r.error.data.details == 1) {
            setParams({ sort })
          }
          notification.show(<NotificationError text={r.error.data.message} />)
        } else {
          setParams({ sort })
          notification.show(<NotificationSuccess text={'Документы проведены'} />)
        }

      }
    )
  }

  const onMoveCancel = (selected: { all: boolean, count: number, include: any[], exclude: any[] }) => {
    moveCancelDocs({selected, params}).then(
      (r: any) => {
        // setParams({ cat, ...filter2, filterText, onlyRoot, sort })

        if(r?.error) {
          console.log(r.error.data.details)
          if(r.error.data.details == 1) {
            setParams({ sort })
          }
          notification.show(<NotificationError text={r.error.data.message} />)
        } else {
          setParams({ sort })
          notification.show(<NotificationSuccess text={'Проводка отменена'} />)
        }

      }
    )
  }

  return (
    <>
      <div className={'mb-2'}>
        <Button text={'Создать'} onClick={create} />
      </div>

      <div className="flex flex-col justify-start"
           {...{
             style: {
               height: `calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 82px)`,
             },
           }}>

        <TableConstantMultiColumns
          columns={columns}
          data={orders.data}
          isLoading={isLoading}
          isFetching={isFetching}
          hasNextPage={isLoading ? isLoading : orders?.meta.hasNextPage}
          getData={getSupplierOrderGap}
          params={params}
          onClickRow={(info) => clickSupplierOrder(info)}
          isCheckbox={true}
          isRowNumber={true}
          actionGroup={<ActionGroupOrder
            onDelete={onDelete}
            onMove={onMove}
            onMoveCancel={onMoveCancel}
          />}
          meta={ {totalElements: orders?.meta.totalElements} }
        />

      </div>
    </>
  );
};

export default DocSupplierOrders;