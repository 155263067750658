import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {IProduct} from "../models/IProduct";

export type ProductsRequest = {
  page?: number
  size: number | string | undefined
  sort: string | undefined
  last: string
  last_sort: string
  filterText: string
  cat: string
  isPool: boolean
  isOnline: boolean
  onlyRoot: boolean
}

export type ProductsResponse = {
  data: IProduct[];
  meta: any
}

export type AddProductRequest = {
  title_rus: string;
  number: string;
  Brand: string
  Categories: string[]
  isPool: boolean
  isOnline: boolean
  price_sale: number
  price_purchase: number
  qty: number
  images: string[],
  description: string
}

export interface ListResponse<T> {
  data: T[],
  meta: {
    page: number,
    perPage: number,
    totalElements: number
    hasNextPage: boolean
  }
}

export const abcpApi = createApi({
  reducerPath: 'abcpApi',
  tagTypes: ['Orders', 'LoadOrdersResult'],
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/abcp` }),
  endpoints: (builder) => ({
    getABCPOrders: builder.query<ListResponse<any>, any>({
      query: (params: any) => ({
        url: `/orders`,
        method: 'POST',
        body: params,
        credentials: 'include',
      }),
      serializeQueryArgs: ({queryArgs, endpointDefinition, endpointName}) => {
        // console.log(555, queryArgs, endpointDefinition, endpointName)
        return endpointName
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { page, size, sort, last, filterText, cat, isPool, isOnline } }) => {
        // if((filterText || cat) && !last) { // filterText = 'term'  last = ''
        if(!last) { // last = ''
          console.log('----------новый массив для фильтра', filterText)
          currentCache.data = newItems.data
          currentCache.meta = newItems.meta
        }
          // else if(!filterText && !last) { // filterText = ''  last = ''
          //   console.log('----------новый массив')
          //   currentCache.data = newItems.data
          //   currentCache.meta = newItems.meta
        // }
        else {
          currentCache.data.push(...newItems.data)
          currentCache.meta = newItems.meta
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }: any) {
        console.log(9999999)
        return currentArg !== previousArg
      },
      providesTags: ['Orders'],
      // providesTags: ['Products'],
      // providesTags: (result) =>
      //   result
      //     ? [
      //       ...result.data.map(({ _id }) => {console.log(1, _id); return { type: 'Products' as const, id: _id }}),
      //       { type: 'Products', id: 'PARTIAL-LIST' },
      //     ]
      //     : [{ type: 'Products', id: 'PARTIAL-LIST' }],
      // providesTags: (result) => result ? []
    }),
    getOrdersFromABCP: builder.mutation<any, void>({
      query: () => ({
        url: `orders/get`,
        method: 'POST',
        credentials: 'include',
      }),
      invalidatesTags: ['Orders', 'LoadOrdersResult'],
      // invalidatesTags: (result, error, arg) => {
      //   if(error) return []
      //   return ['Products']
      // },
    }),
    getLoadOrdersResult: builder.query<any, {size?: number} | void>({
      query: (params) => ({
        url: params?.size ? `orders/load-result?size=${params.size}` : `orders/load-result`,
        method: 'GET',
        credentials: 'include',
      }),
      providesTags: ['LoadOrdersResult'],
    }),
  }),
});

export const {
  useLazyGetABCPOrdersQuery,
  useGetOrdersFromABCPMutation,
  useLazyGetLoadOrdersResultQuery,
} = abcpApi;