import * as React from 'react';
// import {useAppDispatch, useAppSelector} from "hooks/redux";
import {cloneElement, useEffect, useRef, useState} from "react";
import {
  arrow, autoPlacement, autoUpdate, flip, FloatingArrow, FloatingPortal,
  offset,
  size,
  useClick,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs
} from "@floating-ui/react";
// import {fetchClient} from "store/reducers/clients/ClientThunk";

type Props = {
  children?: any
  param?: string
  content: any
};
export const PopoverContext = React.createContext<any>(null);
const PopoverIndex: React.FC<Props> = (props: Props) => {
  const arrowRef = useRef(null);
  const {children, param: name = 'по-умолчанию'} = props;
  const [isOpen, setIsOpen] = useState(false);
  const ARROW_HEIGHT = 7;
  const GAP = 2;
  const [maxWidth, setMaxWidth] = useState<number>(0)
  const {refs, floatingStyles, context} = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right-end',
    elements: {
      // reference: props.anchor,
    },
    middleware: [
      // flip(),
      // autoPlacement(),
      arrow({
        element: arrowRef,
      }),
      offset(ARROW_HEIGHT + GAP),
      size({
        apply({ rects, elements, availableHeight, availableWidth }) {
          setMaxWidth(availableWidth)
          // console.log('elements.floating.style', elements.floating.style)
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            maxWidth: `${availableWidth}px`,
            // minWidth: `${rects.reference.width}px`
          });
        },
        padding: 10
      })
    ],
    // whileElementsMounted(...args) {
    //   const cleanup = autoUpdate(...args, {animationFrame: true});
    //   // Important! Always return the cleanup function.
    //   return cleanup;
    // },
  });

  const ref = useMergeRefs([refs.setReference, children.ref]);
  const hover = useHover(context, {
    enabled: true,
    mouseOnly: true,
    restMs: 50,
  });
  const focus = useFocus(context, {
    enabled: true
  });
  const click = useClick(context, {
    enabled: true,
    ignoreMouse: true,
    toggle: true,
    keyboardHandlers: true,
    event: "click"
  });

  const {getReferenceProps, getFloatingProps} = useInteractions([
    hover,
    focus,
    click,
  ]);

  // useEffect(() => {
  //   console.log('floatingStyles', floatingStyles)
  //   // console.log('getFloatingProps', getFloatingProps())
  // }, [floatingStyles])

  return (
    <>
      {cloneElement(children, getReferenceProps({
        ref,
        // onClick(event) {
        //   event.stopPropagation();
        // }
      }))}
      {isOpen && (
        <FloatingPortal>
          <PopoverContext.Provider value={{maxWidth}}>

            <div className={'z-50'} ref={refs.setFloating} style={{
              ...floatingStyles,
              // overflowX: "auto",
              // width: '100%'
            }} {...getFloatingProps()}>
              {props.content}
              <FloatingArrow ref={arrowRef} context={context} className="
            opacity-50
            fill-black
            [&>path:first-of-type]:stroke-black
            [&>path:last-of-type]:stroke-black
          " />
            </div>
          </PopoverContext.Provider>
        </FloatingPortal>

      )}
    </>
  );
};

export default PopoverIndex;