import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import jwt_decode from "jwt-decode";
import { DateTime } from "luxon";
import {fetchProfile} from "../store/reducers/ProfileSlice";

const Profile: React.FC = () => {
  const {data: profile, error, isLoading} = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch();

    useEffect(() => {
        // if(orders?.length < 1) {
          dispatch(fetchProfile());
        // }

    },[])

    return (
        <div>
          {isLoading && <h1>Идет загрузка...</h1>}
          {error && <h1>Ошибка</h1>}
          <div>
            <div>_id: {profile?.userId ? profile.userId : 'не задано'}</div>
            {/*<div>Имя: {profile.userId ? profile.firstName : 'не задано'}</div>*/}
            <div>Логин: {profile && profile.login}</div>
          </div>
        </div>
    );


};

export default Profile;