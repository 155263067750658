import {useEffect, useMemo, useState} from 'react';
import type { FormEvent, FC } from 'react';

import { Link } from 'react-router-dom';

import {
  EnvelopeIcon,
  LockClosedIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowPathIcon,
  XMarkIcon, ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { useLoginMutation } from 'api/auth';
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";


const useLogInError = (error: FetchBaseQueryError | SerializedError | undefined | any): { message: string; details: string[] } | null => {
  const memoizedError = useMemo(() => {
    if (error !== undefined && 'status' in error) {
      try {
        const result: { message: string; details: { property: string; message: string; }[] } = error.data;

        return {
          message: result.message,
          details: result.details?.map(({ message }) => message) ?? [],
        };
      } catch {
        return { message: 'Unknown error', details: [] };
      }

    }

    return null;
  }, [error]);

  return memoizedError;
};

const LoginPage: FC<{ children?: never }> = () => {
  const [doLogin, { reset, isLoading, isError, error }] = useLoginMutation();

  const [login, setLogin] = useState(''); // vasya@yandex.ru
  const [password, setPassword] = useState(''); // 123
  const [rememberMe, setRememberMe] = useState(false);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const logInError = useLogInError(error);


  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    doLogin({ login, password, rememberMe });
  };

  useEffect(() => {
    console.log(123, error)
  }, [error])

  return (
    <div className="flex flex-col items-center min-w-[320px] min-h-screen p-4 bg-slate-50 sm:pt-8 sm:pb-6 md:px-8 md:pt-12">
      <h1 className="flex flex-col items-center gap-1 sm:gap-4 md:gap-6">
        <span className="text-2xl text-slate-500 font-medium sm:text-3xl md:text-5xl"><span className="text-sky-500 italic uppercase underline">v</span>tovare</span>
        <span className="text-lg text-slate-700 font-bold sm:text-2xl md:text-3xl">Войдите в свой аккаунт</span>
      </h1>

      <main className="max-w-xs shadow rounded-lg w-full mt-2 bg-white sm:max-w-sm sm:mt-6 md:max-w-md md:mt-8">
        <form className="flex flex-col gap-4 text-sm text-slate-700 p-6 md:gap-6 md:p-10" onSubmit={onSubmit}>
          <div>
            <label htmlFor="email" className="font-medium">Электронная почта</label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="flex items-center absolute inset-y-0 left-0 pl-3 pointer-events-none">
                <EnvelopeIcon className="text-slate-400 w-5 h-5" />
              </div>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Введите e-mail"
                className="w-full rounded-md text-slate-900 border-slate-300 py-2 pl-10 pr-3 placeholder:text-slate-400 focus:border-sky-500"
                value={login}
                disabled={isLoading}
                onChange={(event) => setLogin(event.target.value)}
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="font-medium">Пароль</label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="flex items-center absolute inset-y-0 left-0 pl-3 pointer-events-none">
                <LockClosedIcon className="text-slate-400 w-5 h-5" />
              </div>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                name="password"
                id="password"
                placeholder="Введите пароль"
                className="w-full rounded-md text-slate-900 border-slate-300 px-10 py-2 placeholder:text-slate-400 focus:border-sky-500"
                value={password}
                disabled={isLoading}
                onChange={(event) => setPassword(event.target.value)}
              />
              <div className="flex items-center absolute inset-y-0 right-0 pr-3">
                <button type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                  <span className="sr-only">{isPasswordVisible ? 'Скрыть пароль' : 'Показать пароль'}</span>
                  {isPasswordVisible ? <EyeSlashIcon className="text-slate-400 w-5 h-5" /> : <EyeIcon className="text-slate-400 w-5 h-5" />}
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                className="h-4 w-4 rounded border-slate-400 text-sky-600 hover:border-sky-500 focus:ring-sky-300"
                checked={rememberMe}
                disabled={isLoading}
                onChange={(event) => setRememberMe(event.target.checked)}
              />
              <label htmlFor="remember-me" className="ml-2">Запомнить меня</label>
            </div>

            <Link to="/password-recovery" className="text-sky-600 hover:text-sky-500">Забыли пароль?</Link>
          </div>

          <div>
            <button
              type="submit"
              className="flex justify-center w-full rounded-md bg-sky-600 px-3 py-2 text-sm font-medium text-white hover:bg-sky-500 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:text-base md:px-6 md:py-3"
              disabled={isLoading}
            >
              Войти
              {isLoading && <ArrowPathIcon className="animate-spin ml-2 w-6 h-6" />}
            </button>
          </div>

          <div className="hidden flex items-center text-slate-500">
            <span className="w-full border-t"></span>
            <span className="mx-6 text-xs whitespace-nowrap">Или продолжить с</span>
            <span className="w-full border-t"></span>
          </div>

          <div className="hidden flex gap-2 md:gap-4">
            <button
              type="button"
              className="flex justify-center grow rounded-md border border-slate-300 bg-white px-2.5 py-1.5 text-xs font-medium hover:bg-slate-50 hover:shadow focus:bg-white focus:border-sky-600 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:py-2 sm:text-sm md:text-base"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">Google</span>
              <ExclamationCircleIcon className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
            <button
              type="button"
              className="flex justify-center grow rounded-md border border-slate-300 bg-white px-2.5 py-1.5 text-xs font-medium hover:bg-slate-50 hover:shadow focus:bg-white focus:border-sky-600 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:py-2 sm:text-sm md:text-base"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">VK</span>
              <ExclamationCircleIcon className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
            <button
              type="button"
              className="flex justify-center grow rounded-md border border-slate-300 bg-white px-2.5 py-1.5 text-xs font-medium hover:bg-slate-50 hover:shadow focus:bg-white focus:border-sky-600 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:py-2 sm:text-sm md:text-base"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">Yandex</span>
              <ExclamationCircleIcon className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
          </div>
        </form>
      </main>

      <div className="flex flex-col gap-2 max-w-xs shadow rounded-lg w-full mt-2 px-6 py-4 bg-white sm:gap-4 sm:max-w-sm sm:mt-6 sm:p-6 md:gap-6 md:max-w-md md:mt-8 md:p-10">
        <Link
          to="/registration"
          className="flex justify-center w-full rounded-md border border-sky-200 bg-sky-50 shadow-sm px-4 py-2 text-sm font-medium text-sky-600 hover:bg-white focus:ring-2 focus:ring-sky-300 sm:text-base md:px-6 md:py-3"
        >
          Зарегистрироваться
        </Link>

        <p className="text-xs text-slate-700 text-center font-light">После регистрации вы получите доступ ко всем функциям сервиса Vtovare. <Link to="/" className="text-sky-600 hover:text-sky-500">Узнать больше</Link></p>
      </div>

      <div className="text-xs text-slate-600 text-center mt-auto sm:text-sm md:flex md:flex-row-reverse md:justify-between md:w-full md:pt-8">
        <p>
          {/*<span>Русский</span>*/}
          {/*<span className="mx-4">English</span>*/}
          {/*<span>Все языки</span>*/}
        </p>

        <p className="mt-2 sm:mt-4 md:mt-0">Vtovare &copy; 2023</p>
      </div>

      {/*{isError && (*/}
      {/*  <div className="fixed inset-0 z-10 flex items-center justify-center bg-slate-700/25">*/}
      {/*    <div className="rounded-2xl m-4 p-6 max-w-sm bg-white text-center relative">*/}
      {/*      <button*/}
      {/*        type="button"*/}
      {/*        className="absolute top-4 right-4 text-slate-500 hover:text-rose-500 focus:text-rose-500"*/}
      {/*        onClick={reset}*/}
      {/*      >*/}
      {/*        <span className="sr-only">Закрыть</span>*/}
      {/*        <XMarkIcon className="w-6 h-6" />*/}
      {/*      </button>*/}
      {/*      <div className="rounded-full w-12 mx-auto p-3 bg-rose-50">*/}
      {/*        <ArrowPathIcon className="w-6 h-6 text-rose-500" />*/}
      {/*      </div>*/}

      {/*      <p className="text-slate-900 text-lg font-medium mt-5">Что-то пошло не так</p>*/}

      {/*      <p className="text-slate-500 text-sm">Сервер временно не доступен. Мы уже над этим трудимся! Попробуйте войти через несколько минут или обратитесь в техническую поддержку</p>*/}

      {/*      <div className="flex mt-5 gap-2 md:gap-4">*/}
      {/*        <button*/}
      {/*          type="button"*/}
      {/*          className="flex justify-center w-full rounded-md border border-slate-300 bg-white px-2.5 py-1.5 text-xs font-medium hover:bg-slate-50 hover:shadow focus:bg-white focus:border-sky-600 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:py-2 sm:text-sm md:text-base"*/}
      {/*          onClick={() => alert('Not implemented!')}*/}
      {/*        >*/}
      {/*          Тех. поддержка*/}
      {/*        </button>*/}

      {/*        <button*/}
      {/*          type="button"*/}
      {/*          className="flex justify-center w-full rounded-md bg-sky-600 px-3 py-2 text-sm font-medium text-white hover:bg-sky-500 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:text-base md:px-6 md:py-3"*/}
      {/*          onClick={reset}*/}
      {/*        >*/}
      {/*          Понятно*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      {logInError && (
        <div className="fixed inset-x-0 top-0 p-4 flex gap-3 items-start bg-rose-50">
          <ExclamationTriangleIcon className="w-5 h-5 text-rose-400" />
          <div className="grow text-sm">
            <p className="font-medium text-rose-800">{logInError.message}</p>
            {!!logInError.details.length && (
              <ul className="list-disc list-inside text-rose-700">
                {logInError.details.map((message) => <li key={message}>{message}</li>)}
              </ul>
            )}
          </div>
          <button
            type="button"
            className="rounded-md p-1.5 text-rose-500 hover:bg-rose-100 focus:bg-rose-50 focus:ring-2 focus:ring-rose-100"
            onClick={reset}
          >
            <span className="sr-only">Закрыть</span>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginPage;