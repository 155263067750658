import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  useAddProductMutation, useCopyProductMutation,
  useLazyGetProductQuery,
  useSetImgMutation,
  useUpdateProductMutation
} from "../../../../api/product";
import SelectConstantHeight from "components/ui/select/selectConstantHeight/SelectConstantHeight";
import Input from "../../Input/Input";
import {useGetBrands} from "../../../../hooks/useGetBrands";
import {useGetProductCategoriesQuery} from "../../../../api/productCategory";
import SelectMulti from "components/ui/select/selectMulti/SelectMulti";
import {InformationCircleIcon, PlusIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {useTooltip} from "../../../../hooks/tootip";
import {TooltipPlacement} from "../../tooltips/TooltipPanel";
import { formatNumber } from "../../../../utils/helpers";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";
import {SlideServiceType} from "../Slides";
import {useDialog} from "../../../../hooks/dialog";
import {useDropzone} from "react-dropzone";
import {SlideContext} from "../SlideWrap";
import InputNumber from "../../Input/tmpl/InputNumber";
import {useNavigate} from "react-router-dom";
import Checkbox from "../../checkbox/Checkbox";
import {useGetProductApplicabilityQuery} from "../../../../api/product-applicability-api";
import InputProductNumber from "../../Input/tmpl/InputProductNumber";
import DialogProductReplacement from "../../dialogs/tmpl/DialogProductReplacement";
import {EnumDialogWidth} from "../../dialogs/DialogWrap";

type Props = {
  idProduct: string
  categoryForNew?: string
  applicabilityForNew?: string
  productData?: any

  _slide?: SlideServiceType
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const useErrorValidation = (error1: FetchBaseQueryError | SerializedError | undefined, error2: FetchBaseQueryError | SerializedError | undefined, error3: FetchBaseQueryError | SerializedError | undefined): {} | null => {
  const memoizedError = useMemo(() => {
    const error = error1 || error2 || error3
    if (error !== undefined && 'status' in error) {
      try {
        console.log(1, error)
        const result: { message: string; details: { property: string; message: string; }[] } = error.data as any

        return result.details
      } catch(e) {
        console.log(e)
        return { message: 'Unknown error', details: [] };
      }

    }

    return null;
  }, [error1, error2, error3]);

  return memoizedError;
};

const ProductSlide: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const {idProduct, categoryForNew, applicabilityForNew, _slide} = props;
  const [getProduct, {data, isFetching, status}] = useLazyGetProductQuery()
  const [ updateProduct , { error: errorUpdateProduct }] = useUpdateProductMutation()
  const [ addProduct , { error: errorCreateProduct }] = useAddProductMutation()
  const [ copyProduct , { error: errorCopyProduct }] = useCopyProductMutation()
  const [ setImg, {data: resultSetImage, isLoading: isLoadingSetImage, isSuccess: isSuccessSetImg, reset} ] = useSetImgMutation()
  const tooltip = useTooltip();
  const errorValidation: any = useErrorValidation(errorCreateProduct, errorUpdateProduct, errorCopyProduct);
  const dialog = useDialog()

  const [titleRus, setTitleRus] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [brand, setBrand] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [applicability, setApplicability] = useState<any>();
  const [pool, setPool] = useState<any>(true); // по-умолчанию галка стоит
  const [online, setOnline] = useState<any>(true); // по-умолчанию галка стоит
  const [pricePurchase, setPricePurchase] = useState<{ value: number, format: string}>({ value: 0, format: ''});
  const [priceSale, setPriceSale] = useState<{ value: number, format: string}>({ value: 0, format: ''});
  const [qty, setQty] = useState<{ value: number, format: string}>({ value: 0, format: ''});
  const [images, setImages] = useState<string[]>([])
  const [description, setDescription] = useState<string>('')
  // const [bigImg, setBigImg] = useState<string>('')
  const {refBigImg, setBigImg} = useContext(SlideContext)
  const [markup, setMarkup] = useState<{ value: number, format: string}>({ value: 0, format: ''})

  const onOk = useCallback(async (): Promise<void> => {
    // срабатывает, когда происходит accept диалогового окна.
    // если этого обработчика нет, тогда диалоговое окно закрывается по-умолчанию при событии закрытия в dialogWrap
    // и ничего не возвращает
    // если этот обработчик есть, но нет accept(result), тогда окно не закрывается.
    // из любого места компонента можно закрыть диалоговое окно (accept() или decline()),
    // минуя регистрацию onOk и onCancel

    return new Promise((resolve, reject) => {
      const body = {
        Brand: brand?._id,
        number: number,
        title_rus: titleRus,
        Categories: category.map((item: any) => item._id),
        Applicability: applicability.map((item: any) => item._id),
        isPool: pool,
        isOnline: online,
        price_purchase: pricePurchase.value,
        price_sale: priceSale.value,
        qty: qty.value,
        images,
        description
      }

      if(idProduct === 'new') {
        addProduct(body).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }

          }
        )
      } else if(idProduct === 'copy') {
        copyProduct(body).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        )
      } else if(data?._id) {
        updateProduct({
          id: data._id,
          body: body }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        );
      }

    })

  }, [data, titleRus, number, brand, category, applicability, pool, online, pricePurchase, priceSale, qty, images, description])

  useEffect(() => {
    _slide?.acceptHandler(onOk)

    if(idProduct === 'new' || idProduct === 'copy') {
      _slide?.acceptButton('Создать')
    } else {
      _slide?.acceptButton('Сохранить')
    }
  }, [onOk])

  useEffect(() => {
    if(idProduct !== 'new' && idProduct !== 'copy') {
      getProduct({id: idProduct})
    }

    if(idProduct === 'copy') {
      getProduct({id: props.productData._id})
    }

  }, [])

  useEffect(() => {
    if(idProduct !== 'new') {
      if(data) {
        // title in header
        const title = `${data?.title_rus} [${data?.Brand.name}]`
        _slide?.titleFn(title);
        _slide?.subTitleFn('Товар')

        setTitleRus(data?.title_rus ?? '')
        setNumber(data?.number ?? '')
        setBrand(data?.Brand)
        setPool(!!data?.isPool)
        setOnline(!!data?.isOnline)
        setPriceSale({
          value: formatNumber(data.price_sale, 2).number,
          format: formatNumber(data.price_sale, 2).string })
        setPricePurchase({
          value: formatNumber(data.price_purchase, 2).number,
          format: formatNumber(data.price_purchase, 2).string })
        setQty({
          value: formatNumber(data.qty, 0).number,
          format: formatNumber(data.qty, 0).string
        })
        setImages(data?.images && Array.isArray(data?.images) ? data.images : [])
        setDescription(data?.description ?? '')
      }
    } else {
      // title in header
      _slide?.titleFn('Новый товар');
      _slide?.subTitleFn('Товар')
    }
  }, [data]);

  const [getBrands, {data: brands, isFetching: isFetchingSelect, isLoading, status: statusBrands}] = useGetBrands()

  const {data: productCategories} = useGetProductCategoriesQuery()
  const {data: productApplicability} = useGetProductApplicabilityQuery()
  const treeToList = (tree: any, parentName: any) => {
    // console.log(555, tree)
    const a = tree.map((item: any) => {
      const {subCategories, ...rest} = item;
      if(parentName) {
        rest.categoryName_rus = `${parentName} - ${rest.categoryName_rus}`
      }
      if(subCategories.length > 0) {
        // return [...treeToList(subCategories, rest.categoryName_rus)] // не отображаем родительские категории, только конечные
        return [rest, ...treeToList(subCategories, rest.categoryName_rus)]
      } else {
        return [rest, ...treeToList(subCategories, rest.categoryName_rus)]
      }
    })

    // добавить пункт Все в начало: не используется - нужно очищать select при выборе Все
    // if(!parentName) {
    //   a.unshift([{_id: '', Tenant: '', categoryName_rus: 'Все'}])
    // }

    return a.flat()
  }

  const treeApplicabilityToList = (tree: any, parentName: any) => {
    // console.log(555, tree)
    const a = tree.map((item: any) => {
      const {subApplicability, ...rest} = item;
      if(parentName) {
        rest.applicabilityName_rus = `${parentName} - ${rest.applicabilityName_rus}`
      }
      if(subApplicability.length > 0) {
        // return [...treeApplicabilityToList(subApplicability, rest.applicabilityName_rus)] // не отображаем родительские категории, только конечные
        return [rest, ...treeApplicabilityToList(subApplicability, rest.applicabilityName_rus)]
      } else {
        return [rest, ...treeApplicabilityToList(subApplicability, rest.applicabilityName_rus)]
      }
    })

    // добавить пункт Все в начало: не используется - нужно очищать select при выборе Все
    // if(!parentName) {
    //   a.unshift([{_id: '', Tenant: '', applicabilityName_rus: 'Все'}])
    // }

    return a.flat()
  }

  const refInputTitle = useRef<any>()

  // фокус на наименовании
  useEffect(() => {
    // console.log(444, refInputTitle)
    if(refInputTitle.current && idProduct === 'new') {
      refInputTitle.current.focus()
    }
  }, [refInputTitle.current])

  const getCategoryForNew = (catTree: any) => {
    const catList = treeToList(catTree, null);
    return catList.filter((item: any) => item._id === categoryForNew)
  }

  const getApplicabilityForNew = (appTree: any) => {
    const appList = treeApplicabilityToList(appTree, null);
    return appList.filter((item: any) => item._id === applicabilityForNew)
  }

  const downloadImages = () => {
    // dialog.show(<DialogDownloadImagesProduct />)
  }

  const onDrop = useCallback((acceptedFiles: any) => {
    console.log('file', acceptedFiles)

    // console.log('file', acceptedFiles)
    // const formData = new FormData();
    // formData.append('file', acceptedFiles[0]);
    // // остановился здесь! Получил файлы из пк, дальше...
    // setImg(formData)

    // const files: FormData[] = []
    const formData = new FormData();
    acceptedFiles.forEach( (item: any) => {
      formData.append('files', item);
      // files.push(formData)
    })

    // let productimages = [];
    // for (let i = 0; i < acceptedFiles.length; i++) {
    //   productimages.push(acceptedFiles[i]);
    // }
    // // @ts-ignore
    // formData.append('files', productimages);


    setImg(formData)
  }, [])

  useEffect(() => {
    if(isSuccessSetImg && Array.isArray(resultSetImage)) {
      setImages([...images, ...resultSetImage.map((item: any) => item.fileName)])
      reset()
    }
  }, [isSuccessSetImg])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const delImgLocal = (fileName: string) => {
    const newArr = images.filter(item => item !== fileName);
    setImages([...newArr])
  }

  const [isClickImg, setIsClickImg] = useState<any>(false)
  const showBigImg = (key: string, e: any) => {
    console.log('showBigImg')
    setBigImg(`https://s3.aaabox.ru/${key}`)
    setIsClickImg(false)
  }

  const closeBigImg = () => {
    if(!isClickImg) {
      setBigImg('')
    }
  }

  const onClickImg = (e: any) => {
    setIsClickImg(true)
  }

  const getThumbLink = (key: string) => {
    const name = key.split('.')[0];
    const ext = key.split('.').pop();
    // console.log('ext', ext, key)
    return `https://s3.aaabox.ru/${name}-200x200.${ext}`
  }

  const addBrand = () => {
    _slide?.decline()
    setTimeout(() => {
      navigate(`brands`, );
    }, 500)
  }

  // расчет наценки
  useEffect(() => {
    const m = priceSale.value * 100 / pricePurchase.value - 100
    setMarkup({ value: formatNumber(m, 2).number, format: formatNumber(m, 2).string})
  }, [priceSale, pricePurchase])

  const onReplacement = () => {
    console.log('onReplacement')
    dialog.show(<DialogProductReplacement product={data} width={EnumDialogWidth.MAXW5XL} />).then(
      (result) => {
        // console.log(6, result)
      }
    )
  }

  return (
    <>
      {isFetching ? 'Загрузка...'
      :
        <form className="flex flex-col gap-6 text-sm text-slate-700 " /*onSubmit={onSubmitClient}*/>

          <div>
            <div className="block text-sm font-medium text-gray-700 mb-1">Фотографии товара</div>
            <div className={'flex gap-3 flex-wrap'}>
              {images.map((item, index, array) => {
                return <div key={item} onMouseEnter={(e) => showBigImg(item, e)} onMouseLeave={closeBigImg} onClick={onClickImg} className="relative w-16 h-16 border rounded bg-cover bg-center group/img" style={{backgroundImage: `url('${getThumbLink(item)}')`}}>
                    <div className={'invisible group-hover/img:visible'}>
                      <div className={'absolute top-0.5 right-0.5 bg-slate-800 rounded-full w-4 h-4 opacity-50 cursor-pointer hover:opacity-80'} onClick={() => delImgLocal(item)}>
                        <XMarkIcon className={'h-4 w-4 text-white'} />
                      </div>
                    </div>
                  </div>
              }) }

              { isLoadingSetImage ?
                <div className="w-16 h-16 border rounded flex justify-center items-center">
                  <div className={classNames("flex justify-center items-center")}>
                    <svg className="animate-spin h-5 w-5 self-center text-slate-500" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" fillOpacity="0%" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                </div>
                :
                  <div className={'w-16 h-16 border rounded text-slate-300 hover:text-slate-500 hover:border-slate-500 '} onClick={downloadImages}>
                    <label htmlFor="file-upload" className="h-full flex flex-col items-center justify-center cursor-pointer">
                      <PlusIcon className={'w-6 h-6'} />
                      <div className={'text-xs'}>Добавить</div>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only"  {...getInputProps()}/>
                    </label>
                  </div> }
            </div>
          </div>

          <Input label='Наименование'
                 value={titleRus}
                 onChange={(e) => setTitleRus(e.target.value)}
                 ref={refInputTitle}
                 isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'title_rus') : false}
                 errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'title_rus')?.message : ''}
          />


          <SelectConstantHeight
                label='Бренд'
                data={brands?.data ? brands.data.map((item: any) => ({option: item})) : []}
                optionKey='name'
                initSelected={data?.Brand}
                lastId='_id'
                isLoading={isFetchingSelect}
                hasNextPage={isLoading ? isLoading : brands?.meta.hasNextPage}
                getData={getBrands}
                onChange={setBrand}
                initStatus={idProduct==='new' ? 'fulfilled' : status}
                isSearchInput={true}
                isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'Brand') : false}
                errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'Brand')?.message : ''}
                onClickAdd={addBrand}
              />


          <InputProductNumber label='Артикул'
                 value={number}
                 onChange={(e) => setNumber(e.target.value)}
                 isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'number') : false}
                 errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'number')?.message : ''}
                  onClean={() => setNumber('')}
                  onClickReplacement={idProduct === 'new' ? undefined : onReplacement}
                  product_data={data}
          />

          <SelectMulti
            label='Применимость'
            data={productApplicability?.data ? treeApplicabilityToList(productApplicability.data, null).map((item: any) => {
              return {option: item}
            }) : []}
            optionKey='applicabilityName_rus'
            initSelected={idProduct === 'new' ? productApplicability?.data && getApplicabilityForNew(productApplicability.data) : data?.Applicability}
            onChange={setApplicability}
            initStatus={idProduct==='new' ? 'fulfilled' : status}
          />

          <SelectMulti
            label='Категории'
            data={productCategories?.data ? treeToList(productCategories.data, null).map((item: any) => {
              return {option: item}
            }) : []}
            optionKey='categoryName_rus'
            initSelected={idProduct === 'new' ? productCategories?.data && getCategoryForNew(productCategories.data) : data?.Categories}
            onChange={setCategory}
            initStatus={idProduct==='new' ? 'fulfilled' : status}
          />

          <div className='flex space-x-5'>

            <div className="flex items-center">
              <Checkbox
                checked={pool}
                // disabled={isLoading || isError}
                onChange={(checked) => {setPool(checked); if(!checked) setOnline(false)}}
                label={'Торговый пул'}
              />
              <InformationCircleIcon className='h-5 w-5 text-slate-400 ml-2'
                                     onMouseEnter={ (event: any) => { tooltip.show('Торговый пул - совокупность товаров, которыми торгует Ваша компания. Если стоит данная галочка, это означает, что товар входит в ваш торговый пул. Так же можно создавать товары для общей информации и не вносить их в торговый пул', event.currentTarget, TooltipPlacement.TOP) } }
                                     onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
                                     onClick={ (event: any) => { tooltip.close(event.currentTarget) } }
              />
            </div>

            <div className="flex items-center">
              <Checkbox
                checked={online}
                disabled={!pool}
                onChange={(checked) => setOnline(checked)}
                label={'Online'}
              />
              <InformationCircleIcon className='h-5 w-5 text-slate-400 ml-2'
                                     onMouseEnter={ (event: any) => { tooltip.show('Отображать товар в онлайн-витрине для клиентов', event.currentTarget, TooltipPlacement.TOP) } }
                                     onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
                                     onClick={ (event: any) => { tooltip.close(event.currentTarget) } }
              />
            </div>

          </div>

          <div className={'flex space-x-7'}>
            <InputNumber label='Цена закупочная, шт'
                         fractionDigits={2}
                         value={pricePurchase.format}
                         onChange={(price) => setPricePurchase(price)}
                         onBlur={ ( price ) => setPricePurchase(price) }
                         onFocus={(e) => e.currentTarget.select()}
                         onClean={() => setPricePurchase({value: 0, format: '0,00'})}
            />

            <InputNumber label='Цена продажная, ₽'
                         fractionDigits={2}
                         value={priceSale.format}
                         onChange={ (price) => { setPriceSale(price) } }
                         onBlur={ ( price ) => setPriceSale(price) }
                         onFocus={(e) => e.currentTarget.select()}
                         onClean={() => setPriceSale({value: 0, format: '0,00'})}
                         helpText={markup.value != 0 ? `${markup.value > 0 ? 'Наценка' : 'Скидка'}: ${markup.format}%` : ''}
            />
          </div>

          <div>
            <InputNumber label='Наличие, шт'
                         fractionDigits={0}
                         value={qty.format}
                         onChange={(value) => setQty(value) }
                         onBlur={(value) => setQty(value) }
                         onFocus={(e) => e.currentTarget.select()}
                         onClean={(refInput) => setQty({value: 0, format: '0'}) }
                         isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'qty') : false}
                         errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'qty')?.message : ''}
            />
          </div>

          <div>
            <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
              Описание
            </label>
            <div className="mt-1">
            <textarea
              rows={4}
              name="description"
              id="description"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              value={description}
              // defaultValue={''}
              onChange={ (value) => setDescription(value.target.value) }
            />
            </div>
          </div>



        </form>
      }

    </>
  );
};

export default ProductSlide;