import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';

interface IDialog {
  id?: string
  // title?: string;
  // text?: string
  // onOk?: () => (boolean | void)
  // onCancel?: () => void
  component?: any
  resolve?: any
  accept3?: any
  acceptHandler?: any
  data?: any
  buttonAccept?: any
}

interface DialogsState {
  dialogs: IDialog[]
}

const initialState: DialogsState = {
  dialogs: []
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    showDialog(state, action: PayloadAction<IDialog>) {
      // state.dialogs.push({title: action.payload.title, text: action.payload.text });
      console.log('showDialog', state.dialogs, action.payload)
      const newDialog = action.payload;
      newDialog.id = uuidv4();
      console.log(222, newDialog)
      state.dialogs = [...state.dialogs, newDialog]
    },
    unmountDialog(state, action: PayloadAction<IDialog>) {
      const dialogs = state.dialogs.filter( dialog => dialog.id !== action.payload.id);
      state.dialogs = [...dialogs]
    },
    // setData(state, action: PayloadAction<any>) {
    //   const dialog = state.dialogs.find(item => item.id === action.payload.id);
    //   const dialogs = state.dialogs.filter(item => item.id !== action.payload.id);
    //   if(dialog) {
    //     dialog.data = action.payload.data;
    //     state.dialogs = [...dialogs, dialog]
    //   }
    // }
  },
  extraReducers: {}
})

export const { showDialog, unmountDialog } = dialogSlice.actions;
export default dialogSlice.reducer;