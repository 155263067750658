import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { get } from "../../api";
import {IProfile} from "../../models/IProfile";

interface ProfileState {
  profile: IProfile | null;
  isLoading: boolean;
  error: string;
}

const initialState: ProfileState = {
  profile: null,
  isLoading: false,
  error: '',
}

export const fetchProfile = createAsyncThunk(
    'profile/fetchProfile',
    async (_, thunkAPI) => {
      try {
        const response = await get(`${process.env.REACT_APP_URL_API}/profile`)
        return response;
      } catch (e: any) {
          return thunkAPI.rejectWithValue('Ошибка загрузки')
      }

    }
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProfile.fulfilled.type]: (state, action: PayloadAction<IProfile>) => {
      console.log(111, action)
      state.isLoading = false;
      state.error = '';
      state.profile = action.payload;
    },
    [fetchProfile.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchProfile.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  }
})

// export const {  } = orderSlice.actions;
export default profileSlice.reducer;