import React, {forwardRef, useId, useImperativeHandle, useRef} from 'react';
import {MinusSmallIcon, PlusIcon, PlusSmallIcon, XMarkIcon} from "@heroicons/react/20/solid";
export type InputProps = {
  label?: string
  value?: string | number | undefined
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  onFocus?: (refInput: React.FocusEvent<HTMLInputElement>) => void
  ref?: React.ForwardedRef<HTMLInputElement>
  isError?: boolean
  errorText?: string
  helpText?: string
  type?: string
  isCleanerX?: boolean
  disabled?: boolean
  onEnter?: () => void
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const InputPriceCell: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>((
  { label,
    onChange,
    onBlur,
    onFocus,
    value,
    placeholder,
    onClick,
    isError,
    errorText,
    helpText,
    type,
    isCleanerX = true,
    disabled = false,
    onEnter,
  }, ref) => {
  const inputRef = useRef<any>(null)
  const id = useId()

  // раскрываем внешнему ref только нужные методы
  useImperativeHandle(ref, () => {
    return {
      focus() {
        inputRef.current.focus()
      },
      select() {
        inputRef.current.select()
      }
    } as HTMLInputElement
  }, [])

  const cleanInput = () => {
    inputRef.current.value = ''
    console.log('refInput',inputRef)
    if(onChange) onChange(inputRef.current.value)
    inputRef.current.focus()
  }
  return (
      <div className='w-full'>
          {label && <label htmlFor={id} className={classNames("block text-sm font-medium", disabled ? 'text-slate-300' : 'text-gray-700')}>{ label }</label>}
        <div className={ classNames("relative rounded-md",
          // 'shadow-sm',
          label ? 'mt-1' : '',
          isError ? 'border border-rose-400' : disabled ? 'border border-transparent' : 'border border-slate-300',
          )}>
          <input
            id={id}
            name={id}
            type={type ?? 'text'}
            placeholder={placeholder}
            className={
              classNames(
                " block w-full h-9.5 rounded-md border-0 text-sm  py-2 pl-3 placeholder:text-slate-400 focus:ring-0 focus:outline-none focus:border-sky-500 bg-white",
                // isCleanerX ? 'pr-14' : 'pr-3',
                'pr-7',
                disabled ? 'text-slate-900 text-right' : 'text-slate-900 text-right',
              )}
            value={ value }
            ref={ inputRef }
            onChange={(e) => onChange ? onChange(e) : null }
            onClick={onClick}
            onBlur={(e) => onBlur ? onBlur(e) : null}
            onFocus={(e) => onFocus ? onFocus(e): null}
            disabled={disabled}
            onKeyUp={(e) => onEnter && e.key==='Enter' && onEnter()}
          />
          {/*{isCleanerX &&*/}
          {/*  <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={(e) => cleanInput()}>*/}
          {/*    <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />*/}
          {/*  </div>*/}
          {/*}*/}
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              ₽
            </span>
          </div>

        </div>

        { helpText && <div className={classNames('line-clamp-6 text-xs', disabled ? 'text-slate-300' : 'text-slate-500')}>{helpText}</div> }
        { isError && <div className='text-rose-400 line-clamp-6 text-xs'>{errorText}</div> }


      </div>
  );
});

export default InputPriceCell;
