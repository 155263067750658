import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {IBrand} from "../models/IBrand";

export type BrandsRequest = {
  page?: number
  size: number | string | undefined
  sort: string | undefined
  last: string
  filter: string
}

export type BrandsResponse = {
  data: IBrand[];
  meta: any
}

export type AddBrandRequest = {
  name: string;
}

export interface ListResponse<T> {
  data: T[],
  meta: {
    page: number,
    perPage: number,
    totalElements: number
    hasNextPage: boolean
  }
}

export const brandApi = createApi({
  reducerPath: 'brandApi',
  tagTypes: ['Brands'],
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/brand` }),
  endpoints: (builder) => ({
    getBrands: builder.query<ListResponse<IBrand>, BrandsRequest>({
      query: ({page, size, sort, last, filter}) => ({
          url: `/`,
          method: 'GET',
          params: { page: page, size, sort, last, filter },
          credentials: 'include',
      }),
      serializeQueryArgs: ({queryArgs, endpointDefinition, endpointName}) => {
        // console.log(555, queryArgs, endpointDefinition, endpointName)
        return endpointName
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { page, size, sort, last, filter } }) => {
        if(!last) { // filter = 'term'  last = ''
          console.log('----------новый массив для фильтра', filter)
          currentCache.data = newItems.data
          currentCache.meta = newItems.meta
        } else {
          currentCache.data.push(...newItems.data)
          currentCache.meta = newItems.meta
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }: any) {
        // console.log(444, currentArg !== previousArg, currentArg, previousArg)
        return currentArg !== previousArg
      },
      providesTags: ['Brands'],
      // providesTags: (result) =>
      //   result
      //     ? [
      //       ...result.data.map(({ _id }) => {console.log(1, _id); return { type: 'Brands' as const, id: _id }}),
      //       { type: 'Brands', id: 'PARTIAL-LIST' },
      //     ]
      //     : [{ type: 'Brands', id: 'PARTIAL-LIST' }],
      // providesTags: (result) => result ? []
    }),
    getBrand: builder.query<IBrand, {id: string}>({
      query: ({id}) => ({
        url: `/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      // providesTags: ['Brands']
    }),
    addBrand: builder.mutation<IBrand, AddBrandRequest>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['Brands']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    updateBrand: builder.mutation<IBrand, { id: string, body: AddBrandRequest }>({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
      // invalidatesTags: ['Brands']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    delBrand: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['Brands']
    }),
    delBrands: builder.mutation<void, string[]>({
      query: (ids) => ({
        url: `/del-arr`,
        method: 'POST',
        body: ids,
        credentials: 'include',
      }),
      invalidatesTags: ['Brands']
    })
  }),
});

export const {
  useGetBrandsQuery,
  useLazyGetBrandsQuery,
  useGetBrandQuery,
  useLazyGetBrandQuery,
  useAddBrandMutation,
  useUpdateBrandMutation,
  useDelBrandMutation,
  useDelBrandsMutation,
} = brandApi;