import * as React from 'react';
import {useEffect, useState} from "react";
import Button from "../../../buttons/Button";
import {formatSumSeparator} from "../../../../../utils/helpers";
import {XMarkIcon} from "@heroicons/react/20/solid";
import Checkbox from "../../../checkbox/Checkbox";
type Selected = { all: boolean, count: number, include: any[], exclude: any[] }
type Props = {
  _selected?: Selected
  cleanSelected?: () => void
  onDelete?: (selected: Selected, cleanSelected: any) => void | Promise<any>
  toFit100?: (selected: Selected, cleanSelected: any) => void | Promise<any>
  fromFit100?: (selected: Selected, cleanSelected: any) => void | Promise<any>
};

const ActionGroupProductReplacement: React.FC<Props> = (props: Props) => {
  const { _selected,
    onDelete,
    toFit100,
    fromFit100
  } = props;

  return (
    <>
      <div className={'flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-8 md:items-center'}>

        <div className={'flex justify-center relative w-full md:w-auto'}>
          <div className="absolute top-0 left-0 md:hidden cursor-pointer flex items-center pr-3" onClick={props?.cleanSelected ? props.cleanSelected : () => {}}>
            <XMarkIcon className="h-7 w-7 text-gray-500 hover:text-gray-700" aria-hidden="true" />
          </div>
          <Checkbox className={'hidden md:flex md:items-center mr-2'} checked={true} onChange={props?.cleanSelected ? props.cleanSelected : () => {}} />
          <div className={'flex-none whitespace-nowrap text-ellipsis overflow-hidden font-bold'}>Выбрано: {formatSumSeparator(_selected?.count)}</div>
        </div>

        <div className={'flex flex-wrap justify-start gap-3'}>
          { onDelete && _selected ? <div><Button text={'Удалить'} style={'DANGER'} onClick={() => onDelete(_selected, props?.cleanSelected)}/> </div> : '' }
          { toFit100 && _selected ? <div><Button text={'Подходит на 100%'} style={'TERTIARY'} onClick={() => toFit100(_selected, props?.cleanSelected)}/> </div> : '' }
          { fromFit100 && _selected ? <div><Button text={'Не подходит на 100%'} style={'TERTIARY'} onClick={() => fromFit100(_selected, props?.cleanSelected)}/> </div> : '' }
        </div>

      </div>
    </>
  );
};

export default ActionGroupProductReplacement;