import * as React from 'react';
import {Cell, Column, ColumnDef, ColumnMeta, flexRender, Row, RowData} from "@tanstack/react-table";
import {useEffect, useMemo} from "react";


type Props = {
  // cell: Cell<any, any>
  cell: any
  checked: boolean
  setSelect: any
  rowData: Row<any>
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CellStretch: React.FC<Props> = (props: Props) => {
  const { cell, checked, setSelect, rowData } = props;
  const meta = useMemo(() => cell.column.columnDef.meta, [])

  // useEffect(() => {
  //   console.log('cell1', cell, cell.column.columnDef.cell)
  // }, [cell])
  return (
    <>

      <div
        {...{
          style: {
            // width: cell.column.getSize()+1, // +1 - поправка по ширине - учет разделителей в заголовках
            // minWidth: cell.column.columnDef.minSize ? cell.column.columnDef.minSize+1 : 0,
            // maxWidth: cell.column.columnDef.maxSize ? cell.column.columnDef.maxSize+1 : 0
          }
        }}
        className={classNames("flex grow items-center h-full",
          meta?.side ? meta.side : 'justify-start',
          meta?.padding_not ? '' : 'px-4'
        )}>
        <div className='text-sm line-clamp-2 w-full'>
          {/*{flexRender(cell.column.columnDef.cell, cell.getContext())}*/}
          {<cell.column.columnDef.cell { ...{...cell.getContext(), setSelected2:(checked: boolean) => setSelect(checked, rowData.original), checked2:checked } }/>}
        </div>
      </div>

    </>
  );
};

export default CellStretch;