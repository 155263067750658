import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {IProductApplicability} from "../models/IProductApplicability";

export type AddProductApplicabilityRequest = {
  applicabilityName_rus: string;
  ProductApplicabilityParent?: string;
  description_employee: string
}

export interface ListResponse<T> {
  data: T[],
  meta: {
    totalElements: number
  }
}

export const productApplicabilityApi = createApi({
  reducerPath: 'productApplicabilityApi',
  tagTypes: ['ProductApplicability'],
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/product-applicability` }),
  endpoints: (builder) => ({
    getProductApplicability: builder.query<ListResponse<IProductApplicability>, void>({
      query: () => ({
          url: `/`,
          method: 'GET',
          credentials: 'include',
      }),
      providesTags: ['ProductApplicability'],
    }),
    getProductApplicabilityOne: builder.query<IProductApplicability, {id: string}>({
      query: ({id}) => ({
        url: `/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      // providesTags: ['Brands']
    }),
    addProductApplicability: builder.mutation<IProductApplicability, AddProductApplicabilityRequest>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['ProductApplicability']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    updateProductApplicability: builder.mutation<IProductApplicability, { id: string, body: AddProductApplicabilityRequest }>({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['ProductApplicability']
    }),
    delProductApplicability: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['ProductApplicability']
    }),
  }),
});

export const {
  useGetProductApplicabilityQuery,
  useLazyGetProductApplicabilityOneQuery,
  useAddProductApplicabilityMutation,
  useUpdateProductApplicabilityMutation,
  useDelProductApplicabilityMutation,
} = productApplicabilityApi;