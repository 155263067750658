import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {CheckCircleIcon, ExclamationTriangleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import Button from "../buttons/Button";
import {DocumentTextIcon, QuestionMarkCircleIcon} from "@heroicons/react/20/solid";

export enum EnumDialogWidth {
  MAXWXL = 'MAXWXL',
  MAXW2XL = 'MAXW2XL',
  MAXW3XL = 'MAXW3XL',
  MAXW4XL = 'MAXW4XL',
  MAXW5XL = 'MAXW5XL',
  MAXW6XL = 'MAXW6XL',
  MAXW7XL = 'MAXW7XL',
}

export enum EnumDialogIcon {
  ALERT = 'ALERT',
  SUCCESS = 'SUCCESS',
  FORM = 'FORM',
  QUESTION = 'QUESTION',
  QUESTION_DANGER = 'QUESTION_DANGER',
}

type Props = {
  children: any
  unmount: (dialog: any) => void
  dialog: any
  width: EnumDialogWidth
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const DialogContext = React.createContext<any>(null);

const DialogWrap: React.FC<Props> = ({ children, unmount, dialog, width}) => {
  const dialogRef = React.useRef<any>()
  const [open, setOpen] = useState(true)
  const [acceptHandler, setAcceptHandler] = useState<any>(() => null)
  const [declineHandler, setDeclineHandler] = useState<any>(() => null)
  const [acceptButton, setAcceptButton] = useState<any>()
  const [declineButton, setDeclineButton] = useState<any>()
  const [acceptButtonRaw, setAcceptButtonRaw] = useState<any>()
  const [declineButtonRaw, setDeclineButtonRaw] = useState<any>()
  const [dialogTitle, setDialogTitle] = useState<string>('???')
  const [icon, setIcon] = useState<EnumDialogIcon>(EnumDialogIcon.FORM) // по-умолчанию дизайн окна FORM
  const [dialogWidth, setDialogWidth] = useState<EnumDialogWidth>(width)

  const onOk = useCallback((): Promise<void> | undefined => {

    if(typeof acceptHandler === 'function') {
      const result = acceptHandler();
      if(result instanceof Promise) {
        // флаг прелоадера на кнопке
        return result
      } else {
        doAccept(result)
      }

    } else { // если обработчика нет
      doAccept()
    }

  }, [acceptHandler])

  const onCancel = useCallback((): Promise<void> | undefined => {

    if(typeof declineHandler === 'function') {
      const result = declineHandler();
      if(result instanceof Promise) {
        return result
      } else {
        doDecline(result)
      }

    } else { // если обработчика нет
      doDecline()
    }

  }, [declineHandler])

  const rootRef = useCallback((node: any) => {
    // срабатывает, когда элемент монтируется и размонтируется (удаляется) из DOM
    if(!node) {
      console.log('unmount')
      if(unmount) unmount(dialog)
    }
  }, [])

  useEffect(() => {
    let newButton

    if(acceptButtonRaw?.type === Button) { // пользовательская кнопка
      newButton = () => React.createElement(acceptButtonRaw.type, { ...acceptButtonRaw.props, onClick: onOk })

    } else if(typeof acceptButtonRaw === 'string') { // изменяем название кнопки
      if(acceptButton?.type === Button) {
        newButton = () => React.createElement(acceptButton.type, { ...acceptButton.props, onClick: onOk, text: acceptButtonRaw })
      } else {
        newButton = () => React.createElement(Button, { onClick: onOk, text: acceptButtonRaw, style: 'PRIMARY' })
      }

    } else if(typeof acceptButtonRaw === 'undefined') { // кнопка по-умолчанию
      newButton = () => React.createElement(Button, { onClick: onOk, text: 'Ок', style: 'PRIMARY' })

    } else if(typeof acceptButtonRaw === 'boolean' && !acceptButtonRaw) { // нет кнопки
      newButton = undefined
    }

    setAcceptButton(newButton);
  }, [acceptButtonRaw, onOk]);

  useEffect(() => {
    let newButton

    if(declineButtonRaw?.type === Button) { // пользовательская кнопка
      newButton = () => React.createElement(declineButtonRaw.type, { ...declineButtonRaw.props, onClick: onCancel })

    } else if(typeof declineButtonRaw === 'string') { // изменяем название кнопки
      if(declineButton?.type === Button) {
        newButton = () => React.createElement(declineButton.type, { ...declineButton.props, onClick: onCancel, text: declineButtonRaw })
      } else {
        newButton = () => React.createElement(Button, { onClick: onCancel, text: declineButtonRaw, style: 'SECONDARY' })
      }

    } else if(typeof declineButtonRaw === 'undefined') { // кнопка по-умолчанию
      newButton = () => React.createElement(Button, { onClick: onCancel, text: 'Отмена', style: 'SECONDARY' })

    } else if(typeof declineButtonRaw === 'boolean' && !declineButtonRaw) { // нет кнопки
      newButton = undefined
    }

    setDeclineButton(newButton);
  }, [declineButtonRaw, onCancel]);

  const doAccept = (result?: any) => {
    setOpen(false); // закрываем окно
    setTimeout(() => {
      dialog.resolve(result); // разрешаем вызов окна .then( (result) => {-здесь-}, () -> {} ) у родителя
    }, 200); // делаем задержку на закрытие окна (transition), чтобы ответ от окна не отображался быстрее, чем закроется окно
  }

  const doDecline = (result?: any) => {
    setOpen(false); // закрываем окно
    setTimeout(() => {
      dialog.reject(result); // разрешаем вызов окна .then( () => {}, (result) -> {-здесь-} )  или .catch у родителя
    }, 200); // делаем задержку на закрытие окна (transition), чтобы ответ от окна не отображался быстрее, чем закроется окно
  }

  return (
    <DialogContext.Provider value={{dialogRef}} >
      <Transition.Root show={open} as={Fragment} appear={true}>
        <Dialog as="div" className="relative z-50" onClose={onCancel} ref={rootRef}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto" ref={dialogRef}>
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-100"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-50"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className={classNames("relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8  sm:p-6",
                  'sm:w-full',
                  dialogWidth === EnumDialogWidth.MAXWXL ? 'sm:max-w-xl' : '',
                  dialogWidth === EnumDialogWidth.MAXW2XL ? 'sm:max-w-2xl' : '',
                  dialogWidth === EnumDialogWidth.MAXW3XL ? 'sm:max-w-3xl' : '',
                  dialogWidth === EnumDialogWidth.MAXW4XL ? 'sm:max-w-4xl' : '',
                  dialogWidth === EnumDialogWidth.MAXW5XL ? 'sm:max-w-5xl' : '',
                  dialogWidth === EnumDialogWidth.MAXW6XL ? 'sm:max-w-6xl' : '',
                  dialogWidth === EnumDialogWidth.MAXW7XL ? 'sm:max-w-7xl' : '',
                )}>

                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onCancel}
                    >
                      <span className="sr-only">Отмена</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">

                    {icon === EnumDialogIcon.SUCCESS &&
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                      </div>
                    }

                    {icon === EnumDialogIcon.ALERT &&
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                    }

                    {icon === EnumDialogIcon.FORM &&
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                        <DocumentTextIcon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                      </div>
                    }

                    {icon === EnumDialogIcon.QUESTION &&
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                        <QuestionMarkCircleIcon className="h-12 w-12 sm:h-10 sm:w-10 text-sky-600" aria-hidden="true" />
                      </div>
                    }

                    {icon === EnumDialogIcon.QUESTION_DANGER &&
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-white sm:mx-0 sm:h-10 sm:w-10">
                        <QuestionMarkCircleIcon className="h-12 w-12 sm:h-10 sm:w-10 text-red-500" aria-hidden="true" />
                      </div>
                    }

                    <div
                      // @ts-ignore
                      style={{'--align-side':'calc(100% - 56px)'}}
                      className="mt-3 pt-2 text-center sm:mt-0 sm:ml-4 sm:text-left w-[var(--align-side)]">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mr-5">
                        {dialogTitle}
                      </Dialog.Title>
                      <div className="mt-2">
                        { children(
                          (result: any) => doAccept(result), // закрываем диалоговое окно с результатом
                          (reason: any) => doDecline(reason), // закрываем диалоговое окно с результатом
                          (handler: any) => { setAcceptHandler(() => handler) }, // регистрация обработчика onOk
                          (handler: any) => { setDeclineHandler(() => handler) }, // регистрация обработчика onCancel
                          (acceptButton: any) => setAcceptButtonRaw(acceptButton), // настройки кнопки Accept
                          (declineButton: any) => setDeclineButtonRaw(declineButton), // настройки кнопки Decline
                          (icon: EnumDialogIcon) => { setIcon(icon) }, // устанавливаем иконку слева
                          (title: string) => { setDialogTitle(title) }, // устанавливаем title окна
                          (width: EnumDialogWidth) => { setDialogWidth(width) }, // устанавливаем ширину окна

                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sticky bottom-3 border px-4 py-3 rounded bg-slate-200/50 backdrop-blur-sm backdrop-opacity-90">
                    { acceptButton }
                    { declineButton }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </DialogContext.Provider>
  )
};

export default DialogWrap;