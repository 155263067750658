import * as React from 'react';
import {Fragment} from "react";
import {useNotification} from "../../../hooks/notification";
import { useAppSelector} from "../../../hooks/redux";

type Props = {
  children?: React.ReactNode
  param?: string
};

const Notifications: React.FC<Props> = (props: Props) => {
  const notification = useNotification();
  const { notifications } = useAppSelector(state => state.notifications)

  return (
    <>
      {!!notifications.length && <div aria-live="assertive" className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:px-6 sm:py-20 z-50">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          { notifications.map( (notify: any) => {
            const notifyComponent = React.createElement(
              notify.component.type,
              {...notify.component.props, id: notify.id, key: notify.id},
            )
            setTimeout(() => {
              notification.unmount(notify)
            }, 5000)
            return (
              <Fragment key={notify.id}>
                {notifyComponent}
              </Fragment>
            )}
          )}
        </div>
      </div>}

    </>
  );
};

export default Notifications;