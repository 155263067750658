import {combineReducers} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./reducers/AuthSlice";
import profileReducer from "./reducers/ProfileSlice";
import abcp from "./reducers/abcp/AbcpSlice";
import customerSlice from "./reducers/customers/CustomerSlice";
import dialogSlice from "./reducers/DialogSlice";
import notificationSlice from "./reducers/NotificationSlice";
import tenantSlice from "./reducers/tenant/TenantSlice";
import tooltipSlice from "./reducers/TooltipSlice";

import {authApi} from '../api/auth';
import {tenantApi} from "../api/tenant";
import {customerApi} from "../api/customer-api";
import slideSlice from "./reducers/SlideSlice";
import contextMenuSlice from "./reducers/ContextMenuSlice";
import productSlice from "./reducers/products/ProductSlice";
import {productApi} from "../api/product";
import {brandApi} from "../api/brand";
import {productCategoryApi} from "../api/productCategory";
import {employeeApi} from "../api/employees";
import {docCustomerOrdersApi} from "../api/doc-customer-orders-api";
import {abcpApi} from "../api/abcp";
import {docOurOrdersApi} from "../api/doc-our-orders-api";
import {suppliersApi} from "../api/suppliers-api";
import {productApplicabilityApi} from "../api/product-applicability-api";
import {docSupplierOrdersApi} from "../api/doc-supplier-orders-api";

const rootReducer = combineReducers({
  // [orderAPI.reducerPath]: orderAPI.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [tenantApi.reducerPath]: tenantApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [brandApi.reducerPath]: brandApi.reducer,
  [productCategoryApi.reducerPath]: productCategoryApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [docCustomerOrdersApi.reducerPath]: docCustomerOrdersApi.reducer,
  [abcpApi.reducerPath]: abcpApi.reducer,
  [docOurOrdersApi.reducerPath]: docOurOrdersApi.reducer,
  [suppliersApi.reducerPath]: suppliersApi.reducer,
  [productApplicabilityApi.reducerPath]: productApplicabilityApi.reducer,
  [docSupplierOrdersApi.reducerPath]: docSupplierOrdersApi.reducer,
  auth: authReducer,
  profile: profileReducer,
  abcp: abcp,
  customers: customerSlice,
  products: productSlice,
  dialogs: dialogSlice,
  notifications: notificationSlice,
  tooltips: tooltipSlice,
  tenants: tenantSlice,
  slides: slideSlice,
  contextMenu: contextMenuSlice,

})


export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(orderAPI.middleware, loginAPI.middleware,)
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          // Ignore these paths in the state
          ignoredPaths: ['dialogs', 'notifications', 'tooltips', 'slides', 'contextMenu'],
          // Ignore these action types
          ignoredActions: [
            'dialog/showDialog',
            'dialog/unmountDialog',
            'notification/showNotify',
            'notification/unmountNotify',
            'tooltip/showTooltip',
            'tooltip/closeTooltip',
            'slide/showSlide',
            'slide/unmountSlide',
            'contextMenu/showContextMenu',
            'contextMenu/closeContextMenu',
          ],
          // Ignore these field paths in all actions
          // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        }
      }).concat(
        authApi.middleware,
        tenantApi.middleware,
        customerApi.middleware,
        productApi.middleware,
        brandApi.middleware,
        productCategoryApi.middleware,
        employeeApi.middleware,
        docCustomerOrdersApi.middleware,
        abcpApi.middleware,
        docOurOrdersApi.middleware,
        suppliersApi.middleware,
        productApplicabilityApi.middleware,
        docSupplierOrdersApi.middleware,
      )
    }
  })
}

// export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']