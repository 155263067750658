import React, {forwardRef, Fragment, useCallback, useEffect, useState} from 'react';
import {
  ColumnResizeMode,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable
} from "@tanstack/react-table";
import {useVirtualizer} from "@tanstack/react-virtual";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {useContextMenu} from "../../../hooks/contextMenu";
import calculateSize from 'calculate-size'
import ActionGroupIndex from "./action-groups/ActionGroupIndex";
import {useTooltip} from "../../../hooks/tootip";
import {TooltipPlacement} from "../tooltips/TooltipPanel";


type Props = {
  columns?: any
  data?: any // принимать объект data = { data: [], meta: {} }
  isLoading?: any // true при начальной загрузке
  isFetching?: any //
  getData?: (arg0: any) => void | Promise<any>
  hasNextPage?: any
  onClickRow?: (rowData: Record<string, unknown>) => void
  onDoubleClickRow?: (rowData: Record<string, unknown>) => void
  size?: string | number
  // sort?: string
  tableContextMenu?: any
  rowContextMenu?: any
  meta?: any
  params?: {[key: string]: any} // параметры фильтрации, категории или любые другие параметры, которые будут добавлены к запросу
  isError?: any
  error?: any
  isRowNumber?: boolean // отображать номер строки
  isCheckbox?: boolean // отображать чекбоксы - возможность одиночного/множественного выбора
  actionGroup?: any
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const defaultProps = {
  params: { sort: 'id:desc' },
}

const Table3: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    columns,
    getData,
    data:allRows,
    hasNextPage,
    isFetching:isFetchingNextPage,
    onClickRow=()=>{},
    onDoubleClickRow=()=>{},
    size=100, // по-умолчанию
    // sort='_id', // по-умолчанию
    tableContextMenu=null,
    rowContextMenu=null,
    meta,
    params = { ...defaultProps.params, ...props.params },
    isError,
    error,
    isRowNumber = true,
    isCheckbox = false,
    actionGroup
  } = props;
  const lastEmpty = { last: '' }
  const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange') // or 'onEnd'
  const tableRef = React.useRef<any>()
  const parentRef = React.useRef<any>()
  const headerRef = React.useRef<any>()
  const [widthTable, setWidthTable] = useState(0);
  const [columns2, setColumns2] = useState<any>(columns);
  const [columnsCalc, setColumnsCalc] = useState<any>(columns);
  const [scrollWidthDef, setScrollWidthDef] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [selected, setSelected] = useState<{all: boolean, count: number, include: any[], exclude: any[]}>({all: false, count: 0, include: [], exclude: []});
  const [last, setLast] = useState<{last: string}>(lastEmpty)
  const contextMenu = useContextMenu();
  const [init, setInit] = useState<any>(false)
  // const params2 = useMemo<any>(() => ({...params}), [params])
  const tooltip = useTooltip();



  const setSelect = (checked: boolean, row: any) => {
    // console.log('setSelect', checked, row)
    if(checked) {
      if(selected.all) {
        // такой ситуации не бывает
      } else {
        if(selected.exclude.length) {
          let exclude = [...selected.exclude.filter((item: any) => item._id !== row._id)]
          const count = meta.totalElements - exclude.length
          const all = count == meta.totalElements
          setSelected({ all, count, exclude, include: [] })

        } else {
          const include = [...selected.include, row]
          const count = include.length
          const all = count == meta.totalElements
          setSelected({all, count, include, exclude: []});
        }
      }

    } else {
      if(selected.all) {
        const exclude = [...selected.exclude, row]
        const count = meta.totalElements - exclude.length
        setSelected({ all: false, count, exclude, include: [] })
      } else {
        if(selected.exclude.length) {
          let exclude = [...selected.exclude, row]
          const count = meta.totalElements - exclude.length
          exclude = count === 0 ? [] : exclude
          setSelected({ all: false, count, exclude, include: [] })
        } else {
          const include = [...selected.include.filter((item: any) => item._id !== row._id)]
          const count = include.length
          const all = count == meta.totalElements
          setSelected({all, count, include, exclude: []});
        }

      }

    }
  }

  // useEffect(() => {
  //   console.log('selected', selected)
  // }, [selected])

  const setSelectAll = (checked: boolean) => {
    // console.log('setSelectAll', checked)

    if(checked) {
      setSelected({ all: true, count: meta.totalElements, include: [], exclude: [] })
    } else {
      setSelected({ all: false, count: 0, include: [], exclude: [] })
    }
  }

  const table = useReactTable({
    data: allRows,
    // columns: widthTable ? changeColumn(columns) : columns,
    columns: columnsCalc,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      // columnSizing: {date: 300}, // здесь подгружать настройки с сервера
    },
    columnResizeMode,
    meta
  })
  const { rows } = table.getRowModel(); // строки таблицы

  const changeColumn = (widthTable: number) => {}

  useEffect( () => {
    // находим ширину полосы прокрутки
    // создадим элемент с прокруткой
    let div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';

    // мы должны вставить элемент в документ, иначе размеры будут равны 0
    document.body.append(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;

    div.remove();
    setScrollWidthDef(scrollWidth);

    // колонки по-умолчанию
    let colTemp = [];
    const columnHelper = createColumnHelper<any>();

    if(isRowNumber) {
      const columnRowNumber: any = columnHelper.accessor('rowNumber', {
        id: 'rowNumber',
        cell: info => <>{info.row.index+1}</>,
        header: 'N' ,
        size: 70,
        minSize: 70,
        maxSize: 70,
      })
      colTemp.push(columnRowNumber)
    }

    if(isCheckbox) {
      const columnCheckbox: any = columnHelper.accessor('checkbox', {
        id: 'checkbox',
        size: 20,
        minSize: 20,
        maxSize: 20,
      })
      colTemp.push(columnCheckbox)
    }


    colTemp = [ ...colTemp, ...columns2];

    if(tableContextMenu && !columns.some((item: any) => item.id === 'menu') ) {
      const columnMenu: any = columnHelper.accessor('menu', {
        id: 'menu',
        cell: info => '',
        header: info => <div className='flex justify-center items-center w-[30px] h-[30px] hover:text-sky-600 hover:border hover:border-sky-200 hover:rounded cursor-pointer' onClick={(e) => contextMenu.show({items: tableContextMenu, dataOnClick: { meta: info.table.options.meta }}, e.currentTarget)}><EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" /></div>,
        size: 60,
        minSize: 60,
        maxSize: 60,
      })
      setColumnsCalc([...colTemp, columnMenu])
    } else {
      setColumnsCalc([...colTemp])
    }
// console.log(77777)

  }, [columns2])

  const onWidth = useCallback(() => {
    // let hasScroll = false;
    // if (parentRef.current?.scrollHeight > parentRef.current?.clientHeight) {
    //   hasScroll = true;
    // }

    if(!allRows.length) return

    let widthSum = 0

    columns2.forEach((col: any) => {
      if(col.id === 'rowNumber' || col.id === 'checkbox' || col.id === 'menu') {
        widthSum += col.size
      } else {
        if(col?.meta?.width === 'max-content' || col?.meta?.width === 'max-content-or-header') {
          let count = 0
          let text = ''
          const sizeHeader = calculateSize(String(col.header), {
            font: 'Inter var',
            fontSize: '16px'
          })

          allRows.forEach((row: any) => {
            const rowCount = String(row[col.accessorKey]).length
            if(rowCount > count) {
              count = rowCount
              text = row[col.accessorKey]
            }
          })
          const sizeCell = calculateSize(String(text), {
            font: 'Inter var',
            fontSize: '16px'
          })


          // TODO исправить этот костыль
          let addWidth = 0;
          if(col?.meta?.addWidth) {
            const size = calculateSize(String(col?.meta?.addWidth), {
              font: 'Inter var',
              fontSize: '16px'
            })
            addWidth = size.width;
          }

          // console.log('text', String(text), size)

          const width = Math.max(sizeCell.width, sizeHeader.width)
          const widthCell = sizeCell.width + 37 + addWidth
          const widthHeader = sizeHeader.width + 37
          // console.log(col, 'widthCell', widthCell, 'widthHeader', widthHeader, 'addWidth', addWidth)
          if(widthCell > widthHeader) {
            col.size = widthCell
            if(col.maxSize) {
              col.minSize = widthCell < col.maxSize ? widthCell : col.maxSize
            } else {
              col.minSize = widthCell < col.minSize ? col.minSize : widthCell
            }
          } else {
            col.size = widthHeader
            if(col.maxSize) {
              col.minSize = widthHeader < col.maxSize ? widthHeader : col.maxSize
            } else {
              col.minSize = widthHeader < col.minSize ? col.minSize : widthHeader

            }
          }
          widthSum += +col.size
        } else if(col?.meta?.width !== 'stretch') {
          widthSum += col.size
        }
      }


    })

    let stretchCol = columns2.find((item: any) => item?.meta?.width === 'stretch');
    // console.log('stretchCol', stretchCol)
    const total = table.getTotalSize() // некорректный при инициализации

    // widthSum += 50 + 54 + 60 // rowNumber + checkbox + menu

    if(tableRef.current?.offsetWidth && stretchCol) {
      let size = tableRef.current?.offsetWidth - widthSum + (stretchCol?.size ? stretchCol.size : 150) - scrollWidth; // 150 - ширина по-умолчанию
      // console.log('size', tableRef.current?.offsetWidth, '-', widthSum, '+', (stretchCol?.size ? stretchCol?.size : 150), '-', scrollWidth, '=', size, stretchCol?.size, table.getAllColumns())

      stretchCol.size = size < 100 ? 100 : size;
      stretchCol.maxSize = size < 100 ? 100 : size;
    }

    setColumns2([...columns2]); // TODO при удалении этой строки, ширина колонок меняется плавно пропорционально, а так при ресайзе задается ширина колонок по-умолчанию

  }, [allRows.length]) // parentRef.current?.scrollHeight

  useEffect(() => {
    window.requestAnimationFrame(() => {
      onWidth();
      window.addEventListener("resize", onWidth);
    })
    return () => window.removeEventListener("resize", onWidth);
  }, [ onWidth ])

  const onScroll = useCallback(() => {
    let hasScroll = false;
    if (parentRef.current?.scrollHeight > parentRef.current?.clientHeight) {
      hasScroll = true;
    }

    setScrollWidth(hasScroll ? scrollWidthDef : 0)
  }, [parentRef.current?.scrollHeight])

  useEffect(() => {
    window.requestAnimationFrame(() => {
      onScroll();
      window.addEventListener("scroll", onScroll);
    })
    return () => window.removeEventListener("scroll", onScroll);
  }, [ onScroll ])

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length, // +1 - отображение строки загрузки
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48, // высота строки
    overscan: 50, // На сколько больше строк вверх вниз отображать от области видимости, дальше уже будут идти виртуальные строки
  })

  useEffect(() => {
    if (getData && init) {
      console.log('Change last: новый запрос из Table3', 'last=', last.last)
      const result = getData({ last: last.last, size, ...params })
      if(result instanceof Promise) {
        result.then(
          () => {
            if(!last.last) {
              setSelected({ all: false, count: 0, include: [], exclude: [] })
            }

          }
        );
      }

    }
  }, [last])

  useEffect(() => {
    // console.log('useEffect hasNextPage')
    // @ts-ignore
    rowVirtualizer.calculateRange();
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }
    let isLoadRow = lastItem.index + 1 >= allRows.length; // индикация, что есть строка загрузки: true - есть, false - нет строки значит ничего не нужно загружать

    // console.log('useEffect', 'isLoadRow', isLoadRow, lastItem.index, allRows.length, 'isFetchingNextPage', isFetchingNextPage, 'hasNextPage', hasNextPage, 'rowVirtualizer', rowVirtualizer.getVirtualItems().length, 'isError:', isError)

    if (isLoadRow && hasNextPage && !isFetchingNextPage && !isError) {
      let lastData = allRows[allRows.length-1]; // получаем последнюю строку данных, строку загрузки пропускаем
      let last = '';
      if(lastData) {
        console.log(999, params)
        const match = params.sort.split(':')
        if(match.length == 2 && !['id','_id'].includes(match[0])) {
          last = `${lastData[match[0]] ? lastData[match[0]] : ''}##${lastData['_id']}`; // берем дату последней строки, чтобы по ней загрузить следующие данные
        } else {
          const match = defaultProps.params.sort.split(':')
          const key = match[0] === 'id' ? '_id' : match[0]
          last = lastData[key]; // берем дату последней строки, чтобы по ней загрузить следующие данные
        }

      }

      if(init) {
        console.log('setLast form Table3', last, isError, error)
        setLast({last})
      }


    }
  }, [
    hasNextPage,
    allRows.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
    isError
  ])

  useEffect(() => {
    if(init) {
      console.log('setLast empty')
      setLast(lastEmpty)
    } else {
      setInit(true)
    }
  }, [params])

  const [minWidthTable, setMinWidthTable] = useState<number>(0);
  useEffect(() => {
    let countCols = columnsCalc.length;
    if(columnsCalc.some((item: any) => item.id==='menu')) countCols -= 1;
    if(columnsCalc.some((item: any) => item.id==='checkbox')) countCols -= 1;
    if(columnsCalc.some((item: any) => item.id==='rowNumber')) countCols -= 1;
    const min = table.getAllColumns().reduce(
      (previousValue, currentValue, currentIndex, array) => {
        return previousValue + (currentValue.columnDef.minSize ?? 0);
      }, 48 + countCols) // 48 - padding table both size, countCols - разделители колонок по 1px
    setMinWidthTable(min);
  }, [table,columnsCalc])


  return (
    <>
      <div /*table*/
        ref={tableRef}
        {...{
          className: 'bg-white w-full shadow rounded-lg h-full relative',
          style: {
            minWidth: minWidthTable,
          },
        }}
      >
        <div className='flex flex-col p-3 h-full'>
          <div /*thead*/
            ref={headerRef}
            className="flex justify-between h-12 border border-slate-200 rounded-md bg-slate-50"
            {...{
              style: {
                // width: table.getTotalSize()
              }
            }}
          >
            {table.getHeaderGroups().map(headerGroup => (
              <Fragment key={headerGroup.id}>
                <div /*tr*/
                  {...{
                    key: headerGroup.id,
                    className: 'flex items-center min-w-0',
                    style: {
                      width: table.getTotalSize()
                    }
                  }}
                >
                  {/* header номер по порядку */}
                  {headerGroup.headers.filter((item) => item.id==='rowNumber').map(header => {
                    // console.log(67, header)
                    return (
                      <Fragment key={header.id}>
                        <div className='flex space-x-2 pl-[32px] py-3 items-center justify-start h-full text-slate-400 overflow-hidden'
                             {...{style: {
                                 width: header.getSize(),
                                 maxWidth: header.column.columnDef.maxSize,
                                 minWidth: header.column.columnDef.minSize,
                               }}}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        </div>
                      </Fragment>)
                  })}

                  {/* header checkbox */}
                  {headerGroup.headers.filter((item) => item.id==='checkbox').map(header => {
                    // console.log(67, header)
                    return (
                      <Fragment key={header.id}>
                        <div className='h-full py-1 flex items-center justify-start'
                             {...{style: {
                                 width: header.getSize(),
                                 // maxWidth: header.getSize(),
                                 minWidth: header.column.columnDef.minSize,
                               }}}>
                          <input
                            type="checkbox"
                            name="remember-me"
                            id="remember-me"
                            className="h-4 w-4 rounded border-slate-400 text-sky-600 hover:border-sky-500 focus:ring-sky-300 "
                            checked={selected.all}
                            // disabled={isLoading || isError}
                            onChange={(event) => {setSelectAll(event.target.checked)}}
                          />
                        </div>
                      </Fragment>)
                  })}

                  {/* header columns */}
                  {headerGroup.headers.filter((item) => item.id!=='menu' && item.id!=='checkbox' && item.id!=='rowNumber').map(header => (
                    <Fragment key={header.id}>
                      <div /*th*/
                        {...{
                          // key: header.id,
                          colSpan: header.colSpan,
                          className: 'flex space-x-2 px-4 py-3 items-center justify-start h-full text-slate-600 overflow-hidden',
                          style: {
                            width: header.getSize(),
                            maxWidth: header.column.columnDef.maxSize,
                            minWidth: header.column.columnDef.minSize,
                          },
                        }}
                      >
                        <span className='overflow-hidden whitespace-nowrap text-ellipsis'>
                          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        </span>

                      </div>
                      <div className='w-[1px] min-w-[1px] h-6 bg-slate-200'></div>
                    </Fragment>
                  ))}
                </div>

                {/* header menu */}
                <div className='flex'>
                  {headerGroup.headers.filter((item) => item.id==='menu').map(header => {
                    return (
                      <Fragment key={header.id}>
                        <div className='flex px-3 py-2 items-center justify-center h-full text-slate-600'
                             {...{style: {
                                 // width: header.getSize() + scrollWidth*2, // *2, т.к. ширина делится пополам
                                 // minWidth: (Number(header.column.columnDef.minSize) ? Number(header.column.columnDef.minSize) : 0) + scrollWidth*2,
                                 // maxWidth: (Number(header.column.columnDef.maxSize) ? Number(header.column.columnDef.maxSize) : 0) + scrollWidth*2,
                                 width: header.getSize(),
                                 maxWidth: header.column.columnDef.maxSize,
                                 minWidth: header.column.columnDef.minSize,
                               }}}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          {/*<ArrowsUpDownIcon className='w-5 h-5'/>*/}
                        </div>
                      </Fragment>)
                  })}

                  {/* header компенсатор ширины скролла */}
                  <div
                    {...{style: {
                        width: scrollWidth,
                        minWidth: scrollWidth,
                        maxWidth: scrollWidth,
                      }}}></div>
                </div>


              </Fragment>

            ))}
          </div>

          <div ref={parentRef} className='overflow-y-auto '
               {...{
                 style: {
                   height: rowVirtualizer.getTotalSize(),
                 }
               }}
          >
            <div /*tbody*/
              {...{
                className: 'relative',
                style: {
                  height: rowVirtualizer.getTotalSize(),
                  // width: table.getTotalSize(),
                }
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                const isLoaderRow = virtualRow.index + 1 > rows.length
                const rowData = rows[virtualRow.index] as Row<any> // as Row<IOrder>
                return (
                  <div /*tr*/
                    {...{
                      key: virtualRow.index,
                      style: {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`,
                      }
                    }}
                    className={classNames('flex justify-between', index>0 && 'border border-t-slate-200 border-x-0 border-b-0')}
                    // onClick={() => onClickRow(post)}
                    // onDoubleClick={() => onDoubleClickRow(post)}
                  >
                    {isLoaderRow
                      ? hasNextPage
                        ? <div className="whitespace-nowrap py-2 pl-2 pr-2 font-medium text-gray-900 sm:pl-2">Загрузка...</div>
                        : <div>Больше ничего нет</div>
                      :
                      <>
                        <div className='flex items-center min-w-0'>

                          {/* cells номер по порядку */}
                          {rowData.getVisibleCells().filter((item: any) => item.column.id==='rowNumber').map(cell => (
                            <div key={cell.id}
                                 className='flex gap-3 pl-1 pr-1 py-3 items-center justify-start h-full text-slate-400 overflow-hidden'
                                 {...{ style: {
                                     width: cell.column.getSize(),
                                     minWidth: cell.column.columnDef.minSize,
                                     maxWidth: cell.column.columnDef.maxSize
                                   } }}
                            >
                              {cell.row.original?.isOnline ?
                                <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400"
                                     onMouseEnter={ (event: any) => { tooltip.show('Online', event.currentTarget, TooltipPlacement.TOP) } }
                                     onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }>
                                <div className="h-2 w-2 rounded-full bg-current" />
                              </div> :
                                <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400 opacity-0">
                                  <div className="h-2 w-2 rounded-full bg-current" />
                                </div>
                              }
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}

                            </div>
                          ))}

                          {/* cells checkbox */}
                          {rowData.getVisibleCells().filter((item: any) => item.column.id==='checkbox').map(cell => (
                            <div key={cell.id}
                                 className='flex py-1 justify-start items-center h-full'
                                 {...{
                                   style: {
                                     width: cell.column.getSize(),
                                     minWidth: cell.column.columnDef.minSize,
                                     maxWidth: cell.column.columnDef.maxSize,
                                   }
                                 }}
                            >
                              <input
                                type="checkbox"
                                name="remember-me"
                                id="remember-me"
                                className="h-4 w-4 rounded border-slate-400 text-sky-600 hover:border-sky-500 focus:ring-sky-300 "
                                checked={ !!selected.include.find((item: any) => item._id === rowData.original._id) || selected.all || !!(selected.exclude.length && !selected.exclude.find((item: any) => item._id === rowData.original._id)) }
                                // disabled={isLoading || isError}
                                onChange={(event) => {setSelect(event.target.checked, rowData.original)}}
                              />
                            </div>
                          ))}

                          {/* cells */}
                          {rowData.getVisibleCells().filter((item: any) => item.column.id!=='checkbox' && item.column.id!=='menu' && item.column.id!=='rowNumber').map((cell: any) => (
                            <div /*td*/
                              {...{
                                key: cell.id,
                                style: {
                                  width: cell.column.getSize()+1, // +1 - поправка по ширине - учет разделителей в заголовках
                                  minWidth: cell.column.columnDef.minSize ? cell.column.columnDef.minSize+1 : 0,
                                  maxWidth: cell.column.columnDef.maxSize ? cell.column.columnDef.maxSize+1 : 0
                                }
                              }}
                              className={classNames("flex space-x-2 px-4 py-3 items-center h-full text-slate-600 overflow-hidden", cell.column.columnDef.meta?.side ? cell.column.columnDef.meta?.side : 'justify-start')}>
                              <div className='whitespace-nowrap text-ellipsis overflow-hidden'>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/*cells context menu*/}
                        {rowContextMenu ?
                          rowData.getVisibleCells().filter((item: any) => item.column.id === 'menu').map((cell: any) => {
                            return (
                              <div key={cell.id}
                                   className='flex px-3 py-2 items-center justify-center h-full text-slate-600'
                                   {...{
                                     style: {
                                       width: cell.column.getSize(),
                                       minWidth: cell.column.columnDef.minSize,
                                       maxWidth: cell.column.columnDef.maxSize
                                     }
                                   }}
                              >
                                <div className='flex justify-center items-center w-[30px] h-[30px] hover:text-sky-600 hover:border hover:border-sky-200 hover:rounded cursor-pointer' onClick={(e) => contextMenu.show({ items: rowContextMenu, dataOnClick: { row: rowData, meta: table.options.meta } }, e.currentTarget)}>
                                  <EllipsisVerticalIcon className="h-5 w-5 " aria-hidden="true" />
                                </div>
                              </div>
                            )
                          })
                          :
                          tableContextMenu && <div {...{
                            style: {
                              width: 60,
                              minWidth: 60,
                              maxWidth:60
                            }
                          }}></div>
                        }


                      </>

                    }
                  </div>
                )
              })}
            </div>
          </div>


          <ActionGroupIndex selected={selected}>{actionGroup}</ActionGroupIndex>

        </div>


      </div>
    </>
  );
});

export default Table3;