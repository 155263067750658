import * as React from 'react';
import {Cell, flexRender, Header, Row} from "@tanstack/react-table";
import {forwardRef, Fragment, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from "react";

type Props = {
  cell: Header<any, any>
  selected: any
  setSelectAll: any
  ref?: any
  widthHeaderRef: any
  widthBodyRef: any
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const FooterCellCheckbox: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { cell, selected, setSelectAll, widthHeaderRef, widthBodyRef} = props;
  const ref2 = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<any>({})

  useLayoutEffect(() => {

    // console.log('useLayoutEffect HeaderCellRowNumber', )
    if(cell) {
      // console.log('useLayoutEffect', cell, ref2.current?.offsetWidth)
      const id = cell.id
      const offsetWidth = ref2.current?.offsetWidth ? ref2.current?.offsetWidth : 0
      const minWidth = cell.column.columnDef.minSize || 50 // 50 - никогда не должно сработать, настраивается в useTable defaultColumn
      const maxWidth = cell.column.columnDef.maxSize || 400 // 400 - никогда не должно сработать

      let width = offsetWidth < minWidth ? minWidth : offsetWidth
      width = width > maxWidth ? maxWidth : width


      if(widthHeaderRef.current[id]) {
        widthHeaderRef.current = {...widthHeaderRef.current, [id]: widthHeaderRef.current[id] > width ? widthHeaderRef.current[id] : width }
      } else {
        widthHeaderRef.current = { ...widthHeaderRef.current, [id]: width }
      }
    }

  },[])

  useEffect(() => {
    if(cell) {
      const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
      const b = widthBodyRef.current[cell.column.id] ? widthBodyRef.current[cell.column.id] : 0
      const s = h > b ? h : b
      setStyle({
        minWidth: s,
        maxWidth: s
      })
    }
  }, [widthHeaderRef.current, widthBodyRef.current])

  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle', cell?.column.id, ref2.current?.offsetWidth)
    return {
      clientWidth: ref2.current?.clientWidth,
      offsetWidth: ref2.current?.offsetWidth,
    } as HTMLDivElement | any
  }, [])

  return (
    <Fragment>
      <div ref={ref2} className='h-full py-1 flex items-center justify-start'
           {...{style: {
               ...style
             }}}>
        {/*<input*/}
        {/*  type="checkbox"*/}
        {/*  name="remember-me"*/}
        {/*  id="remember-me"*/}
        {/*  className="h-4 w-4 rounded border-slate-400 text-sky-600 hover:border-sky-500 focus:ring-sky-300 "*/}
        {/*  checked={selected.all}*/}
        {/*  // disabled={isLoading || isError}*/}
        {/*  onChange={(event) => {setSelectAll(event.target.checked)}}*/}
        {/*/>*/}
      </div>
    </Fragment>
  )
})

export default FooterCellCheckbox;