import {AsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICustomer} from "models/ICustomer";
import {createCustomer, fetchCustomer, fetchCustomers, updateCustomer} from "./CustomerThunk";
import {tenantApi, TenantResponse} from "../../../api/tenant";
import {customerApi, CustomersResponse} from "../../../api/customer-api";

interface CustomerState {
  list: {
    data: ICustomer[];
    isLoading: boolean;
    error: string;
    pages: any[];
    hasNextPage: boolean;
    meta: any
  },
  one: {
    data: Partial<ICustomer>;
    isLoading: boolean;
    error: string;
  },
  create: {
    data: Partial<ICustomer>;
    isLoading: boolean;
    error: string;
  },
  update: {
    data: Partial<ICustomer>;
    isLoading: boolean;
    error: string;
  }
}

const initialState: CustomerState = {
  list: {
    data: [],
    isLoading: false,
    error: '',
    pages: [],
    hasNextPage: true,
    meta: null,
  },
  one: {
    data: {},
    isLoading: false,
    error: ''
  },
  create: {
    data: {},
    isLoading: false,
    error: ''
  },
  update: {
    data: {},
    isLoading: false,
    error: ''
  }

}



export const CustomerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addMatcher<ReturnType<AsyncThunk<CustomersResponse, unknown, {}>['fulfilled']>>(
      (action) => customerApi.endpoints.getCustomers.matchFulfilled(action),
      (state, { payload }) => {
        state.list.data = payload.data;
        // state.list.meta = payload.meta;
      },
    );

    // //fetchCustomers
    // [fetchCustomers.fulfilled.type]: (state, action: PayloadAction<{data:ICustomer[], meta:any}>) => {
    //   state.list.isLoading = false;
    //   state.list.error = '';
    //   state.list.hasNextPage = !!action.payload.data.length;
    //   const last = action.payload.meta?.last;
    //   const pages = state.list.pages;
    //   // console.log('slice', 'pages', pages.join(','), 'last', last)
    //   if(last) {
    //     // console.log('last true')
    //     if(!pages.some( item => String(item) === String(last))) {
    //       // console.log('1')
    //       state.list.data = [...state.list.data, ...action.payload.data];
    //     }
    //     state.list.pages = [ ...pages, last ];
    //   } else {
    //     // console.log('last false')
    //     state.list.data = action.payload.data;
    //     state.list.pages = [];
    //   }
    // },
    // [fetchCustomers.pending.type]: (state) => {
    //   state.list.isLoading = true;
    // },
    // [fetchCustomers.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.list.isLoading = false;
    //   state.list.error = action.payload;
    //   state.list.hasNextPage = false;
    // },
    //
    // [fetchCustomer.fulfilled.type]: (state, action: PayloadAction<ICustomer>) => {
    //   state.one.isLoading = false;
    //   state.one.error = '';
    //   state.one.data = action.payload;
    // },
    // [fetchCustomer.pending.type]: (state) => {
    //   state.one.isLoading = true;
    // },
    // [fetchCustomer.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.one.isLoading = false;
    //   state.one.error = action.payload;
    // },
    //
    // [createCustomer.fulfilled.type]: (state, action: PayloadAction<ICustomer>) => {
    //   state.one.isLoading = false;
    //   state.one.error = '';
    //   state.one.data = action.payload;
    // },
    // [createCustomer.pending.type]: (state) => {
    //   state.one.isLoading = true;
    // },
    // [createCustomer.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.one.isLoading = false;
    //   state.one.error = action.payload;
    // },
    //
    // [updateCustomer.fulfilled.type]: (state, action: PayloadAction<ICustomer>) => {
    //   state.update.isLoading = false;
    //   state.update.error = '';
    //   state.update.data = action.payload;
    //   state.one.data = action.payload;
    // },
    // [updateCustomer.pending.type]: (state) => {
    //   state.update.isLoading = true;
    // },
    // [updateCustomer.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.update.isLoading = false;
    //   state.update.error = action.payload;
    // },

  }
})

export default CustomerSlice.reducer;