import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Input from "../../Input/Input";
import {EnumDialogIcon, EnumDialogWidth} from "../DialogWrap";
import {useAddTenantMutation} from "../../../../api/tenant";
import {ClientError} from "../../../../api/util/fetchBaseQueryWithReauth";
import AlertError from "../../AlertError";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";
import dialogs, {DialogServiceType} from "../Dialogs";
import {PaperClipIcon} from "@heroicons/react/24/solid";
import {useDropzone} from "react-dropzone";
import {useUploadProductsStep1Mutation, useUploadProductsStep2Mutation} from "../../../../api/product";
import SelectConstantHeight from "../../select/selectConstantHeight/SelectConstantHeight";
import {ChevronRightIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {ChatBubbleLeftIcon, CheckCircleIcon} from "@heroicons/react/24/outline";
import {useGetBrands} from "../../../../hooks/useGetBrands";
import {formatCurrencyView} from "../../../../utils/helpers";
import {useDialog} from "../../../../hooks/dialog";
import DialogSuccess from "./DialogSuccess";
import Checkbox from "../../checkbox/Checkbox";
import {RadioGroup, Switch} from "@headlessui/react";
import RadioGroupIndex from "../../radio-groups/RadioGroupIndex";
import MultiSwitch from "../../multi-switch/MultiSwitch";
import InputNumber from "../../Input/tmpl/InputNumber";

type Props = {
  _dialog?: DialogServiceType
  width?: EnumDialogWidth
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0';
  } else {
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}

const isClientError = (error: any): error is ClientError => 'data' in error

const useErrorValidation = (error1: FetchBaseQueryError | SerializedError | undefined, error2?: FetchBaseQueryError | SerializedError | undefined): {} | null => {
  const memoizedError = useMemo(() => {
    const error = error1 || error2
    if (error !== undefined && 'status' in error) {
      try {
        const result: { message: string; details: { property: string; message: string; }[] } = error.data as any
        return result.details
      } catch(e) {
        console.log(e)
        return { message: 'Unknown error', details: [] };
      }

    }

    return null;
  }, [error1, error2]);

  return memoizedError;
};

const DialogImportProducts: React.FC<Props> = ({ _dialog }) => {
  const dialog = useDialog()
  const [addTenant, { error, isError}] = useAddTenantMutation()
  const [uploadProductsStep1, { data: dataStep1, isSuccess: isSuccessStep1, isLoading, error: errorStep1, reset}] = useUploadProductsStep1Mutation()
  const [uploadProductsStep2, {data: dataStep2, error: errorStep2, isSuccess: isSuccessStep2, isLoading: isLoadingStep2}] = useUploadProductsStep2Mutation()
  const [table, setTable] = useState<any[]>([])
  const [tenantName, setTenantName] = useState<string>('')
  const inputRef = useRef<any>()
  const errorValidation: any = useErrorValidation(errorStep2);
  const [map, setMap] = useState<any>({})
  const [optionMapInit, setOptionMapInit] = useState<any>([
    {option: {_id: 'brand', value: 'Бренд'}},
    {option: {_id: 'number', value: 'Артикул'}},
    {option: {_id: 'title', value: 'Наименование'}},
    {option: {_id: 'qty', value: 'Кол-во'}},
    {option: {_id: 'price_purchase', value: 'Цена закупочная'}},
    {option: {_id: 'price_sale', value: 'Цена продажная'}},
  ])
  const [optionMap, setOptionMap] = useState<any>(optionMapInit)
  const [file, setFile] = useState<any>({})
  const [getBrands, {data: brands, isFetching: isFetchingSelect, isLoading: isLoadingBrands, status: statusBrands}] = useGetBrands()
  const [brand, setBrand] = useState<any>();
  const [brandDisabled, setBrandDisabled] = useState<boolean>(false);
  const [fromRow, setFromRow] = useState<string>('1')
  const [createMode, setCreateMode] = useState('1')
  const [updateMode, setUpdateMode] = useState('1')
  const [isPriceSaleCalc, setIsPriceSaleCalc] = useState(false)
  const [markupPricePurchase, setMarkupPricePurchase] = useState<any>({ value: '30', format: '30,00'})
  const [fixedPricePurchase, setFixedPricePurchase] = useState<any>({ value: '0', format: '0,00'})
  const [roundPriceSale, setRoundPriceSale] = useState<string>('0.01')


  const onOk = useCallback((): Promise<void> => {
    // срабатывает, когда происходит accept диалогового окна.
    // если этого обработчика нет, тогда диалоговое окно закрывается по-умолчанию при событии закрытия в dialogWrap
    // и ничего не возвращает
    // если этот обработчик есть, но нет accept(result), тогда окно не закрывается.
    // из любого места компонента можно закрыть диалоговое окно (accept() или decline()),
    // минуя регистрацию onOk и onCancel

    return new Promise((resolve, reject) => {
      if(dataStep1?.file) {
        uploadProductsStep2({
          map,
          file: dataStep1.file,
          Brand: brandDisabled ? '' : (brand ? brand._id : ''),
          fromRow: Number(fromRow),
          createMode,
          updateMode,
          isPriceSaleCalc,
          markupPricePurchase: markupPricePurchase.value,
          fixedPricePurchase: fixedPricePurchase.value,
          roundPriceSale
        }).then((result: any) => {
          if(result?.error) {
            reject() // окно не закрывается, ошибка на кнопке
          } else {
            resolve() // окно не закрывается, success на кнопке
            // _dialog?.accept({ Tenant: result.data }) // закрывается окно, выполнение .then( (result) => {-здесь-}, () -> {} ) у родителя
          }

        })
      } else {
        reject()
      }

    })

  }, [map, dataStep1, brand, fromRow, brandDisabled, createMode, updateMode, isPriceSaleCalc, markupPricePurchase, fixedPricePurchase, roundPriceSale])

  const onCancel = (): Promise<void> => {
    // варианты:
    //
    // 1. Без этого обработчика - окно просто закроется с помощью стандартного обработчика в dialogWrap
    //
    // 2. Возврат Promise - тогда окно не закроется пока не отработает Promise
    //    - resolve(result) - окно закроется, выполнение у родителя - .then( () => {}, (result) -> {-здесь-} )
    //    - reject() - окно не закроется
    //
    // 3. Возврат string | object ...etc - окно закроется с разрешением у родителя - .then( () => {}, (result) -> {-здесь-} )
    //
    // 4. Возврат Boolean(false) - окно не закроется


    // return 1
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
        _dialog?.decline('rrreee')
        // reject()
      }, 2000)

    })
  }

  useEffect(() => {
    console.log('onOk change', tenantName)

    _dialog?.acceptHandler(onOk)
    // _dialog?.declineHandler(onCancel)

    // acceptButton() // стандартная кнопка
    // acceptButton('Создать') // стандартная кнопка с нашим названием
    // acceptButton(<Button text={'Создать3'} />) // пользовательская кнопка: можно использовать все props Button, кроме onClick
    // acceptButton(false) // без кнопки

    if(isSuccessStep1) {
      _dialog?.acceptButton('Начать импорт') // стандартная кнопка с нашим названием
    } else {
      _dialog?.acceptButton(false) // скрываем кнопку
    }


  }, [onOk])

  useEffect(() => {
    _dialog?.setTitle('Импорт товаров из файла')
    _dialog?.setIcon(EnumDialogIcon.FORM)
  }, [])

  useEffect(() => {
    if(dataStep1) {
      setTable(dataStep1.rows)
    } else {
      setTable([])
    }
  }, [isSuccessStep1]);

  useEffect(() => {
    if(isLoadingStep2) {
      _dialog?.acceptButton('Импорт...')
    } else {
      _dialog?.acceptButton('Начать импорт')
    }
  }, [isLoadingStep2])

  useEffect(() => {
    if(isSuccessStep2) {
      _dialog?.acceptButton('Готово')
    }

    if(dataStep2) {
      dialog.show(<DialogSuccess title={'Импорт завершён'} text={`
        <div>Всего обработано строк: ${dataStep2?.all ?? '-'}</div>
        <div>из них</div>
          <div class="ml-3 text-green-600">создано: ${dataStep2?.inserted ?? '-'}</div>
          <div class="ml-3 text-sky-600">обновлено: ${dataStep2?.updated ?? '-'}</div>
          ${dataStep2?.updated_zero > 0 ? `<div class="ml-3 text-sky-600">установлено наличие=0: ${dataStep2?.updated_zero}</div>` : ''}
          ${dataStep2?.errors.notTitle > 0 ? `<div class="ml-3 text-red-500">нет наименования: ${dataStep2?.errors.notTitle}</div>` : ''}
          ${dataStep2?.errors.notBrand > 0 ? `<div class="ml-3 text-red-500">нет бренда: ${dataStep2?.errors.notBrand}</div>` : ''}
          ${dataStep2?.errors.notNumber > 0 ? `<div class="ml-3 text-red-500">нет артикула: ${dataStep2?.errors.notNumber}</div>` : ''}
          ${dataStep2?.errors.notUniq > 0 ? `<div class="ml-3 text-red-500">повторы (артикул-бренд): ${dataStep2?.errors.notUniq}</div>` : ''}
    `} />).then(
        () => {
          _dialog?.accept()
        }
      )
    }

  }, [isSuccessStep2])


  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0])
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    uploadProductsStep1(formData)
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const changeMap = (value: any, indexCol: number) => {
    if(value) {
      const filter: any = {}
      for(const key in map) {
        if(key !== String(indexCol)) {
          filter[key] = map[key]
        }
      }

      const m = { ...filter, [indexCol]: value }
      setMap(m)

    }
  }

  useEffect(() => {
    const filter = optionMapInit.filter((item: any) => {
      for(const key in map) {
        if(map[key] === item.option) {
          return false
        }
      }

      return true
    })

    setOptionMap([...filter])

    let bs = false
    for(const key in map) {
      if(map[key]._id === 'brand') {
        bs = true
      }
    }
    setBrandDisabled(bs)
  }, [map]);

  const onCleanSelection = (indexCol: number) => {
    const filter: any = {}
    for(const key in map) {
      if(key !== String(indexCol)) {
        filter[key] = map[key]
      }
    }

    setMap({ ...filter })
  }




  return (
    <div className={'w-full'}>
      <div className={'mb-3'}>
        { error && isClientError(error) ? (error.status == 400) && (<AlertError text={error.data.message} />) : '' }
        { errorStep1 && isClientError(errorStep1) ? (errorStep1.status == 400) && (<AlertError text={errorStep1.data.message} />) : '' }
        {errorValidation?.length ? <AlertError text={errorValidation.filter((e: any) => e.property === 'number' || e.property === 'brand').map((item: any) => item.message)} />
          :
          errorStep2 && isClientError(errorStep2) ? (errorStep2.status == 400) && (<AlertError text={errorStep2.data.message} />) : ''
        }
      </div>

      <div className={'mt-10'}>

        <div className="col-span-full mb-5" >
          {/*<label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">*/}
          {/*  Файл*/}
          {/*</label>*/}

          {isLoading ?
            <div className={classNames("mt-2 h-[190px] flex justify-center items-center rounded-lg border border-dashed", 'border-sky-900 bg-sky-50')}>

              <svg className="animate-spin h-10 w-10 self-center text-slate-500" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" fillOpacity="0%" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>

            </div>
             :
            (!isSuccessStep1 && <div className={classNames("mt-2 flex justify-center rounded-lg border border-dashed px-6 py-10", isDragActive ? 'border-sky-900 bg-sky-50' : 'border-gray-900/25')} {...getRootProps()}>
                <div className="text-center">
                  <PaperClipIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                  <div className="mt-4 flex text-xs leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-600 focus-within:ring-offset-2 hover:text-sky-500"
                    >
                      <span>Загрузите файл</span>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only" {...getInputProps()}/>
                    </label>
                    <p className="pl-1">или перетащите его в данную область</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">Формат XLS, XLSX до 10MB</p>
                  <p className="text-xs leading-5 text-gray-600">Не больше 10 000 строк</p>
                </div>
              </div>)
          }
        </div>

        <div className="">

          {isSuccessStep1 && <div className={'flex space-x-3 text-sm justify-between'}>
            <div className={'flex space-x-2'}>
              <div>{file?.name}</div>
              <div>({file?.size ? formatBytes(file.size) : ''})</div>
            </div>

            <div className={'cursor-pointer mb-3 text-sky-600 border-b border-dashed border-sky-600 hover:text-sky-500'} onClick={reset}>Загрузить другой файл</div>
          </div>}
          {isSuccessStep1 && <div className="overflow-x-auto">
              <div className="inline-block align-middle">
                <table className="divide-y divide-gray-300 min-w-full border">
                  <thead>
                    <tr className="divide-x divide-gray-200">
                      <th scope="col" className="py-1 px-2 text-left text-sm font-semibold text-gray-900">

                      </th>
                      {!!table.length && table[0].map((col: any, index: number) =>
                        <th key={index} scope="col" className="py-1 px-2 text-left text-sm font-semibold text-gray-900">
                          <div className='flex items-center w-full'>
                            <div className='grow'>
                              <SelectConstantHeight
                                data={optionMap}
                                initSelected={map[index]}
                                optionKey={'value'}
                                isLoading={true}
                                initStatus={'fulfilled'}
                                onChange={(option) => changeMap(option, index)}
                              />
                            </div>

                            <div className="cursor-pointer flex items-center ml-1 -mr-1 outline-0 hover:outline hover:outline-1 hover:outline-slate-500 hover:rounded" onClick={(e) => {onCleanSelection(index)}}>
                              <XMarkIcon className="flex-none h-5 w-5 text-slate-400 hover:text-slate-500" aria-hidden="true" />
                            </div>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">

                    {table && table.map((row: any, index) => {
                      return (
                        <tr key={index} className="divide-x divide-gray-200">
                          <td className="py-1 px-2 text-sm text-gray-500 w-[30px] min-w-[30px] max-w-[30px]">{++index}</td>
                          { row && row.map((col: any, index: number) =>
                            col ?
                              <td key={index} className="py-1 px-2 text-sm text-gray-500 w-[205px] min-w-[205px] max-w-[250px] overflow-hidden whitespace-nowrap text-ellipsis">{col}</td>
                              :
                              <td key={index} className="py-1 px-2 text-sm text-gray-500 w-[150px] min-w-[150px] max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">{<>&nbsp;</>}</td>

                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

            </div>}



          {/* настройки */}

          {isSuccessStep1 && <ul role="list" className="divide-y divide-gray-100 mt-3 mb-5">

            <li className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap">
              <div>
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  Область видимости
                </p>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    С какой строки загружать
                  </p>
                </div>
              </div>
              <div className="flex justify-between min-w-[330px]">
                <Input value={fromRow} placeholder={'Номер строки'}
                       onChange={(e) => setFromRow(e.target.value)}
                       onBlur={(e) => setFromRow(e.target.value != '' ? formatCurrencyView(e.target.value, 0).toString() : '') }
                />
              </div>
            </li>

            <li className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap">
              <div>
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  Бренд для всего файла
                </p>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    Если в разметке не указан бренд, то для создаваемых товаров будет использоваться указанный
                  </p>
                </div>
              </div>
              <div className="flex justify-between min-w-[330px]">
                <SelectConstantHeight
                  data={brands?.data ? brands.data.map((item: any) => ({option: item})) : []}
                  optionKey='name'
                  lastId='_id'
                  isLoading={isFetchingSelect}
                  hasNextPage={isLoadingBrands ? isLoadingBrands : brands?.meta.hasNextPage}
                  getData={getBrands}
                  onChange={setBrand}
                  initStatus={'fulfilled'}
                  isSearchInput={true}
                  isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'Brand') : false}
                  errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'Brand')?.message : ''}
                  isDisabled={brandDisabled}
                />
              </div>
            </li>

            <li className="flex flex-wrap items-start justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap">
              <div>
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  Создание новых товаров
                </p>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    Выберите режим создания новых товаров
                  </p>
                </div>
              </div>
              <div className="flex justify-between min-w-[330px] text-sm max-w-[330px]">
                <div className={'flex flex-col gap-3'}>
                  <RadioGroupIndex values={[
                    {id: '1', title: 'Создавать новые товары'},
                    {id: '2', title: 'Не создавать товары, только обновлять'}
                  ]}
                                   onChange={(value) => setCreateMode(value.id)}
                                   checked={{id: createMode}} classNameOption={'text-sm'}/>
                </div>
              </div>
            </li>

            <li className="flex flex-wrap items-start justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap">
              <div className={''}>
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  Обновление наличия
                </p>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    Выберите режим обновления товаров
                  </p>
                </div>
              </div>
              <div className="flex justify-between min-w-[330px] text-sm max-w-[330px]">
                <div className={''}>
                  <RadioGroupIndex values={[
                    {id: '1', title: 'Обновлять наличие для товаров из файла, для других товаров из справочника наличие не обновлять'},
                    {id: '2', title: 'Обновлять наличие для товаров из файла, для других товаров из справочника присваивать наличию 0'}
                  ]} onChange={(value) => setUpdateMode(value.id)}
                                   checked={{id: updateMode}}/>
                </div>

              </div>
            </li>

            <li className="flex flex-wrap items-start justify-between gap-x-16 xl:gap-x-56 gap-y-4 py-5 sm:flex-nowrap">
              <div className={''}>
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  Расчёт продажной цены
                </p>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    Расчет и установка продажной цены на основании закупочной цены. При включении данной настройки, выбор колонки Продажной цены в разметке игнорируется
                  </p>
                </div>
              </div>
              <div className="flex justify-between min-w-[330px] text-sm max-w-[330px]">
                <div className={'flex flex-col gap-4 w-full'}>
                  <Switch.Group as="div" className="flex items-center">
                    <Switch
                      checked={isPriceSaleCalc}
                      onChange={setIsPriceSaleCalc}
                      className={classNames(
                        isPriceSaleCalc ? 'bg-sky-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
                      )}
                    >
                      <span className="sr-only">Установка продажной цены</span>
                      <span
                        className={classNames(
                          isPriceSaleCalc ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      >
                      <span
                        className={classNames(
                          isPriceSaleCalc ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                        )}
                        aria-hidden="true"
                      >
                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                          <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span
                        className={classNames(
                          isPriceSaleCalc ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                        )}
                        aria-hidden="true"
                      >
                        <svg className="h-3 w-3 text-sky-600" fill="currentColor" viewBox="0 0 12 12">
                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                      </span>
                    </span>
                    </Switch>
                    <Switch.Label as="span" className="ml-3 text-sm">
                      {isPriceSaleCalc ?
                        <span className="text-slate-900">Расчёт включен</span>
                        :
                        <span className="text-gray-400">Не рассчитывается</span>}
                    </Switch.Label>
                  </Switch.Group>



                  <InputNumber label={'Наценка от закупочной цены, %'}
                               fractionDigits={2}
                               value={markupPricePurchase.format ? markupPricePurchase.format : ''}
                               onChange={(price) => setMarkupPricePurchase(price)}
                               onBlur={ ( price ) => setMarkupPricePurchase(price) }
                               onFocus={(e) => e.currentTarget.select()}
                               onClean={() => setMarkupPricePurchase({value: 0, format: '0,00'})}
                               disabled={!isPriceSaleCalc} />

                  <InputNumber label={'Фиксированная надбавка, ₽'}
                               fractionDigits={2}
                               value={fixedPricePurchase.format ? fixedPricePurchase.format : ''}
                               onChange={(price) => setFixedPricePurchase(price)}
                               onFocus={(e) => e.currentTarget.select()}
                               onClean={() => setFixedPricePurchase({value: 0, format: '0,00'})}
                               disabled={!isPriceSaleCalc} helpText={'Например, в цену включена доставка'}
                  />

                  <MultiSwitch
                    label={'Округление продажной цены'}
                    items={[
                      {id: '100', title: 'до 100'},
                      {id: '10', title: 'до 10'},
                      {id: '1', title: 'до 1'},
                      {id: '0.1', title: 'до 0,1'},
                      {id: '0.01', title: 'до 0,01'},
                    ]}
                    onChange={(value) => setRoundPriceSale(value.id)}
                    checked={{id: '0.01', title: 'до 0,01'}}
                    disabled={!isPriceSaleCalc}
                  />


                </div>

              </div>
            </li>

          </ul>}


        </div>



      </div>

    </div>
  );
};

export default DialogImportProducts;