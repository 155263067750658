import * as React from 'react';
// import {useAppDispatch, useAppSelector} from "hooks/redux";
import {Fragment, useEffect, useState} from "react";
import {employeeApi, useDelEmployeeMutation, useGetEmployeesQuery} from "../../api/employees";
import Table3 from "../../components/ui/table3/Table3";
import {useGetProducts} from "../../hooks/useGetProducts";
import {useGetEmployees} from "../../hooks/useGetEmployees";
import {Link} from "react-router-dom";
import {createColumnHelper} from "@tanstack/react-table";
import {IEmployee} from "../../models/IEmployee";
import ProductSlide from "../../components/ui/slide/tmpl/ProductSlide";
import {useSlide} from "../../hooks/slide";
import EmployeeSlide from "../../components/ui/slide/tmpl/EmployeeSlide";
import {productApi, useDelProductMutation} from "../../api/product";
import {useContextMenu} from "../../hooks/contextMenu";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useNotification} from "../../hooks/notification";
import Notifications from "../../components/ui/notifications/Notifications";
import NotificationError from "../../components/ui/notifications/tmpl/NotificationError";
import {ColumnsWidth} from "../../components/ui/table/Table";

type Props = {
  children?: React.ReactNode
  param?: string
};


const EmployeesPage: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const slides = useSlide();
  const [getEmployees, {data: employees, isFetching, isLoading, isError: isError3, error: error3}] = useGetEmployees()
  const [params, setParams] = useState<any>()
  const [delEmployee] = useDelEmployeeMutation();
  const notification = useNotification();
  const columnHelper = createColumnHelper<IEmployee>()

  const tableContextMenu = [
    [
      {text: 'Добавить', onClick:({meta}: any) => { create(meta) }},
    ],
    [{text: 'Обновить', onClick:(info: any) => { dispatch(employeeApi.util.invalidateTags(['Employees']));}}]
  ]
  const rowContextMenu = [
    // [{text: 'Редактировать'}, {text: 'Печать'}],
    [{text: 'Удалить', onClick:({ row, meta }: any) => {
        delEmployee(row.original._id).then(
          (r: any) => {
            if(r?.error) {
              console.log(r.error.data.message)
              notification.show(<NotificationError text={r.error.data.message} />)
            }
            // setParams({filterText, cat: meta?.cat, ...meta?.filter2})
            // updateCategories(meta?.cat, meta?.filter2)
          }
        )
      }}]
  ]

  const clickEmployee = (e: any, info: any) => {
    e.preventDefault();
    // notify.show(<NotificationSuccess text={`Клиент ${info.row.original.firstName}`} />)
    // setOpenSlide(!openSlide)

    // console.log(1, info, info.table.options.meta)
    const meta = info.table.options.meta
    slides.show(<EmployeeSlide idEmployee={info.row.original._id} />).then(
      (result) => {},
      (reason) => {
        console.log(reason)
      }
    )
  }
  const columns = [
    columnHelper.accessor('lastName', {
      header: 'ФИО',
      cell: info => <Fragment>
        <Link to="/" onClick={(e) => clickEmployee(e, info)} className="text-sky-600 hover:text-sky-500 border-b border-dashed border-sky-600 hover:border-sky-500">
          {info.cell.row.original.lastName || info.cell.row.original.firstName ?
            <>{info.cell.row.original.lastName} {info.cell.row.original.firstName}</> : <>не заполнено</>}
        </Link> {auth.data.userId===info.cell.row.original._id ? '(текущий)' : ''} </Fragment>,
      size: 800,
      minSize: 150,
      maxSize: 800,
      meta: {
          width: ColumnsWidth.STRETCH
      },
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      size: 300,
      // minSize: 300,
      maxSize: 300,
      meta: {
        width: ColumnsWidth.MAX_CONTENT_OR_HEADER
      }
    }),

    columnHelper.accessor('roles', {
      header: 'Роль',
      size: 130,
      minSize: 130,
      maxSize: 130,
    }),

  ]

  const create = (meta: any) => {
    slides.show(<EmployeeSlide idEmployee={'new'} />).then(
      (result) => {},
      (reason) => {
        console.log(reason)
      }
    )
  }

  useEffect(() => {
    console.log('setParams')
    setParams({ })
  }, [])

  return (
    <>
      <div className='h-full'>
        <Table3
          columns={columns}
          data={employees.data}
          isLoading={isLoading}
          isFetching={isFetching}
          hasNextPage={isLoading ? isLoading : employees?.meta.hasNextPage}
          getData={getEmployees}
          tableContextMenu={tableContextMenu}
          rowContextMenu={rowContextMenu}
          params={params}
          isError={isError3}
          error={error3}
        />
      </div>
    </>
  );
};

export default EmployeesPage;