import * as React from 'react';
import {Row, Table} from "@tanstack/react-table";

import {Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {ColumnsWidth} from "../Table";
import CellPreloader from "./cells/CellPreloader";
import CellRowNumber from "./cells/CellRowNumber";
import CellCheckbox from "./cells/CellCheckbox";
import CellSimple from "./cells/CellSimple";
import CellStretch from "./cells/CellStretch";
import CellRowContextMenu from "./cells/CellRowContextMenu";
import HeaderCellSimple from "../headers/cells/HeaderCellSimple";
import HeaderCellSeparator from "../headers/cells/HeaderCellSeparator";


type Props = {
  widthBodyRef?: any
  widthHeaderRef?: any
  widthFooterRef?: any
  isLoaderRow: boolean
  hasNextPage: string
  rowData: Row<any>
  checked: boolean
  selected: any
  setSelect: any
  rowContextMenu: any
  tableContextMenu: any
  table: Table<any>
  minWidthTrRef?: any
  data?: any
  cellWidth?: any
  setCellWidth?: any
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Slow = ({data, cell, widthBodyRef, widthHeaderRef, widthFooterRef, ref}: any ) => <CellSimple data={data} cell={cell} widthBodyRef={widthBodyRef} widthHeaderRef={widthHeaderRef} widthFooterRef={widthFooterRef} ref={ref} />

const  TrMultiColumns: React.FC<Props> = (props: Props) => {
  const {widthBodyRef, widthHeaderRef, widthFooterRef, isLoaderRow, hasNextPage, rowData, checked, selected, setSelect, rowContextMenu, tableContextMenu, table, minWidthTrRef, data, cellWidth, setCellWidth} = props;

  const cells = useMemo(() => rowData.getVisibleCells().filter((item: any) => item.column.id!=='checkbox' && item.column.id!=='menu' && item.column.id!=='rowNumber'), [])
  const colStretchIndex = useMemo(() => cells.findIndex((item: any) => item.column.columnDef.meta?.width === ColumnsWidth.STRETCH), [])
  const colStretch = useMemo(() => cells[colStretchIndex], [])
  const colRowNumber = useMemo(() => rowData.getVisibleCells().find((item: any) => item.column.id==='rowNumber'), [])
  const colCheckbox = useMemo(() => rowData.getVisibleCells().find((item: any) => item.column.id==='checkbox'), [])
  const colMenu = useMemo(() => rowData.getVisibleCells().find((item: any) => item.column.id==='menu'), [])
  const colsUntilStretch = useMemo(() => colStretchIndex > -1 ? cells.slice(0, colStretchIndex) : [], [])
  const colsAfterStretch = useMemo(() => colStretchIndex > -1 ? cells.slice(colStretchIndex+1, cells.length) : [], [])

  const [minWidthLeft, setMinWidthLeft] = useState(0)
  const [minWidthRight, setMinWidthRight] = useState(0)

  const rowNumberRef = useRef<any>(null);
  const checkboxRef = useRef<any>(null);
  const untilRef = useRef<any>({});
  const afterRef = useRef<any>({});
  const contextMenuRef = useRef<any>(null);

  const separatorsLeftRef = useRef<any>(0);
  const separatorsRightRef = useRef<any>(0);
  separatorsLeftRef.current = 0
  separatorsRightRef.current = 0

  useLayoutEffect(() => {
    // console.log('монтирование row')
  }, [])

  useEffect(() => {
    // console.log(111, widthBodyRef.current)
    let leftSum = 0
    let rightSum = 0
    const left = colsUntilStretch.map(item => item.column.id)
    const right = colsAfterStretch.map(item => item.column.id)
    let countCellSimple = 0
    for (let key of Object.keys(widthBodyRef.current)) {
      const h = widthHeaderRef.current[key] ? widthHeaderRef.current[key] : 0
      const b = widthBodyRef.current[key] ? widthBodyRef.current[key] : 0
      const f = widthFooterRef && widthFooterRef.current[key] ? widthFooterRef.current[key] : 0
      const max = Math.max(h, b, f)
      if(['rowNumber', 'checkbox'].includes(key)) {
        // console.log(1111, key, h, b, s)
        leftSum += max
      }
      else if([...left].includes(key)) {
        leftSum += max+1
        countCellSimple += 1
      }
      else if([...right].includes(key)) {
        rightSum += max+1
        countCellSimple += 1
      }
      else if(['menu'].includes(key)) {
        rightSum += max
      }

    }

    leftSum += separatorsLeftRef.current
    rightSum += separatorsRightRef.current

    setMinWidthLeft(leftSum)
    setMinWidthRight(rightSum)

    const minWidthStretch = (colStretch.column.columnDef.minSize || 300) + 2 + countCellSimple // +1 - border right col stretch
    if(minWidthTrRef.current !== leftSum + minWidthStretch + rightSum) { // +2 - both borders header
      minWidthTrRef.current = leftSum + minWidthStretch + rightSum
    }

  }, [cellWidth])

  // useEffect(() => {
  //   minWidthTrRef.current = minWidthLeft + 303 + minWidthRight
  // }, [minWidthLeft, minWidthRight])

  return (
    <>
        <>
        {colStretch ?
          <>
            <div className={classNames(
              'flex items-center border-l border-transparent ',
              rowData.original?.move === 1 ? 'text-green-600/70' : '',
              rowData.original?.move === 2 ? 'text-amber-600/70' : '',
              rowData.original?.move !== 1 && rowData.original?.move !== 2 ? 'text-slate-500' : '',
              // 'border border-red-500'
            )} {...{style: {
              minWidth: minWidthLeft,
            }}}
            >

              {/* cells номер по порядку */}
              <CellRowNumber widthBodyRef={widthBodyRef} widthHeaderRef={widthHeaderRef} ref={rowNumberRef} cell={colRowNumber} />

              {/* cells checkbox */}
              <CellCheckbox widthBodyRef={widthBodyRef} widthHeaderRef={widthHeaderRef} ref={checkboxRef} rowData={rowData} cell={colCheckbox} selected={selected} setSelect={setSelect} />

              {colsUntilStretch.map(cell =>
                <Fragment key={cell.column.id}>
                  <CellSimple key={cell.column.id} cellWidth={cellWidth} setCellWidth={setCellWidth} data={data} widthBodyRef={widthBodyRef} widthHeaderRef={widthHeaderRef} widthFooterRef={widthFooterRef} cell={cell} ref={(el: any) => untilRef.current[cell.column.id] = el} />
                  <HeaderCellSeparator ref={(el: number) => separatorsLeftRef.current += el}/>
                </Fragment>
              )}

            </div>

            <div className={classNames(
              'flex flex-1 items-center',
              rowData.original?.move === 1 ? 'text-green-600/70' : '',
              rowData.original?.move === 2 ? 'text-amber-600/70' : '',
              rowData.original?.move !== 1 && rowData.original?.move !== 2 ? 'text-slate-500' : '',
            )} {...{style: {minWidth: (colStretch.column.columnDef.minSize || 300) + 2}}}>
              <CellStretch cell={colStretch} checked={checked} setSelect={setSelect} rowData={rowData} />
            </div>

            <div className={'flex items-center'}>
              <HeaderCellSeparator isHidden={colsAfterStretch.length == 0 && !colMenu}/>
            </div>

            <div className={classNames(
              'flex items-center border-r border-transparent', 0 ? 'border border-red-500' : '',
              rowData.original?.move === 1 ? 'text-green-600/70' : '',
              rowData.original?.move === 2 ? 'text-amber-600/70' : '',
              rowData.original?.move !== 1 && rowData.original?.move !== 2 ? 'text-slate-500' : '',
            )} {...{style: {
                minWidth: minWidthRight
              }}}>
              {colsAfterStretch.map((cell, index) =>
                <Fragment key={cell.column.id}>
                  <CellSimple cellWidth={cellWidth} setCellWidth={setCellWidth} data={data} widthBodyRef={widthBodyRef} widthHeaderRef={widthHeaderRef} widthFooterRef={widthFooterRef} cell={cell} ref={(el: any) => afterRef.current[cell.column.id] = el} />
                  {index+1 < colsAfterStretch.length ? <HeaderCellSeparator ref={(el: number) => separatorsRightRef.current += el} isHidden={colsAfterStretch.length == index+1 && !colMenu}/> : ''}
                </Fragment>
              )}
              {/*cells context menu*/}
              <CellRowContextMenu widthBodyRef={widthBodyRef} widthHeaderRef={widthHeaderRef} rowData={rowData} cell={colMenu} tableContextMenu={tableContextMenu} rowContextMenu={rowContextMenu} meta={table.options.meta} ref={contextMenuRef} />
            </div>
          </>
        :
          <>
            <div className='flex min-w-0 border border-red-500'>

              {/* cells номер по порядку */}
              <CellRowNumber ref={rowNumberRef} cell={colRowNumber} />

              {/* cells checkbox */}
              <CellCheckbox ref={checkboxRef} rowData={rowData} cell={colCheckbox} selected={selected} setSelect={setSelect} />

              {/*{cells.map(cell => <CellSimple cell={cell} />)}*/}

            </div>

            <div className={'flex items-center min-w-0 border border-red-500'}>
              {/*cells context menu*/}
              <CellRowContextMenu widthBodyRef={widthBodyRef} widthHeaderRef={widthHeaderRef} rowData={rowData} cell={colMenu} tableContextMenu={tableContextMenu} rowContextMenu={rowContextMenu} meta={table.options.meta} ref={contextMenuRef} />

            </div>
          </>
        }



        </>
    </>
  );
};

export default TrMultiColumns;