// import {useAppDispatch, useAppSelector} from "hooks/redux";
import * as React from 'react';
import {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {
  useCreateDocOurOrderMutation,
  useDelDocOurOrdersMutation,
  useLazyGetDocOurOrdersQuery, useMoveCancelDocOurOrdersMutation, useMoveDocOurOrdersMutation
} from "../../api/doc-our-orders-api";
import Button from "../../components/ui/buttons/Button";
import TableConstantMultiColumns from "../../components/ui/table/tmpl/TableConstantMultiColumns";
import {createColumnHelper, flexRender} from "@tanstack/react-table";
import {ColumnsWidth} from "../../components/ui/table/Table";
import {MainContext} from "../../components/Main";
import {IProduct} from "../../models/IProduct";
import {useDialog} from "../../hooks/dialog";
import DialogAddProducts from "../../components/ui/dialogs/tmpl/DialogAddProducts";
import {EnumDialogWidth} from "../../components/ui/dialogs/DialogWrap";
import Input from "../../components/ui/Input/Input";
// import {fetchClient} from "store/reducers/clients/ClientThunk";
import { v4 as uuidv4 } from 'uuid';
import {formatCurrencyView, formatNumber, formatSumSeparator} from "../../utils/helpers";
import InputQtyCell from "../../components/ui/Input/tmpl/InputQtyCell";
import InputPriceCell from "../../components/ui/Input/tmpl/InputPriceCell";
import ActionGroup1 from "../../components/ui/table3/action-groups/tmpl/ActionGroup1";
import ActionGroupOrder from "../../components/ui/table3/action-groups/tmpl/ActionGroupOrder";
import {useDelProductsMutation, useLazyGetSelectedProductsQuery} from "../../api/product";
import {isArray} from "lodash";
import {Link, useNavigate} from "react-router-dom";
import ProductSlide from "../../components/ui/slide/tmpl/ProductSlide";
import {useSlide} from "../../hooks/slide";
import {useLazyGetABCPOrdersQuery} from "../../api/abcp";
import {useGetCursor} from "../../hooks/useGetCursor";
import {IDocOurOrder} from "../../models/IDocOurOrder";
import {DateTime} from "luxon";
import NotificationError from "../../components/ui/notifications/tmpl/NotificationError";
import NotificationSuccess from "../../components/ui/notifications/tmpl/NotificationSuccess";
import {useNotification} from "../../hooks/notification";
import DialogQuestionDanger from "../../components/ui/dialogs/tmpl/DialogQuestionDanger";
import {deleteCustomer} from "../../store/reducers/customers/CustomerThunk";

type Props = {
  children?: React.ReactNode
  param?: string
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const DocOurOrders: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const notification = useNotification();
  const {children, param: name = 'по-умолчанию'} = props;
  const [createDocOurOrder] = useCreateDocOurOrderMutation()
  const [positionsList, setPositionsList] = useState<any[]>([])
  const [positionsData, setPositionsData] = useState<any[]>([])
  const {main, title, content} = useContext(MainContext)
  const dialog = useDialog()
  const [getSelectedProducts] = useLazyGetSelectedProductsQuery()
  const slides = useSlide();
  const [changeData, setChangeData] = useState<any>()

  const [sort, setSort] = useState<string>('date:desc')
  const [params, setParams] = useState<any>()

  const [getOrder, obj, lastArgs] = useLazyGetDocOurOrdersQuery()
  const [getOurOrders, {data: orders, isFetching, isLoading, isError: isError3, error: error3}] = useGetCursor(getOrder, obj)

  const [delDocs, {}] = useDelDocOurOrdersMutation();
  const [moveDocs, {}] = useMoveDocOurOrdersMutation();
  const [moveCancelDocs, {}] = useMoveCancelDocOurOrdersMutation();

  const getOurOrderGap = (params: any) => {
    // if(filterText) params.filterText = filterText
    // if(cat) params.cat = cat;
    console.log('gap', params)
    // setParams(params)
    return getOurOrders({ ...params, sort })
  }
  useEffect(() => {
    console.log('setParams2', params, { sort })
    setParams({ sort })
  }, [ sort ])


  const columnHelper = createColumnHelper<IDocOurOrder & {price_sum: string}>()
  const columns = [
    columnHelper.accessor('number', {
      header: () => 'Номер',
      // cell: info => <span className={'text-sky-600'}>{info.renderValue()}</span>,
      minSize: 50,
      // size: 200,
      meta: {side: 'justify-center'}
    }),
    columnHelper.accessor('date', {
      header: () => 'Дата',
      cell: info => {
        const date = info.renderValue()
        if(date) {
          return DateTime.fromISO(date).toFormat('dd.MM.yyyy HH:mm:ss')
        }
      },
      minSize: 50,
      size: 200,
    }),
    columnHelper.accessor('countPositionsSuppliers', {
      header: () => 'Поставщики',
      cell: info => {
        const suppliers = info.renderValue()
        if(Array.isArray(suppliers)) {
          return suppliers.map(item => `${item.supplierName || 'Без поставщика'} (${item.count})`).join(', ')
        }
      },
      minSize: 50,
      size: 200,
      meta: { width: ColumnsWidth.STRETCH, },
    }),
    columnHelper.accessor('countPositions', {
      header: () => 'Кол-во строк',
      cell: info => info.renderValue(),
      minSize: 50,
      size: 200,
      meta: {side: 'justify-center'}

    }),
    columnHelper.accessor('sumPositions', {
      header: () => 'Сумма',
      cell: info => info.getValue() ? formatSumSeparator(formatCurrencyView(info.getValue())) + ' ₽' : '0 ₽',
      minSize: 50,
      size: 200,
      meta: {side: 'justify-end'}
    }),
    columnHelper.accessor('note', {
      header: 'Примечание',
      cell: (info: any) => info.renderValue(),
      // size: 200,
      maxSize: 150,
      // minSize: 100
    }),
  ]

  const create = () => {
    // createDocOurOrder({number: '1', date: '2'})
    navigate('/doc-our-orders/new')
  }

  const clickOurOrder = (info: any) => {
    console.log('123', info)
    navigate(`/doc-our-orders/${info.row.original._id}`)
  }

  const onDelete = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    dialog.show(<DialogQuestionDanger title='Внимание' text='Вы действительно хотите удалить выбранные документы?' buttonTextOk={'Удалить'}/>).then(
      () => {
        delDocs({selected, params}).then(
          (r: any) => {
            // setParams({ cat, ...filter2, filterText, onlyRoot, sort })

            if(r?.error) {
              console.log(r.error.data.details)
              if(r.error.data.details == 1) {
                setParams({ sort })
              }
              notification.show(<NotificationError text={r.error.data.message} />)
            } else {
              setParams({ sort })
              notification.show(<NotificationSuccess text={'Документы удалены'} />)
            }

          }
        )
      }
    )

  }

  const onMove = (selected: { all: boolean, count: number, include: any[], exclude: any[] }) => {
    moveDocs({selected, params}).then(
      (r: any) => {
        // setParams({ cat, ...filter2, filterText, onlyRoot, sort })

        if(r?.error) {
          console.log(r.error.data.details)
          if(r.error.data.details == 1) {
            setParams({ sort })
          }
          notification.show(<NotificationError text={r.error.data.message} />)
        } else {
          setParams({ sort })
          notification.show(<NotificationSuccess text={'Документы проведены'} />)
        }

      }
    )
  }

  const onMoveCancel = (selected: { all: boolean, count: number, include: any[], exclude: any[] }) => {
    moveCancelDocs({selected, params}).then(
      (r: any) => {
        // setParams({ cat, ...filter2, filterText, onlyRoot, sort })

        if(r?.error) {
          console.log(r.error.data.details)
          if(r.error.data.details == 1) {
            setParams({ sort })
          }
          notification.show(<NotificationError text={r.error.data.message} />)
        } else {
          setParams({ sort })
          notification.show(<NotificationSuccess text={'Проводка отменена'} />)
        }

      }
    )
  }

  return (
    <>
      <div className={'mb-2'}>
        <Button text={'Создать'} onClick={create} />
      </div>

      <div className="flex flex-col justify-start"
           {...{
             style: {
               height: `calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 82px)`,
             },
           }}>

        <TableConstantMultiColumns
          columns={columns}
          data={orders.data}
          isLoading={isLoading}
          isFetching={isFetching}
          hasNextPage={isLoading ? isLoading : orders?.meta.hasNextPage}
          getData={getOurOrderGap}
          params={params}
          onClickRow={(info) => clickOurOrder(info)}
          isCheckbox={true}
          isRowNumber={true}
          actionGroup={<ActionGroupOrder
            onDelete={onDelete}
            onMove={onMove}
            onMoveCancel={onMoveCancel}
          />}
          meta={ {totalElements: orders?.meta.totalElements} }
        />

      </div>
    </>
  );
};

export default DocOurOrders;