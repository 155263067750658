import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "hooks/redux";
import {logoutToServer} from "store/reducers/AuthSlice";

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.auth)
  useEffect(() => {
    dispatch(logoutToServer(user.data.userId))
  }, [])
  return (
      <div></div>
  );
};

export default LogoutPage;