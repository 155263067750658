import * as React from 'react';
import {forwardRef, Fragment, useEffect} from "react";
import {RowData} from "@tanstack/react-table";

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    width?: ColumnsWidth,
    side?: string,
    padding_not?: boolean,
    // [type: string]: string
  }
}

export enum ColumnsWidth {
  MAX_CONTENT = 'max-content',
  STRETCH = 'stretch',
  MAX_CONTENT_OR_HEADER = 'max-content-or-header', // ширина колонки подстраивается под содержимое
}

type Props = {
  children?: React.ReactNode
  tableRef: any
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Table: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {

  const {
    children,
    tableRef,
  } = props;


  return (
    <>
      <div /*table*/
        ref={tableRef}
        {...{
          className: 'bg-white shadow rounded-lg w-full overflow-x-auto relative',
          style: {
            // width: table.getTotalSize(),
            // width: tableRef.current?.clientWidth,
            // minWidth: 500,
          },
        }}
      >

        {children}

      </div>
    </>
  )
})

export default Table;