import * as React from 'react';
// import {useAppDispatch, useAppSelector} from "hooks/redux";
import {useEffect, useState} from "react";
// import {fetchClient} from "store/reducers/clients/ClientThunk";

type Props = {
  items: { id: string, title: string }[]
  label?: string
  onChange?: (value: { id: string, title: string }) => void
  checked?: { id: string, title: string }
  disabled?: boolean
};
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
const MultiSwitch: React.FC<Props> = (props: Props) => {
  const {items, label, checked, disabled, onChange} = props;
  const [active, setActive] = useState<{id: string, title: string} | undefined>(checked)

  const onClick = (item: {id: string, title: string}) => {
    setActive(item)
    if(onChange) onChange(item)
  }

  return (
    <div>
      {label ? <label htmlFor="" className={classNames("block text-sm font-medium", 'mb-1', disabled ? 'text-slate-300' : 'text-gray-700')}>{ label }</label> : ''}
      <span className="isolate inline-flex rounded-md shadow-sm">
        {items.map( (item, index) =>
          <button
            key={item.id}
            type="button"
            className={classNames(
              'relative inline-flex items-center px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10',
              index == 0 ? 'rounded-l-md' : '',
              index > 0 && index < items.length-1 ? '-ml-px' : '',
              items.length-1 == index ? '-ml-px rounded-r-md' : '',
              disabled ?
                item.id === active?.id ? 'pointer-events-none text-white bg-gray-200' : 'pointer-events-none text-gray-300 bg-white'
                : item.id === active?.id ? 'text-white bg-sky-600 hover:bg-sky-700' : 'text-gray-900 bg-white hover:bg-gray-50',
            )}
            onClick={() => {onClick(item)}}
        >
            {item.title}
        </button>)}
      </span>
    </div>
  );
};

export default MultiSwitch;