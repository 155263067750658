
/**
 * Форматирование отображения цен и чисел с дробными значениями для пользователей
 *
 * @param number
 * @param countCharacters
 * @return {String}
 */
const formatCurrencyView = function (number: number | string | undefined, countCharacters: number = 2) {
  let val = number || 0 //по-умолчанию ноль

  val = val.toString()
  //-.,12.3,4,y

  // ищем минус впереди
  let minus = val.indexOf('-') === -1 ? '' : '-';
  //-.,12.3,4,y

  val = val.replace(/[^0-9.,]/gi,'')//удаляем все, кроме "0-9.,"
  //.,12.3,4,

  val = val.replace(/^([.,]*)|([.,]*)$/gi,'')//удаляем первые и последние символы ".,"
  //12.3,4

  //удаляем все символы ".,", кроме последнего
  //меняем последний символ(.,) на точку(.), чтобы корректно отработал parseFloat или Number
  let res = ''
  let f = false
  val.split('').forEach((item,index,array)=>{
    f = false
    if(item==='.' || item===',') {
      for(let i=index+1; i<array.length; i++) {
        if(array[i]==='.' || array[i]===',') {
          // console.log('eще есть точка')
          f = true
        }
      }
      if(!f) res += '.'
    } else {
      res += item
    }
  })
  //123.4

  //проверяем является ли результат числом, если не является (случай, когда ввели только буквы и т.д.),
  //тогда приравниваем к нулю
  // res = Number(res);//Number('') === 0, Number('a') === NaN
  if(isNaN(Number(res)) || res === '') {
    res = '0';
  }

  //если запятых нет, добавляем до 2-х
  //если после запятой одна цифра, добавляем до 2-х
  //если после запятой больше 2-х цифр, округляем до 2-х
  let d = countCharacters ?? 2; //кол-во цифр после точки
  //http://www.jacklmoore.com/notes/rounding-in-javascript/
  res = Number(Math.round(Number(res + 'e' + d))+'e-'+d).toFixed(d)
  //123.40

  //меняем точку на запятую
  res = res.toString().replace('.',',')
  //123,40

  //добавляем минус, если был
  res = `${minus}${res}`;

  return res;
}

const formatNumber = function (number: number | string | undefined, countCharacters: number = 2) {
  let val = number || 0 //по-умолчанию ноль

  val = val.toString()
  //-.,12.3,4,y

  // ищем минус впереди
  let minus = val.indexOf('-') === -1 ? '' : '-';
  //-.,12.3,4,y

  val = val.replace(/[^0-9.,]/gi,'')//удаляем все, кроме "0-9.,"
  //.,12.3,4,

  val = val.replace(/^([.,]*)|([.,]*)$/gi,'')//удаляем первые и последние символы ".,"
  //12.3,4

  //удаляем все символы ".,", кроме последнего
  //меняем последний символ(.,) на точку(.), чтобы корректно отработал parseFloat или Number
  let res = ''
  let f = false
  val.split('').forEach((item,index,array)=>{
    f = false
    if(item==='.' || item===',') {
      for(let i=index+1; i<array.length; i++) {
        if(array[i]==='.' || array[i]===',') {
          // console.log('eще есть точка')
          f = true
        }
      }
      if(!f) res += '.'
    } else {
      res += item
    }
  })
  //123.4

  //проверяем является ли результат числом, если не является (случай, когда ввели только буквы и т.д.),
  //тогда приравниваем к нулю
  // res = Number(res);//Number('') === 0, Number('a') === NaN
  if(isNaN(Number(res)) || res === '') {
    res = '0';
  }

  //если запятых нет, добавляем до 2-х
  //если после запятой одна цифра, добавляем до 2-х
  //если после запятой больше 2-х цифр, округляем до 2-х
  let d = countCharacters ?? 2; //кол-во цифр после точки
  //http://www.jacklmoore.com/notes/rounding-in-javascript/
  res = Number(Math.round(Number(res + 'e' + d))+'e-'+d).toFixed(d)
  //123.40

  //добавляем минус, если был
  res = `${minus}${res}`;

  const resNumber = isNaN(Number(res)) ? 0 : Number(res)

  //меняем точку на запятую
  res = res.toString().replace('.',',')
  //123,40

  // console.log('res', {
  //   number: resNumber,
  //   string: res,
  // })

  return {
    number: resNumber,
    string: res,
  }
}

const formatNumberToServer = function (number: any) {
  let val = number || 0 //по-умолчанию ноль

  val = val.toString()
  //-.,12.3,4,y

  // ищем минус впереди
  let minus = val.indexOf('-') === -1 ? '' : '-';
  //-.,12.3,4,y

  val = val.replace(/[^0-9.,]/gi,'')//удаляем все, кроме "0-9.,"
  //.,12.3,4,

  val = val.replace(/^([.,]*)|([.,]*)$/gi,'')//удаляем первые и последние символы ".,"
  //12.3,4

  //удаляем все символы ".,", кроме последнего
  //меняем последний символ(.,) на точку(.), чтобы корректно отработал parseFloat или Number
  let res: any = ''
  let f = false
  val.split('').forEach((item: string, index: number, array: [])=>{
    f = false
    if(item==='.' || item===',') {
      for(let i=index+1; i<array.length; i++) {
        if(array[i]==='.' || array[i]===',') {
          // console.log('eще есть точка')
          f = true
        }
      }
      if(!f) res += '.'
    } else {
      res += item
    }
  })
  //123.4

  //добавляем минус, если был
  res = `${minus}${res}`;

  //проверяем является ли результат числом, если не является (случай, когда ввели только буквы и т.д.),
  //тогда приравниваем к нулю
  res = Number(res);//Number('') === 0, Number('a') === NaN
  if(isNaN(res)) {
    res = 0;
  }

  //если запятых нет, добавляем до 2-х
  //если после запятой одна цифра, добавляем до 2-х
  //если после запятой больше 2-х цифр, округляем до 2-х
  let d = 2 //кол-во цифр после точки
  // //http://www.jacklmoore.com/notes/rounding-in-javascript/
  res = Number(Math.round(Number(res + 'e' + d))+'e-'+d).toFixed(d)
  //123.40

  // //меняем точку на запятую
  // res = res.toString().replace('.',',')
  // //123,40

  return res;
}

// const addCurrencyChar = function (number: number, charString: string) {
//   if(!isEmpty(charString)) {
//     let s = unescape('%u' + charString.replace(/\\u/g,''));
//     // console.log('s',s,newValue[0]);
//     if(String(number[0]) !== String(s) && !isEmpty(s)) {
//       // console.log('ss',`${s}${newValue}`);
//       return `${s}${number}`;
//     }
//   }
//
//   return number;
// }

/**
 * Форматирование отображения цен <br>
 * Было: 50000,00 / 500 / 1000 / 2000000,00 <br>
 * Стало: 50 000,00 / 500 / 1 000 / 2 000 000,00
 *
 * @param sum
 * @return {String}
 */
const formatSumSeparator = function (sum: string | number | undefined) {
  // входящая сумма подразумевает формат без разделителя или с разделителем запятая или точка, так же возможен минус

  let val = sum || 0 //по-умолчанию ноль

  let formatSum = val.toString();

  // ищем минус впереди
  let minus = formatSum.indexOf('-') === -1 ? '' : '-';
  if(minus) {
    formatSum = formatSum.replace('-', '');
  }

  // ищем разделитель запятую
  let math = formatSum.split(',');
  if(!math[1]) { // если не находим, тогда ищем точку
    math = formatSum.split('.');
  }

  formatSum = math[0];
  const length = formatSum.length;
  if(length <= 3) {
    formatSum = math[1] ? `${formatSum},${math[1]}` : `${formatSum}`;
  } else {
    const c = length % 3;
    const a = formatSum.slice(0, c);
    const b = formatSum.slice(c, formatSum.length);
    let temp = '';
    if(a) {
      temp = `${a} `;
    }
    for(let f=0; f < b.length; f = f+3) {
      temp += `${b.slice(f, f+3)} `;
    }
    temp = temp.trim();
    temp = math[1] ? `${temp},${math[1]}` : `${temp}`;
    formatSum = temp;
  }

  return `${minus}${formatSum}`;
}

export {
  formatNumberToServer,
  formatCurrencyView,
  formatSumSeparator,
  formatNumber,
}