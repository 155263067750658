import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Input from "../../Input/Input";
import {useTooltip} from "../../../../hooks/tootip";
import {useAddEmployeeMutation, useLazyGetEmployeeQuery, useUpdateEmployeeMutation} from "../../../../api/employees";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";
import {SlideServiceType} from "../Slides";

type Props = {
  idEmployee: string

  _slide?: SlideServiceType
};

const useErrorValidation = (error: FetchBaseQueryError | SerializedError | undefined): {} | null => {
  const memoizedError = useMemo(() => {
    if (error !== undefined && 'status' in error) {
      try {
        console.log(1, error)
        const result: { message: string; details: { property: string; message: string; }[] } = error.data as any

        return result.details
      } catch(e) {
        console.log(e)
        return { message: 'Unknown error', details: [] };
      }

    }

    return null;
  }, [error]);

  return memoizedError;
};

const EmployeeSlide: React.FC<Props> = (props: Props) => {
  const {idEmployee, _slide} = props;
  const [getEmployee, {data, isFetching, status}] = useLazyGetEmployeeQuery();
  const [ updateEmployee ] = useUpdateEmployeeMutation();
  const [ addEmployee, { isError, error } ] = useAddEmployeeMutation();
  const refInputTitle = useRef<any>()


  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<any>('');
  const [password, setPassword] = useState<any>('');

  const errorValidation: any = useErrorValidation(error);

  const onOk = useCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if(data?._id) {
        updateEmployee({id: data._id, body: { firstName, lastName, email, password } }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        );
      } else if(idEmployee === 'new') {
          addEmployee({ firstName, lastName, email, password }).then(
            (r: any) => {
              if(r?.error) {
                reject()
              } else {
                resolve()
                _slide?.accept()
              }
            }
          )
      }
    })
  }, [data, firstName, lastName, email, password])

  useEffect(() => {
    _slide?.acceptHandler(onOk) // устанавливаем обработчик Ok (Save) в Slide
    if(idEmployee === 'new') {
      _slide?.acceptButton('Создать')
    } else {
      _slide?.acceptButton('Сохранить')
    }

  }, [onOk])

  useEffect(() => {
    if(idEmployee !== 'new') {
      getEmployee({id: idEmployee})
    }

  }, [])

  useEffect(() => {
    if(idEmployee !== 'new') {
      // title in header
      const title = `${data?.lastName} ${data?.firstName}`
      _slide?.titleFn(title);
      _slide?.subTitleFn('Сотрудник')

      setLastName(data?.lastName ?? '')
      setFirstName(data?.firstName ?? '')
      setEmail(data?.email ?? '')
    } else {
      // title in header
      _slide?.titleFn('Новый сотрудник');
      _slide?.subTitleFn('Сотрудник')
    }
  }, [data]);

  // фокус на наименовании
  useEffect(() => {
    if(refInputTitle.current && idEmployee === 'new') {
      refInputTitle.current.focus()
    }
  }, [refInputTitle.current])

  return (
    <>
      {isFetching ? 'Загрузка...'
      :
        <form className="flex flex-col gap-6 text-sm text-slate-700 " /*onSubmit={onSubmitClient}*/>

          <Input label='Фамилия'
                 value={lastName}
                 onChange={(e) => setLastName(e.target.value)}
                 ref={refInputTitle}
                 isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'lastName') : false}
                 errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'lastName')?.message : ''}
          />
          
          <Input label='Имя'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
                 isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'firstName') : false}
                 errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'firstName')?.message : ''} />

          <Input label='Email (логин)'
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'email') : false}
                 errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'email')?.message : ''} />

          <Input label='Пароль'
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'password') : false}
                 errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'password')?.message : ''} />


        </form>
      }

    </>
  );
};

export default EmployeeSlide;