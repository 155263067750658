import React, { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {CheckCircleIcon} from "@heroicons/react/24/outline";

type Props = {
  text: string | number
}
const NotificationSuccess: React.FC<Props> = ({ text }) => {
  const [show, setShow] = useState(true)

  return (
      <>
        <Transition
            appear={true}
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 bg-red-50">
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <CheckCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <div className="flex w-0 flex-1 justify-between ml-3 pt-0.5">
                  <p className="w-0 flex-1 text-sm font-medium text-gray-900">{text}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                      type="button"
                      className="inline-flex rounded-md bg-transparent text-red-300 hover:text-red-800 focus:outline-none border-red-300 hover:border-red-800"
                      onClick={() => {
                        setShow(false)
                      }}
                  >
                    <span className="sr-only">Закрыть</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </>
  )
};

export default NotificationSuccess;