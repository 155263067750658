import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Input from "../../Input/Input";
import {EnumDialogIcon} from "../DialogWrap";
import {useAddTenantMutation} from "../../../../api/tenant";
import {ClientError} from "../../../../api/util/fetchBaseQueryWithReauth";
import AlertError from "../../AlertError";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";
import {DialogServiceType} from "../Dialogs";

type Props = {
  _dialog?: DialogServiceType
}

const useErrorValidation = (error1: FetchBaseQueryError | SerializedError | undefined, error2?: FetchBaseQueryError | SerializedError | undefined): {} | null => {
  const memoizedError = useMemo(() => {
    const error = error1 || error2
    if (error !== undefined && 'status' in error) {
      try {
        const result: { message: string; details: { property: string; message: string; }[] } = error.data as any
        return result.details
      } catch(e) {
        console.log(e)
        return { message: 'Unknown error', details: [] };
      }

    }

    return null;
  }, [error1, error2]);

  return memoizedError;
};

const DialogAddNewTenant: React.FC<Props> = ({ _dialog }) => {
  const [addTenant, { error, isError}] = useAddTenantMutation()
  const [tenantName, setTenantName] = useState<string>('')
  const inputRef = useRef<any>()
  const errorValidation: any = useErrorValidation(error);
  const onOk = useCallback((): Promise<void> => {
    // срабатывает, когда происходит accept диалогового окна.
    // если этого обработчика нет, тогда диалоговое окно закрывается по-умолчанию при событии закрытия в dialogWrap
    // и ничего не возвращает
    // если этот обработчик есть, но нет accept(result), тогда окно не закрывается.
    // из любого места компонента можно закрыть диалоговое окно (accept() или decline()),
    // минуя регистрацию onOk и onCancel

    return new Promise((resolve, reject) => {
      addTenant({ name: tenantName }).then((result: any) => {
        if(result?.error) {
          reject() // окно не закрывается, ошибка на кнопке
        } else {
          resolve() // окно не закрывается, success на кнопке
          _dialog?.accept({ Tenant: result.data }) // закрывается окно, выполнение .then( (result) => {-здесь-}, () -> {} ) у родителя
        }

      })

    })
  }, [tenantName])

  const onCancel = (): Promise<void> => {
    // варианты:
    //
    // 1. Без этого обработчика - окно просто закроется с помощью стандартного обработчика в dialogWrap
    //
    // 2. Возврат Promise - тогда окно не закроется пока не отработает Promise
    //    - resolve(result) - окно закроется, выполнение у родителя - .then( () => {}, (result) -> {-здесь-} )
    //    - reject() - окно не закроется
    //
    // 3. Возврат string | object ...etc - окно закроется с разрешением у родителя - .then( () => {}, (result) -> {-здесь-} )
    //
    // 4. Возврат Boolean(false) - окно не закроется


    // return 1
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
        _dialog?.decline('rrreee')
        // reject()
      }, 2000)

    })
  }

  useEffect(() => {
    console.log('onOk change', tenantName)

    _dialog?.acceptHandler(onOk)
    // _dialog?.declineHandler(onCancel)

    // acceptButton() // стандартная кнопка
    // acceptButton('Создать') // стандартная кнопка с нашим названием
    // acceptButton(<Button text={'Создать3'} />) // пользовательская кнопка: можно использовать все props Button, кроме onClick
    // acceptButton(false) // без кнопки

    _dialog?.acceptButton('Создать') // стандартная кнопка с нашим названием
    _dialog?.setTitle('Создание новой организации')
    _dialog?.setIcon(EnumDialogIcon.FORM)
  }, [onOk])

  useEffect(() => {
    inputRef.current.focus()
    if(inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current]);

  const isClientError = (error: any): error is ClientError => 'data' in error

  return (
    <div className={'w-full'}>
      <div className={'mb-3'}>
        { error && isClientError(error) ? (error.status != 400) && (<AlertError text={error.data.message} />) : '' }
      </div>

      <div className={'mt-10'}>
        <Input label='Название организации'
               value={tenantName}
               onChange={(e) => setTenantName(e.target.value)}
               ref={inputRef}
               isError={errorValidation?.length ? errorValidation.some((e: any) => e.property === 'name') : false}
               errorText={errorValidation?.length ? errorValidation.find((e: any) => e.property === 'name')?.message : ''}
        />
      </div>

    </div>
  );
};

export default DialogAddNewTenant;