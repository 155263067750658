import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {ITenant} from "../models/ITenant";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {IEmployee} from "../models/IEmployee";

export type EmployeeRequest = {
  page: number
}

export type EmployeeResponse = {
  data: IEmployee[];
  meta: any
}

export type UpdateEmployeeRequest = {
  firstName: string
  lastName: string
  email: string
  password: string
}

export const employeeApi = createApi({
  reducerPath: 'employeeApi',
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/employee` }),
  tagTypes: ['Employees'],
  endpoints: (builder) => ({
    getEmployees: builder.query<EmployeeResponse, void>({
      query: () => {
        return {
          url: '/',
          method: 'GET',
          // params: { page: options.page },
          credentials: 'include',
        }
      },
      providesTags: ['Employees']

    }),
    getEmployee: builder.query<IEmployee, {id: string}>({
      query: ({id}) => ({
        url: `${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      // providesTags: ['Products']
    }),
    addEmployee: builder.mutation<IEmployee, UpdateEmployeeRequest>({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['Employees']
    }),
    updateEmployee: builder.mutation<IEmployee, {id: string, body: UpdateEmployeeRequest}>({
      query: ({id, body}) => ({
        url: `${id}`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
      invalidatesTags: ['Employees']
    }),
    delEmployee: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['Employees']
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useLazyGetEmployeesQuery,
  useLazyGetEmployeeQuery,
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
  useDelEmployeeMutation,
} = employeeApi;