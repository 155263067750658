import * as React from 'react';
import {Fragment, useCallback, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {useEffect} from "react";
import Button from "../buttons/Button";

type Props = {
  children?: any
  unmount?: (slide: any) => void
  slide?: any
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const SlideContext = React.createContext<any>(null);
const SlideWrap: React.FC<Props> = (props: Props) => {
  const {children, slide, unmount} = props;
  const [open, setOpen] = useState(true)
  const [acceptHandler, setAcceptHandler] = useState<any>(() => null)
  const [declineHandler, setDeclineHandler] = useState<any>(() => null)
  const [acceptButton, setAcceptButton] = useState<any>(() => null)
  const [declineButton, setDeclineButton] = useState<any>(() => null)
  const [acceptButtonRaw, setAcceptButtonRaw] = useState<any>()
  const [declineButtonRaw, setDeclineButtonRaw] = useState<any>()
  const [title, setTitle] = useState<any>('')
  const [subTitle, setSubTitle] = useState<any>('')
  const [errorText, setErrorText] = useState<any>('')
  const [isBigImg, setIsBigImg] = useState<boolean>(false)
  const [bigImg, setBigImg] = useState<string>('')

  const onOk = useCallback((): Promise<void> | undefined => {

    if(typeof acceptHandler === 'function') {
      const result = acceptHandler();
      if(result instanceof Promise) {
        // флаг прелоадера на кнопке
        return result
      } else {
        doAccept(result)
      }

    } else { // если обработчика нет
      doAccept()
    }

  }, [acceptHandler])

  const onCancel = useCallback((): Promise<void> | undefined => {

    if(typeof declineHandler === 'function') {
      const result = declineHandler();
      if(result instanceof Promise) {
        return result
      } else {
        doDecline(result)
      }

    } else { // если обработчика нет
      doDecline()
    }

  }, [declineHandler])

  const rootRef = useCallback((node: any) => {
    // срабатывает, когда элемент монтируется и размонтируется (удаляется) из DOM
    if(!node) {
      console.log('unmount')
      if(unmount) unmount(slide)
    }
  }, [])

  useEffect(() => {
    let newButton

    if(acceptButtonRaw?.type === Button) { // пользовательская кнопка
      newButton = () => React.createElement(acceptButtonRaw.type, { ...acceptButtonRaw.props, onClick: onOk })

    } else if(typeof acceptButtonRaw === 'string') { // изменяем название кнопки
      if(acceptButton?.type === Button) {
        newButton = () => React.createElement(acceptButton.type, { ...acceptButton.props, onClick: onOk, text: acceptButtonRaw })
      } else {
        newButton = () => React.createElement(Button, { onClick: onOk, text: acceptButtonRaw, style: 'PRIMARY' })
      }

    } else if(typeof acceptButtonRaw === 'undefined') { // кнопка по-умолчанию
      newButton = () => React.createElement(Button, { onClick: onOk, text: 'Сохранить', style: 'PRIMARY' })

    } else if(typeof acceptButtonRaw === 'boolean' && !acceptButtonRaw) { // нет кнопки
      newButton = undefined
    }

    setAcceptButton(newButton);
  }, [acceptButtonRaw, onOk]);

  useEffect(() => {
    let newButton

    if(declineButtonRaw?.type === Button) { // пользовательская кнопка
      newButton = () => React.createElement(declineButtonRaw.type, { ...declineButtonRaw.props, onClick: onCancel })

    } else if(typeof declineButtonRaw === 'string') { // изменяем название кнопки
      if(declineButton?.type === Button) {
        newButton = () => React.createElement(declineButton.type, { ...declineButton.props, onClick: onCancel, text: declineButtonRaw })
      } else {
        newButton = () => React.createElement(Button, { onClick: onCancel, text: declineButtonRaw, style: 'SECONDARY' })
      }

    } else if(typeof declineButtonRaw === 'undefined') { // кнопка по-умолчанию
      newButton = () => React.createElement(Button, { onClick: onCancel, text: 'Отмена', style: 'SECONDARY' })

    } else if(typeof declineButtonRaw === 'boolean' && !declineButtonRaw) { // нет кнопки
      newButton = undefined
    }

    setDeclineButton(newButton);
  }, [declineButtonRaw, onCancel]);

  const doAccept = (result?: any) => {
    setOpen(false); // закрываем окно
    setTimeout(() => {
      slide.resolve(result); // разрешаем вызов окна .then( (result) => {-здесь-}, () -> {} ) у родителя
    }, 200); // делаем задержку на закрытие окна (transition), чтобы ответ от окна не отображался быстрее, чем закроется окно
  }

  const doDecline = (result?: any) => {
    setOpen(false); // закрываем окно
    setTimeout(() => {
      slide.reject(result); // разрешаем вызов окна .then( () => {}, (result) -> {-здесь-} )  или .catch у родителя
    }, 200); // делаем задержку на закрытие окна (transition), чтобы ответ от окна не отображался быстрее, чем закроется окно
  }
  const refBigImg = useRef<any>();

  useEffect(() => {
    // console.log('bigImg', refBigImg?.current?.naturalWidth, refBigImg?.current?.naturalHeight, bigImg)
  }, [bigImg])
  return (
    <SlideContext.Provider value={{refBigImg: refBigImg, setIsBigImg: setIsBigImg, setBigImg: setBigImg}}>
      <Transition.Root show={open} as={Fragment} appear={true}>
        <Dialog as="div" className="relative z-50" onClose={setOpen} ref={rootRef}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className={classNames("fixed inset-0 overflow-hidden")}>
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl divide-y divide-gray-20">
                      <div className="bg-sky-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            {title}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-sky-700 text-sky-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-sky-200">
                            {subTitle}
                          </p>
                        </div>

                        {!!errorText && <div className='bg-white text-xs py-1 px-3 text-rose-500 line-clamp-4 rounded mt-3 -mb-3 border border-rose-400'>
                          {errorText}
                        </div>}
                      </div>
                      <div className="relative flex-1 py-6 px-4 sm:px-6">
                        <div className='flex min-h-0 flex-1 flex-col pb-6'>
                          {children(
                            (result: any) => doAccept(result), // закрываем диалоговое окно с результатом
                            (reason: any) => doDecline(reason), // закрываем диалоговое окно с результатом
                            (handler: any) => { setAcceptHandler(() => handler) }, // регистрация обработчика onOk
                            (handler: any) => { setDeclineHandler(() => handler) }, // регистрация обработчика onCancel
                            (acceptButton: any) => setAcceptButtonRaw(acceptButton), // настройки кнопки Accept
                            (declineButton: any) => setDeclineButtonRaw(declineButton), // настройки кнопки Decline

                            (title: string | undefined) => setTitle(title),
                            (subTitle: string | undefined) => setSubTitle(subTitle),
                            (errorText: string | undefined) => {
                              // console.log(8)
                              setErrorText(errorText)
                            }
                          )}
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 space-x-2 justify-end px-4 py-4">
                        { declineButton ? declineButton :
                          <Button onClick={onCancel} text={'Отменить'} isDisabled={false} style={'TERTIARY'} />
                        }
                        { acceptButton ? acceptButton :
                          <Button onClick={onOk} text={'Сохранить'} isDisabled={false} />
                        }
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>

              {/*{ bigImg ? */}
              {/*  <div className={'absolute top-[150px] right-[500px] border rounded-md bg-sky-400/10 max-h-[50%] max-w-[50%] flex items-center'}>*/}
              {/*    <img ref={refBigImg} src={bigImg} alt="" className={'rounded-md'}/>*/}
              {/*  </div> */}
              {/*  : '' }*/}

              { bigImg ?
                <div className={'absolute top-0 left-0 right-[448px] h-full max-h-[100%] max-w-[100%] flex justify-end items-start'}>
                  {/*<div className={'flex justify-end items-center w-full h-full bg-sky-400/10'}>*/}
                    <img ref={refBigImg} src={bigImg} alt="" className={'max-h-[80%] max-w-[80%] border rounded-md mt-40 mr-10'}/>
                  {/*</div>*/}
                </div>
                : '' }

              {/*{ bigImg ?*/}
              {/*    <img ref={refBigImg} src={bigImg} alt="" loading='lazy' className={'absolute top-[150px] right-[500px] border rounded-md bg-sky-400/10 max-h-[80%] max-w-[70%]'}/>*/}
              {/*  : '' }*/}

            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </SlideContext.Provider>

  );
};

export default SlideWrap;



