import * as React from 'react';
import {Fragment} from 'react';
import {flexRender, Header} from "@tanstack/react-table";

type Props = {
  cell: Header<any, any>
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const FooterCellStretch: React.FC<Props> = (props: Props) => {
  const { cell} = props;

  return (
    <Fragment>
      <div
        {...{
          colSpan: cell.colSpan,
          className: 'flex grow text-sm px-4 py-3 items-center justify-start h-full text-slate-600 overflow-hidden',
        }}
      >
          <span className='whitespace-nowrap text-ellipsis overflow-hidden'>
            {/*{cell.isPlaceholder ? null : flexRender(cell.column.columnDef.header, cell.getContext())}*/}
          </span>
      </div>
    </Fragment>
  );
};

export default FooterCellStretch;