import React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid'

type Props = {
  text: string
}

const AlertSuccess: React.FC<Props> = ({ text}) => {
  return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className="mt-0.5 text-sm text-green-700">
              {text}
            </div>
          </div>
        </div>
      </div>
  )
};

export default AlertSuccess;