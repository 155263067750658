import * as React from 'react';
import {useEffect, useState} from "react";
import Button from "../../../buttons/Button";
import {formatSumSeparator} from "../../../../../utils/helpers";
import {XMarkIcon} from "@heroicons/react/20/solid";
import Checkbox from "../../../checkbox/Checkbox";
type Selected = { all: boolean, count: number, include: any[], exclude: any[] }
type Props = {
  _selected?: Selected
  onChangeCategory?: (selected: Selected) => void | Promise<any>
  onChangeApplicability?: (selected: Selected) => void | Promise<any>
  onDelete?: (selected: Selected) => void | Promise<any>
  onExport?: (selected: Selected) => void | Promise<any>
  cleanSelected?: () => void
  onPool?: (selected: Selected, isPool: boolean) => void | Promise<any>
  onOnline?: (selected: Selected, isOnline: boolean) => void | Promise<any>
};

const ActionGroup1: React.FC<Props> = (props: Props) => {
  const { _selected,
    onChangeCategory ,
    onChangeApplicability ,
    onDelete,
    onExport,
    onPool,
    onOnline,
  } = props;
  const [count, setCount] = useState<number>(0)

  useEffect(() => {

  }, [_selected])


  return (
    <>
      <div className={'flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-8 md:items-center'}>

        <div className={'flex justify-center relative w-full md:w-auto'}>
          <div className="absolute top-0 left-0 md:hidden cursor-pointer flex items-center pr-3" onClick={props?.cleanSelected ? props.cleanSelected : () => {}}>
            <XMarkIcon className="h-7 w-7 text-gray-500 hover:text-gray-700" aria-hidden="true" />
          </div>
          <Checkbox className={'hidden md:flex md:items-center mr-2'} checked={true} onChange={props?.cleanSelected ? props.cleanSelected : () => {}} />
          <div className={'flex-none whitespace-nowrap text-ellipsis overflow-hidden font-bold'}>Выбрано: {formatSumSeparator(_selected?.count)}</div>
        </div>

        <div className={'flex flex-wrap justify-start gap-3'}>
          { onChangeCategory && _selected ? <div><Button text={'В категорию'} style={'TERTIARY'} onClick={() => onChangeCategory(_selected)}/> </div> : '' }
          { onPool && _selected ? <div><Button text={'Добавить в пул'} style={'TERTIARY'} onClick={() => onPool(_selected, true)}/> </div> : '' }
          { onPool && _selected ? <div><Button text={'Удалить из пула'} style={'TERTIARY'} onClick={() => onPool(_selected, false)}/> </div> : '' }
          { onOnline && _selected ? <div><Button text={'Добавить в Online'} style={'TERTIARY'} onClick={() => onOnline(_selected, true)}/> </div> : '' }
          { onOnline && _selected ? <div><Button text={'Удалить из Online'} style={'TERTIARY'} onClick={() => onOnline(_selected, false)}/> </div> : '' }
          { onChangeApplicability && _selected ? <div><Button text={'Применимости'} style={'TERTIARY'} onClick={() => onChangeApplicability(_selected)}/> </div> : '' }
          { onExport && _selected ? <div><Button text={'Экспорт в Excel'} style={'TERTIARY'} onClick={() => onExport(_selected)}/> </div> : '' }
          { onDelete && _selected ? <div><Button text={'Удалить'} style={'DANGER'} onClick={() => onDelete(_selected)}/> </div> : '' }
        </div>

      </div>
    </>
  );
};

export default ActionGroup1;