import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {EnumDialogIcon, EnumDialogWidth} from "../DialogWrap";
import {ClientError} from "../../../../api/util/fetchBaseQueryWithReauth";
import AlertError from "../../AlertError";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";
import {DialogServiceType} from "../Dialogs";
import SelectFlexHeight from "../../select/selectFlexHeight/SelectFlexHeight";
import {useGetProductCategoriesQuery} from "../../../../api/productCategory";
import {
  useLazyGetSelectedProductsQuery,
  useMoveToCategoryMutation, useUpdateReplacementMutation
} from "../../../../api/product";
import TableConstantMultiColumns from "../../table/tmpl/TableConstantMultiColumns";
import ActionGroupOrder from "../../table3/action-groups/tmpl/ActionGroupOrder";
import {createColumnHelper} from "@tanstack/react-table";
import {IDocOurOrderPositionResponse, IDocOurOrderPositionTable} from "../../../../models/IDocOurOrder";
import {Link} from "react-router-dom";
import {ColumnsWidth} from "../../table/Table";
import DialogAddProducts from "./DialogAddProducts";
import {v4 as uuidv4} from "uuid";
import {isArray} from "lodash";
import {useDialog} from "../../../../hooks/dialog";
import Button from "../../buttons/Button";
import {IProductBrandReplacement} from "../../../../models/IProductReplacement";
import Input from "../../Input/Input";
import {formatNumber} from "../../../../utils/helpers";
import InputPriceCell from "../../Input/tmpl/InputPriceCell";
import ActionGroupProductReplacement from "../../table3/action-groups/tmpl/ActionGroupProductReplacement";
import {CheckCircleIcon, ExclamationCircleIcon} from "@heroicons/react/24/outline";
import {TooltipPlacement} from "../../tooltips/TooltipPanel";
import {useTooltip} from "../../../../hooks/tootip";
import ProductSlide from "../../slide/tmpl/ProductSlide";
import {useSlide} from "../../../../hooks/slide";

type Props = {
  _dialog?: DialogServiceType
  width?: EnumDialogWidth
  product: any
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const useErrorValidation = (error1: FetchBaseQueryError | SerializedError | undefined, error2?: FetchBaseQueryError | SerializedError | undefined): {} | null => {
  const memoizedError = useMemo(() => {
    const error = error1 || error2
    if (error !== undefined && 'status' in error) {
      try {
        const result: { message: string; details: { property: string; message: string; }[] } = error.data as any
        return result.details
      } catch(e) {
        console.log(e)
        return { message: 'Unknown error', details: [] };
      }

    }

    return null;
  }, [error1, error2]);

  return memoizedError;
};

const InputNote: React.FC<{info: any, setPositionsData: any}> = ({info, setPositionsData}) => {
  const [note, setNote] = useState<string>(info.row.original.note)

  return (
    <Fragment>
      <Input key={info.row.original._id}
                      value={note}
                      onChange={(a) => {
                        setNote(a.target.value)
                      }}
                      onBlur={(a) => {
                        const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
                        item.note = note
                        setPositionsData(() => [...info.table.options.meta.positions])
                      }}
             onClean={() => {
               setNote('')
             }}
      />
    </Fragment>)
}

const InputNumber: React.FC<{info: any, setPositionsData: any}> = ({info, setPositionsData}) => {
  const [number, setNumber] = useState<string>(info.row.original.number)

  return (
    <Fragment>
      <Input key={info.row.original._id}
             value={number}
             onChange={(a) => {
               setNumber(a.target.value)
             }}
             onBlur={(a) => {
               const item = info.table.options.meta.positions.find((item: any) => item._id === info.row.original._id)
               item.number = number
               setPositionsData(() => [...info.table.options.meta.positions])
             }}
             onClean={() => {
               setNumber('')
             }}
      />
    </Fragment>)
}

const Fit100: React.FC<{value: any}> = ({value}) => {
  const tooltip = useTooltip();

  return (
    <Fragment>
      {value ?
        <CheckCircleIcon className={'h-5 w-5 text-green-600'}
                         onMouseEnter={ (event: any) => { tooltip.show('Достоверный аналог 100%', event.currentTarget, TooltipPlacement.TOP) } }
                         onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
        />
        :
        <ExclamationCircleIcon className={'h-5 w-5 text-amber-400'}
                               onMouseEnter={ (event: any) => { tooltip.show('Недостоверный аналог', event.currentTarget, TooltipPlacement.TOP) } }
                               onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
        /> }
    </Fragment>)
}

const DialogProductReplacement: React.FC<Props> = ({ _dialog, product }) => {
  const [tabs, setTabs] = useState<{id: string, name: string, href: string, count?: string, current: boolean}[]>(
    [
      { id: 'brandReplacement', name: 'Аналоги', href: '#', count: '', current: false },
      { id: 'originalReplacement', name: 'Оригинальные замены', href: '#', count: '', current: false },
      { id: 'numberReplacement', name: 'Замены номера', href: '#', count: '4', current: true },
    ]
  );
  const dialog = useDialog()
  const slides = useSlide();
  const [getSelectedProducts] = useLazyGetSelectedProductsQuery()
  const [currentTab, setCurrentTab] = useState<string>('brandReplacement')

  const [brandList, setBrandList] = useState<any[]>([])
  const [brandData, setBrandData] = useState<any[]>([])

  const [originalList, setOriginalList] = useState<any[]>([])
  const [originalData, setOriginalData] = useState<any[]>([])

  const [numberList, setNumberList] = useState<any[]>([])
  const [numberData, setNumberData] = useState<any[]>([])

  const [updateReplacement, { error }] = useUpdateReplacementMutation()

  const errorValidation: any = useErrorValidation(error);

  const onOk = useCallback((): Promise<void> => {
    console.log('Ok', brandData, originalData)
    return new Promise((resolve, reject) => {
      updateReplacement({id: product._id, body: {
        brandReplacement: brandData.map(item => ({
          product_id: item.product_id,
          number: item.number,
          brand_id: item.brand_id,
          note: item.note,
          fit_100: item.fit_100
        })),
        originalReplacement: originalData.map(item => ({
          product_id: item.product_id,
          number: item.number,
          brand_id: item.brand_id,
          note: item.note,
          fit_100: item.fit_100
        })),
        numberReplacement: numberData.map(item => ({
          number: item.number,
          note: item.note
        }))
      }}).then((result: any) => {
        if(result?.error) {
          reject() // окно не закрывается, ошибка на кнопке
        } else {
          resolve()
          _dialog?.accept(result)
        }
      })
    })
  }, [brandData, originalData, numberData])

  useEffect(() => {
    // console.log('onOk change', tenantName)

    _dialog?.acceptHandler(onOk)
    // _dialog?.declineHandler(onCancel)

    // acceptButton() // стандартная кнопка
    // acceptButton('Создать') // стандартная кнопка с нашим названием
    // acceptButton(<Button text={'Создать3'} />) // пользовательская кнопка: можно использовать все props Button, кроме onClick
    // acceptButton(false) // без кнопки


    _dialog?.acceptButton('Сохранить')


    // _dialog?.acceptButton(false) // стандартная кнопка с нашим названием

    _dialog?.setTitle('Аналоги/замены для ' + product.Brand.name + ' ' + product.number)
    _dialog?.setIcon(EnumDialogIcon.FORM)
  }, [onOk])

  const isClientError = (error: any): error is ClientError => 'data' in error

  const onTab = (tab: any) => {
    setCurrentTab(tab.id)
  }

  useEffect(() => {
    if(product?.brand_replacement) {
      const pos = product?.brand_replacement.map( (item: any) => ({ _id: uuidv4(), product_id: item.product_id, brandName: item.brand_name, number: item.number, brand_id: item.brand_id, note: item.note, fit_100: item.fit_100 }))
      setBrandList(pos)
      setBrandData(pos)
    }
    if(product?.original_replacement) {
      const pos = product?.original_replacement.map( (item: any) => ({ _id: uuidv4(), product_id: item.product_id, brandName: item.brand_name, number: item.number, brand_id: item.brand_id, note: item.note, fit_100: item.fit_100 }))
      setOriginalList(pos)
      setOriginalData(pos)
    }

    if(product?.number_replacement) {
      const pos = product?.number_replacement.map( (item: any) => ({ _id: uuidv4(), number: item.number, note: item.note }))
      setNumberList(pos)
      setNumberData(pos)
    }

    const ls = window.localStorage.getItem('DialogProductReplacement_currentTab')
    if(ls) {
      setCurrentTab(ls) // открываем запомнившуюся вкладку
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem('DialogProductReplacement_currentTab', currentTab);
  }, [currentTab]);

  // кол-во в табах
  useEffect(() => {
    tabs.forEach(item => {
      if(item.id === 'brandReplacement') {
        item.count = String(brandList.length)
      } else if(item.id === 'originalReplacement') {
        item.count = String(originalList.length)
      } else if(item.id === 'numberReplacement') {
        item.count = String(numberList.length)
      }
    })
    setTabs([...tabs])
  }, [brandList, originalList, numberList])

  const clickProduct = (info: any) => {
    slides.show(<ProductSlide idProduct={info.row.original.product_id} />).then(
      (result) => {
        // setParams({ cat: meta?.cat, ...meta?.filter2, filterText: meta?.filterText, onlyRoot: meta?.onlyRoot, sort: meta?.sort })
        // updateCategories(meta.cat, meta?.filter2, meta?.filterText, meta?.onlyRoot)
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const columnHelper = createColumnHelper<IProductBrandReplacement & {aaa: string}>()

  const columns_b = [
    columnHelper.accessor('fit_100', {
      header: () => '100%',
      cell: info => <Fit100 value={info.renderValue()} />,
      minSize: 50,
      // size: 200,
      meta: { side: 'justify-center' },
    }),
    columnHelper.accessor('brandName', {
      header: () => 'Бренд',
      // cell: info => info.renderValue(),
      minSize: 50,
      // size: 200,
    }),
    columnHelper.accessor('number', {
      header: () => 'Артикул',
      // cell: info => info.renderValue(),
      cell: info => <Link to="/" onClick={(e) => {e.preventDefault(); e.stopPropagation(); clickProduct(info)}} className="text-sky-600 hover:text-sky-500 border-b border-dashed border-sky-600 hover:border-sky-500">{info.getValue() || 'не задано'}</Link>,

      minSize: 50,
      size: 200,
    }),
    columnHelper.accessor('note', {
      header: () => 'Примечание',
      cell: info => <InputNote info={info} setPositionsData={setBrandData} />,
      minSize: 300,
      size: 200,
      meta: { width: ColumnsWidth.STRETCH, },
    }),

  ]

  const addBrandReplacement = () => {
    // setPositions((p) => [...p, {number: 1000, title_rus: '123', price_sale: 3400 }])
    dialog.show(<DialogAddProducts width={EnumDialogWidth.MAXW7XL} />).then(
      (result) => {
        // notify.show(<NotificationSuccess text={'Организация успешно создана'} />)
        // onChangeTenant(result.Tenant)
        console.log('add product', result)
        if(result?.selected?.include && result?.selected?.include.length > 0) {
          const pos = result.selected.include.filter((item: any) => item.brandId !== product.Brand._id && !brandList.some(item2 => item2.product_id == item._id)).map( (item: any) => ({ _id: uuidv4(), product_id: item._id, brandName: item.brandName, number: item.number, brand_id: item.brandId, note: '' }))

          setBrandList((p) => [...p, ...pos])
          setBrandData((p) => [...p, ...pos])
        } else if((result?.selected?.exclude && result?.selected?.exclude.length > 0) || result?.selected?.all) {
          getSelectedProducts(result).then((res: any) => {
            // console.log(11111, res.data)
            if(res.data && isArray(res.data)) {
              const pos = res.data.filter((item: any) => item.brandId !== product.Brand._id && !brandList.some(item2 => item2.product_id == item._id)).map( (item: any) => ({ _id: uuidv4(), product_id: item._id, brandName: item.brandName, number: item.number, brand_id: item.brandId, note: '' }))
              setBrandList((p) => [...p, ...pos])
              setBrandData((p) => [...p, ...pos])
            }
          })
        }
      }
    )
  }

  const onDelete_b = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    console.log('onDelete', selected)
    if(selected.all) {
      setBrandList([])
      setBrandData([])
    } else {
      if(selected.include.length) {
        const newPos = brandData.filter((item: any) => !selected.include.some(item2 => item2._id === item._id))
        setBrandList([...newPos])
        setBrandData([...newPos])
      } else if(selected.exclude.length) {
        const newPos = brandData.filter((item: any) => selected.exclude.some(item2 => item2._id === item._id))
        setBrandList([...newPos])
        setBrandData([...newPos])
      }
    }
    if(cleanSelected) cleanSelected()
  }

  const toFit100_b = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    console.log('toFit100', selected)
    if(selected.all) {
      brandData.forEach(item => { item.fit_100 = true })
      setBrandList([...brandData])
      setBrandData([...brandData])
    } else {
      if(selected.include.length) {
        brandData.forEach(item => {
          if(selected.include.some(item2 => item2._id === item._id)) {
            item.fit_100 = true
          }
        })
        setBrandList([...brandData])
        setBrandData([...brandData])
      } else if(selected.exclude.length) {
        brandData.forEach(item => {
          if(!selected.exclude.some(item2 => item2._id === item._id)) {
            item.fit_100 = true
          }
        })
        setBrandList([...brandData])
        setBrandData([...brandData])
      }
    }
    if(cleanSelected) cleanSelected()
  }

  const fromFit100_b = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    if(selected.all) {
      brandData.forEach(item => { item.fit_100 = false })
      setBrandList([...brandData])
      setBrandData([...brandData])
    } else {
      if(selected.include.length) {
        brandData.forEach(item => {
          if(selected.include.some(item2 => item2._id === item._id)) {
            item.fit_100 = false
          }
        })
        setBrandList([...brandData])
        setBrandData([...brandData])
      } else if(selected.exclude.length) {
        brandData.forEach(item => {
          if(!selected.exclude.some(item2 => item2._id === item._id)) {
            item.fit_100 = false
          }
        })
        setBrandList([...brandData])
        setBrandData([...brandData])
      }
    }
    if(cleanSelected) cleanSelected()
  }


  const columns_o = [
    columnHelper.accessor('fit_100', {
      header: () => '100%',
      cell: info => <Fit100 value={info.renderValue()} />,
      minSize: 50,
      // size: 200,
      meta: { side: 'justify-center' },
    }),
    columnHelper.accessor('brandName', {
      header: () => 'Бренд',
      // cell: info => info.renderValue(),
      minSize: 50,
      // size: 200,
    }),
    columnHelper.accessor('number', {
      header: () => 'Артикул',
      // cell: info => info.renderValue(),
      cell: info => <Link to="/" onClick={(e) => {e.preventDefault(); e.stopPropagation(); clickProduct(info)}} className="text-sky-600 hover:text-sky-500 border-b border-dashed border-sky-600 hover:border-sky-500">{info.getValue() || 'не задано'}</Link>,

      minSize: 50,
      size: 200,
    }),
    columnHelper.accessor('note', {
      header: () => 'Примечание',
      cell: info => <InputNote info={info} setPositionsData={setOriginalData} />,
      minSize: 300,
      size: 200,
      meta: { width: ColumnsWidth.STRETCH, },
    }),

  ]

  const addOriginalReplacement = () => {
    // setPositions((p) => [...p, {number: 1000, title_rus: '123', price_sale: 3400 }])
    dialog.show(<DialogAddProducts width={EnumDialogWidth.MAXW7XL} />).then(
      (result) => {
        console.log('add product', result)
        if(result?.selected?.include && result?.selected?.include.length > 0) {
          const pos = result.selected.include.filter((item: any) => item.brandId == product.Brand._id && !originalList.some(item2 => item2.product_id == item._id) && item._id != product._id).map( (item: any) => ({ _id: uuidv4(), product_id: item._id, brandName: item.brandName, number: item.number, brand_id: item.brandId, note: '' }))
          setOriginalList((p) => [...p, ...pos])
          setOriginalData((p) => [...p, ...pos])
        } else if((result?.selected?.exclude && result?.selected?.exclude.length > 0) || result?.selected?.all) {
          getSelectedProducts(result).then((res: any) => {
            if(res.data && isArray(res.data)) {
              const pos = res.data.filter((item: any) => item.brandId == product.Brand._id && !originalList.some(item2 => item2.product_id == item._id) && item._id != product._id).map( (item: any) => ({ _id: uuidv4(), product_id: item._id, brandName: item.brandName, number: item.number, brand_id: item.brandId, note: '' }))
              setOriginalList((p) => [...p, ...pos])
              setOriginalData((p) => [...p, ...pos])
            }
          })
        }
      }
    )
  }

  const onDelete_o = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    console.log('onDelete', selected)
    if(selected.all) {
      setOriginalList([])
      setOriginalData([])
    } else {
      if(selected.include.length) {
        const newPos = originalData.filter((item: any) => !selected.include.some(item2 => item2._id === item._id))
        setOriginalList([...newPos])
        setOriginalData([...newPos])
      } else if(selected.exclude.length) {
        const newPos = originalData.filter((item: any) => selected.exclude.some(item2 => item2._id === item._id))
        setOriginalList([...newPos])
        setOriginalData([...newPos])
      }
    }
    if(cleanSelected) cleanSelected()
  }

  const toFit100_o = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    console.log('toFit100', selected)
    if(selected.all) {
      originalData.forEach(item => { item.fit_100 = true })
      setOriginalList([...originalData])
      setOriginalData([...originalData])
    } else {
      if(selected.include.length) {
        originalData.forEach(item => {
          if(selected.include.some(item2 => item2._id === item._id)) {
            item.fit_100 = true
          }
        })
        setOriginalList([...originalData])
        setOriginalData([...originalData])
      } else if(selected.exclude.length) {
        originalData.forEach(item => {
          if(!selected.exclude.some(item2 => item2._id === item._id)) {
            item.fit_100 = true
          }
        })
        setOriginalList([...originalData])
        setOriginalData([...originalData])
      }
    }
    if(cleanSelected) cleanSelected()
  }

  const fromFit100_o = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    if(selected.all) {
      originalData.forEach(item => { item.fit_100 = false })
      setOriginalList([...originalData])
      setOriginalData([...originalData])
    } else {
      if(selected.include.length) {
        originalData.forEach(item => {
          if(selected.include.some(item2 => item2._id === item._id)) {
            item.fit_100 = false
          }
        })
        setOriginalList([...originalData])
        setOriginalData([...originalData])
      } else if(selected.exclude.length) {
        originalData.forEach(item => {
          if(!selected.exclude.some(item2 => item2._id === item._id)) {
            item.fit_100 = false
          }
        })
        setOriginalList([...originalData])
        setOriginalData([...originalData])
      }
    }
    if(cleanSelected) cleanSelected()
  }


  const columns_n = [
    columnHelper.accessor('number', {
      header: () => 'Артикул',
      // cell: info => info.renderValue(),
      cell: info => <InputNumber info={info} setPositionsData={setNumberData} />,
      minSize: 50,
      size: 200,
    }),
    columnHelper.accessor('note', {
      header: () => 'Примечание',
      cell: info => <InputNote info={info} setPositionsData={setNumberData} />,
      minSize: 300,
      size: 200,
      meta: { width: ColumnsWidth.STRETCH, },
    }),

  ]

  const addNumberReplacement = () => {
    const el = { _id: uuidv4(), number: '', note: '' }
    setNumberList((p) => [...p, el])
    setNumberData((p) =>  [...p, el])
  }

  const onDelete_n = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, cleanSelected: any) => {
    console.log('onDelete', selected)
    if(selected.all) {
      setNumberList([])
      setNumberData([])
    } else {
      if(selected.include.length) {
        const newPos = numberData.filter((item: any) => !selected.include.some(item2 => item2._id === item._id))
        setNumberList([...newPos])
        setNumberData([...newPos])
      } else if(selected.exclude.length) {
        const newPos = numberData.filter((item: any) => selected.exclude.some(item2 => item2._id === item._id))
        setNumberList([...newPos])
        setNumberData([...newPos])
      }
    }
    if(cleanSelected) cleanSelected()
  }

  return (
    <div className={'w-full'}>
      <div className={'mb-3'}>
        {Array.isArray(errorValidation) && errorValidation.filter((e: any) => e.property === 'mode').length ?
          <AlertError text={errorValidation.filter((e: any) => e.property === 'mode').map((item: any) => item.message)} />
          :
          error && isClientError(error) ? (error.status != 400) && (<AlertError text={error.data.message} />) : ''
        }
      </div>

      <div className={'mt-7 mb-7'}>

        <div className={'mb-3'}>

          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={tabs.find((tab) => tab.current)?.name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href="#"
                    className={classNames(
                      tab.id === currentTab
                        ? 'border-sky-500 text-sky-600'
                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                      'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                    )}
                    aria-current={tab.id === currentTab ? 'page' : undefined}
                    onClick={(e) => {e.preventDefault(); onTab(tab)}}
                  >
                    {tab.name}
                    {tab.count ? (
                      <span
                        className={classNames(
                          tab.id === currentTab ? 'bg-sky-100 text-sky-600' : 'bg-gray-100 text-gray-900',
                          'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                        )}
                      >
                        {tab.count}
                      </span>
                    ) : null}
                  </a>
                ))}
              </nav>
            </div>
          </div>

        </div>

        <div className={'border'}>

          {currentTab === 'brandReplacement' &&
            <div>
              <Button text={'Добавить товар'} onClick={addBrandReplacement} />
              <div className="flex flex-col justify-start"
                   {...{
                     style: {
                       height: `calc(100vh - 600px)`,
                     },
                   }}>

                <TableConstantMultiColumns
                  columns={columns_b}
                  data={brandList}
                  meta={ {positions: brandData, totalElements: brandList.length} }
                  isCheckbox={true}
                  isRowNumber={true}
                  actionGroup={<ActionGroupProductReplacement
                    onDelete={onDelete_b}
                    toFit100={toFit100_b}
                    fromFit100={fromFit100_b}
                  />}
                  isFooter={false}
                />

              </div>
            </div>
          }

          {currentTab === 'originalReplacement' &&
            <div>
              <Button text={'Добавить оригинальную замену'} onClick={addOriginalReplacement} />
              <div className="flex flex-col justify-start"
                   {...{
                     style: {
                       height: `calc(100vh - 600px)`,
                     },
                   }}>

                <TableConstantMultiColumns
                  columns={columns_o}
                  data={originalList}
                  meta={ {positions: originalData, totalElements: originalList.length} }
                  isCheckbox={true}
                  isRowNumber={true}
                  actionGroup={<ActionGroupProductReplacement
                    onDelete={onDelete_o}
                    toFit100={toFit100_o}
                    fromFit100={fromFit100_o}
                  />}
                  isFooter={false}
                />

              </div>
            </div>
          }
          {currentTab === 'numberReplacement' &&
            <div>
              <Button text={'Добавить замену номера'} onClick={addNumberReplacement} />
              <div className="flex flex-col justify-start"
                   {...{
                     style: {
                       height: `calc(100vh - 600px)`,
                     },
                   }}>

                <TableConstantMultiColumns
                  columns={columns_n}
                  data={numberList}
                  meta={ {positions: numberData, totalElements: numberList.length} }
                  isCheckbox={true}
                  isRowNumber={true}
                  actionGroup={<ActionGroupProductReplacement
                    onDelete={onDelete_n}
                  />}
                  isFooter={false}
                />

              </div>
            </div>
          }

        </div>
      </div>

    </div>
  );
};

export default DialogProductReplacement;