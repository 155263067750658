import React, {useEffect, useState} from 'react';
import Input from "../../components/ui/Input/Input";
import Button from "../../components/ui/buttons/Button";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useNavigate, useParams} from "react-router-dom";
import AlertSuccess from "../../components/ui/AlertSuccess";
import {useDialog} from "../../hooks/dialog";
import {useNotification} from "../../hooks/notification";
import NotificationSuccess from "../../components/ui/notifications/tmpl/NotificationSuccess";
import {createTenant, deleteTenant, fetchTenant, updateTenant} from "../../store/reducers/tenant/TenantThunk";
import DialogQuestionDanger from "../../components/ui/dialogs/tmpl/DialogQuestionDanger";

export const TenantPageBreadcrumb: React.FC = (props: any) => {
  const { data, isLoading} = useAppSelector(state => state.tenants.one)
  const { id } = props?.match?.params;
  const name = id == 'new' ? 'Создание' : (!isLoading && `${data?.name}`)
  return (
    <>
      { name }
    </>
  )
}
export const TenantPageTitle: React.FC = (props: any) => {
  const { data, isLoading } = useAppSelector(state => state.tenants.one)
  const { id } = props?.match?.params;
  const name = id == 'new' ? 'Создание нового арендатора' : (!isLoading ? `${data?.name}` : '')
  return (
    <>
      { name ? name : <span className={'opacity-25'}>Загрузка...</span> }
    </>
  )
}

const TenantPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading, error} = useAppSelector( state => state.tenants.one);
  const [name, setName] = useState('');
  const { id } = useParams();
  const [alert, setAlert] = useState('');
  const dialog = useDialog();
  const notify = useNotification();

  useEffect(() => {
    if(id != 'new') {
      dispatch(fetchTenant({id})).then(
        (response) => {
          setName(response.payload.name);
        }
      );
    }

  }, [])
  const save = () => {
    return new Promise<void>((resolve, reject) => {
      if(id === 'new') {
        dispatch(createTenant({ name })).then(
          (result) => {
            console.log('444', result)
            if(result.meta.requestStatus === 'rejected') reject()
            setAlert('Арендатор успешно создан')
            resolve()
            navigate(`/tenants/${result.payload._id}`);
          },
        );
      } else {
        dispatch(updateTenant({ id, name })).then(
          (result: any) => {
            if(result.meta.requestStatus === 'rejected') reject()
            resolve()
          },
        );
      }
    })

  }
  const remove = () => {
      dialog.show(<DialogQuestionDanger title='Внимание' text='Вы действительно хотите удалить арендатора?' />).then(
        () => {
          dispatch(deleteTenant({id})).then(
            (result) => {
              if(result.meta.requestStatus !== 'rejected') {
                navigate(`/tenants`);
                setTimeout(() => {
                  notify.show(<NotificationSuccess text={'Арендатор удалён'} />)
                }, 300)
              }
            }
          )
        }
      )
  }
  return (
      <div className='max-w-sm'>
        {alert && (<AlertSuccess text={alert} />) }
        { error &&
           <div className='text-sm text-red-500 py-2 px-0'>
             {error}
           </div>
        }

        <div className="grid grid-cols-1 gap-3">
          <div className="">
            <Input label='Название' value={name} onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className="mt-4 flex gap-4 items-center justify-end">
          <Button onClick={save} text='Сохранить' isLoading={false}/>
          <Button onClick={remove} text='Удалить' isLoading={false}/>
        </div>
      </div>
  );
};

export default TenantPage;