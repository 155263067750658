import React, {forwardRef, useEffect, useMemo, useState} from 'react';
import Input from "components/ui/Input/Input";
import Button from "components/ui/buttons/Button";
import {useAppDispatch, useAppSelector} from "hooks/redux";
import {fetchABCPSettings, saveABCPSettings} from "store/reducers/abcp/AbcpThunk";
import {PayloadAction} from "@reduxjs/toolkit";
import {ISettingsABCP} from "models/ISettingsABCP";
import "react-datepicker/dist/react-datepicker.css";
import {DateTime} from "luxon";
import SettingsPage from "../SettingsPage";
import DataPicker from "../../../components/ui/Input/tmpl/DataPicker";
function isNumeric(str: any) {
    if (typeof str != "string") return false // we only process strings!
    console.log(6, str,  typeof str)
    return !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const SettingsUserPage = () => {
    const dispatch = useAppDispatch();
    const {data: settingsABCP, isLoading, error} = useAppSelector(state => state.abcp.settings)
    const [host, setHost] = useState('');
    const [userLogin, setUserLogin] = useState('');
    const [userPsw, setUserPsw] = useState('');
    const [dateLoad, setDateLoad] = useState<any>(0);
    const [canLoad, setCanLoad] = useState(false);

    useEffect(() => {
        dispatch(fetchABCPSettings()).then((settings: PayloadAction<ISettingsABCP> | any) => {
            console.log('load settings', settingsABCP, settings.payload)
            setHost(settings.payload?.host || '')
            setUserLogin(settings.payload?.userLogin || '')
            setUserPsw(settings.payload?.userPsw || '')
            if (settings.payload?.dateLoad) {
                const date = DateTime.fromSeconds(settings.payload?.dateLoad).toJSDate();
                setDateLoad(settings.payload?.dateLoad || 0)
            }
            setCanLoad(settings.payload.canLoad || false);

            // setHost('23')
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const saveSettings = () => {
        console.log('saveSettings1', host, userLogin, userPsw, dateLoad, DateTime.fromSeconds(dateLoad), canLoad)
        console.log('saveSettings2', settingsABCP?.host, settingsABCP?.userLogin, settingsABCP?.userPsw)
        dispatch(saveABCPSettings({host, userLogin, userPsw, dateLoad, canLoad}))
    }

    // const dd = useMemo(() => {
    //     console.log('useMemo', dateLoad)
    //     if(isNumeric(dateLoad)) {
    //         return setDateLoad(DateTime.fromJSDate(dateLoad).toSeconds())
    //     } else {
    //         return dateLoad
    //     }
    // }, [dateLoad])

    // const ExampleCustomInput = forwardRef<HTMLInputElement, { value?: string | number | undefined, onClick?: () => void }>(({ value, onClick }, ref) => (
    //     <Input label='Дата последней загрузки' value={value} onClick={onClick} ref={ref} />
    // ));

    return (
      <SettingsPage>
          <div className='max-w-sm'>
              <div className='text-sm text-red-500 py-2 px-0'>
                  {error}
              </div>
              <div className='text-sm py-2 px-0'>
                  {isLoading && 'Загрузка...'}
              </div>
              <div className="grid grid-cols-1 gap-3">
                  <div className="">
                      <Input label='Хост' value={host} onChange={(e) => setHost(e.target.value)}/>
                  </div>
                  <div className="">
                      <Input label='Логин ABCP' value={userLogin} onChange={(e) => setUserLogin(e.target.value)}/>
                  </div>
                  <div className="">
                      <Input label='Пароль ABCP' value={userPsw} onChange={(e) => setUserPsw(e.target.value)}/>
                  </div>
                  <div>
                      <DataPicker label={'Дата последней загрузки'} value={DateTime.fromSeconds(dateLoad).toJSDate()} onChange={(date: Date) => setDateLoad(DateTime.fromJSDate(date).toSeconds())} />
                  </div>
                  <div>
                      <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                checked={canLoad}
                                onChange={() => setCanLoad(!canLoad)}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                          </div>
                          <div className="ml-3 text-sm">
                              <label htmlFor="comments" className="font-medium text-gray-700">
                                  Загружать автоматически заказы из ABCP
                              </label>
                              <p id="comments-description" className="text-gray-500">
                                  Каждые 5 минут
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="mt-4 flex items-center justify-end">
                  <Button onClick={saveSettings} text='Сохранить' isLoading={false}/>
              </div>
          </div>
      </SettingsPage>
    );
};

export default SettingsUserPage;