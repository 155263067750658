import React, {useEffect} from 'react';
import {EnumDialogIcon} from "../DialogWrap";
import {DialogServiceType} from "../Dialogs";

type Props = {
  title: string
  text: string
  buttonTextOk?: string

  _dialog?: DialogServiceType
}
const DialogSuccess: React.FC<Props> = ({ text, title, buttonTextOk, _dialog}) => {

  useEffect(() => {
    _dialog?.setTitle(title)
    _dialog?.acceptButton(buttonTextOk)
    _dialog?.declineButton(false)
    _dialog?.setIcon(EnumDialogIcon.SUCCESS)

  }, [])

  return (
    <div>
      <p className="text-sm text-gray-500 mt-8 mb-10" dangerouslySetInnerHTML={{ __html: text }}>

      </p>
    </div>
  );
};

export default DialogSuccess;