import React, {useEffect} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import ContextMenu from "./ContextMenu";

const ContextGlobal: React.FC = () => {
  const { items, data, target } = useAppSelector(state => state.contextMenu);
  // const click = () => {
  //   console.log(444, 'click');
  //   // contextMenu.close();
  // }
  // useEffect(() => {
  //   if(items.length) {
  //     document.addEventListener('click', click);
  //     return () => document.removeEventListener('click', click);
  //   }
  // }, [])

  return (
    <>
      {!!items.length && <ContextMenu />}
    </>
  )
};

export default ContextGlobal;