import {useAppDispatch, useAppSelector} from "./redux";
import {showSlide, unmountSlide} from "../store/reducers/SlideSlice";


export const useSlide = () => {
  const dispatch = useAppDispatch();
  const show = (component: any) => {
    return new Promise<any>((resolve, reject) => {
      const data = {
        component,
        resolve,
        reject
      };
      dispatch(showSlide(data));
    })
  }
  const unmount = (slide: any) => {
    dispatch(unmountSlide(slide));
  }

  // const accept = (id: any, accept: any) => {
  //   console.log('accept1', id)
  //   const dialog = dialogs.find(item => item.id === id);
  //   if(dialog) dialog.accept3 = accept;
  // }

  return {
    show,
    unmount
  }
}