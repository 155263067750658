import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import Input from "../../Input/Input";
import {SlideServiceType} from "../Slides";
import {useAddCustomerMutation, useLazyGetCustomerQuery, useUpdateCustomerMutation} from "../../../../api/customer-api";

type Props = {
  idCustomer: string

  _slide?: SlideServiceType
};

const CustomerSlide: React.FC<Props> = (props: Props) => {
  const {idCustomer, _slide} = props;
  const [getCustomer, {data, isFetching}] = useLazyGetCustomerQuery();
  const [ updateCustomer ] = useUpdateCustomerMutation();
  const [ addCustomer ] = useAddCustomerMutation();
  const refInput = useRef<any>()

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const onOk = useCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if(data?._id) {
        updateCustomer({id: data._id, body: { firstName: firstName, lastName: lastName } }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        );
      } else if(idCustomer === 'new') {
        addCustomer({ firstName: firstName, lastName: lastName }).then(
          (r: any) => {
            if(r?.error) {
              reject()
            } else {
              resolve()
              _slide?.accept()
            }
          }
        )
      }
    })

  }, [data, firstName, lastName])

  useEffect(() => {
    _slide?.acceptHandler(onOk) // устанавливаем обработчик Ok (Save) в Slide
    if(idCustomer === 'new') {
      _slide?.acceptButton('Создать')
    } else {
      _slide?.acceptButton('Сохранить')
    }
  }, [onOk])

  useEffect(() => {
    if(idCustomer !== 'new') {
      getCustomer({id: idCustomer})
    }

  }, [])

  useEffect(() => {
    if(idCustomer !== 'new') {
      // title in header
      const title = `${data?.lastName} ${data?.firstName}`
      _slide?.titleFn(title);
      _slide?.subTitleFn('Клиент')

      setFirstName(data?.firstName ?? '')
      setLastName(data?.lastName ?? '')
    } else {
      // title in header
      _slide?.titleFn('Новый клиента');
      _slide?.subTitleFn('Клиент')
    }
  }, [data]);

  // фокус на наименовании
  useEffect(() => {
    if(refInput.current && idCustomer === 'new') {
      refInput.current.focus()
    }
  }, [refInput.current])

  return (
    <>
      {isFetching ? 'Загрузка...'
        :
        <form className="flex flex-col gap-6 text-sm text-slate-700 " /*onSubmit={onSubmitClient}*/>

          <Input label='Фамилия'
                 value={lastName}
                 onChange={(e) => setLastName(e.target.value)}
                 ref={refInput} />
          <Input label='Имя'
                 value={firstName}
                 onChange={(e) => setFirstName(e.target.value)} />


        </form>
      }

    </>
  );
};

export default CustomerSlide;