import {useAppDispatch, useAppSelector} from "./redux";
import {showNotify, unmountNotify} from "../store/reducers/NotificationSlice";

export const useNotification = () => {
  const dispatch = useAppDispatch();

  const show = (component: any) => {
    dispatch(showNotify({ component }))
  }
  const unmount = (notify: any) => {
    dispatch(unmountNotify(notify));
  }

  return {
    show,
    unmount
  }
}