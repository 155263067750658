// import {useAppDispatch, useAppSelector} from "hooks/redux";
import * as React from 'react';
import {Fragment, useContext, useEffect, useState} from 'react';
import {Bars3BottomLeftIcon, BellIcon, EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import {CheckIcon, ChevronDownIcon, PlusIcon, RectangleGroupIcon, UsersIcon} from "@heroicons/react/20/solid";
import {Listbox, Menu, Transition} from "@headlessui/react";
import {NavLink, useNavigate} from "react-router-dom";
import {useAppSelector} from "../hooks/redux";
import {useContextMenu} from "../hooks/contextMenu";
import {SideMenu} from "./ui/contextMenu/ContextMenu";
import {useChangeTenantMutation} from "../api/auth";
import {useDialog} from "../hooks/dialog";
import DialogAddNewTenant from "./ui/dialogs/tmpl/DialogAddNewTenant";
import {useNotification} from "../hooks/notification";
import {useTooltip} from "../hooks/tootip";
import {AppContext} from "../App";

type Props = {
  children?: React.ReactNode
  setSidebarOpen?: (sidebarOpen: boolean) => void
};

const userNavigation = [
  // { name: 'Профиль', href: '/profile' },
  { name: 'Настройки', href: '/settings/tenant' },
  { name: `Выйти`, href: '/logout' },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const navigation = [
  { name: 'Справочники', href: '/', icon: RectangleGroupIcon, current: false },
  { name: 'Документы', href: '/ui', icon: UsersIcon, chevron: true, count: true, current: true },
]
const people = [
  { id: 1, name: 'Auto care' },
  { id: 2, name: 'ООО "Рога и копыта"' },
  { id: 3, name: 'Магазин косметики Beautiful Ultra Class Exclusive' },
  { id: 4, name: 'Toyota' },
  { id: 5, name: 'Emeta.ru' },
]
const Header2: React.FC<Props> = (props: Props) => {
  const dialog = useDialog();
  const notify = useNotification();
  const {children, setSidebarOpen = ()=>{} } = props;
  const user = useAppSelector((state) => state.auth)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const context = useContextMenu();
  const navigate = useNavigate();
  const [tenant, setTenant] = useState(user.data.Tenant)
  const [link, setLink] = useState({ href: '', name: '' })
  const catalogsContextMenu = [
    [
      {text: 'Товары', href: '/products'},
      {text: 'Бренды', href: '/brands'},
      // {text: 'Склады'}
    ],
    [
      // { text: 'Клиенты', href: '/customers' },
      { text: 'Сотрудники', href: '/employees' },
      { text: 'Поставщики', href: '/suppliers' },
      { text: 'Клиенты', href: '/customers' }
    ]
  ]
  const docsContextMenu = [
    [
      {text: 'Наши заказы', href: '/doc-our-orders'},
      {text: 'Заказы клиентов', href: '/doc-customer-orders'},
      {text: 'Заказы ABCP', href: '/abcp-orders'},
      {text: 'Заказы поставщикам', href: '/doc-supplier-orders'},
    ],
  ]
  const [doChangeTenant, { reset, isLoading, isError }] = useChangeTenantMutation();
  const [logoWords, setLogoWords] = useState<string>('')
  const tooltip = useTooltip()
  const {settings} = useContext(AppContext)
  const onChangeTenant = (newTenant: any) => {
    setTenant(newTenant)
    doChangeTenant({TenantID: newTenant._id}).then(
      () => {
        window.location.reload();
      }
    )
  }

  useEffect(() => {
    let href: string = process.env.REACT_APP_URL_PUBLIC ?? ''
    href = href.replace('#','id'+tenant.publicId)
    let domain: string = process.env.REACT_APP_URL_PUBLIC_DOMAIN ?? ''
    domain = 'id'+tenant.publicId+'.'+domain
    // domain = 'Публичная ссылка'
    setLink({ href, name: domain })

    const name = tenant.name.split(' ')
    const first = name[0] ? name[0][0] : 'NO'
    const two = first && name[1] ? name[1][0] : ''
    setLogoWords(first+two)
  }, [tenant])

  const addTenant = () => {
    dialog.show(<DialogAddNewTenant />).then(
      (result) => {
        // notify.show(<NotificationSuccess text={'Организация успешно создана'} />)
        onChangeTenant(result.Tenant)
      }
    )
  }

  return (
    <>
      <header className="sticky top-0 z-30 flex h-16 flex-shrink-0 bg-slate-700 drop-shadow-[0_3px_3px_rgba(0,0,0,0.1)]" aria-label="Global">

        {/*бургер*/}
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Открыть меню</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="flex flex-1 justify-end md:justify-between min-w-0 md:pr-8">
          <div className='hidden md:flex md:items-center'>
            <NavLink key='toStart' to="/" className='ml-0 md:flex md:items-center md:justify-center h-full w-36 relative '>
              <img
                className=" rounded"
                src={`${process.env.REACT_APP_URL}/logo-vtovare.png`}
                alt=""
              />
              {/*<div className={'absolute text-white text-[10px] bottom-4 left-9 overflow-hidden'}>Слоган еще какой-то</div>*/}
            </NavLink>
            {/*<NavLink key='toStart' to="/" className='ml-0 w-8 flex items-center justify-center relative md:hidden'>*/}
            {/*  <img*/}
            {/*    className="w-8 h-auto"*/}
            {/*    src={`${process.env.REACT_APP_URL}/5@2x.png`}*/}
            {/*    alt=""*/}
            {/*  />*/}
            {/*  /!*<div className={'absolute text-white text-[10px] bottom-4 left-9 overflow-hidden'}>Слоган еще какой-то</div>*!/*/}
            {/*</NavLink>*/}

            {/* справочники */}
            <div className="flex items-center gap-x-12 ml-4">
              <div className="md:flex md:gap-x-12">

                <div onClick={(e) => {context.show({items: catalogsContextMenu, side: SideMenu.Right}, e.currentTarget)}} className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-slate-100 cursor-pointer">
                  Справочники
                  <ChevronDownIcon
                    className={classNames(false ? 'rotate-180' : '', 'h-5 w-5 flex-none text-gray-400')}
                    aria-hidden="true"
                  />
                </div>

                {settings.view1 && <div onClick={(e) => {context.show({items: docsContextMenu, side: SideMenu.Right}, e.currentTarget)}} className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-slate-100 cursor-pointer">
                  Документы
                  <ChevronDownIcon
                    className={classNames(false ? 'rotate-180' : '', 'h-5 w-5 flex-none text-gray-400')}
                    aria-hidden="true"
                  />
                </div>}

              </div>
            </div>


          </div>

          {/*Public link*/}
          <div className='hidden md:flex md:items-center text-slate-100'
               onMouseEnter={ (event: any) => { tooltip.show('Публичная ссылка', event.currentTarget) } }
               onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }>
            <a href={link.href} target='_blank'>{link.name}</a>
          </div>

          <div className='hidden md:flex md:justify-between w-[30rem] md:ml-10'>
            {/*название магазина*/}
            <Listbox value={tenant} onChange={onChangeTenant} by={(a, z) => {return a?._id === z._id}}>
              {({ open }) => (
                <>
                  <div className="relative">
                    <Listbox.Button as='div' className='flex flex-1 h-full'>

                      <div className='flex items-center space-x-2 w-64 bg-sky-500 p-4'>

                        <div className="shrink-0 inline-flex h-8 w-8 items-center justify-center rounded-md bg-gray-500">
                          <span className="text-sm font-medium leading-none text-white">{logoWords}</span>
                        </div>
                        <div>
                          <div className='uppercase text-white text-sm'>{tenant.name}</div>
                          <div className='flex space-x-2'>
                            {tenant.address ?
                              <>
                                <p className='text-emerald-100 text-xs line-clamp-1'>{tenant.address}</p>
                                {/*<ChevronDownIcon className='h-4 w-4 text-emerald-100 cursor-pointer' />*/}
                              </>

                              : <p className='text-emerald-100 text-xs line-clamp-1'>адрес не заполнен</p>
                            }

                          </div>

                        </div>

                      </div>
                      <div className='w-16 bg-sky-600 text-white p-4 cursor-pointer'>
                        <EllipsisHorizontalIcon />
                      </div>

                    </Listbox.Button>
                    <Listbox.Options className='absolute z-20 w-full overflow-auto bg-sky-400 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {user.data.memberTenants.map((tenant) => (
                        <Listbox.Option
                          key={tenant._id}
                          value={tenant}
                          className={({ selected, active }) =>
                            classNames(
                              selected ? 'bg-sky-500 text-white pointer-events-none' : (active ? 'bg-sky-500 text-white' : ''),
                              'relative cursor-pointer select-none my-2 mx-2 py-2 px-2 max-h-[80px] rounded'
                            )
                          }
                        >

                          {({ selected, active }) => (
                            <>
                              <div className={classNames('flex', selected ? 'pr-8' : '')}>
                                <div className='flex items-start space-x-2'>

                                  <div className="shrink-0 inline-flex h-8 w-8 items-center justify-center rounded-md bg-gray-500 mt-0.5">
                                    <span className="text-sm font-medium leading-none text-white">AC</span>
                                  </div>
                                  <div>
                                    <div className='uppercase text-white text-sm max-h-[42px] line-clamp-2'>{tenant.name}</div>
                                    <div className='flex space-x-2'>
                                      <p className='text-sky-100 text-xs'>Всего магазинов: 1</p>
                                    </div>

                                  </div>

                                </div>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    // active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4 text-white'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}



                        </Listbox.Option>
                      ))}
                      <div className={'flex mb-1 mt-4 mx-2'}>
                        <button
                          className='flex items-center justify-center space-x-2 bg-white h-[38px] border border-sky-50 rounded-md text-sky-600 w-full hover:bg-sky-100 hover:border-sky-200'
                          onClick={addTenant}
                        >
                          <div className=''>Добавить организацию</div>
                          <PlusIcon className='h-5 w-5' />
                        </button>
                      </div>

                    </Listbox.Options>

                  </div>
                </>
              )}

            </Listbox>

            {/*правое меню*/}
            <div className="ml-4 md:flex md:items-center md:ml-6">
              {/*<button*/}
              {/*  type="button"*/}
              {/*  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
              {/*>*/}
              {/*  <span className="sr-only">Уведомления</span>*/}
              {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
              {/*</button>*/}

              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">Открыть меню пользователя</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className='px-4 py-2 line-clamp-1 text-slate-700'>{user.data.login}</div>
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <NavLink
                            key={item.href}
                            to={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            {item.name}
                          </NavLink>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>

          <div className='flex justify-end md:hidden'>
            {/*название магазина*/}
            <Listbox value={tenant} onChange={onChangeTenant} by={(a, z) => {return a?._id === z._id}}>
              {({ open }) => (
                <>
                  <div className="relative">
                    <Listbox.Button as='div' className='flex flex-1 h-full'>

                      <div className='flex items-center space-x-2 w-64 bg-sky-500 p-4'>

                        <div className="shrink-0 inline-flex h-8 w-8 items-center justify-center rounded-md bg-gray-500">
                          <span className="text-sm font-medium leading-none text-white">{logoWords}</span>
                        </div>
                        <div>
                          <div className='uppercase text-white text-sm'>{tenant.name}</div>
                          <div className='flex space-x-2'>
                            {tenant.address ?
                              <>
                                <p className='text-emerald-100 text-xs line-clamp-1'>{tenant.address}</p>
                                {/*<ChevronDownIcon className='h-4 w-4 text-emerald-100 cursor-pointer' />*/}
                              </>

                              : <p className='text-emerald-100 text-xs line-clamp-1'>адрес не заполнен</p>
                            }

                          </div>

                        </div>

                      </div>
                      <div className='w-16 bg-sky-600 text-white p-4 cursor-pointer'>
                        <EllipsisHorizontalIcon />
                      </div>

                    </Listbox.Button>
                    <Listbox.Options className='absolute z-20 w-full overflow-auto bg-sky-400 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {user.data.memberTenants.map((tenant) => (
                        <Listbox.Option
                          key={tenant._id}
                          value={tenant}
                          className={({ selected, active }) =>
                            classNames(
                              selected ? 'bg-sky-500 text-white pointer-events-none' : (active ? 'bg-sky-500 text-white' : ''),
                              'relative cursor-pointer select-none my-2 mx-2 py-2 px-2 max-h-[80px] rounded'
                            )
                          }
                        >

                          {({ selected, active }) => (
                            <>
                              <div className={classNames('flex', selected ? 'pr-8' : '')}>
                                <div className='flex items-start space-x-2'>

                                  <div className="shrink-0 inline-flex h-8 w-8 items-center justify-center rounded-md bg-gray-500 mt-0.5">
                                    <span className="text-sm font-medium leading-none text-white">AC</span>
                                  </div>
                                  <div>
                                    <div className='uppercase text-white text-sm max-h-[42px] line-clamp-2'>{tenant.name}</div>
                                    <div className='flex space-x-2'>
                                      <p className='text-sky-100 text-xs'>Всего магазинов: 1</p>
                                    </div>

                                  </div>

                                </div>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    // active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4 text-white'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}



                        </Listbox.Option>
                      ))}
                      <div className={'flex mb-1 mt-4 mx-2'}>
                        <button
                          className='flex items-center justify-center space-x-2 bg-white h-[38px] border border-sky-50 rounded-md text-sky-600 w-full hover:bg-sky-100 hover:border-sky-200'
                          onClick={addTenant}
                        >
                          <div className=''>Добавить организацию</div>
                          <PlusIcon className='h-5 w-5' />
                        </button>
                      </div>

                    </Listbox.Options>

                  </div>
                </>
              )}

            </Listbox>
          </div>


        </div>

      </header>
    </>
  );
};

export default Header2;