import * as React from 'react';
// import {useAppDispatch, useAppSelector} from "hooks/redux";
import {useEffect} from "react";
// import {fetchClient} from "store/reducers/clients/ClientThunk";

type Props = {
  children?: React.ReactNode
  rowVirtualizer: any
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const TableBody: React.FC<Props> = (props: Props) => {
  const {children, rowVirtualizer} = props;


  return (
    <>
      <div className=''>
        <div /*tbody*/
          {...{
            className: 'relative w-full',
            style: {
              height: rowVirtualizer.getTotalSize(),
              // width: table.getTotalSize(),
            }
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default TableBody;