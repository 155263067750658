import * as React from 'react';

type Props = {
  children?: React.ReactNode
  // parentRef: any
  rowVirtualizer: any
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const TableBodyScroll: React.FC<Props> = (props: Props) => {
  const {children, rowVirtualizer} = props;

  const items = rowVirtualizer.getVirtualItems();
  return (
    <>
      <div className=' '
           {...{
             style: {
               // height: rowVirtualizer.getTotalSize(),
             }
           }}
      >
        <div
          {...{
            className: 'relative',
            style: {
              height: rowVirtualizer.getTotalSize(),
              // width: table.getTotalSize(),
            }
          }}
        >
          {items && items.length ?
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${items[0].start - rowVirtualizer.options.scrollMargin}px)`,
              }}
            >
              {children}
            </div> : '' }
        </div>
      </div>
    </>
  );
};

export default TableBodyScroll;