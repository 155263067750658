import {ColumnResizeMode, createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {useVirtualizer} from "@tanstack/react-virtual";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useContextMenu} from "./contextMenu";
import {useTooltip} from "./tootip";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import calculateSize from "calculate-size";
import {ColumnsWidth} from "../components/ui/table/Table";

const defaultProps = {
  params: { sort: 'id:desc' },
}

export const useTable = (props: any) => {

  const {
    columns,
    getData,
    data:allRows,
    hasNextPage,
    isFetching:isFetchingNextPage,
    onClickRow=()=>{},
    onDoubleClickRow=()=>{},
    size=100, // по-умолчанию
    // sort='_id', // по-умолчанию
    tableContextMenu=null,
    rowContextMenu=null,
    meta,
    // params = { ...defaultProps.params, ...props.params },
    isError,
    error,
    isRowNumber = false,
    isCheckbox = false,
    parentRefExt = { current: null },
    actionGroup
  } = props;
// console.log('parentRef444', props.parentRefExt)
  const lastEmpty = { last: '' }
  const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange') // or 'onEnd'
  const tableRef = React.useRef<any>()
  // const parentRef = React.useRef<any>(parentRefExt.current)
  const headerRef = React.useRef<any>()
  const [widthTable, setWidthTable] = useState(0);
  const [columns2, setColumns2] = useState<any>(columns);
  // const [columnsCalc, setColumnsCalc] = useState<any>(columns);
  const [scrollWidthDef, setScrollWidthDef] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [selected, setSelected] = useState<{all: boolean, count: number, include: any[], exclude: any[]}>({all: false, count: 0, include: [], exclude: []});
  const [last, setLast] = useState<{last: string}>(lastEmpty)
  const contextMenu = useContextMenu();
  const params = useMemo<any>(() => ({ ...defaultProps.params, ...props.params }), [props.params])

  const setSelect = (checked: boolean, row: any) => {
    console.log('setSelect', checked, row)
    const countAnyMove: number = allRows.filter((item: any) => item?.move).length
    const totalElements = meta.totalElements - countAnyMove
    if(checked) {
      if(selected.all) {
        // такой ситуации не бывает
      } else {
        if(selected.exclude.length) {
          let exclude = [...selected.exclude.filter((item: any) => item._id !== row._id)]
          const count = totalElements - exclude.length
          const all = count == totalElements
          setSelected({ all, count, exclude, include: [] })

        } else {
          const include = [...selected.include, row]
          const count = include.length
          const all = count == totalElements
          setSelected({all, count, include, exclude: []});
        }
      }

    } else {
      if(selected.all) {
        const exclude = [...selected.exclude, row]
        const count = totalElements - exclude.length
        setSelected({ all: false, count, exclude, include: [] })
      } else {
        if(selected.exclude.length) {
          let exclude = [...selected.exclude, row]
          const count = totalElements - exclude.length
          exclude = count === 0 ? [] : exclude
          setSelected({ all: false, count, exclude, include: [] })
        } else {
          const include = [...selected.include.filter((item: any) => item._id !== row._id)]
          const count = include.length
          const all = count == totalElements
          setSelected({all, count, include, exclude: []});
        }

      }

    }
  }

  const setSelectAll = (checked: boolean) => {
    console.log('setSelectAll', checked)
    if(checked) {
      const isAnyMove = allRows.some((item: any) => item?.move)
      if(isAnyMove) {
        const include = [...selected.include]
        allRows.forEach((row: any) => {
          if(!row?.move && !selected.include.some((item: any) => item._id !== row._id)) {
            console.log('row', row)
            include.push(row)
          }
        })
        setSelected({ all: true, count: include.length, include, exclude: [] })
      } else {
        setSelected({ all: true, count: meta.totalElements, include: [], exclude: [] })
      }
    } else {
      setSelected({ all: false, count: 0, include: [], exclude: [] })
    }
  }

  const columnsCalc = useMemo( () => {
    // находим ширину полосы прокрутки
    // создадим элемент с прокруткой
    let div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';

    // мы должны вставить элемент в документ, иначе размеры будут равны 0
    document.body.append(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;

    div.remove();
    setScrollWidthDef(scrollWidth);

    // колонки по-умолчанию
    let colTemp = [];
    const columnHelper = createColumnHelper<any>();

    if(isRowNumber) {
      const columnRowNumber: any = columnHelper.accessor('rowNumber', {
        id: 'rowNumber',
        // cell: (info)=> <>{info.row.index+1}</>,
        header: 'N',
        size: 70,
        minSize: 70,
        maxSize: 70,
      })
      colTemp.push(columnRowNumber)
    }

    if(isCheckbox) {
      const columnCheckbox: any = columnHelper.accessor('checkbox', {
        id: 'checkbox',
        size: 20,
        minSize: 20,
        maxSize: 20,
      })
      colTemp.push(columnCheckbox)
    }


    colTemp = [ ...colTemp, ...columns];

    if(tableContextMenu && !columns.some((item: any) => item.id === 'menu') ) {
      const columnMenu: any = columnHelper.accessor('menu', {
        id: 'menu',
        cell: info => '',
        header: info => <div className='flex justify-center items-center w-[30px] h-[30px] hover:text-sky-600 hover:border hover:border-sky-200 hover:rounded cursor-pointer' onClick={(e) => contextMenu.show({items: tableContextMenu, dataOnClick: { meta: info.table.options.meta }}, e.currentTarget)}><EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" /></div>,
        minSize: 60,
        maxSize: 60,
      })
      // setColumnsCalc([...colTemp, columnMenu])
      // setColumnsCalc([...colTemp, columnMenu])
      return [...colTemp, columnMenu]
    } else {
      // setColumnsCalc([...colTemp])
      return [...colTemp]

    }
    // console.log(77777, colTemp)

  }, [])

  const table = useReactTable({
    data: allRows ? allRows : [],
    columns: columnsCalc,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      // columnSizing: {date: 300}, // здесь подгружать настройки с сервера
    },
    columnResizeMode,
    meta,
    defaultColumn: {
      // size: undefined,
      minSize: 50,
      maxSize: 400
    }
  })
  // console.log(333,allRows)
  const { rows } = table.getRowModel(); // строки таблицы



  // const onWidth = useCallback(() => {
    // let hasScroll = false;
    // if (parentRef.current?.scrollHeight > parentRef.current?.clientHeight) {
    //   hasScroll = true;
    // }
    // if(!allRows.length) return
    //
    // let widthSum = 0
    //
    // columns2.forEach((col: any) => {
    //   if(col.id === 'rowNumber' || col.id === 'checkbox' || col.id === 'menu') {
    //     widthSum += col.size
    //   }
    //   else {
    //     if(col?.meta?.width === ColumnsWidth.MAX_CONTENT || col?.meta?.width === ColumnsWidth.MAX_CONTENT_OR_HEADER) {
    //       let count = 0
    //       let text = ''
    //       const sizeHeader = calculateSize(String(col.header), {
    //         font: 'Inter var',
    //         fontSize: '16px'
    //       })
    //
    //       allRows.forEach((row: any) => {
    //         const rowCount = String(row[col.accessorKey]).length
    //         if(rowCount > count) {
    //           count = rowCount
    //           text = row[col.accessorKey]
    //         }
    //       })
    //       const sizeCell = calculateSize(String(text), {
    //         font: 'Inter var',
    //         fontSize: '16px'
    //       })
    //
    //
    //       // TODO исправить этот костыль
    //       let addWidth = 0;
    //       if(col?.meta?.addWidth) {
    //         const size = calculateSize(String(col?.meta?.addWidth), {
    //           font: 'Inter var',
    //           fontSize: '16px'
    //         })
    //         addWidth = size.width;
    //       }
    //
    //       // console.log('text', String(text), size)
    //
    //       const width = Math.max(sizeCell.width, sizeHeader.width)
    //       const widthCell = sizeCell.width + 37 + addWidth
    //       const widthHeader = sizeHeader.width + 37
    //       // console.log(col, 'widthCell', widthCell, 'widthHeader', widthHeader, 'addWidth', addWidth)
    //       if(widthCell > widthHeader) {
    //         col.size = widthCell
    //         if(col.maxSize) {
    //           col.minSize = widthCell < col.maxSize ? widthCell : col.maxSize
    //         } else {
    //           col.minSize = widthCell < col.minSize ? col.minSize : widthCell
    //         }
    //       } else {
    //         col.size = widthHeader
    //         if(col.maxSize) {
    //           col.minSize = widthHeader < col.maxSize ? widthHeader : col.maxSize
    //         } else {
    //           col.minSize = widthHeader < col.minSize ? col.minSize : widthHeader
    //
    //         }
    //       }
    //       widthSum += +col.size
    //     }
    //     else if(col?.meta?.width !== ColumnsWidth.STRETCH) {
    //       // console.log(111, widthSum, col)
    //       widthSum += col.size ? +col.size : 0
    //     }
    //   }
    //
    //
    // })
    //
    //
    // let stretchCol = columns2.find((item: any) => item?.meta?.width === ColumnsWidth.STRETCH);
    // // console.log('stretchCol', stretchCol)
    // const total = table.getTotalSize() // некорректный при инициализации
    //
    // // widthSum += 50 + 54 + 60 // rowNumber + checkbox + menu
    //
    // if(tableRef.current?.offsetWidth && stretchCol) {
    //   // let size = tableRef.current?.offsetWidth - widthSum + (stretchCol?.size ? stretchCol.size : 150) - scrollWidth; // 150 - ширина по-умолчанию
    //   // console.log('size', tableRef.current?.offsetWidth, '-', widthSum, '+', (stretchCol?.size ? stretchCol?.size : 150), '-', scrollWidth, '=', size, stretchCol?.size, table.getAllColumns())
    //   // console.log(222, tableRef.current?.offsetWidth, widthSum, stretchCol?.size, scrollWidth)
    //   // stretchCol.size = size < 100 ? 100 : size;
    //   // stretchCol.maxSize = size < 100 ? 100 : size;
    // }
    //
    // setColumns2([...columns2]); // TODO при удалении этой строки, ширина колонок меняется плавно пропорционально, а так при ресайзе задается ширина колонок по-умолчанию
  // }, [allRows.length]) // parentRef.current?.scrollHeight

  // useEffect(() => {
  //   window.requestAnimationFrame(() => {
  //     onWidth();
  //     window.addEventListener("resize", onWidth);
  //   })
  //   return () => window.removeEventListener("resize", onWidth);
  // }, [ onWidth ])

  const onScroll = useCallback(() => {
    // console.log(333)
    let hasScroll = false;
    if (parentRefExt.current?.scrollHeight > parentRefExt.current?.clientHeight) {
      hasScroll = true;
    }

    setScrollWidth(hasScroll ? scrollWidthDef : 0)
  }, [parentRefExt.current?.scrollHeight])

  useEffect(() => {
    window.requestAnimationFrame(() => {
      onScroll();
      window.addEventListener("scroll", onScroll);
    })
    return () => window.removeEventListener("scroll", onScroll);
  }, [ onScroll ])

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length, // +1 - отображение строки загрузки
    getScrollElement: () => { return parentRefExt.current},
    estimateSize: () => 48, // высота строки
    overscan: 50, // На сколько больше строк вверх вниз отображать от области видимости, дальше уже будут идти виртуальные строки
  })

  useEffect(() => {
    if (getData && props.params) {
      console.log('Change last: новый запрос из Table3', 'last=', last.last, 'params', params)
      const result = getData({ last: last.last, size, ...params })
      // console.log(333, result)
      if(result instanceof Promise) {
        result.then(
          (res) => {
            // console.log(444, res)
            if(!last.last) {
              setSelected({ all: false, count: 0, include: [], exclude: [] })
            }

          }
        );
      }

    }
  }, [last])

  useEffect(() => {
    console.log('setLast empty')
    setLast(lastEmpty)
  }, [props.params])

  useEffect(() => {
    // console.log('useEffect hasNextPage')
    // @ts-ignore
    rowVirtualizer.calculateRange();
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }
    let isLoadRow = lastItem.index + 1 >= allRows.length; // индикация, что есть строка загрузки: true - есть, false - нет строки значит ничего не нужно загружать

    // console.log('useEffect', 'isLoadRow', isLoadRow, lastItem.index, allRows.length, 'isFetchingNextPage', isFetchingNextPage, 'hasNextPage', hasNextPage, 'rowVirtualizer', rowVirtualizer.getVirtualItems().length, 'isError:', isError)

    if (isLoadRow && hasNextPage && !isFetchingNextPage && !isError) {
      let lastData = allRows[allRows.length-1]; // получаем последнюю строку данных, строку загрузки пропускаем
      let last = '';
      if(lastData) {
        // console.log(999, params, allRows)
        const match = params.sort.split(':')
        if(match.length == 2 && !['id','_id'].includes(match[0])) {
          last = `${lastData[match[0]] ? lastData[match[0]] : ''}##${lastData['_id']}`; // берем дату последней строки, чтобы по ней загрузить следующие данные
        } else {
          const match = defaultProps.params.sort.split(':')
          const key = match[0] === 'id' ? '_id' : match[0]
          last = lastData[key]; // берем дату последней строки, чтобы по ней загрузить следующие данные
        }

      }

      // if(init) {
        console.log('setLast form Table3', last, isError, error)
        setLast({last})
      // }


    }
  }, [
    hasNextPage,
    allRows.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
    isError
  ])



  const [minWidthTable, setMinWidthTable] = useState<number>(0);
  // useEffect(() => {
  //   let countCols = columnsCalc.length;
  //   if(columnsCalc.some((item: any) => item.id==='menu')) countCols -= 1;
  //   if(columnsCalc.some((item: any) => item.id==='checkbox')) countCols -= 1;
  //   if(columnsCalc.some((item: any) => item.id==='rowNumber')) countCols -= 1;
  //   const min = table.getAllColumns().reduce(
  //     (previousValue, currentValue, currentIndex, array) => {
  //       return previousValue + (currentValue.columnDef.minSize ?? 0);
  //     }, 48 + countCols) // 48 - padding table both size, countCols - разделители колонок по 1px
  //   setMinWidthTable(min);
  // }, [table,columnsCalc])


  return {
    table,
    rows,
    rowVirtualizer,
    tableRef,
    parentRef: parentRefExt.current,
    headerRef,
    minWidthTable,
    scrollWidth,
    selected,
    setSelect,
    setSelectAll,
    hasNextPage,
    tableContextMenu,
    rowContextMenu,
  }
}