import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';

interface INotification {
  id?: string
  component: string
}

interface INotificationsState {
  notifications: INotification[]
}

const initialState: INotificationsState = {
  notifications: []
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotify(state, action: PayloadAction<INotification>) {
      const newNotify = action.payload;
      newNotify.id = uuidv4();
      state.notifications = [...state.notifications, newNotify]
    },
    unmountNotify(state, action: PayloadAction<INotification>) {
      const notifications = state.notifications.filter( notify => notify.id !== action.payload.id);
      state.notifications = [...notifications]
    },
  }
})

export const { showNotify, unmountNotify } = notificationSlice.actions;
export default notificationSlice.reducer;