import { Navigate } from "react-router-dom";
import LoginPage from "./auth/LoginPage";
import RegistrationPage from "./auth/RegistrationPage";

import PasswordRecoveryPage from "./auth/PasswordRecoveryPage";
import { VerificationEmailPage } from "./auth/VerificationEmailPage";
import SetNewPasswordRecoveryPage from "./auth/SetNewPasswordRecoveryPage";

const RouterPublic = [

  { path: 'auth/verification-email', element: <VerificationEmailPage />, title: 'Подтверждение почты' },
  { path: 'login', element: <LoginPage />, title: 'Вход', breadcrumb: 'Вход'},
  { path: 'registration', element: <RegistrationPage />, title: 'Регистрация нового пользователя', breadcrumb: 'Регистрация'},
  { path: 'password-recovery', element: <PasswordRecoveryPage />, title: 'Восстановление пароля', breadcrumb: 'Восстановление пароля'},
  { path: 'set-new-password-recovery', element: <SetNewPasswordRecoveryPage />, title: 'Новый пароль', breadcrumb: 'Новый пароль'},
  { path: '*', element: <Navigate to={'/login'} replace /> },

]


export default RouterPublic;