import { useMemo, useState, useEffect } from 'react';
import type { FormEvent, FC } from 'react';

import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { useSearchParams, Link, useNavigate } from 'react-router-dom';

import {
  XMarkIcon,
  CheckIcon,
  KeyIcon,
  ExclamationTriangleIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/solid';

import { useAppDispatch } from 'hooks/redux';
import { setCurrentUser } from 'store/reducers/AuthSlice';

import { useVerifyEmailMutation } from 'api/auth';

const useVerifyEmailError = (error: FetchBaseQueryError | SerializedError | undefined): { message: string; details: string[] } | null => {
  const memoizedError = useMemo(() => {
    if (error !== undefined && 'status' in error) {
      const result = (error.data as { message: string; details: { property: string; message: string }[] });
      if (result.message) {
        return {
          message: result.message,
          details: result.details?.map(({ message }) => message) ?? [],
        }
      }

      return { message: 'Unknown error', details: [] };
    }

    return null;
  }, [error]);

  return memoizedError;
};

export const VerificationEmailPage: FC<{ children?: never }> = () => {
  const [params, setParams] = useSearchParams();
  const verificationKey = params.get('key') ?? '';

  const [verifyEmail, { reset, data, error, isSuccess, isLoading }] = useVerifyEmailMutation();

  const verifyEmailError = useVerifyEmailError(error);

  useEffect(() => {
    if (verificationKey !== '') {
      verifyEmail(verificationKey);
    }
  }, [verifyEmail, verificationKey]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onUnderstandButtonClick = () => {
    if (data !== undefined) {
      let userData = {
        _id: data.Employee._id,
        accessToken: data.accessToken,
        memberTenants: data.memberTenants,
        Tenant: data.Tenant,
        login: data.login,
      }

      dispatch(setCurrentUser(userData));
      navigate('/products');
    }
  };

  const [key, setKey] = useState(verificationKey);

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setParams({ key });
  };

  return (
    <div className="flex flex-col items-center min-w-[320px] min-h-screen p-4 bg-slate-50 sm:pt-8 sm:pb-6 md:px-8 md:pt-12">
      <h1 className="flex flex-col items-center gap-1 sm:gap-4 md:gap-6">
        <span className="text-2xl text-slate-500 font-medium sm:text-3xl md:text-5xl"><span className="text-sky-500 italic uppercase underline">v</span>tovare</span>
        <span className="text-lg text-slate-700 font-bold sm:text-2xl md:text-3xl">Подтвердите почту</span>
      </h1>

      <main className="max-w-xs shadow rounded-lg w-full mt-2 bg-white sm:max-w-sm sm:mt-6 md:max-w-md md:mt-8">
        <form className="flex flex-col gap-4 text-sm text-slate-700 p-6 md:gap-6 md:p-10" onSubmit={onSubmit}>
          <div>
            <label htmlFor="key" className="font-medium">Ключ подтверждения</label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="flex items-center absolute inset-y-0 left-0 pl-3 pointer-events-none">
                <KeyIcon className="text-slate-400 w-5 h-5" />
              </div>
              <input
                type="text"
                name="key"
                id="key"
                placeholder="Введите ключ"
                className="w-full rounded-md text-slate-900 border-slate-300 py-2 pl-10 pr-3 placeholder:text-slate-400 focus:border-sky-500"
                value={key}
                disabled={isSuccess || isLoading}
                onChange={(event) => setKey(event.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex justify-center w-full rounded-md bg-sky-600 px-3 py-2 text-sm font-medium text-white hover:bg-sky-500 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:text-base md:px-6 md:py-3"
              disabled={isSuccess || isLoading}
            >
              <span className="relative">
                Подтвердить
                {isLoading && <ArrowPathIcon className="animate-spin absolute top-0.5 -right-7 w-6 h-6" />}
              </span>
            </button>
          </div>

          <div className="text-right">
            <Link to="/login" className="text-sky-600 hover:text-sky-500">
              У меня есть аккаунт
            </Link>
          </div>
        </form>
      </main>

      <div className="text-xs text-slate-600 text-center mt-auto sm:text-sm md:flex md:flex-row-reverse md:justify-between md:w-full md:pt-8">
        <p>
          {/*<span>Русский</span>*/}
          {/*<span className="mx-4">English</span>*/}
          {/*<span>Все языки</span>*/}
        </p>

        <p className="mt-2 sm:mt-4 md:mt-0">Vtovare &copy; 2023</p>
      </div>

      {verifyEmailError && (
        <div className="fixed inset-x-0 top-0 p-4 flex gap-3 items-start bg-rose-50">
          <ExclamationTriangleIcon className="w-5 h-5 text-rose-400" />
          <div className="grow text-sm">
            <p className="font-medium text-rose-800">{verifyEmailError.message}</p>
            {verifyEmailError.details.length && (
              <ul className="list-disc list-inside text-rose-700">
                {verifyEmailError.details.map((message) => <li key={message}>{message}</li>)}
              </ul>
            )}
          </div>
          <button
            type="button"
            className="rounded-md p-1.5 text-rose-500 hover:bg-rose-100 focus:bg-rose-50 focus:ring-2 focus:ring-rose-100"
            onClick={reset}
          >
            <span className="sr-only">Закрыть</span>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      )}

      {isSuccess && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-slate-700/25">
          <div className="rounded-2xl m-4 p-6 max-w-sm bg-white text-center relative">
            <button
              type="button"
              className="absolute top-4 right-4 text-slate-500 hover:text-rose-500 focus:text-rose-500"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">Закрыть</span>
              <XMarkIcon className="w-6 h-6" />
            </button>
            <div className="rounded-full w-12 mx-auto p-3 bg-emerald-50">
              <CheckIcon className="w-6 h-6 text-emerald-500" />
            </div>

            <p className="text-slate-900 text-lg font-medium mt-5">E-mail подтверждён</p>

            <p className="text-slate-500 text-sm">Ваша учётная запись успешно активирована</p>

            <button
              type="button"
              className="flex justify-center w-full rounded-md bg-sky-600 mt-4 px-3 py-2 text-sm font-medium text-white hover:bg-sky-500 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:text-base md:px-6 md:py-3"
              onClick={onUnderstandButtonClick}
            >
              Отлично
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
