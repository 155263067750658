import * as React from 'react';
import {Fragment, useEffect, useRef, useState} from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {ChevronDownIcon, XMarkIcon} from "@heroicons/react/20/solid";
import Input from "components/ui/Input/Input";
import SelectOptionsFlexHeight from "../options/SelectOptionsFlexHeight";

type Props = {
  children?: React.ReactNode
  param?: string
  label?: string
  data?: any
  isLoading?: any
  getData?: (arg0: any) => void
  hasNextPage?: any
  lastId?: string
  onChange?: (optionObject: {} | null) => void // событие при выборе option
  initSelected: {_id: string, [type: string]: string} | any // текущий выбранный объект
  optionKey: string // ключ в data, по которому будет отображаться текст в options
  initStatus?: string
  size?: string | number
  sort?: string
  onAdd?: () => void // событие при выборе option -- прототип
  onDelete?: () => void // событие при удалении option -- прототип
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const SelectMulti: React.FC<Props> = (props: Props) => {
  const {
    label,
    data,
    isLoading: isFetchingNextPage,
    getData=()=>{},
    hasNextPage,
    lastId='_id',
    onChange=()=>{},
    initSelected,
    optionKey,
    initStatus,
    size=10, // по-умолчанию
    sort='_id', // по-умолчанию
  } = props;

  const [currentOption, setCurrentOption] = useState<any>()
  const [currentOptions, setCurrentOptions] = useState<any>([])
  const [filter, setFilter] = useState('');
  const [init, setInit] = useState(true);
  const refInput = useRef<any>()
  useEffect(() => {
    console.log(56, initStatus, initSelected)
    if(initStatus === 'fulfilled') {
      setCurrentOptions(initSelected ? initSelected : [])
      setInit(false)
    }
  }, [initStatus])

  useEffect(() => {
    console.log('SelectMulti 1', currentOptions)
    onChange(currentOptions);
  }, [currentOptions])

  const change = (value: any) => {
    setCurrentOption('')
    if(!currentOptions.some((item: any) => item._id === value._id)) {
      setCurrentOptions([...currentOptions, value])
    }
  }

  const onDeleteItem = (item: any) => {
    const newItems = currentOptions.filter((o: any) => o._id !== item._id);
    setCurrentOptions(newItems);
  }

  // фокус на поиске
  useEffect(() => {
    if(refInput.current) {
      refInput.current.focus()
    }
  }, [refInput.current])

  return (
    <>
      {init ?
        <div>
          {label && <div className="block text-sm font-medium text-gray-700">{label}</div>}
          <div className={classNames("relative w-full ", label ? 'mt-1' : '')}>
            <div className="relative w-full h-9.5 min-h-[38px] text-sm rounded-md text-slate-900 border border-slate-300 py-2 pl-3 pr-10 placeholder:text-slate-400 focus:ring-0 focus:border-sky-500 cursor-pointer shadow-sm  bg-white text-left">
              <span className="block truncate">Загрузка...</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-slate-400" aria-hidden="true"/>
              </span>
            </div>
          </div>
        </div>
        :
        <Listbox value={currentOption ? currentOption : null} onChange={change} by={(a, z) => {return a?._id === z._id}}>
          {({open}) => (
            <>
              <div>
                {label && <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>}
                <div className={classNames("relative w-full ", label ? 'mt-1' : '')}>
                  <Listbox.Button
                    className="relative w-full h-9.5 min-h-[38px] text-sm rounded-md text-slate-900 border border-slate-300 py-1.5 pl-1.5 pr-10 placeholder:text-slate-400 focus:ring-0 focus:border-sky-500 cursor-pointer shadow-sm  bg-white text-left">
                    <div className='w-full flex flex-wrap gap-x-1.5 gap-y-1.5'>

                      {currentOptions.map((item: any) => (
                          <div key={item._id} className="relative flex max-w-full pl-2 py-0.5 pr-6 bg-sky-700 text-slate-50 rounded">
                            <span className='line-clamp-3'>{item?.[optionKey]}</span>
                            <span className='absolute inset-y-0 right-0 flex items-center pr-0.5 text-slate-400 hover:text-slate-50'>
                            <XMarkIcon className='h-5 w-5' onClick={(e) => {e.stopPropagation(); onDeleteItem(item)}} />
                          </span>
                          </div>
                      ))}
                      {/*<div className="relative flex max-w-full pl-2 py-0.5 pr-6 bg-sky-700 text-slate-50 rounded">*/}
                      {/*  <span className='line-clamp-3'>Двигатель - {currentOption?.[optionKey]}</span>*/}
                      {/*  <span className='absolute inset-y-0 right-0 flex items-center pr-0.5 text-slate-400 hover:text-slate-50'>*/}
                      {/*    <XMarkIcon className='h-5 w-5' />*/}
                      {/*  </span>*/}
                      {/*</div>*/}

                      {/*<div className="relative flex max-w-full pl-2 py-0.5 pr-6 bg-sky-700 text-slate-50 rounded">*/}
                      {/*  <span className='line-clamp-3'>{currentOption?.[optionKey]}</span>*/}
                      {/*  <span className='absolute inset-y-0 right-0 flex items-center pr-0.5 text-slate-400 hover:text-slate-50'>*/}
                      {/*    <XMarkIcon className='h-5 w-5' />*/}
                      {/*  </span>*/}
                      {/*</div>*/}

                    </div>

                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon className="h-5 w-5 text-slate-400" aria-hidden="true"/>
                    </span>
                  </Listbox.Button>

                  {open &&
                   <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                   >
                     <Listbox.Options
                      className="absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"

                     >
                       <div className='pt-1 pb-2 px-2 border-b'><Input ref={refInput} placeholder='Поиск..'
                                                                       value={filter}
                                                                       onChange={(e) => setFilter(e.target.value)}/>
                       </div>
                       <SelectOptionsFlexHeight data={data ? data : []}
                                           optionKey={optionKey}
                                           isLoading={isFetchingNextPage}
                                           hasNextPage={hasNextPage}
                                           getData={getData}
                                           filter={filter}
                                           size={size}
                                           sort={sort}
                       />
                     </Listbox.Options>
                   </Transition>}

                </div>
              </div>
            </>
          )}
        </Listbox>
      }
    </>
  );
};

export default SelectMulti;