import { useMemo, useState } from 'react';
import type { FormEvent, FC } from 'react';

import { Link } from 'react-router-dom';

import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { Popover } from '@headlessui/react';

import {
  UserIcon,
  EnvelopeIcon,
  LockClosedIcon,
  EyeIcon,
  EyeSlashIcon,
  XMarkIcon,
  CheckIcon,
  ExclamationTriangleIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { useSignUpMutation } from 'api/auth';
import Input from "components/ui/Input/Input";

const useSignUpError = (error: FetchBaseQueryError | SerializedError | undefined): { message: string; details: string[] } | null => {
  const memoizedError = useMemo(() => {
    if (error !== undefined && 'status' in error) {
      try {
        const result: { message: string; details: { property: string; message: string; }[] } = JSON.parse(error.data as string);
  
        return {
          message: result.message,
          details: result.details?.map(({ message }) => message) ?? [],
        };
      } catch {
        return { message: 'Unknown error', details: [] };
      }
  
    }
  
    return null;
  }, [error]);

  return memoizedError;
};

const RegistrationPage: FC<{ children?: never }> = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [signUp, { reset, data, error, isSuccess, isLoading }] = useSignUpMutation();

  const signUpError = useSignUpError(error);

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    signUp({ lastName, firstName: name, login, password });
  };

  return (
    <div className="flex flex-col items-center min-w-[320px] min-h-screen p-4 bg-slate-50 sm:pt-8 sm:pb-6 md:px-8 md:pt-12">
      <h1 className="flex flex-col items-center gap-1 sm:gap-4 md:gap-6">
        <span className="text-2xl text-slate-500 font-medium sm:text-3xl md:text-5xl"><span className="text-sky-500 italic uppercase underline">v</span>tovare</span>
        <span className="text-lg text-slate-700 font-bold sm:text-2xl md:text-3xl">Зарегистрируйте аккаунт</span>
      </h1>

      <main className="max-w-xs shadow rounded-lg w-full mt-2 bg-white sm:max-w-sm sm:mt-6 md:max-w-md md:mt-8">
        <form className="flex flex-col gap-4 text-sm text-slate-700 p-6 md:gap-6 md:p-10" onSubmit={onSubmit}>
          <Input label='Имя' value={name} onChange={(e) => setName(e.target.value)} />
          <Input label='Фамилия' value={lastName} onChange={(e) => setLastName(e.target.value)} />
          <Input label='Электронная почта (логин)' value={login} onChange={(e) => setLogin(e.target.value)} type={'email'} />
          <Input label='Новый пароль' value={password} onChange={(e) => setPassword(e.target.value)} type={isPasswordVisible ? 'text' : 'password'} />
          {/*<div>*/}
          {/*  <label htmlFor="name" className="font-medium">Имя</label>*/}
          {/*  <div className="relative mt-1 rounded-md shadow-sm">*/}
          {/*    <div className="flex items-center absolute inset-y-0 left-0 pl-3 pointer-events-none">*/}
          {/*      <UserIcon className="text-slate-400 w-5 h-5" />*/}
          {/*    </div>*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      name="name"*/}
          {/*      id="name"*/}
          {/*      placeholder="Введите имя"*/}
          {/*      className="w-full rounded-md text-slate-900 border-slate-300 py-2 pl-10 pr-3 placeholder:text-slate-400 focus:border-sky-500"*/}
          {/*      value={name}*/}
          {/*      disabled={isSuccess || isLoading}*/}
          {/*      onChange={(event) => setName(event.target.value)}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div>*/}
          {/*  <label htmlFor="email" className="font-medium">Электронная почта</label>*/}
          {/*  <div className="relative mt-1 rounded-md shadow-sm">*/}
          {/*    <div className="flex items-center absolute inset-y-0 left-0 pl-3 pointer-events-none">*/}
          {/*      <EnvelopeIcon className="text-slate-400 w-5 h-5" />*/}
          {/*    </div>*/}
          {/*    <input*/}
          {/*      type="email"*/}
          {/*      name="email"*/}
          {/*      id="email"*/}
          {/*      placeholder="Введите e-mail"*/}
          {/*      className="w-full rounded-md text-slate-900 border-slate-300 py-2 pl-10 pr-3 placeholder:text-slate-400 focus:border-sky-500"*/}
          {/*      value={login}*/}
          {/*      disabled={isSuccess || isLoading}*/}
          {/*      onChange={(event) => setLogin(event.target.value)}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div>*/}
          {/*  <label htmlFor="password" className="flex font-medium">*/}
          {/*    Придумайте пароль*/}
          {/*    <Popover className="inline-block ml-1 relative h-5">*/}
          {/*      <Popover.Button className="p-0.5">*/}
          {/*        <span className="sr-only">Показать подсказу с требованиями к паролю</span>*/}
          {/*        <InformationCircleIcon className="w-4 h-4 text-slate-400" />*/}
          {/*      </Popover.Button>*/}
          {/*      <Popover.Panel*/}
          {/*        className="*/}
          {/*          absolute z-10 left-0.5 bottom-7 -translate-x-2/4 rounded-md p-2 bg-slate-700/90*/}
          {/*          after:content-[''] after:absolute after:top-full after:left-2/4 after:border-x-transparent after:border-t-slate-700/90 after:border-x-8 after:border-t-8*/}
          {/*        "*/}
          {/*      >*/}
          {/*        <p className="text-white font-bold">Пароль должен содержать:</p>*/}
          {/*        <ul className="list-['—_'] list-inside text-xs whitespace-nowrap text-slate-200">*/}
          {/*          <li>Не менее 8 символов, но не более 128 символов;</li>*/}
          {/*          <li>Как минимум одна заглавная и одна строчная буква;</li>*/}
          {/*          <li>Как минимум одна цифра;</li>*/}
          {/*          <li>Без пробелов</li>*/}
          {/*          <li>Допустимые символы:{"~ ! ? @ # $ % ^ & * _ - + ( ) [ ] { } > < / \\ | \" ' . , : ;"}</li>*/}
          {/*        </ul>*/}
          {/*      </Popover.Panel>*/}
          {/*    </Popover>*/}
          {/*  </label>*/}
          {/*  <div className="relative mt-1 rounded-md shadow-sm">*/}
          {/*    <div className="flex items-center absolute inset-y-0 left-0 pl-3 pointer-events-none">*/}
          {/*      <LockClosedIcon className="text-slate-400 w-5 h-5" />*/}
          {/*    </div>*/}
          {/*    <input*/}
          {/*      type={isPasswordVisible ? 'text' : 'password'}*/}
          {/*      name="password"*/}
          {/*      id="password"*/}
          {/*      placeholder="Ваш надёжный пароль"*/}
          {/*      className="w-full rounded-md text-slate-900 border-slate-300 px-10 py-2 placeholder:text-slate-400 focus:border-sky-500"*/}
          {/*      value={password}*/}
          {/*      disabled={isSuccess || isLoading}*/}
          {/*      onChange={(event) => setPassword(event.target.value)}*/}
          {/*    />*/}
          {/*    <div className="flex items-center absolute inset-y-0 right-0 pr-3">*/}
          {/*    <button type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>*/}
          {/*        <span className="sr-only">{isPasswordVisible ? 'Скрыть пароль' : 'Показать пароль'}</span>*/}
          {/*        {isPasswordVisible ? <EyeSlashIcon className="text-slate-400 w-5 h-5" /> : <EyeIcon className="text-slate-400 w-5 h-5" />}*/}
          {/*      </button>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="hidden">
            <p className="text-xs text-slate-600">
              Регистрируясь, вы подтверждаете своё <Link to="/" className="text-sky-600 hover:text-sky-500">согласие с офертой</Link>,
              а также даёте <Link to="/" className="text-sky-600 hover:text-sky-500">согласие на обработку</Link> своих персональных данных
            </p>
          </div>

          <div>
            <button
              type="submit"
              className="flex justify-center w-full rounded-md bg-sky-600 px-3 py-2 text-sm font-medium text-white hover:bg-sky-500 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:text-base md:px-6 md:py-3"
              disabled={isSuccess || isLoading}
            >
              <span className="relative">
                Зарегистрироваться
                {isLoading && <ArrowPathIcon className="animate-spin absolute top-0.5 -right-7 w-6 h-6" />}
              </span>
            </button>
          </div>

          <div className="hidden flex items-center text-slate-500">
            <span className="w-full border-t"></span>
            <span className="mx-6 text-xs whitespace-nowrap">Или продолжить с</span>
            <span className="w-full border-t"></span>
          </div>

          <div className="hidden flex gap-2 md:gap-4">
            <button
              type="button"
              className="flex justify-center grow rounded-md border border-slate-300 bg-white px-2.5 py-1.5 text-xs font-medium hover:bg-slate-50 hover:shadow focus:bg-white focus:border-sky-600 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:py-2 sm:text-sm md:text-base"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">Google</span>
              <ExclamationCircleIcon className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
            <button
              type="button"
              className="flex justify-center grow rounded-md border border-slate-300 bg-white px-2.5 py-1.5 text-xs font-medium hover:bg-slate-50 hover:shadow focus:bg-white focus:border-sky-600 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:py-2 sm:text-sm md:text-base"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">VK</span>
              <ExclamationCircleIcon className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
            <button
              type="button"
              className="flex justify-center grow rounded-md border border-slate-300 bg-white px-2.5 py-1.5 text-xs font-medium hover:bg-slate-50 hover:shadow focus:bg-white focus:border-sky-600 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:py-2 sm:text-sm md:text-base"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">Yandex</span>
              <ExclamationCircleIcon className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
          </div>

          <div className="text-right">
            <Link to="/login" className="text-sky-600 hover:text-sky-500">
              У меня есть аккаунт
            </Link>
          </div>
        </form>
      </main>

      <div className="text-xs text-slate-600 text-center mt-auto sm:text-sm md:flex md:flex-row-reverse md:justify-between md:w-full md:pt-8">
        <p>
          {/*<span>Русский</span>*/}
          {/*<span className="mx-4">English</span>*/}
          {/*<span>Все языки</span>*/}
        </p>

        <p className="mt-2 sm:mt-4 md:mt-0">Vtovare &copy; 2023</p>
      </div>

      {signUpError && (
        <div className="fixed inset-x-0 top-0 p-4 flex gap-3 items-start bg-rose-50">
          <ExclamationTriangleIcon className="w-5 h-5 text-rose-400" />
          <div className="grow text-sm">
            <p className="font-medium text-rose-800">{signUpError.message}</p>
            {signUpError.details.length && (
              <ul className="list-disc list-inside text-rose-700">
                {signUpError.details.map((message) => <li key={message}>{message}</li>)}
              </ul>
            )}
          </div>
          <button
            type="button"
            className="rounded-md p-1.5 text-rose-500 hover:bg-rose-100 focus:bg-rose-50 focus:ring-2 focus:ring-rose-100"
            onClick={reset}
          >
            <span className="sr-only">Закрыть</span>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      )}

      {isSuccess && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-slate-700/25">
          <div className="rounded-2xl m-4 p-6 max-w-sm bg-white text-center relative">
            <button
              type="button"
              className="absolute top-4 right-4 text-slate-500 hover:text-rose-500 focus:text-rose-500"
              onClick={() => alert('Not implemented!')}
            >
              <span className="sr-only">Закрыть</span>
              <XMarkIcon className="w-6 h-6" />
            </button>
            <div className="rounded-full w-12 mx-auto p-3 bg-emerald-50">
              <CheckIcon className="w-6 h-6 text-emerald-500" />
            </div>

            <p className="text-slate-900 text-lg font-medium mt-5">Регистрация прошла успешно</p>

            <p className="text-slate-500 text-sm">Для завершения регистрации необходимо подтвердить почту. Зайдите в почту и перейдите по ссылке для подтверждения</p>

            <Link
              to="/auth/verification-email"
              className="flex justify-center w-full rounded-md bg-sky-600 mt-5 px-3 py-2 text-sm font-medium text-white hover:bg-sky-500 focus:ring-2 focus:ring-sky-300 sm:px-4 sm:text-base md:px-6 md:py-3"
            >
              Хорошо
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrationPage;