import React, {useEffect} from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid'

type Props = {
  text: string | string[]
}

const AlertError: React.FC<Props> = ({ text}) => {
  useEffect(() => {

  }, [text]);
  return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className="mt-0.5 text-sm text-red-700">
              {Array.isArray(text) ?
                text.map((item: string, index: number) => <div key={index}>{item}</div>)
              :
                text
              }
            </div>
          </div>
        </div>
      </div>
  )
};

export default AlertError;