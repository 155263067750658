import * as React from 'react';
// import {useAppDispatch, useAppSelector} from "hooks/redux";
import {forwardRef, Fragment, useEffect} from "react";

type Props = {
  children?: React.ReactNode
  parentRef: any
  minWidthTable: any
  // height: any
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Table: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {

  const {
    children,
    parentRef,
    minWidthTable,
    // height
  } = props;



  return (
    <>
      <div className='w-full h-full overflow-hidden'>
        <div /*table*/
          ref={parentRef}
          {...{
            className: 'bg-white w-full shadow rounded-lg h-full overflow-x-auto overflow-y-auto relative pl-3 py-3 ',
            style: {
              minWidth: minWidthTable,
            },
          }}
        >
          <div className='flex flex-col  '>
            {children}
          </div>
        </div>
      </div>
    </>
  )
})

export default Table;