import {useEffect, useMemo, useState} from "react";
import {useDebounce} from "./useDebounce";

export const useGetCursor: any = (getData: any, {data, isFetching, isLoading, originalArgs, currentData, requestId, isError, error, status }: any, abort: any) => {
  const [params, setParams] = useState<any>({})
  const [params2, setParams2] = useState<any>({})
  const [filterText, setFilterText] = useState<string>('')
  // const filterText = useMemo(() => agr)
  // const [category, setCategory] = useState<string>('')
  const debounceFilter = useDebounce(filterText, 200)
  const [request, setRequest] = useState<any>()
  // const [isError2, setIsError2] = useState<any>(isError)

  // useEffect(() => {
  //   setIsError2(isError)
  // }, [isError])

  const getDataTransit = (args: any): Promise<any> | undefined => {
    console.log('getData2', 'last', args.last, 'filterText', args.filterText, filterText, 'status:', status, request)
    const { filterText: filterIn, ...paramsIn } = args;
    setParams(paramsIn);
    if((args.filterText ? args.filterText : '') === filterText) {
      if((status !== 'pending' && status !== 'rejected')
        // || !paramsIn.last
      ) {
        // if(status === 'pending' && !paramsIn.last) {
        //   request.abort();
        // }
        console.log('getData for table...')
        let params2 = {...paramsIn}
        if(debounceFilter) {
          // console.log(6666666666, debounceFilter)
          params2 = {...params2, filterText: debounceFilter};
        }
        const promise = getData(params2)
        setRequest(promise)
        return promise
      } else if(status === 'pending') {
        if(!paramsIn.last) {
          console.log(88888888, request, paramsIn)
          request.abort('няяяяф')
          return request.then(
            (res: any) => {
              console.log(123, res)
              setParams2(paramsIn);
            },
            () => console.log(456)
          );
          // abort()
          // setParams2(paramsIn);
          // return Promise.reject()
        } else {
          return request
        }
      } else if(status === 'reject') {
        request.abort('няяяяф2');
        return Promise.reject()
      }
    }
    else {
      console.log('+++ setFilterText debounce', 'args.filterText:', args.filterText, 'filterText:', filterText, 'debounceFilter:', debounceFilter)
      setFilterText(args.filterText ? args.filterText : '')
      return Promise.resolve() // нужно для согласованности, потому что обработчик должен возвращать Promise
    }
  }

  useEffect(() => {
    console.log('useEffect setFilterText')
  }, [filterText])
  useEffect(() => {
    console.log('set filter2', 'term:', debounceFilter, 'status:', status, 'isFetching:', isFetching, 'request:', request, 'isLoading', isLoading)
    if(status === 'pending') {
      console.log(1, 'to abort...')
      request.abort('хуйняяяяф');
      request.then(
        (res: any) => {
          console.log(123, res)
          setParams2(params)
        },
        () => console.log(456)
      );

    } else if(status !== 'uninitialized') {
      console.log(2, 'getData...', params)
      let params2 = {...params}
      if(debounceFilter) {
        // console.log(5555555555, debounceFilter)
        params2 = {...params2, filterText: debounceFilter};
      }
      const promise = getData({ last: '', ...params2 })
      setRequest(promise)
    }
  }, [debounceFilter])

  useEffect(() => {
    if(isError && (error as any).name === 'AbortError' && !error?.message) {
      console.log('---abort and getData...', params)
      let params2 = {...params}
      if(debounceFilter) {
        // console.log(444444444, debounceFilter)
        params2 = {...params2, filterText: debounceFilter};
      }
      setRequest(getData({  ...params2, last: '' }))
    }
  }, [isError])

  useEffect(() => {
    console.log('---params0', status)
    if(status !== 'uninitialized') {
      console.log('---params and getData...', params2)
      let params22 = {...params2}
      if(debounceFilter) {
        params22 = {...params22, filterText: debounceFilter};
      }
      setRequest(getData({  ...params22, last: '' }))
    }
  }, [params2])

  const data2 = data?.data ? data : {data: [], meta: {}}


  return [getDataTransit, {data: data2, isFetching, isLoading, originalArgs, currentData, requestId, isError: isError, error, status }]
}