import React, {forwardRef, useEffect, useId, useState} from 'react';
import {RadioGroup} from "@headlessui/react";

type Props = {
  label?: any
  values?: { id: string, title: string, disabled?: boolean }[]
  onChange?: (value: any) => void
  ref?: any
  isError?: boolean
  errorText?: string
  helpText?: string
  type?: string
  id?: string
  checked?: { id: string, [type: string]: any }
  // disabled?: boolean
  classNameWrapItems?: string
  classNameOption?: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const RadioGroupIndex: React.FC<Props> = forwardRef<HTMLInputElement, Props>((
  { label,
    onChange,
    values,
    isError,
    errorText,
    helpText,
    type,
    checked,
    classNameWrapItems,
    classNameOption,
  }, ref) => {

  // const componentId = useId()
  const [current, setCurrent] = useState<any>(checked)
  const id = useId();
  const change = (value: any) => {
    setCurrent(value)
    if(onChange) {
      onChange(value)
    }
  }

  return (
    <div>
      <RadioGroup value={current ? current : ''} by={(a, b) => a?.id === b?.id} onChange={change}>
        {/*<label className="text-base font-semibold text-gray-900">Notifications</label>*/}
        {/*<p className="text-sm text-gray-500">How do you prefer to receive notifications?</p>*/}
        <fieldset className="">
          {/*<legend className="sr-only">Notification method</legend>*/}
          <div className={classNames(classNameWrapItems ? classNameWrapItems : "flex flex-col gap-0")}>
            {values && values.map((option) => (
              <RadioGroup.Option
                key={option.id+id}
                value={option}
                disabled={!!option.disabled}
                className={classNames(
                  "flex outline-none group/option",
                  !!option.disabled ? 'pointer-events-none' : 'cursor-pointer',
                )}
              >
                {({ checked , disabled}) => (
                  <div className="flex items-start ">
                    <input
                      id={option.id}
                      // name={componentId}
                      type="radio"
                      checked={checked}
                      onChange={() => {}}
                      disabled={disabled}
                      // defaultChecked={notificationMethod.id === notification.id}
                      // defaultChecked={checked}
                      className={classNames(
                        'h-4 w-4 mt-1 border-slate-400 text-sky-600',
                        'outline-none',
                        'group-focus-visible/option:outline-sky-300',
                        'group-focus/option:outline-sky-300',
                        'focus:outline-sky-300',
                        'disabled:text-slate-400 disabled:border-slate-300',
                        disabled ? 'pointer-events-none' : 'cursor-pointer',
                      )}
                      tabIndex={-1}
                    />
                    <label htmlFor={option.id} className={classNames(
                      "ml-3 block leading-6  ui-active:text-slate-900 ",
                      classNameOption ? classNameOption : '',
                      disabled ? 'text-slate-400 pointer-events-none' : 'text-slate-900 cursor-pointer',
                    )}>
                      {option.title}
                    </label>
                  </div>
                )}

              </RadioGroup.Option>

            ))}
          </div>
        </fieldset>
      </RadioGroup>

    </div>

    // <RadioGroup value={plan} onChange={setPlan}>
    //   <RadioGroup.Label className={'text-base font-semibold text-gray-900'}>Notifications</RadioGroup.Label>
    //   <div className={'space-y-4'}>
    //     {plans.map((plan) => (
    //       <RadioGroup.Option
    //         key={plan}
    //         value={plan}
    //         className="flex ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black"
    //       >
    //         <div key={plan} className="flex items-center">
    //                      <input
    //                       id={plan}
    //                       name="notification-method"
    //                       type="radio"
    //                       defaultChecked={plan === 'email'}
    //                       className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
    //                     />
    //                     <label htmlFor={plan} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
    //                       {plan}
    //                     </label>
    //                   </div>
    //         <CheckIcon className="w-5 h-5 hidden ui-checked:block" />
    //         {plan}
    //       </RadioGroup.Option>
    //     ))}
    //   </div>
    // </RadioGroup>
  );
});

export default RadioGroupIndex;
