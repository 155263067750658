import React, {ReactComponentElement, ReactElement, ReactNode} from 'react'
import {useAppSelector} from "../../../hooks/redux";
import {useSlide} from "../../../hooks/slide";
import SlideWrap from "./SlideWrap";

export type SlideServiceType = {
    accept: any
    decline: any
    acceptHandler: any
    declineHandler: any
    acceptButton: any
    declineButton: any
    titleFn: any
    subTitleFn: any
    errorTextFn: any
}

/*
Функции:
1. Вставлять любой компонент в окно
2. Окно генерирует свои события:
    - Ок - клик по кнопке Ок
    - Cancel - клик по кнопке Cancel
    - Cancel - клик по крестику в углу
    - Cancel - клик за пределами окна
3. Компонент внутри окна должен мочь сгенерировать Ок и Cancel
4. Во внутреннем компоненте можно отслеживать события Ок и Cancel (если возвращать false, то действие отменяется,
    можно возвращать Promise - окно должно ждать ответа и отображать на кнопках прелоадер)
5.
 */
const Slides: React.FC = () => {
    const { slides } = useAppSelector(state => state.slides);
    const slide = useSlide();

    const deleteSlide = (slide2: any) => {
        slide.unmount(slide2);
    }

    return (
        <>
            {slides?.length ? slides.map((slide: any) => {

                const slideComponent: any = (accept: any, decline: any, acceptHandler: any, declineHandler: any, acceptButton: any, declineButton: any, titleFn: any, subTitleFn: any, errorTextFn: any) => React.createElement(
                  slide.component.type,
                    {...slide.component.props, _slide: { accept, decline, acceptHandler, declineHandler, acceptButton, declineButton, titleFn, subTitleFn, errorTextFn }},
                )

                return (
                  <SlideWrap key={slide.id} slide={slide} unmount={deleteSlide}>
                    {slideComponent}
                  </SlideWrap>
                )

            }) : ''}
        </>
    )
};

export default Slides;