import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {v4 as uuidv4} from 'uuid';
import {SideMenu} from "../../components/ui/contextMenu/ContextMenu";

type Item = {
  text: string
  onClick?: (data: any) => void
  href?: string
}

interface IContextMenu {
  id?: string
  target?: any
  items: Item[][]
  data?: any
  side?: SideMenu
}

const initialState: IContextMenu = {
  items: [],
  target: null,
  data: null,
}

export const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState,
  reducers: {
    showContextMenu(state, action: PayloadAction<IContextMenu>) {
      console.log('showContextMenu', action.payload)
      const newContextMenu = action.payload;
      newContextMenu.id = uuidv4();
      state.items = newContextMenu.items;
      state.data = newContextMenu.data;
      state.target = newContextMenu.target;
      state.side = newContextMenu.side ? newContextMenu.side : SideMenu.Left;
      console.log('showContextMenu2', state)

    },
    closeContextMenu(state, action: PayloadAction<undefined>) {
      console.log('closeContextMenu', action.payload)
      state.items = [];
      state.data = null;
      state.target = null;
      state.side = SideMenu.Left;
    },
  },
  extraReducers: {}
})

export const { showContextMenu, closeContextMenu } = contextMenuSlice.actions;
export default contextMenuSlice.reducer;