import * as React from 'react';
import {forwardRef, Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {flexRender, Table} from "@tanstack/react-table";
import {ColumnsWidth} from "../Table";
import HeaderCellSimple from "./cells/HeaderCellSimple";
import HeaderCellStretch from "./cells/HeaderCellStretch";
import HeaderCellCheckbox from "./cells/HeaderCellCheckbox";
import HeaderCellRowNumber from "./cells/HeaderCellRowNumber";
import HeaderCellRowContextMenu from "./cells/HeaderCellRowContextMenu";
import HeaderCellSeparator from "./cells/HeaderCellSeparator";

type Props = {
  table: Table<any>
  // headerRef: any
  selected: any
  setSelectAll: any
  scrollWidth: any
  widthHeaderRef?: any
  widthBodyRef?: any
  widthFooterRef?: any
  ref?: any
  data?: any
  cellWidth: any
  setCellWidth: any
  minWidthTrRef?: any
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const TableHeader: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const {table, selected, setSelectAll, scrollWidth, widthHeaderRef, widthBodyRef, widthFooterRef, data, cellWidth, setCellWidth, minWidthTrRef} = props

  const headers = useMemo(() => {
    const headerGroup = table.getHeaderGroups()
    return headerGroup.flatMap(group => group.headers.filter((item: any) => item.id!=='checkbox' && item.id!=='menu' && item.id!=='rowNumber'))
  }, [])

  const colStretchIndex = useMemo(() => headers.findIndex((item: any) => item.column.columnDef.meta?.width === ColumnsWidth.STRETCH), [])
  const colStretch = useMemo(() => headers[colStretchIndex], [])
  const colRowNumber = useMemo(() => table.getHeaderGroups()[0].headers.find((item: any) => item.id==='rowNumber'), [])
  const colCheckbox = useMemo(() => table.getHeaderGroups()[0].headers.find((item: any) => item.id==='checkbox'), [])
  const colMenu = useMemo(() => table.getHeaderGroups()[0].headers.find((item: any) => item.id==='menu'), [])
  const colsUntilStretch = useMemo(() => colStretchIndex > -1 ? headers.slice(0, colStretchIndex) : [], [])
  const colsAfterStretch = useMemo(() => colStretchIndex > -1 ? headers.slice(colStretchIndex+1, headers.length) : [], [])

  const [minWidthLeft, setMinWidthLeft] = useState(0)
  const [minWidthRight, setMinWidthRight] = useState(0)
  const minWidthHeader = minWidthLeft + (colStretch.column.columnDef.minSize || 300) + 3 + minWidthRight + 2 // +1 - border right col stretch, +2 - both borders header

  const rowNumberRef = useRef<any>(null);
  const checkboxRef = useRef<any>(null);
  const untilRef = useRef<any>({});
  const afterRef = useRef<any>({});
  const contextMenuRef = useRef<any>(null);
  const separatorsLeftRef = useRef<any>(0);
  const separatorsRightRef = useRef<any>(0);
  separatorsLeftRef.current = 0
  separatorsRightRef.current = 0


  useEffect(() => {
    // console.log(111222, widthHeaderRef.current, widthBodyRef.current)
    let leftSum = 0
    let rightSum = 0
    const left = colsUntilStretch.map(item => item.column.id)
    const right = colsAfterStretch.map(item => item.column.id)
    let countCellSimple = 0
    for (let key of Object.keys(widthHeaderRef.current)) {
      const h = widthHeaderRef.current[key] ? widthHeaderRef.current[key] : 0
      const b = widthBodyRef.current[key] ? widthBodyRef.current[key] : 0
      const f = widthFooterRef && widthFooterRef.current[key] ? widthFooterRef.current[key] : 0
      const max = Math.max(h, b, f)
      if(['rowNumber', 'checkbox'].includes(key)) {
        leftSum += max
      } else if([...left].includes(key)) {
        leftSum += max+1
        countCellSimple += 1
      }
      else if([...right].includes(key)) {
        // console.log('separatorsRightRef', separatorsRightRef)
        rightSum += max+1
        countCellSimple += 1
      }
      else if(['menu'].includes(key)) {
        rightSum += max
      }
    }

    setMinWidthLeft(leftSum+separatorsLeftRef.current)
    setMinWidthRight(rightSum+separatorsRightRef.current)
  }, [cellWidth])


  return (
    <>
      <div /*thead*/
        ref={ref}
        className="flex flex-1 justify-between h-12 border border-slate-200 rounded-md bg-slate-50 opacity-100 z-10 before:content-[''] before:h-3 before:absolute before:-top-3 before:inset-x-0 before:bg-white before:min-w-[var(--alter-width)]"
        // @ts-ignore
        style={{minWidth: minWidthTrRef.current, '--alter-width': `${minWidthTrRef.current}px`}}
      >

        {colStretch &&
          <>
            {/* header left */}
            <div className={classNames('flex items-center overflow-hidden', 0 ? 'border border-red-500' : '')} {...{style: {minWidth: minWidthLeft}}} >

              {colRowNumber && <HeaderCellRowNumber ref={rowNumberRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} cell={colRowNumber} />}

              {/* header checkbox */}
              {colCheckbox && <HeaderCellCheckbox ref={checkboxRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} cell={colCheckbox} selected={selected} setSelectAll={setSelectAll} />}

              {colsUntilStretch.map(header =>
                <Fragment key={header.id}>
                  <HeaderCellSimple cellWidth={cellWidth} setCellWidth={setCellWidth} data={data} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} widthFooterRef={widthFooterRef} cell={header} ref={(el: any) => untilRef.current[header.id] = el} />
                  <HeaderCellSeparator ref={(el: number) => separatorsLeftRef.current += el}/>
                </Fragment>
              )}
            </div>


            {/* header stretch */}
            <div className={'flex flex-1 items-center'} {...{style: {minWidth: (colStretch.column.columnDef.minSize || 300) + 0}}}>
              <HeaderCellStretch cell={colStretch} selected={selected} setSelectAll={setSelectAll} />
            </div>

            <div className={'flex items-center'}>
              <HeaderCellSeparator isHidden={colsAfterStretch.length == 0 && !colMenu}/>
            </div>

            {/* header right */}
            <div className={classNames('flex items-center ', 0 ? 'border border-red-500' : '')}
                 {...{ style: {
                     minWidth: minWidthRight
                 }}}
            >

              {colsAfterStretch.map((header, index) => {
                // console.log(4, index+1, colsAfterStretch.length)
                // return <HeaderCellSimple key={header.id} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} cell={header} ref={(el: any) => afterRef.current[header.id] = el} />
                return <Fragment key={header.id}>
                  <HeaderCellSimple cellWidth={cellWidth} setCellWidth={setCellWidth} data={data} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} widthFooterRef={widthFooterRef} cell={header} ref={(el: any) => afterRef.current[header.id] = el} />
                  <HeaderCellSeparator ref={(el: number) => separatorsRightRef.current += el} isHidden={colsAfterStretch.length == index+1 && !colMenu}/>
                </Fragment>
              })}

              {/* header menu */}
              {colMenu ?
                <HeaderCellRowContextMenu ref={contextMenuRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} cell={colMenu} scrollWidth={scrollWidth} />
              :
                ''
              }
            </div>

          </>
        }

      </div>
      <div className={'min-w-[12px]'}></div>
    </>
  );
})

export default TableHeader;