import {AsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  createTenant,
  fetchTenant,
  fetchTenants,
  updateTenant
} from "./TenantThunk";
import {ITenant} from "../../../models/ITenant";
import {authApi, LoginResponse} from "../../../api/auth";
import {tenantApi, TenantResponse} from "../../../api/tenant";

interface TenantState {
  list: {
    data: ITenant[];
    isLoading: boolean;
    error: string;
    pages: any[];
    hasNextPage: boolean;
    meta: any
  },
  one: {
    data: Partial<ITenant>;
    isLoading: boolean;
    error: string;
  },
  create: {
    data: Partial<ITenant>;
    isLoading: boolean;
    error: string;
  },
  update: {
    data: Partial<ITenant>;
    isLoading: boolean;
    error: string;
  }
}

const initialState: TenantState = {
  list: {
    data: [],
    isLoading: false,
    error: '',
    pages: [],
    hasNextPage: true,
    meta: null
  },
  one: {
    data: {},
    isLoading: false,
    error: ''
  },
  create: {
    data: {},
    isLoading: false,
    error: ''
  },
  update: {
    data: {},
    isLoading: false,
    error: ''
  }

}



export const TenantSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher<ReturnType<AsyncThunk<TenantResponse, unknown, {}>['fulfilled']>>(
      (action) => tenantApi.endpoints.getTenants.matchFulfilled(action),
      (state, { payload }) => {
        state.list.data = payload.data;
        // state.list.meta = payload.meta;
      },
    );
    //fetchCustomers
    // [fetchTenants.fulfilled.type]: (state, action: PayloadAction<{data:ITenant[], meta:any}>) => {
    //   state.list.isLoading = false;
    //   state.list.error = '';
    //   state.list.hasNextPage = !!action.payload.data.length;
    //   const last = action.payload.meta?.last;
    //   const pages = state.list.pages;
    //   // console.log('slice', 'pages', pages.join(','), 'last', last)
    //   if(last) {
    //     // console.log('last true')
    //     if(!pages.some( item => String(item) === String(last))) {
    //       // console.log('1')
    //       state.list.data = [...state.list.data, ...action.payload.data];
    //     }
    //     state.list.pages = [ ...pages, last ];
    //   } else {
    //     // console.log('last false')
    //     state.list.data = action.payload.data;
    //     state.list.pages = [];
    //   }
    // },
    // [fetchTenants.pending.type]: (state) => {
    //   state.list.isLoading = true;
    // },
    // [fetchTenants.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.list.isLoading = false;
    //   state.list.error = action.payload;
    //   state.list.hasNextPage = false;
    // },
    //
    // [fetchTenant.fulfilled.type]: (state, action: PayloadAction<ITenant>) => {
    //   state.one.isLoading = false;
    //   state.one.error = '';
    //   state.one.data = action.payload;
    // },
    // [fetchTenant.pending.type]: (state) => {
    //   state.one.isLoading = true;
    // },
    // [fetchTenant.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.one.isLoading = false;
    //   state.one.error = action.payload;
    // },
    //
    // [createTenant.fulfilled.type]: (state, action: PayloadAction<ITenant>) => {
    //   state.one.isLoading = false;
    //   state.one.error = '';
    //   state.one.data = action.payload;
    // },
    // [createTenant.pending.type]: (state) => {
    //   state.one.isLoading = true;
    // },
    // [createTenant.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.one.isLoading = false;
    //   state.one.error = action.payload;
    // },
    //
    // [updateTenant.fulfilled.type]: (state, action: PayloadAction<ITenant>) => {
    //   state.update.isLoading = false;
    //   state.update.error = '';
    //   state.update.data = action.payload;
    //   state.one.data = action.payload;
    // },
    // [updateTenant.pending.type]: (state) => {
    //   state.update.isLoading = true;
    // },
    // [updateTenant.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.update.isLoading = false;
    //   state.update.error = action.payload;
    // },

  }
})

export default TenantSlice.reducer;