import * as React from 'react';
import {useEffect, useState} from 'react';
import Input from "../../../components/ui/Input/Input";
import SettingsPage from "../SettingsPage";
import {useDelTenantInfoMutation, useGetTenantInfoQuery, useUpdateTenantInfoMutation} from "../../../api/tenant";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import Button from "../../../components/ui/buttons/Button";
import {useNotification} from "../../../hooks/notification";
import NotificationError from "../../../components/ui/notifications/tmpl/NotificationError";
import {useDialog} from "../../../hooks/dialog";
import DialogQuestionDanger from "../../../components/ui/dialogs/tmpl/DialogQuestionDanger";
import DialogSuccess from "../../../components/ui/dialogs/tmpl/DialogSuccess";
import {useChangeTenantMutation} from "../../../api/auth";
import {ClientError} from "../../../api/util/fetchBaseQueryWithReauth";
import {logoutToServer} from "../../../store/reducers/AuthSlice";

const SettingsTenant: React.FC = () => {
  const dispatch = useAppDispatch();
  const notify = useNotification()
  const dialog = useDialog()
  const { data: userData} = useAppSelector((state) => state.auth);
  const { data } = useGetTenantInfoQuery({ id: userData.Tenant._id })
  const [updateTenant, { isLoading: isLoadingUpdate }] = useUpdateTenantInfoMutation()
  const [doChangeTenant] = useChangeTenantMutation();
  const [delTenant, { data: dataDel, isLoading: isLoadingDel , isSuccess: isSuccessDel, isError: isErrorDel, error: errorDel}] = useDelTenantInfoMutation()
  const [dataForm, setDataForm] = useState<any>({
    name: '',
    phone: '',
    address: '',
  })

  useEffect(() => {
    if(data) {
      setDataForm({
        name: data?.name ?? '',
        phone: data?.phone ?? '',
        address: data?.address ?? '',
      })
    }

  }, [data])

  const saveSettings = () => {
    updateTenant({ id: userData.Tenant._id, body: dataForm })
  }

  useEffect(() => {
    if(isSuccessDel) {
      dialog.show(<DialogSuccess title={'Действие выполнено!'} text={'Организация успешно удалена'} buttonTextOk='Отлично' />).then(
        () => {
          if(dataDel?._id) {
            doChangeTenant({TenantID: dataDel._id as unknown as string}).then(
              () => {
                window.location.href = '/products'
              }
            )
          } else {
            dispatch(logoutToServer(userData.userId))
          }
        }
      ).catch(() => { // если нажали на крестик закрытия
        if(dataDel?._id) {
          doChangeTenant({TenantID: dataDel._id as unknown as string}).then(
            () => {
              window.location.href = '/products'
            }
          )
        } else {
          dispatch(logoutToServer(userData.userId))
        }
      })
    }
  }, [isSuccessDel]);

  useEffect(() => {
    if(isErrorDel) {
      const isClientError = (error: any): error is ClientError => 'data' in error
      const message = errorDel && isClientError(errorDel) ? errorDel?.data.message : 'При удалении организации произошла ошибка'
      notify.show(<NotificationError text={message} />)
    }
  }, [isErrorDel]);

  const doDel = () => {
    dialog.show(<DialogQuestionDanger title={'Подтверждение'} text={'Вы действительно хотите удалить текущую организацию? Это действие отменить не получится'} buttonTextOk='Удалить' />).then(() => delTenant(userData.Tenant._id))
  }

  return (
    <>
      <SettingsPage>
        <div className={'flex-col w-full max-w-4xl'}>
          <div className='flex-col space-y-3 w-full'>
            <Input label={'Название'} value={dataForm.name} onChange={(e) => setDataForm({ ...dataForm, name: e.target.value})} />
            <Input label={'Телефон'} value={dataForm.phone} onChange={(e) => setDataForm({ ...dataForm, phone: e.target.value})} />
            <Input label={'Адрес'} value={dataForm.address} onChange={(e) => setDataForm({ ...dataForm, address: e.target.value})} />
            <div className="mt-4 flex items-center justify-end">
              <Button onClick={saveSettings} text='Сохранить' isLoading={isLoadingUpdate}/>
            </div>
          </div>

          <div className={'mt-24'}>
            <div className="px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Опасные настройки</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Данные настройки могут привести к деструктивным действия: удалению/переносу информации,
                невозможности отменить или вернуть данные. Вы должны понимать, что делаете.</p>
            </div>
            <div className="mt-4 border border-red-500 rounded-md p-3">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Удаление организации</dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                    <span className="flex-grow">Это приведёт к удалению всей информации связанной с текущей организацией:
                    все личные данные, товары, бренды и т.д.</span>
                    <span className="ml-4 flex-shrink-0">
                      <Button onClick={doDel} text='Удалить организацию' isLoading={isLoadingDel} style={'DANGER'} isError={isErrorDel} textLoading='Удаление организации...' />
                    </span>
                  </dd>
                </div>


              </dl>
            </div>
          </div>
        </div>

      </SettingsPage>
    </>
  );
};

export default SettingsTenant;