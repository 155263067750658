import React, {useMemo, useState} from 'react';
import './App.css';
import Main from "./components/Main";
import Html from "./components/Html";
import Header from "./components/Header";
import AppRouter from "./components/AppRouter";

import { useAppSelector } from './hooks/redux';
import {store} from "./index";
import Dialogs from "./components/ui/dialogs/Dialogs";
import Sidebar from "./components/Sidebar";
import Header2 from "./components/Header2";
import Notifications from "./components/ui/notifications/Notifications";

import { useRoutes } from 'react-router-dom';
import RouterPublic from './pages/routerPublic';

import { useRefreshTokenQuery } from './api/auth';
import Tooltips from "./components/ui/tooltips/Tooltips";
import Slides from "./components/ui/slide/Slides";
import ContextMenu from "./components/ui/contextMenu/ContextMenu";
import ContextGlobal from "./components/ui/contextMenu/ContextGlobal";
export const AppContext = React.createContext<any>(null);
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  const { isFetching } = useRefreshTokenQuery();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarMini, setSidebarMini] = useState(true);

  const auth = useAppSelector((state) => state.auth);
  const routes = useRoutes(RouterPublic);
  const appRef = React.useRef<any>()

  const tenants = ['64c3b762e766e8d858cf1d4c']
  const settings = useMemo(() => {
    const settings = {
      view1: auth?.data?.Tenant ? tenants.includes(auth.data.Tenant._id) : false
    }
    return settings

  }, [auth])

  return (
    !isFetching && auth.data.accessToken === '' ? routes :
    // <div className="App h-full overflow-auto 2xl:overflow-auto" ref={appRef}>
    <div className="App h-full overflow-auto 2xl:overflow-auto" ref={appRef}>
      {!auth.data.accessToken ? 'Загрузка...' :
        <>
          <AppContext.Provider value={{appRef, settings}} >
            <Sidebar sidebarOpen={sidebarOpen} onClose={setSidebarOpen} sidebarMini={sidebarMini} onMini={setSidebarMini} />
            {/*<div className={classNames(sidebarMini ? "md:pl-16" : "md:pl-80", "flex flex-col min-h-screen")}>*/}
            <div className='flex flex-col flex-1 h-full'>
              <Header2 setSidebarOpen={setSidebarOpen} />
              <Main>
                <Notifications />
                <AppRouter />
                <Dialogs />
                <Tooltips />
                <Slides />
              </Main>
            </div>


            <ContextGlobal />
          </AppContext.Provider>
        </>
      }

    </div>
  );
}

export default App;
