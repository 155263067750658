import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {XMarkIcon} from "@heroicons/react/20/solid";
import Input, {InputProps} from "../Input";
import {formatCurrencyView, formatNumber, formatNumberToServer} from "../../../../utils/helpers";

type InputNumberProps = Omit<InputProps, 'onChange'|'onBlur'> & {
  fractionDigits: number // кол-во цифр после запятой
  onChange?: (value: {value: number, format: string}) => void
  onBlur?: (value: {value: number, format: string}) => void
}

const InputNumber: React.FC<InputNumberProps> = forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {

  return (
    <Input {...props}
           onChange={ (e) => props.onChange ? props.onChange({value: formatNumber(e.target.value, props.fractionDigits).number, format: e.target.value}) : null }
           onBlur={ (e) => props.onBlur ? props.onBlur({value: formatNumber(e.target.value, props.fractionDigits).number, format: formatNumber(e.target.value, props.fractionDigits).string }) : null }
           ref={ref}
    />
  );
});

export default InputNumber;
