import React, {useCallback, useEffect, useState} from 'react';

type Props = {
  onClick?: () => void | Promise<any>
  text?: string
  isLoading?: boolean
  textLoading?: string
  isDisabled?: boolean
  style?: string
  isError?: boolean
  iconResolve?: boolean
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const generalClasses = 'flex w-full justify-center rounded-md border  text-base whitespace-nowrap font-medium shadow-sm disabled:bg-slate-100 disabled:text-slate-400 disabled:pointer-events-none sm:w-auto sm:text-sm'

const styleData: any = {
  PRIMARY: {
    classMain: classNames(generalClasses, 'px-4 py-2 bg-sky-600 text-white border-transparent hover:bg-sky-500'),
    classResolve: 'text-white w-5 h-5 -ml-0.5 mr-1.5 -mt-1 self-center',
    classReject: 'text-red-200 w-5 h-5 -ml-0.5 mr-1.5 -mt-0.5 self-center'
  },
  SECONDARY: {
    classMain: classNames(generalClasses, 'px-4 py-2 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white text-gray-700 shadow-sm hover:text-gray-500 disabled:text-gray-700 disabled:opacity-75 sm:w-auto sm:text-sm'),
    classResolve: 'text-green-500 w-5 h-5 -ml-0.5 mr-1.5 -mt-1 self-center',
    classReject: 'text-red-500 w-5 h-5 -ml-0.5 mr-1.5 -mt-0.5 self-center'
  },
  TERTIARY: {
    classMain: classNames(generalClasses, 'px-4 py-2 bg-white text-slate-700 border-slate-300 hover:bg-slate-50 disabled:border-transparent'),
    classResolve: 'text-green-500 w-5 h-5 -ml-0.5 mr-1.5 -mt-1 self-center',
    classReject: 'text-red-500 w-5 h-5 -ml-0.5 mr-1.5 -mt-0.5 self-center'
  },
  SUCCESS: {
    classMain: classNames(generalClasses, 'px-4 py-2 bg-green-600 text-white border-transparent hover:bg-green-500'),
    classResolve: 'text-white w-5 h-5 -ml-0.5 mr-1.5 -mt-1 self-center',
    classReject: 'text-red-200 w-5 h-5 -ml-0.5 mr-1.5 -mt-0.5 self-center'
  },
  DANGER: {
    classMain: 'px-4 py-2 inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-white shadow-sm hover:bg-red-700 disabled:bg-red-600 disabled:opacity-75 sm:w-auto sm:text-sm',
    classResolve: '',
    classReject: 'text-white w-5 h-5 -ml-0.5 mr-1.5 -mt-0.5 self-center'
  },
  WARNING:'',
  INFO:'',
  LIGHT:'',
  DARK:'',
  SECONDARY_SMALL: {
    classMain: classNames(generalClasses, 'px-4 py-1.5 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white text-gray-700 shadow-sm hover:text-gray-500 disabled:text-gray-700 disabled:opacity-75 sm:w-auto sm:text-xs'),
    classResolve: 'text-green-500 w-5 h-5 -ml-0.5 mr-1.5 -mt-1 self-center',
    classReject: 'text-red-500 w-5 h-5 -ml-0.5 mr-1.5 -mt-0.5 self-center'
  },
}

const Button: React.FC<Props> = ({ onClick = () => {}, text = '???' , isLoading = false, textLoading = '', isDisabled= false, style= 'PRIMARY', isError = false, iconResolve = true}) => {
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingPromise, setIsLoadingPromise] = useState(false);
  const [resultLoading, setResultLoading] = useState('');

  const on = () => {
    const handler = onClick();

    if(handler instanceof Promise) {
      setIsLoadingPromise(true);
      handler.then(
        (res) => {
          setIsLoadingPromise(false)
          if(res?.error) {
           toReject()
          } else {
            toResolve()
          }
        },
        () => {
          setIsLoadingPromise(false);
          toReject()
        }
      )
    }
  }

  useEffect(() => {
    if(isError) {
      toReject()
    }
  }, [isError]);

  const toResolve = () => {
    setResultLoading('resolve');
    setTimeout(() => {
      setResultLoading('');
    }, 3000)
  }

  const toReject = () => {
    setResultLoading('reject');
    setTimeout(() => {
      setResultLoading('');
    }, 5000)
  }

  useEffect(() => {
    setIsLoadingAll(isLoading || isLoadingPromise);
  }, [isLoading, isLoadingPromise])

  return (
      <>
        <button
          className={classNames(styleData[style].classMain, isLoadingAll ? 'opacity-50 pointer-events-none' : '')}
          onClick={ (e) => { e.stopPropagation(); e.preventDefault(); on() } }
          disabled={ isDisabled } >

          { resultLoading === 'resolve' && iconResolve ?
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={styleData[style].classResolve}>
              <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
            </svg> : ''
          }

          { resultLoading === 'reject' ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={styleData[style].classReject}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
            : ''
          }


          { isLoadingAll ?
            <svg className="animate-spin h-4 w-4 mr-2 -mt-0.5 self-center" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" fillOpacity="0%" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg> : '' }

          { isLoadingAll ? textLoading ? textLoading : text : text }


        </button>
      </>
  );
};

export default Button;