import * as React from 'react';
import {Cell, flexRender, Row} from "@tanstack/react-table";
import {useTooltip} from "../../../../../hooks/tootip";
import {TooltipPlacement} from "../../../tooltips/TooltipPanel";
import {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from "react";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {CheckIcon} from "@heroicons/react/20/solid";
import {LockClosedIcon} from "@heroicons/react/24/solid";


type Props = {
  cell: Cell<any, unknown> | undefined
  widthBodyRef?: any
  widthHeaderRef?: any
  ref: any
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const CellRowNumber: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { cell , widthBodyRef, widthHeaderRef} = props;
  const tooltip = useTooltip()
  const ref2 = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<any>({})

  useLayoutEffect(() => {
    if(cell) {
      // console.log('useLayoutEffect CellRowNumber', cell)
      const id = cell.column.id
      const width = ref2.current?.offsetWidth ? ref2.current?.offsetWidth : 0
      if(widthBodyRef.current[id]) {
        if(widthBodyRef.current[id] < width) {
          // console.log('useLayoutEffect CellSimple', widthBodyRef.current[id], width)
          widthBodyRef.current = {...widthBodyRef.current, [id]: width }
        }
      } else {
        widthBodyRef.current = { ...widthBodyRef.current, [id]: width }
      }
    }

    const h = cell && widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
    const b = cell && widthBodyRef.current[cell.column.id]
    const s = h > b ? h : b
    // console.log('useEffect', cell.column.id, widthBodyRef.current, ext)
    setStyle({
      minWidth: s,
      maxWidth: s
    })

  },[])

  useEffect(() => {
    if(cell) {
      const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
      const b = widthBodyRef.current[cell.column.id]
      const s = h > b ? h : b
      // console.log('useEffect', cell.column.id, widthBodyRef.current, ext)
      setStyle({
        minWidth: s,
        maxWidth: s
      })
    }
  }, [widthBodyRef.current])

  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle', cell?.column.id, ref2.current?.offsetWidth)
    return {
      offsetWidth: ref2.current?.offsetWidth,
    } as HTMLDivElement | any
  }, [])

  return (
    <>

      {cell &&
        <div ref={ref2} key={cell.id}
             className='flex gap-3 text-sm pl-1 pr-1 items-center justify-start h-full text-slate-400 overflow-hidden'
             {...{ style: {
                 ...style
               } }}
        >
          {cell.row.original?.isOnline ?
            <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400"
                 onMouseEnter={ (event: any) => { tooltip.show('Online', event.currentTarget, TooltipPlacement.TOP) } }
                 onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }>
              <div className="h-2 w-2 rounded-full bg-current" />
            </div> :
           ''
          }
          {cell.row.original?.move === true || cell.row.original?.move === 1 ?
            <div className="flex-none -mr-1 text-green-600/70"
                 onMouseEnter={ (event: any) => { tooltip.show('Проведён', event.currentTarget, TooltipPlacement.TOP) } }
                 onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }>
              {/*<div className="h-2 w-2 rounded-full bg-current" />*/}
              <LockClosedIcon className={'h-5 w-5'} />
            </div> :
            ''
          }
          {cell.row.original?.move === 2 ?
            <div className="flex-none -mr-1 text-amber-600/70"
                 onMouseEnter={ (event: any) => { tooltip.show('Проведён частично', event.currentTarget, TooltipPlacement.TOP) } }
                 onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }>
              {/*<div className="h-2 w-2 rounded-full bg-current" />*/}
              <LockClosedIcon className={'h-5 w-5'} />
            </div> :
            ''
          }
          {cell.row.original?.isOnline || cell.row.original?.move ? '' :
            <div className="flex-none rounded-full p-1 opacity-0">
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
          }
          {/*{flexRender(cell.column.columnDef.cell, cell.getContext())}*/}
          {cell.row.index+1}

        </div>
      }
    </>
  );
})

export default CellRowNumber;