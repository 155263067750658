import React, {forwardRef, Fragment, useId, useImperativeHandle, useRef} from 'react';
import {XMarkIcon} from "@heroicons/react/20/solid";
import Button from "../../buttons/Button";
import {useTooltip} from "../../../../hooks/tootip";
import {TooltipPlacement} from "../../tooltips/TooltipPanel";

export type InputProps = {
  label?: string
  value?: string | number | undefined
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  onFocus?: (refInput: React.FocusEvent<HTMLInputElement>) => void
  onClean?: (refInput: React.MutableRefObject<HTMLInputElement>) => void
  ref?: React.ForwardedRef<HTMLInputElement>
  isError?: boolean
  errorText?: string
  helpText?: string
  type?: string
  isCleanerX?: boolean
  disabled?: boolean
  onClickReplacement?: () => void
  product_data: any
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const BrandReplacement: React.FC<{data: any}> = ({data}) => {
  const tooltip = useTooltip();
  return (
    <Fragment>
      <span className={classNames('cursor-default rounded-full  px-2 text-sm leading-5 ',
        data && Array.isArray(data) && data.length > 0 ? 'bg-red-300 text-gray-50 hover:bg-red-400 hover:text-white' : 'text-gray-300'
      )}
            onMouseEnter={ (event: any) => { tooltip.show('Аналоги производителей', event.currentTarget, TooltipPlacement.TOP) } }
            onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
            // onClick={ (event: any) => { event.stopPropagation(); tooltip.close(event.currentTarget); toOnlyRootCategory(item._id) } }
      >
        {data && Array.isArray(data) ?
          data.length : 0
        }
      </span>
    </Fragment>)
}

const OriginalReplacement: React.FC<{data: any}> = ({data}) => {
  const tooltip = useTooltip();
  return (
    <Fragment>
      <span className={classNames('cursor-default rounded-full px-2 text-sm leading-5',
        data && Array.isArray(data) && data.length > 0 ? 'bg-yellow-300 text-gray-500 hover:bg-yellow-400 hover:text-gray-50' : 'text-gray-300'
      )}
            onMouseEnter={ (event: any) => { tooltip.show('Оригинальные замены', event.currentTarget, TooltipPlacement.TOP) } }
            onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
        // onClick={ (event: any) => { event.stopPropagation(); tooltip.close(event.currentTarget); toOnlyRootCategory(item._id) } }
      >
        {data && Array.isArray(data) ?
          data.length : 0
        }
      </span>
    </Fragment>)
}

const NumberReplacement: React.FC<{data: any}> = ({data}) => {
  const tooltip = useTooltip();
  return (
    <Fragment>
      <span className={classNames('cursor-default rounded-full px-2 text-sm leading-5',
        data && Array.isArray(data) && data.length > 0 ? 'bg-green-300 text-gray-500 hover:bg-green-400 hover:text-gray-50' : 'text-gray-300'
      )}
            onMouseEnter={ (event: any) => { tooltip.show('Замены номера', event.currentTarget, TooltipPlacement.TOP) } }
            onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
        // onClick={ (event: any) => { event.stopPropagation(); tooltip.close(event.currentTarget); toOnlyRootCategory(item._id) } }
      >
        {data && Array.isArray(data) ?
          data.length : 0
        }
      </span>
    </Fragment>)
}

const InputProductNumber: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>((
  { label,
    onChange,
    onBlur,
    onFocus,
    onClean,
    value,
    placeholder,
    onClick,
    isError,
    errorText,
    helpText,
    type,
    isCleanerX = true,
    disabled = false,
    onClickReplacement,
    product_data
  }, ref) => {
  const inputRef = useRef<any>(null)
  const id = useId()

  // раскрываем внешнему ref только нужные методы
  useImperativeHandle(ref, () => {
    return {
      focus() {
        inputRef.current.focus()
      },
      select() {
        inputRef.current.select()
      }
    } as HTMLInputElement
  }, [])

  const cleanInput = () => {
    if(onClean) onClean(inputRef)
    setTimeout(() => {
      inputRef.current.select()
    }, 50)
  }
  return (
      <div className='w-full'>
          {label && <label htmlFor={id} className={classNames("block text-sm font-medium", disabled ? 'text-slate-300' : 'text-gray-700')}>{ label }</label>}
        <div className={ classNames("relative rounded-md shadow-sm",
          label ? 'mt-1' : '',
          isError ? 'border border-rose-400' : disabled ? 'border border-slate-100' : 'border border-slate-300',
          )}>
          <input
            id={id}
            name={id}
            type={type ?? 'text'}
            placeholder={placeholder}
            className={
              classNames(
                "block w-full h-9.5 rounded-md border-0 text-sm  py-2 pl-3 placeholder:text-slate-400 focus:ring-0 focus:outline-none focus:border-sky-500 bg-white",
                isCleanerX ? 'pr-44' : 'pr-36',
                disabled ? 'text-slate-300' : 'text-slate-900',
              )}
            value={ value }
            ref={ inputRef }
            onChange={(e) => onChange ? onChange(e) : null }
            onClick={onClick}
            onBlur={(e) => onBlur ? onBlur(e) : null}
            onFocus={(e) => onFocus ? onFocus(e): null}
            disabled={disabled}
          />
          {isCleanerX &&
            <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-36" onClick={(e) => cleanInput()}>
              <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
            </div>
          }
          <div className={'absolute inset-y-0 right-0.5 flex items-center'}>
            <Button text={'Аналоги/Замены'} onClick={onClickReplacement} style={'SECONDARY_SMALL'} isDisabled={!onClickReplacement} />
          </div>
        </div>

        <div className={classNames('flex justify-end line-clamp-6 text-xs mt-0.5 -mb-5', disabled ? 'text-slate-300' : 'text-slate-500')}>
          <BrandReplacement data={product_data?.brand_replacement} />
          &nbsp; &nbsp;
          <OriginalReplacement data={product_data?.original_replacement} />
          &nbsp; &nbsp;
          <NumberReplacement data={product_data?.number_replacement} />
        </div>

        { helpText && <div className={classNames('line-clamp-6 text-xs', disabled ? 'text-slate-300' : 'text-slate-500')}>{helpText}</div> }
        { isError && <div className='text-rose-400 line-clamp-6 text-xs'>{errorText}</div> }


      </div>
  );
});

export default InputProductNumber;
