import * as React from 'react';
import {useEffect, useState} from 'react';

type Props = {
  children: React.ReactComponentElement<any>
  selected: { all: boolean, count: number, include: any[], exclude: any[] }
  className?: string
  cleanSelected?: () => void
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const ActionGroupIndex: React.FC<Props> = (props: Props) => {
  const { children, selected } = props;
  const [actionGroup, setActionGroup] = useState<React.ReactComponentElement<any>>()

  useEffect(() => {
    if(children) {
      setActionGroup(() => React.createElement(children.type, {...children.props, _selected: selected, cleanSelected: props.cleanSelected } ))
    }
    // console.log(3, actionGroup)
  }, [selected])


  return (
    <>
      {actionGroup && (actionGroup.props._selected.count) ?
        <div className={classNames('border border-slate-600 text-slate-700 px-5 py-3 rounded bg-slate-200/50 backdrop-blur-sm backdrop-opacity-90', props.className)}>
          {actionGroup}
        </div>
        : ''}
    </>
  );
};

export default ActionGroupIndex;