import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Listbox} from "@headlessui/react";
import {CheckIcon} from "@heroicons/react/20/solid";
import {createColumnHelper, flexRender, getCoreRowModel, Row, useReactTable} from "@tanstack/react-table";
import {useVirtualizer} from "@tanstack/react-virtual";

type Props = {
  children?: React.ReactNode
  param?: string
  label?: string
  data?: any
  isLoading?: any
  getData?: (arg0: any) => void
  hasNextPage?: any
  optionKey: string
  size: string | number
  sort: string
  filter: string
  onClickAdd?: () => void
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const SelectOptionsConstantHeight: React.FC<Props> = (props: Props) => {
  const {
    label,
    data: allRows,
    isLoading: isFetchingNextPage,
    getData,
    hasNextPage,
    optionKey,
    size,
    sort,
    filter,
    onClickAdd = () => {}
  } = props;


  const tableRef = React.useRef<any>()
  const parentRef = React.useRef<any>()
  const optionsRef = React.useRef<any>()
  const listboxOption = React.useRef<any>()
  const columnHelper = createColumnHelper<any>()
  const columns = [
    columnHelper.accessor('option', {
      cell: info => info.getValue()[optionKey],
    }),
  ]
  const [last, setLast] = useState<string>()

  const table = useReactTable({
    data: allRows ? allRows : [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  })
  const { rows } = table.getRowModel(); // строки таблицы

  const [overscan, setOverscan] = useState(50)
  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length, // +1 - отображение строки загрузки
    getScrollElement: () => parentRef.current,
    estimateSize: () => 36, // высота строки
    overscan: overscan, // На сколько больше строк вверх вниз отображать от области видимости, дальше уже будут идти виртуальные строки
  })

  useEffect(() => {
    if (getData) {
      // console.log(777, last, filter, size, sort)
      getData({last, filter, size, sort});
    }
  }, [last])

  useEffect(() => {
    if (getData) {
      // console.log(7772, last, filter, size, sort)
      getData({last: '', filter, size, sort});
    }
  }, [filter])

  useEffect(() => {
    // @ts-ignore
    rowVirtualizer.calculateRange();
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()
    // console.log('lastItem', lastItem, rowVirtualizer.getVirtualItems())

    if (!lastItem) {
      return
    }
    let isLoadRow = lastItem.index + 1 >= allRows.length; // индикация, что есть строка загрузки: true - есть, false - нет строки значит ничего не нужно загружать

    // console.log('useEffect SELECT', 'isLoadRow', isLoadRow, lastItem.index, allRows.length, 'isFetchingNextPage', isFetchingNextPage, 'hasNextPage', hasNextPage, 'rowVirtualizer', rowVirtualizer.getVirtualItems().length)

    if (isLoadRow && hasNextPage && !isFetchingNextPage) {
      let lastData = allRows[allRows.length-1].option; // получаем последнюю строку данных, строку загрузки пропускаем
      // console.log('---------', lastData)
      let last = '';
      if(lastData) {
        last = lastData[sort]; // берем дату последней строки, чтобы по ней загрузить следующие данные
      }
      // dispatch(fetchOrders({last})) // загружаем новые данные - isFetchingNextPage поменяется на true
      if (getData) {
        setLast(last)
      }
    }
  }, [
    hasNextPage,
    allRows.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ])

  const [rn, setRn] = useState<any>('aria-disabled')
  const onScroll = useCallback(async () => {
    setRn('aria-disabled')
    // const arr = parentRef.current.children[0].children;
    // for (const a of arr) {
    //   const li = a.children[0];
    //   if(li.dataset.headlessuiState) {
    //     // console.log('-----------scroll', li)
    //     // setDis(true)
    //     // setTimeout(() => {
    //     //   setDis(false)
    //     // }, 500)
    //     // li.dataset.headlessuiState = 'disabled'
    //     // li.disable = true
    //     // li.blur()
    //     // li.classList.toggle('hidden')
    //     // li.className('hidden')
    //     // li.setAttribute('aria-selected', '123')
    //     // li.setAttribute('disable', 'true')
    //     // li['area-selected'] = '344'
    //     // li.click()
    //     // setOverscan(-1)
    //     // let opts = {view: window, bubbles: true, cancelable: true, buttons: 1};
    //     // li.dispatchEvent(new MouseEvent("keydown", opts));
    //
    //     // document.dispatchEvent(new KeyboardEvent('keydown', {'key': 'ArrowDown'}));
    //     // await new Promise(r => setTimeout(r, 50));
    //     // li.dispatchEvent(new MouseEvent("mouseup", opts));
    //     // li.dispatchEvent(new MouseEvent("click"));
    //     // li.dispatchEvent(new Event('mouseleave', opts));
    //     // li.dispatchEvent(new Event('mouseup', opts));
    //
    //     // setRn('li')
    //     // setTimeout(() => {
    //     //   setRn(undefined)
    //     // }, 3000)
    //
    //     // setDis(true)
    //   } else {
    //     // setOverscan(5)
    //   }
    // }
  }, [parentRef.current?.scrollHeight]);
  useEffect(() => {
    if(rowVirtualizer.getVirtualItems().length == 0) return
    window.requestAnimationFrame(() => {
      onScroll();
      parentRef.current.addEventListener("scroll", onScroll);
    })
    const ref = parentRef.current;
    return () => ref.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const escFunction = useCallback((event: any) => {

    console.log('key event' )
    if (event.key === "ArrowDown" || event.key === 'ArrowUp') {
      console.log('key', event.key)
      setRn(undefined)
      // setDis(false)
    }
  }, []);

  useEffect(() => {

    window.requestAnimationFrame(() => {
      // const s: any = document.getElementById('headlessui-portal-root')
      // s.addEventListener('keydown', escFunction, true)
      // optionsRef.current.onkeydown = escFunction
      // console.log(555, optionsRef)
      // console.log(optionsRef.current)
      // parentRef.current.addEventListener("keydown", escFunction, false);
      document.addEventListener("keydown", escFunction, true);
      // document.addEventListener("keydown", escFunction, false);
      // document.addEventListener('keydown', function(e: any) {
      //   console.log("event", e);
      // })
    })

    return () => {
      document.removeEventListener("keydown", escFunction, true);
    };
  }, []);

  return (
    <>
      {rowVirtualizer.getVirtualItems().length ?
        <div ref={parentRef} className='max-h-60 overflow-auto '
             {...{
               style: {
                 height: rowVirtualizer.getTotalSize(),
               }
             }}
        >

          <div /*tbody*/
            ref={optionsRef}
            {...{
              className: 'relative',
              style: {
                height: rowVirtualizer.getTotalSize(),
                // width: table.getTotalSize(),
              }
            }}
          >

            {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
              const isLoaderRow = virtualRow.index + 1 > rows.length
              const post = rows[virtualRow.index] as Row<any>
              // console.log('post', post)
              return (
                <div /*tr*/
                  // className='hover:bg-sky-700 hover:text-white cursor-pointer'

                  {...{
                    key: virtualRow.index,
                    style: {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }
                  }}
                  // className={classNames('flex justify-between', index>0 && 'border border-t-slate-200 border-x-0 border-b-0')}
                  // onClick={() => onClickRow(post)}
                  // onDoubleClick={() => onDoubleClickRow(post)}
                >
                  {isLoaderRow
                    ? hasNextPage
                      ? <div className="whitespace-nowrap py-2 pl-2 pr-2 font-medium text-gray-900 sm:pl-2">Загрузка...</div>
                      : <div>Больше ничего нет</div>
                    :
                    <>
                      {/*<div className='flex items-center min-w-0'>*/}

                      {post.getVisibleCells().map((cell, index) => {
                        // console.log(222, cell, cell.getValue(),cell.getContext())
                        const optionObject: any = cell.getValue()
                        let optionName = optionObject.title_rus;
                        return <Listbox.Option
                          key={index}
                          as='li'
                          className={({active}) =>
                            classNames(
                              active ? 'bg-sky-700 text-white' : 'text-gray-900',
                              'relative select-none py-2 pl-3 pr-9 cursor-pointer w-full flex '
                            )
                          }
                          value={optionObject}
                          // onMouseLeave={ (event: any) => { console.log('onMouseLeave');  } }
                          // disabled={dis}
                          refName={rn}
                          // onKeyDown={() => console.log(77777777)}
                        >
                          {({selected, active}) => {
                            // console.log(5555585, selected, active)
                            // active = false
                            // if(active) {
                            //   setRn('li')
                            // } else {
                            //   setRn(undefined)
                            // }
                            return (<>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                              {/*{optionName}*/}
                                            </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                              </span>
                              ) : null}
                            </>)
                          }}
                        </Listbox.Option>

                      })}

                      {/*</div>*/}
                    </>
                  }
                </div>
              )
            })}
          </div>
        </div>
        :
        <div className='flex flex-col items-center justify-center h-28'>
          <div className='text-slate-400'>Пусто</div>
          <div className={'text-sky-500 underline decoration-dashed mt-4 cursor-pointer'} onClick={onClickAdd}>Добавить</div>
        </div>
      }



    </>
  );
};

export default SelectOptionsConstantHeight;