import OrdersPage from "./OrdersPage";
import OrderPage, {OrderPageBreadcrumb, OrderPageTitle} from "./OrderPage";
import {Navigate} from "react-router-dom";
import LogoutPage from "./auth/LogoutPage";
import Profile from "./ProfilePage";
import MoreResultLoadPage from "./abcp/MoreResultLoadPage";
import CustomerPage, {CustomerPageBreadcrumb, CustomerPageTitle} from "./CustomerPage";
import HomePage from "./HomePage";
import TenantsPage from "./tenant/TenantsPage";
import TenantPage, {TenantPageBreadcrumb, TenantPageTitle} from "./tenant/TenantPage";
import ProductsPage from "./ProductsPage";
import BrandsPage from "./brands/BrandsPage";
import SettingsPage from "./settings/SettingsPage";
import SettingsTenant from "./settings/tmpl/SettingsTenant";
import SettingsUserPage from "./settings/tmpl/SettingsUserPage";
import EmployeesPage from "./employees/EmployeesPage";
import ABCPOrdersPage from "./abcp/ABCPOrdersPage";
import DocOurOrders from "./doc/DocOurOrders";
import DocOurOrder, {DocOurOrderPageBreadcrumb, DocOurOrderPageTitle} from "./doc/DocOurOrder";
import SuppliersPage from "./suppliers/SuppliersPage";
import CustomersPage from "./customers/CustomersPage";
import DocCustomerOrders from "./doc/DocCustomerOrders";
import DocCustomerOrder, {DocCustomerOrderPageBreadcrumb, DocCustomerOrderPageTitle} from "./doc/DocCustomerOrder";
import DocSupplierOrders from "./doc/DocSupplierOrders";
import DocSupplierOrder, {DocSupplierOrderPageBreadcrumb, DocSupplierOrderPageTitle} from "./doc/DocSupplierOrder";

const RouterProtected = [

  { path: '/', element: <Navigate to={'/products'} replace />, breadcrumb: 'Главная'},
  { path: 'doc-customer-orders', children: [
      { index: true, element: <DocCustomerOrders />, title: 'Заказы клиентов', breadcrumb: 'Заказы клиентов' },
      { path: ':id', element: <DocCustomerOrder />, title: DocCustomerOrderPageTitle, breadcrumb: DocCustomerOrderPageBreadcrumb }
    ]
  },
  { path: 'login', element: <Navigate to={'/products'} replace />, breadcrumb: ''},
  { path: 'logout', element: <LogoutPage />, breadcrumb: ''},
  { path: 'profile', element: <Profile />, title: 'Информация о пользователе', breadcrumb: 'Данные пользователя'},
  { path: 'settings', element: <SettingsPage />, title: 'Настройки', breadcrumb: 'Настройки'},
  { path: 'settings', children: [
      { index: true, element: <SettingsPage />, title: 'Настройки', breadcrumb: 'Настройки' },
      { path: 'tenant', element: <SettingsTenant />, title: 'Настройки организации', breadcrumb: 'Настройки организации' },
      { path: 'abcp', element: <SettingsUserPage />, title: 'Настройки ABCP', breadcrumb: 'Настройки ABCP' }
    ]
  },
  // { path: 'more-result-load-abcp', element: <MoreResultLoadPage />, breadcrumb: 'Настройки пользователя'},
  // { path: 'customers', children: [
  //     { index: true, element: <CustomersPage />, title: 'Клиенты', breadcrumb: 'Клиенты' },
  //     { path: ':id', element: <CustomerPage />, title: CustomerPageTitle, breadcrumb: CustomerPageBreadcrumb }
  //   ]
  // },
  { path: 'products', children: [
      { index: true, element: <ProductsPage />, title: 'Справочник Товары', breadcrumb: 'Товары' },
      { path: ':id', element: <CustomerPage />, title: CustomerPageTitle, breadcrumb: CustomerPageBreadcrumb }
    ]
  },
  { path: 'brands', children: [
      { index: true, element: <BrandsPage />, title: 'Справочник Бренды', breadcrumb: 'Бренды' },
      { path: ':id', element: <CustomerPage />, title: CustomerPageTitle, breadcrumb: CustomerPageBreadcrumb }
    ]
  },
  // { path: 'tenants', children: [
  //     { index: true, element: <TenantsPage />, title: 'Арендаторы', breadcrumb: 'Арендаторы' },
  //     { path: ':id', element: <TenantPage />, title: TenantPageTitle, breadcrumb: TenantPageBreadcrumb }
  //   ]
  // },
  { path: 'employees', children: [
      { index: true, element: <EmployeesPage />, title: 'Справочник Сотрудники', breadcrumb: 'Сотрудники' },
      { path: ':id', element: <TenantPage />, title: TenantPageTitle, breadcrumb: TenantPageBreadcrumb }
    ]
  },
  { path: 'abcp-orders', children: [
      { index: true, element: <ABCPOrdersPage />, title: 'ABCP Заказы', breadcrumb: 'ABCP Заказы' },
      // { path: ':id', element: <OrderPage />, title: OrderPageTitle, breadcrumb: OrderPageBreadcrumb }
    ]
  },
  { path: 'doc-our-orders', children: [
      { index: true, element: <DocOurOrders />, title: 'Наши заказы', breadcrumb: 'Наши заказы' },
      { path: ':id', element: <DocOurOrder />, title: DocOurOrderPageTitle, breadcrumb: DocOurOrderPageBreadcrumb }
    ]
  },
  { path: 'suppliers', children: [
      { index: true, element: <SuppliersPage />, title: 'Справочник Поставщики', breadcrumb: 'Поставщики' },
      { path: ':id', element: <CustomerPage />, title: CustomerPageTitle, breadcrumb: CustomerPageBreadcrumb }
    ]
  },
  { path: 'customers', children: [
      { index: true, element: <CustomersPage />, title: 'Справочник Клиенты', breadcrumb: 'Клиенты' },
      { path: ':id', element: <CustomerPage />, title: CustomerPageTitle, breadcrumb: CustomerPageBreadcrumb }
    ]
  },
  { path: 'doc-supplier-orders', children: [
      { index: true, element: <DocSupplierOrders />, title: 'Заказы поставщикам', breadcrumb: 'Заказы поставщикам' },
      { path: ':id', element: <DocSupplierOrder />, title: DocSupplierOrderPageTitle, breadcrumb: DocSupplierOrderPageBreadcrumb }
    ]
  },
]


export default RouterProtected;