import {useLazyGetProductsQuery} from "../api/product";
import {useGetCursor} from "./useGetCursor";

export const useGetProducts: any = () => {
  const [getProducts, obj, lastArgs] = useLazyGetProductsQuery()
  const abort = () => {
    console.log('abort()')
  }
  const [getData, {data, isFetching, isLoading, originalArgs, currentData, requestId, isError, error, status}] = useGetCursor(getProducts, obj, abort)

  return [getData, {data, isFetching, isLoading, originalArgs, currentData, requestId, isError, error, status }, lastArgs]
}