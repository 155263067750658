import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {ITenant} from "../models/ITenant";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";

export type TenantRequest = {
  page: number
}

export type TenantResponse = {
  data: ITenant[];
  meta: any
}

type CreateTenantRequest = {
  name: string
}

export type UpdateTenantRequest = {
  name: string
  phone: string
  address: string
}

export const tenantApi = createApi({
  reducerPath: 'tenantApi',
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/tenant` }),
  endpoints: (builder) => ({
    getTenants: builder.query<TenantResponse, TenantRequest>({
      query: (options) => {
        return {
          url: '/',
          method: 'GET',
          params: { page: options.page },
          credentials: 'include',
        }
      },
    }),
    getTenantInfo: builder.query<ITenant, {id: string}>({
      query: ({id}) => ({
        url: `/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      // providesTags: ['Products']
    }),
    addTenant: builder.mutation<ITenant, CreateTenantRequest>({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
      // providesTags: ['Products']
    }),
    updateTenantInfo: builder.mutation<ITenant, {id: string, body: UpdateTenantRequest}>({
      query: ({id, body}) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
        credentials: 'include',
      }),
      // providesTags: ['Products']
    }),
    delTenantInfo: builder.mutation<ITenant, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
    }),
  }),
});

export const {
  useGetTenantsQuery,
  useGetTenantInfoQuery,
  useUpdateTenantInfoMutation,
  useAddTenantMutation,
  useDelTenantInfoMutation,
} = tenantApi;