import axios from "axios";
import {store} from "../index";
import {setCurrentUser} from "../store/reducers/AuthSlice";


export const get = async (url: string, config?: any) => {
  const state: any = store.getState();
  try {
    console.log('get', state)
    // основной запрос
    const result = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${state?.auth?.data?.accessToken}`,
        ...config?.headers
      },
      withCredentials: true,
      ...config
    });

    return result?.data;
  } catch (e: any) {
    // если это истекший токен, делаем refresh токенов
    if(e.response.data.type === 'TokenError' && url !== `${process.env.REACT_APP_URL_API}/auth/log-out`) {
      try {
        // await Timeout.set(2000);
        // здесь на сервер отправляется кука refresh_token, по ней и идет обновление
        // если новый пользователь или её, тогда редирект на страницу login
        const response = await get(`${process.env.REACT_APP_URL_API}/auth/refresh-token`, { /*withCredentials: true*/ });
        let userData = {
          tenant: response.tenant,
          _id: response._id,
          accessToken: response.accessToken,
          memberTenants: response.memberTenants,
          Tenant: response.Tenant,
          login: response.login
        }
        // localStorage.setItem('user', JSON.stringify(userData));
        store.dispatch(setCurrentUser(userData)); // установка нового access-токена, refresh-токен пришел в cookies
        const reresponse: any = await get(url, {}); // повторяем неудавшийся запрос

        return reresponse;
      } catch (e: any) {
        console.log('Error', e)
        throw new Error(e)
      }
    } else {
      throw new Error(e)
    }
  }

}

export const post = async (url: string, params: {}, config?: any) => {
  const state: any = store.getState();
  try {
    console.log('get', state)
    // основной запрос
    const result = await axios.post(url, params, {
      headers: {
        'Authorization': `Bearer ${state?.auth?.data?.accessToken}`,
        ...config?.headers
      },
      withCredentials: true,
      ...config
    });

    return result?.data;
  } catch (e: any) {
    // если это истекший токен, делаем refresh токенов
    if(e.response.data.type === 'TokenError' && url !== `${process.env.REACT_APP_URL_API}/auth/log-out`) {
      try {
        // await Timeout.set(2000);
        // здесь на сервер отправляется кука refresh_token, по ней и идет обновление
        // если новый пользователь или её, тогда редирект на страницу login
        const response = await get(`${process.env.REACT_APP_URL_API}/auth/refresh-token`, { /*withCredentials: true*/ });
        let userData = {
          tenant: response.tenant,
          _id: response._id,
          accessToken: response.accessToken,
          memberTenants: response.memberTenants,
          Tenant: response.Tenant,
          login: response.login
        }
        // localStorage.setItem('user', JSON.stringify(userData));
        store.dispatch(setCurrentUser(userData)); // установка нового access-токена, refresh-токен пришел в cookies
        const reresponse: any = await post(url, params, {}); // повторяем неудавшийся запрос

        return reresponse;
      } catch (e: any) {
        console.log('Error', e)
        throw new Error(e)
      }
    } else {
      throw new Error()
    }
  }
}

export const put = async (url: string, params: {}, config?: any) => {
  const state: any = store.getState();
  try {
    // основной запрос
    const result = await axios.put(url, params, {
      headers: {
        'Authorization': `Bearer ${state?.auth?.data?.accessToken}`,
        ...config?.headers
      },
      withCredentials: true,
      ...config
    });

    return result?.data;
  } catch (e: any) {
    // если это истекший токен, делаем refresh токенов
    if(e.response.data.type === 'TokenError' && url !== `${process.env.REACT_APP_URL_API}/auth/log-out`) {
      try {
        // await Timeout.set(2000);
        // здесь на сервер отправляется кука refresh_token, по ней и идет обновление
        // если новый пользователь или её, тогда редирект на страницу login
        const response = await get(`${process.env.REACT_APP_URL_API}/auth/refresh-token`, { /*withCredentials: true*/ });
        let userData = {
          tenant: response.tenant,
          _id: response._id,
          accessToken: response.accessToken,
          memberTenants: response.memberTenants,
          Tenant: response.Tenant,
          login: response.login

        }
        // localStorage.setItem('user', JSON.stringify(userData));
        store.dispatch(setCurrentUser(userData)); // установка нового access-токена, refresh-токен пришел в cookies
        const reresponse: any = await put(url, params, {}); // повторяем неудавшийся запрос

        return reresponse;
      } catch (e: any) {
        console.log('Error', e)
        throw new Error(e)
      }
    } else {
      throw new Error()
    }
  }
}

export const del = async (url: string, config?: any) => {
  const state: any = store.getState();
  try {
    // основной запрос
    const result = await axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${state?.auth?.data?.accessToken}`,
        ...config?.headers
      },
      withCredentials: true,
      ...config
    });

    return result?.data;
  } catch (e: any) {
    // если это истекший токен, делаем refresh токенов
    if(e.response.data.type === 'TokenError' && url !== `${process.env.REACT_APP_URL_API}/auth/log-out`) {
      try {
        // await Timeout.set(2000);
        // здесь на сервер отправляется кука refresh_token, по ней и идет обновление
        // если новый пользователь или её, тогда редирект на страницу login
        const response = await get(`${process.env.REACT_APP_URL_API}/auth/refresh-token`, { /*withCredentials: true*/ });
        let userData = {
          tenant: response.tenant,
          _id: response._id,
          accessToken: response.accessToken,
          memberTenants: response.memberTenants,
          Tenant: response.Tenant,
          login: response.login

        }
        // localStorage.setItem('user', JSON.stringify(userData));
        store.dispatch(setCurrentUser(userData)); // установка нового access-токена, refresh-токен пришел в cookies
        const reresponse: any = await del(url, config); // повторяем неудавшийся запрос

        return reresponse;
      } catch (e: any) {
        console.log('Error', e)
        throw new Error(e)
      }
    } else {
      throw new Error()
    }
  }
}