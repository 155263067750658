import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {FetchBaseQueryArgs} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {logOut} from "../store/reducers/AuthSlice";

export type SignUpRequest = {
  lastName: string;
  firstName: string;
  login: string;
  password: string;
};

export type LoginRequest = {
  login: string;
  password: string;
  rememberMe: boolean;
};

export type LoginResponse = {
  login: string;
  accessToken: string;
  Employee: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  Tenant: {
    _id: string;
    name: string;
  };
  memberTenants: {_id: string, name: string}[]
};

// export const baseQueryAuth = fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_URL_API}/auth` });

export const baseQueryAuthWithReauth = (options: FetchBaseQueryArgs) => {
  const optionsAuth: FetchBaseQueryArgs = {
    prepareHeaders: (headers, { getState }) => {
      const state: any = getState()
      headers.set('Authorization', `Bearer ${state?.auth?.data?.accessToken}`)
      return headers;
    },
    ...options
  }
  const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result;
    if(api.endpoint === 'changeTenant') { // для этих endpoints запросы будут с аутентификацией
      result = await fetchBaseQuery(optionsAuth)(args, api, extraOptions)
      if (result.error && result.error.status === 401) {
        const resultRefresh = await api.dispatch(authApi.endpoints.refreshToken.initiate(void(0), { subscribe: false, forceRefetch: true }));
        if (resultRefresh.isSuccess) {
          // retry the initial query
          result = await fetchBaseQuery(optionsAuth)(args, api, extraOptions)
        } else {
          api.dispatch(logOut())
        }
      }
    } else { // для этих endpoints запросы без аутентификации
      result = await fetchBaseQuery(options)(args, api, extraOptions)
    }

    return result
  }

  return baseQueryWithReauth
}


export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryAuthWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/auth` }),
  // baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_URL_API}/auth` }),
  tagTypes: ['AccessToken'],
  endpoints: (builder) => ({
    signUp: builder.mutation<string, SignUpRequest>({
      query: (body) => ({
        responseHandler: 'text',
        url: '/registration',
        method: 'POST',
        body,
      }),
    }),
    verifyEmail: builder.mutation<LoginResponse, string>({
      query: (key) => ({
        url: '/verification-email',
        method: 'GET',
        params: { key },
        credentials: 'include',
      }),
    }),
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({
        url: '/log-in',
        method: 'POST',
        credentials: 'include',
        body,
      }),
    }),
    // на сервер отправляется кука refresh_token, по ней идет обновление access-token
    refreshToken: builder.query<LoginResponse, void>({
      query: () => ({
        url: '/refresh-token',
        credentials: 'include', // cookie
      }),
      providesTags: ['AccessToken'],
    }),
    changeTenant: builder.mutation<LoginResponse, {TenantID: string}>({
      query: (body) => ({
        url: '/change-tenant',
        method: 'POST',
        credentials: 'include', // cookie
        body
      }),
    }),
    getPasswordRecovery: builder.mutation<{message: string}, {email: string}>({
      query: (body) => ({
        url: '/get-password-recovery',
        method: 'POST',
        credentials: 'include',
        body,
      }),
    }),
    setNewPasswordRecovery: builder.mutation<LoginResponse, {newPassword: string, key: string}>({
      query: (body) => ({
        url: '/set-new-password-recovery',
        method: 'POST',
        credentials: 'include',
        body,
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useVerifyEmailMutation,
  useLoginMutation,
  useRefreshTokenQuery,
  useLazyRefreshTokenQuery,
  useChangeTenantMutation,
  useGetPasswordRecoveryMutation,
  useSetNewPasswordRecoveryMutation
} = authApi;
