import { useAppDispatch } from "./redux";
import { closeContextMenu, showContextMenu } from "../store/reducers/ContextMenuSlice";
import {SideMenu} from "../components/ui/contextMenu/ContextMenu";

export type ContextParams = {
  items: [] | any
  dataOnClick?: any
  side?: SideMenu
}
export const useContextMenu = () => {
  const dispatch = useAppDispatch();

  const show = (params: ContextParams, target: any) => {
    console.log('useContextMenu', target, params.items)
    const data = {
      items: params.items,
      target,
      data: params?.dataOnClick,
      side: params?.side
    };
    dispatch(showContextMenu(data))
  }

  const close = () => {
    dispatch(closeContextMenu())
  }

  return {
    show,
    close
  }
}