import * as React from 'react';
import {Cell, flexRender, Header, Row} from "@tanstack/react-table";
import {forwardRef, Fragment, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from "react";
import HeaderCellSeparator from "./HeaderCellSeparator";

type Props = {
  cell: Header<any, any>
  ref?: any
  widthHeaderRef: any
  widthBodyRef: any
  widthFooterRef: any
  data: any
  cellWidth?: any
  setCellWidth?: any
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const HeaderCellSimple: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { cell, widthHeaderRef, widthBodyRef, widthFooterRef, data, cellWidth, setCellWidth} = props;
  const ref2 = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<any>({})
  const [init, setInit] = useState<boolean>(false)
  const init2 = useRef<boolean>(true)

  useLayoutEffect(() => {

    // console.log('useLayoutEffect1 FooterCellSimple')

    if(cell && (init || init2.current)) {
      // if(cell.column.id === 'price_sum')
      // console.log('useLayoutEffect header', cell.column.id, init, ref2.current?.offsetWidth)
      const id = cell.id
      const offsetWidth = ref2.current?.offsetWidth ? ref2.current?.offsetWidth : 0
      const minWidth = cell.column.columnDef.minSize || 50 // 50 - никогда не должно сработать, настраивается в useTable defaultColumn
      const maxWidth = cell.column.columnDef.maxSize || 400 // 400 - никогда не должно сработать

      let width = offsetWidth < minWidth ? minWidth : offsetWidth
      width = width > maxWidth ? maxWidth : width

      if(widthHeaderRef.current[id]) {
        // console.log('SET1 widthHeaderRef.current')
        widthHeaderRef.current = {...widthHeaderRef.current, [id]: widthHeaderRef.current[id] > width ? widthHeaderRef.current[id] : width }
      } else {
        // console.log('SET2 widthHeaderRef.current')
        widthHeaderRef.current = { ...widthHeaderRef.current, [id]: width }
      }

      const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
      const b = widthBodyRef.current[cell.column.id] ? widthBodyRef.current[cell.column.id] : 0
      const f = widthFooterRef && widthFooterRef.current[cell.column.id] ? widthFooterRef.current[cell.column.id] : 0
      const max = Math.max(h, b, f)

      if(max !== style?.minWidth) {
        setStyle({
          minWidth: max,
          maxWidth: max
        })
        // console.log('setCellWidth header', max)
        if(setCellWidth) setCellWidth({width: max})
      }

      if(init) setInit(false)

    }

  },[init])

  useEffect(() => {
    if(init2.current) {
      init2.current = false
    } else {
      // console.log('setInit header')
      setInit(true)
    }
  }, [data])

  useEffect(() => {
    if(cell) {
      const h = widthHeaderRef.current[cell.column.id] ? widthHeaderRef.current[cell.column.id] : 0
      const b = widthBodyRef.current[cell.column.id] ? widthBodyRef.current[cell.column.id] : 0
      const f = widthFooterRef && widthFooterRef.current[cell.column.id] ? widthFooterRef.current[cell.column.id] : 0
      const max = Math.max(h, b, f)
      // if(cell.column.id === 'price_sum')
      // console.log(0, 'useEffect max', cell.column.id, max, h, b, f)
      if(max !== style?.minWidth) {
        setStyle({
          minWidth: max,
          maxWidth: max
        })
      }
    }
  }, [cellWidth])

  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle', cell?.column.id, ref2.current?.offsetWidth)
    return {
      clientWidth: ref2.current?.clientWidth,
      offsetWidth: ref2.current?.offsetWidth,
    } as HTMLDivElement | any
  }, [])

  return (
    <div ref={ref2}
         {...{
           colSpan: cell.colSpan,
           className: classNames(
             'h-full text-slate-600',
             0 ? 'border' : '',
             ' px-4 text-sm ',
             (init || init2.current) ? 'inline-block whitespace-nowrap' : 'flex items-center h-full w-full line-clamp-2',
             cell.column.columnDef.meta?.side ? cell.column.columnDef.meta?.side : 'justify-start',
           ),
           style: (init || init2.current) ? {} : {minWidth: style.minWidth+1, maxWidth: style.maxWidth+1},

         }}
    >
      {cell.isPlaceholder ? null : flexRender(cell.column.columnDef.header, cell.getContext())}
    </div>
    // <div ref={ref2}
    //   {...{
    //     colSpan: cell.colSpan,
    //     className: classNames('flex px-4 py-3 items-center justify-start h-full text-slate-600', 0 ? 'border' : ''),
    //     style: {
    //       ...style
    //     },
    //   }}
    // >
    //   {/* style?.minWidth ? '' : 'whitespace-nowrap' - чтобы корректно рассчиталась ширина offsetWidth в useLayoutEffect при инициализации*/}
    //   <span className={classNames('text-sm line-clamp-2', style?.minWidth ? '' : 'whitespace-nowrap')}>
    //     {cell.isPlaceholder ? null : flexRender(cell.column.columnDef.header, cell.getContext())}
    //   </span>
    //
    // </div>
  );
})

export default HeaderCellSimple;