import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {fetchBaseQueryWithReauth} from "./util/fetchBaseQueryWithReauth";
import {IProduct} from "../models/IProduct";
import {IDocOurOrder, IDocOurOrderPositionRequest} from "../models/IDocOurOrder";
import {AddProductRequest} from "./product";

export type ProductsRequest = {
  page?: number
  size: number | string | undefined
  sort: string | undefined
  last: string
  last_sort: string
  filterText: string
  cat: string
  isPool: boolean
  isOnline: boolean
  onlyRoot: boolean
}

export type DocOurOrdersResponse = {
  data: IDocOurOrder[];
  meta: any
}

// export interface Position extends IDocOurOrderPosition {
//   product_id: string
//   supplier_id: string
//   qty: string
//   price_purchase: string
//   delivery_time: string
// }

export type newDocOurOrderRequest = {
  number: string;
  date: string;
  positions: IDocOurOrderPositionRequest[],
  note: string
}

export type updateDocOurOrderRequest = {
  positions: IDocOurOrderPositionRequest[],
  note: string
}

export interface ListResponse<T> {
  data: T[],
  meta: {
    page: number,
    perPage: number,
    totalElements: number
    hasNextPage: boolean
  }
}

export const docOurOrdersApi= createApi({
  reducerPath: 'docOurOrdersApi',
  tagTypes: ['DocOurOrdersApi', 'OurOrder'],
  baseQuery: fetchBaseQueryWithReauth({ baseUrl: `${process.env.REACT_APP_URL_API}/doc-our-orders` }),
  endpoints: (builder) => ({
    getDocOurOrders: builder.query<ListResponse<any>, any>({
      query: (params: any) => ({
          url: `/`,
          method: 'POST',
          body: params,
          credentials: 'include',
      }),
      serializeQueryArgs: ({queryArgs, endpointDefinition, endpointName}) => {
        // console.log(555, queryArgs, endpointDefinition, endpointName)
        return endpointName
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { page, size, sort, last, filterText, cat, isPool, isOnline } }) => {
        // if((filterText || cat) && !last) { // filterText = 'term'  last = ''
        if(!last) { // last = ''
          console.log('----------новый массив для фильтра', filterText)
          currentCache.data = newItems.data
          currentCache.meta = newItems.meta
        }
        // else if(!filterText && !last) { // filterText = ''  last = ''
        //   console.log('----------новый массив')
        //   currentCache.data = newItems.data
        //   currentCache.meta = newItems.meta
        // }
        else {
          currentCache.data.push(...newItems.data)
          currentCache.meta = newItems.meta
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }: any) {
        console.log(9999999)
        return currentArg !== previousArg
      },
      // providesTags: ['Products'],
      // providesTags: (result) =>
      //   result
      //     ? [
      //       ...result.data.map(({ _id }) => {console.log(1, _id); return { type: 'Products' as const, id: _id }}),
      //       { type: 'Products', id: 'PARTIAL-LIST' },
      //     ]
      //     : [{ type: 'Products', id: 'PARTIAL-LIST' }],
      // providesTags: (result) => result ? []
    }),
    createDocOurOrder: builder.mutation<IDocOurOrder, newDocOurOrderRequest>({
      query: (body) => ({
        url: '/create',
        method: 'POST',
        body,
        credentials: 'include',
      }),
      // invalidatesTags: ['Products']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    updateDocOurOrder: builder.mutation<IDocOurOrder, { id: string, data: updateDocOurOrderRequest }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['OurOrder']
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
    }),
    getDocOurOrder: builder.query<IDocOurOrder, {id: string}>({
      query: ({id}) => ({
        url: `/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      providesTags: ['OurOrder']
    }),
    delDocOurOrders: builder.mutation<void, { selected: any, params: any }>({
      query: (body) => ({
        url: `/del-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['DocOurOrdersApi']
    }),
    moveDocOurOrders: builder.mutation<void, { selected: any, params: any } | { id: string }>({
      query: (body) => ({
        url: `/move-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['OurOrder']
    }),
    moveCancelDocOurOrders: builder.mutation<void, { selected: any, params: any } | { id: string }>({
      query: (body) => ({
        url: `/move-cancel-selected`,
        method: 'POST',
        body: body,
        credentials: 'include',
      }),
      invalidatesTags: ['OurOrder']
    }),
  }),
});

export const {
  useCreateDocOurOrderMutation,
  useGetDocOurOrdersQuery,
  useLazyGetDocOurOrdersQuery,
  useLazyGetDocOurOrderQuery,
  useUpdateDocOurOrderMutation,
  useDelDocOurOrdersMutation,
  useMoveDocOurOrdersMutation,
  useMoveCancelDocOurOrdersMutation,


} = docOurOrdersApi;