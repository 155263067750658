import * as React from 'react';
import {forwardRef, useImperativeHandle, useRef} from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  ref?: any
  isHidden?: boolean
}

const FooterCellSeparator: React.FC<Props> = forwardRef<number, Props>((props: Props, ref) => {
  const ref2 = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle', cell?.column.id, ref2.current?.offsetWidth)
    return ref2.current?.offsetWidth as number
  }, [])
  return <div ref={ref2} className={classNames('w-[1px] min-w-[1px] h-6 bg-slate-200', props?.isHidden ? 'opacity-0' : '')}></div>
})

export default FooterCellSeparator;