import React, {Fragment, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {CellContext, createColumnHelper, RowData} from "@tanstack/react-table";
import {useAppDispatch} from "hooks/redux";
import {Link, useNavigate} from "react-router-dom";
import {useNotification} from "hooks/notification";
import {useSlide} from "hooks/slide";
import {useContextMenu} from "hooks/contextMenu";
import {
  productApi,
  useAddProductMutation,
  useDelProductMutation,
  useDelProductsMutation,
  useDownloadXlsMutation,
  useLazyGetProductsFiltersQuery, useOnlineProductsMutation, usePoolProductsMutation,
} from "api/product";
import {IProduct} from "models/IProduct";
import {MainContext} from "components/Main";
import ProductSlide from "components/ui/slide/tmpl/ProductSlide";
import {useGetProducts} from "hooks/useGetProducts";
import {ArrowLeftIcon, ChevronRightIcon, PencilIcon, PlusIcon, PlusSmallIcon} from "@heroicons/react/20/solid";
import ProductCategorySlide from "components/ui/slide/tmpl/ProductCategorySlide";
import Checkbox from "components/ui/checkbox/Checkbox";
import {formatCurrencyView, formatSumSeparator} from "utils/helpers";
import {useDialog} from "hooks/dialog";
import DialogImportProducts from "components/ui/dialogs/tmpl/DialogImportProducts";
import {EnumDialogIcon, EnumDialogWidth} from "components/ui/dialogs/DialogWrap";
import ActionGroup1 from "components/ui/table3/action-groups/tmpl/ActionGroup1";
import DialogCategorySelection from "components/ui/dialogs/tmpl/DialogCategorySelection";
import {useTooltip} from "hooks/tootip";
import PopoverIndex, {PopoverContext} from "components/ui/popovers/PopoverIndex";
import SelectConstantHeight from "components/ui/select/selectConstantHeight/SelectConstantHeight";
import Input from "components/ui/Input/Input";
import Button from "components/ui/buttons/Button";
import {AppContext} from "App";
import RadioGroupIndex from "components/ui/radio-groups/RadioGroupIndex";
import {TooltipPlacement} from "components/ui/tooltips/TooltipPanel";
import TableFlexOneColumn from "components/ui/table/tmpl/TableFlexOneColumn";
import TableConstantMultiColumns from "components/ui/table/tmpl/TableConstantMultiColumns";
import TableFlexMultiColumns from "components/ui/table/tmpl/TableFlexMultiColumns";
import {ColumnsWidth} from "components/ui/table/Table";
import {useProducts} from "../../../../hooks/useProducts";
import {DialogServiceType} from "../Dialogs";
import {ISupplier} from "../../../../models/ISupplier";
import SupplierSlide from "../../slide/tmpl/SupplierSlide";
import {useDelSupplierMutation, useLazyGetSuppliersQuery} from "../../../../api/suppliers-api";
import {useGetCursor} from "../../../../hooks/useGetCursor";
import NotificationError from "../../notifications/tmpl/NotificationError";
import NotificationSuccess from "../../notifications/tmpl/NotificationSuccess";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
const FilterName: React.FC<{name: string, count: string}> = (props: {name: string, count: string}) => {
  return (
      <Fragment>
        {props.name} <span className='text-gray-400 text-sm'>{props.count}</span>
      </Fragment>)
}

const OnlyRootLink: React.FC<{cat: string, item: any, onlyRoot: boolean, toOnlyRootCategory: any}> = ({cat, item, onlyRoot, toOnlyRootCategory}) => {
  const tooltip = useTooltip();
  return (
    <Fragment>
      <span className={classNames('cursor-pointer rounded-full  px-2 text-sm leading-5 hover:bg-amber-400 hover:text-white', cat===item._id && onlyRoot ? 'bg-amber-400 text-white' : 'text-amber-500')}
            onMouseEnter={ (event: any) => { tooltip.show('Товары без подкатегорий', event.currentTarget) } }
            onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
            onClick={ (event: any) => { event.stopPropagation(); tooltip.close(event.currentTarget); toOnlyRootCategory(item._id) } }>{item?.productsCountOnlyRoot}</span>
    </Fragment>)
}

const CategoryLink: React.FC<{cat: string, item: any, onlyRoot: boolean, toOnlyRootCategory: any, clickProductCategoryEdit: any, toCategory: any}> = ({cat, item, onlyRoot, toOnlyRootCategory, clickProductCategoryEdit, toCategory}) => {
  return (
    <Fragment>
      <div className='group/category flex items-center space-x-3'>
        <div className={ classNames('cursor-pointer', cat===item._id ? onlyRoot ? 'text-amber-500' : 'text-red-500' : '', 'group-hover/category:text-red-500') } onClick={()=>toCategory(item._id)}>
          {item.categoryName_rus} <span className='text-gray-400 text-sm'>{item.productsCount}</span> { item?.productsCountOnlyRoot ? (<OnlyRootLink cat={cat} item={item} onlyRoot={onlyRoot} toOnlyRootCategory={toOnlyRootCategory} />) : '' }
        </div>

        <div className='group/edit' onClick={() => clickProductCategoryEdit(item._id)}>
          <PencilIcon className='h-4 w-4 invisible cursor-pointer group-hover/category:visible group-hover/category:text-slate-200 group-hover/edit:text-slate-400' />
        </div>
      </div>
    </Fragment>)
}

const getThumb200x200 = (fileName: string) => {
  const ext = fileName.split('.').pop();
  return fileName.replace('.' + ext, '-200x200.' + ext)
}

const ImagePreview: React.FC<any> = ({info}) => {
  const [images, setImages] = useState<string[]>([])
  useEffect(() => {
    const arr = info.getValue()
    if(Array.isArray(arr)) {
      setImages(arr)
    } else {
      setImages([])
    }
  }, [info])
  return (
    images.length > 0 ?
        <PopoverIndex content={<ImagesPopover images={images} />}>
          {/*<div className={'w-10 h-10 bg-cover bg-center rounded border'} style={{backgroundImage: `url('${'https://s3.aaabox.ru/'+getThumb200x200(images[0])}')`}} />*/}
          <div className={'w-10 h-10 bg-cover bg-center rounded border'} style={{backgroundImage: `url('${'https://s3.aaabox.ru/'+getThumb200x200(images[0])}')`}} />
        </PopoverIndex>
        :
        <div className={'w-10 h-10 rounded border'}></div>
  )
}

const ImagePreview16x16: React.FC<any> = ({info}) => {
  const [images, setImages] = useState<string[]>([])
  useEffect(() => {
    const arr = info.row.original.images
    if(Array.isArray(arr)) {
      setImages(arr)
    } else {
      setImages([])
    }
  }, [info])
  return (
    images.length > 0 ?
      <PopoverIndex content={<ImagesPopover images={images} />}>
        <div className={'w-16 h-16 bg-cover bg-center rounded border'} style={{backgroundImage: `url('${'https://s3.aaabox.ru/'+getThumb200x200(images[0])}')`}} />
      </PopoverIndex>
      :
      <div className={'w-16 h-16 rounded border'}></div>
  )
}

const ImagesPopover: React.FC<any> = ({images}) => {
  const {maxWidth} = useContext(PopoverContext)
  return (
    <>
      {/*<div style={{maxWidth: 'inherit'}}>*/}
      <div className={'inline-grid gap-2 z-50 rounded-md p-2 shadow bg-black/50'} style={{gridTemplateColumns: maxWidth > 850 && images.length >=5 ? 'repeat(5, 160px)' : 'repeat(auto-fit, 160px)', maxWidth: 'inherit'}}>
        {/*<div className={'flex flex-wrap gap-3 z-10 rounded-md p-2 shadow bg-black/50'}>*/}
          {images.map((item: string) => <img key={item} className={'w-40 h-40 object-cover border rounded'} src={'https://s3.aaabox.ru/'+getThumb200x200(item)} alt=""/>)}
          {/*{images.map((item: string) => <div key={item} className={'w-40 h-40 bg-cover bg-center rounded border'} style={{backgroundImage: `url('${'https://s3.aaabox.ru/'+item}')`}} />)}*/}
        </div>
      {/*</div>*/}


    </>

  )
}

const ProductPhoneRow: React.FC<{info: CellContext<IProduct, string> & {setSelected2: any, checked2: boolean}, clickProduct: any}> = ({ info, clickProduct }: {info: CellContext<IProduct, string> & {setSelected2: any, checked2: boolean}, clickProduct: any}) => {
  const [checked, setChecked] = useState<boolean>(false)
  const tooltip = useTooltip();

  useEffect(() => {
    setChecked(info.checked2)
  }, [info])

  return (
    <Fragment>
      <div className={'flex gap-3'}>
        <div className={'relative'} onClick={(event)=>{event.stopPropagation()}}>
          <Checkbox
            checked={checked}
            onChange={(value) => {
            // console.log('info', info);
            if(info?.setSelected2)
              info?.setSelected2(value)
            }} className={'absolute top-1 left-1'} />
          <ImagePreview16x16 info={info} />
        </div>
        <div className='flex flex-col gap-3 w-full'>

          <div className='flex flex-col'>
            <div className='flex justify-between text-xs'>
              <div>
                <span className='text-slate-400'>{info.row.original.brandName}</span> {info.row.original.number ? <span className='text-slate-400'>– {info.row.original.number}</span> : ''}
              </div>
              <div className={'flex gap-1'}>
                {info.row.original?.isOnline ?
                  <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400"
                       onMouseEnter={ (event: any) => { tooltip.show('Online', event.currentTarget, TooltipPlacement.TOP) } }
                       onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }>
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div> :
                  <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400 opacity-0">
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div>
                }
                <div className={'text-xs text-slate-400'}>#{info.row.index+1}</div>
              </div>

            </div>

            <div className='text-sm line-clamp-2 whitespace-normal'>{info.row.original.title_rus}</div>
            {/*<div className={'flex'}>*/}
            {/*  <Link*/}
            {/*    to="/"*/}
            {/*    onClick={(e) => {e.preventDefault();}}*/}
            {/*    className={classNames("text-sm line-clamp-2 whitespace-normal",*/}
            {/*      // "text-sky-600 hover:text-sky-500 underline decoration-dashed"*/}
            {/*    )}>*/}
            {/*    {info.row.original.title_rus || 'не задано'}*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
          <div className={'flex text-sm justify-between'}>
            {info.row.original.price_sale || info.row.original.price_sale === 0 ?
              <div className='text-sky-600'>{formatSumSeparator(formatCurrencyView(info.row.original.price_sale)) + ' ₽'}</div>
              : <div></div>
            }
            {info.row.original.qty && info.row.original.qty != 0 ?
              <div className={'text-slate-400'}>В наличии: {info.row.original.qty} шт</div>
              :
              info.row.original.qty == 0 ? <div className={'text-amber-500'}>Нет в наличии</div> : <div></div>
            }

          </div>


        </div>
      </div>
    </Fragment>)
}

type Props = {
  _dialog?: DialogServiceType
  width?: EnumDialogWidth
}

const DialogSelectSuppliers: React.FC<Props> = ({_dialog}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const [getData, obj, lastArgs] = useLazyGetSuppliersQuery()
  const [getSuppliers, {data: suppliers, isFetching, isLoading, isError: isError3, error: error3}] = useGetCursor(getData, obj)
  const [params, setParams] = useState<any>()
  const notification = useNotification();

  const [delSupplier] = useDelSupplierMutation();
  const notify = useNotification();
  const slides = useSlide();
  const contextMenu = useContextMenu();
  const tableContextMenu = [
    [
      {text: 'Добавить', onClick:(info: any) => { create() }},
      // {text: 'Удалить', onClick:(info: any) => { create() }}
    ],
    [{text: 'Обновить', onClick:(info: any) => { dispatch(productApi.util.invalidateTags(['Products']));}}]
  ]
  const rowContextMenu = [
    // [{text: 'Редактировать'}, {text: 'Печать'}],
    [{text: 'Удалить', onClick:({ row, meta}: any) => {
        delSupplier(row.original._id).then(
          (r: any) => {
            if(r?.error) {
              console.log(r.error.data.message)
              notification.show(<NotificationError text={r.error.data.message} />)
            } else {
              setParams({})
              notification.show(<NotificationSuccess text={'Поставщик удалён'} />)
            }
          }
        )
      }}]
  ]

  const {title, setIsSearchInput, setPreloader} = useContext(MainContext)
  const [selected, setSelected] = useState<{ all: boolean, count: number, include: any[], exclude: any[] }>()


  const onOk = useCallback((): Promise<void> => {
    // срабатывает, когда происходит accept диалогового окна.
    // если этого обработчика нет, тогда диалоговое окно закрывается по-умолчанию при событии закрытия в dialogWrap
    // и ничего не возвращает
    // если этот обработчик есть, но нет accept(result), тогда окно не закрывается.
    // из любого места компонента можно закрыть диалоговое окно (accept() или decline()),
    // минуя регистрацию onOk и onCancel

    return new Promise((resolve, reject) => {
      console.log('promise')
      resolve()
      _dialog?.accept({selected, params})
    })

  }, [selected])

  useEffect(() => {
    _dialog?.acceptHandler(onOk)
    _dialog?.setTitle('Справочник Поставщики')
    _dialog?.setIcon(EnumDialogIcon.FORM)
    _dialog?.acceptButton('Выбрать ' + selected?.count)
    _dialog?.declineButton('Закрыть')
  }, [onOk])


  useEffect(() => {
    setPreloader(isFetching)
  }, [isFetching])

  useEffect(() => {
    setIsSearchInput(true)
    return () => setIsSearchInput(false)
  }, [])

  const clickSupplier = (e: any, info: any) => {
    e.preventDefault();
    slides.show(<SupplierSlide idSupplier={info.row.original._id} />).then(
      (result) => {
        console.log(4, 'close slide', result)
        getSuppliers({filter, last: ''})
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const columnHelper = createColumnHelper<ISupplier>()
  const columns = [
    // columnHelper.accessor('_id', {
    //   cell: info => <Link to="/" onClick={(e) => clickBrand(e, info)} className="text-sky-600 hover:text-sky-500">{info.getValue()}</Link>,
    //   size: 100,
    // }),
    columnHelper.accessor('name', {
      header: 'Наименование',
      cell: info => <Link to="/" onClick={(e) => clickSupplier(e, info)} className="text-sky-600 hover:text-sky-500 border-b border-dashed border-sky-600 hover:border-sky-500">{info.getValue()}</Link>,
      // minSize: 1670,
      minSize: 950,
      meta: {
        width: ColumnsWidth.STRETCH
      },
    }),
  ]

  const create = () => {
    slides.show(<SupplierSlide idSupplier={'new'} />).then(
      (result) => {
        getSuppliers({filter, last: ''})
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  useEffect(() => {
    console.log('setParams1')
    setParams({ })
  }, [])

  return (
    <>
      {/*<div className={'flex flex-col'}>*/}
      <div className="flex flex-col justify-start"
           {...{
             style: {
               height: `calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 82px)`,
             },
           }}>
        <div className={'flex justify-between gap-x-3 mb-2'}>
          <div>
            <Button text={'Создать поставщика'} style={'SECONDARY'} onClick={() => create()} />
          </div>

        </div>
        <div className={'overflow-auto'}>
          {/*123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />123<br />*/}
          <TableConstantMultiColumns
            columns={columns}
            data={suppliers.data}
            isLoading={isLoading}
            isFetching={isFetching}
            hasNextPage={isLoading ? isLoading : suppliers?.meta.hasNextPage}
            getData={getSuppliers}
            tableContextMenu={tableContextMenu}
            rowContextMenu={rowContextMenu}
            params={params}
            isError={isError3}
            error={error3}
            meta={ {sort: 'id:desc', totalElements: suppliers?.meta.totalElements} }
            isRowNumber={true}
            isCheckbox={true}
            // parentRefExt={appRef}
            // height={`calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 82px)`}
            onSelect={(selected: any) => setSelected(selected)}
          />
        </div>


      </div>
      {/*</div>*/}

    </>
  )
}

export default DialogSelectSuppliers;