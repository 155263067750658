import React, {ReactNode, useEffect, useRef, useState} from "react";
import Breadcrumbs from "./Breadcrumbs";
import {useAppSelector} from "../hooks/redux";
import RouterProtected from "../pages/routerProtected";
import RouterPublic from "../pages/routerPublic";
import useBreadcrumbs from "use-react-router-breadcrumbs";

type Props = {
    children?: React.ReactNode
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
export const MainContext = React.createContext<any>(null);

const Main: React.FC<Props> = ({ children }) => {
  const user = useAppSelector((state) => state.auth)
  let auth = false;
  if(user?.data.userId) {
    auth = true;
  }
  let routes: any = auth ? RouterProtected : RouterPublic
  const setTitle = ( routes: any ) => {
    return routes.map( (item: any) => {
      if(item?.children) {
        return { ...item, children: setTitle(item.children) }
      } else {
        return { ...item, breadcrumb: item.title }
      }

    })
  }
  routes = setTitle(routes);

  const breadcrumbs: any = useBreadcrumbs(routes);
  const title = breadcrumbs.map( (item: any) => item.breadcrumb )[breadcrumbs.length-1]
  const [titleData, setTitleData] = useState<{}>()

  const refMain = useRef<any>();
  const refTitle = useRef<any>();
  const refInputSearch = useRef<any>();
  const refContent = useRef<any>();

  const [isSearchInput, setIsSearchInput] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [preloader, setPreloader] = useState<boolean>(false)
  const [sort, setSort] = useState<string>('')

  const [optionMapInit, setOptionMapInit] = useState<any>([
    {option: {_id: 'id:desc', value: 'По дате создания'}},
    {option: {_id: 'price_sale:asc', value: 'Дешевле'}},
    {option: {_id: 'price_sale:desc', value: 'Дороже'}},
    {option: {_id: 'qty:asc', value: 'Наличие мало'}},
    {option: {_id: 'qty:desc', value: 'Наличие много'}},
    {option: {_id: 'title_rus.keyword:asc', value: 'Наименование (А-Я)'}},
    {option: {_id: 'title_rus.keyword:desc', value: 'Наименование (Я-А)'}},
  ])
  const [optionMap, setOptionMap] = useState<any>(optionMapInit)
console.log('title', title, breadcrumbs)

  // useEffect(() => {
  //   console.log(12)
  // }, [title.type])

  return (
    <MainContext.Provider value={{main: refMain, title: refTitle, content: refContent, setIsSearchInput, searchText: searchText, setPreloader, sort, setTitleData }}>
      <main className="flex-1 bg-[#F1F5F9] flex flex-col py-3 h-auto" ref={refMain}>

          <div className="px-4 sm:px-6 md:px-8" ref={refTitle}>
            <div className='pb-1'>
              <Breadcrumbs />
            </div>

            {/*<h1 className="text-2xl font-semibold text-gray-900">{ title }</h1>*/}
            <div className='flex h-11 w-full justify-between'>
              <div className='flex h-full items-center'>
                <div className='font-medium text-lg h-7 w-full text-slate-900'>
                  { title.type === 'span' ? title : React.createElement(title.type, {...title.props, ...titleData }) }
                </div>
                {/*<div className='w-full text-xs text-slate-500 leading-4'>Всего позиций: 444</div>*/}
              </div>
              {/*<div className={'flex gap-x-3 border'}>*/}
              {/*  {preloader && <div className={classNames("flex justify-center items-center")}>*/}
              {/*    <svg className="animate-spin h-5 w-5 self-center text-slate-500" viewBox="0 0 24 24">*/}
              {/*      <circle className="opacity-25" cx="12" cy="12" r="10" fillOpacity="0%" stroke="currentColor" strokeWidth="4"></circle>*/}
              {/*      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>*/}
              {/*    </svg>*/}
              {/*  </div>}*/}
              {/*  {isSearchInput && <div className={'flex items-center w-80 h-full'}>*/}
              {/*    <span className={'mr-2'}>Сортировка:</span>*/}
              {/*    <SelectConstantHeight*/}
              {/*      data={optionMap}*/}
              {/*      initSelected={optionMap[0].option}*/}
              {/*      optionKey={'value'}*/}
              {/*      isLoading={true}*/}
              {/*      initStatus={'fulfilled'}*/}
              {/*      onChange={(option) => setSort(option._id)}*/}
              {/*    />*/}
              {/*  </div>}*/}

              {/*  {isSearchInput && <div className='flex items-center w-[250px]'>*/}
              {/*    <Input placeholder='Поиск' ref={refInputSearch} value={searchText} onChange={(value) => setSearchText(value)} />*/}
              {/*  </div>}*/}
              {/*</div>*/}

            </div>

          </div>
          <div className="px-4 pt-2 pb-0 sm:px-6 md:px-8 h-full" ref={refContent}>
            { children }
          </div>

      </main>
    </MainContext.Provider>
  );
};

export default Main;
