import * as React from 'react';
import {forwardRef, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {CellContext, Row} from "@tanstack/react-table";
import ActionGroupIndex from "../../table3/action-groups/ActionGroupIndex";
import {useTable} from "../../../../hooks/useTable";
import Table from "../Table";
import TableBody from "../TableBody";
import {AppContext} from "../../../../App";
import TableHeader from "../headers/TableHeader";
import TrMultiColumns from "../rows/TrMultiColumns";
import CellPreloader from "../rows/cells/CellPreloader";

type Props = {
  columns?: any
  data?: any // принимать объект data = { data: [], meta: {} }
  isLoading?: any // true при начальной загрузке
  isFetching?: any //
  getData?: (arg0: any) => void | Promise<any>
  hasNextPage?: any
  onClickRow?: (firstCell: CellContext<any, unknown>) => void
  onDoubleClickRow?: (rowData: Record<string, unknown>) => void
  size?: string | number
  // sort?: string
  tableContextMenu?: any
  rowContextMenu?: any
  meta?: any
  params?: {[key: string]: any} // параметры фильтрации, категории или любые другие параметры, которые будут добавлены к запросу
  isError?: any
  error?: any
  isRowNumber?: boolean // отображать номер строки
  isCheckbox?: boolean // отображать чекбоксы - возможность одиночного/множественного выбора
  actionGroup?: any
  parentRefExt?: any
  onSelect?: (selected: any) => void
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const TableFlexMultiColumns: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {

  const {
    actionGroup,
    onClickRow=()=>{},
    onSelect=() => {}
  } = props;
  const {appRef} = useContext(AppContext)
  const {
    table,
    rows,
    rowVirtualizer,
    tableRef,
    // headerRef,
    // minWidthTable,
    scrollWidth,
    selected,
    setSelect,
    setSelectAll,
    hasNextPage,
    tableContextMenu,
    rowContextMenu } = useTable({ parentRefExt: appRef, ...props })

  const [cellWidth, setCellWidth] = useState({width: 0})
  const items = useMemo(() => rowVirtualizer.getVirtualItems(), [rowVirtualizer.getVirtualItems()])
  const widthBodyRef = useRef<any>({})
  const widthHeaderRef = useRef<any>({})
  const minWidthTrRef = useRef<any>(0)
  const style = useMemo(() => ({ minWidth: minWidthTrRef.current}), [minWidthTrRef.current])
  const headerRef = React.useRef<any>(null)
  const minWidthHeader = useMemo(() => headerRef.current ? window.getComputedStyle(headerRef.current).getPropertyValue("min-width") : 0, [headerRef?.current?.style?.['min-width']])
  const id2: number = useMemo(() => Math.random(), [props.data])

  // useMemo(() => {
  //   console.log('useMemo params',)
  // }, [props.params])
  //
  // useMemo(() => {
  //   console.log('useMemo hasNextPage',)
  // }, [props.hasNextPage])
  //
  // useMemo(() => {
  //   console.log('useMemo isCheckbox',)
  // }, [props.isCheckbox])
  //
  // useMemo(() => {
  //   console.log('useMemo isFetching', props.isFetching)
  // }, [props.isFetching])
  //
  // useMemo(() => {
  //   console.log('useMemo isLoading',)
  // }, [props.isLoading])
  //
  // useMemo(() => {
  //   console.log('useMemo isRowNumber',)
  // }, [props.isRowNumber])
  //
  // useMemo(() => {
  //   console.log('useMemo columns',)
  // }, [props.columns])
  //
  // useMemo(() => {
  //   console.log('useMemo data', props.data)
  //   // widthBodyRef.current = {}
  // }, [props.data])
  //
  // useMemo(() => {
  //   console.log('useMemo getData',)
  // }, [props.getData])

  const st1 = React.useRef<any>(null)
  const st2 = React.useRef<any>(null)
  const st3 = React.useRef<any>(null)

  // const onScroll2 = useCallback(() => {
  //
  //   if(st2.current) {
  //     // window.getComputedStyle(st2.current).setProperty("top", '1000px');
  //     // st2.current.style.bottom =st1.current?.scrollHeight + 'px'
  //     // st1.current.scrollTo({
  //     //   top: st1.current.offsetTop+100,
  //     //   behavior: "smooth"
  //     // })
  //     // console.log(123, st1.current, st1.current.style.transform)
  //   }
  //
  // }, [st1.current?.scrollHeight])

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     console.log(555, entries[0].isIntersecting)
  //   });
  //   observer.observe(st2.current);
  // }, [])

  // useEffect(() => {
  //   window.requestAnimationFrame(() => {
  //     onScroll();
  //     window.addEventListener("scroll", onScroll);
  //   })
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [ onScroll2 ])

  const [isAction, setIsAction] = useState<boolean>(true)
  const isActionTop = useMemo(() => {
    if(st2.current && st3.current) {
      const pin = st3.current.getBoundingClientRect();
      const fixed = st2.current.getBoundingClientRect();
      const wh = window.innerHeight
      // console.log(111, 'pin:', pin.bottom, 'fixed:', fixed.bottom, 'wh:', wh)
      if (pin.bottom+88 < fixed.bottom) {
        return true
      }

      return false
    }

  }, [selected])
  const onScroll = () => {
    // console.log(222)
    if(st2.current && st3.current) {
      const pin = st3.current.getBoundingClientRect();
      const fixed = st2.current.getBoundingClientRect();
      // const c = window.innerHeight

      const cls3 = st3.current.className.split(' ')
      const cls2 = st2.current.className.split(' ')
      const isOpacityPin = cls2.some((item: any) => item === 'opacity-0')
      // console.log(222, 'pin:', pin.bottom, 'fixed:', fixed.bottom)
      if (pin.bottom > fixed.bottom && isOpacityPin) { // панель fixed
        st2.current.className = cls2.filter((item: string) => item !== 'opacity-0').join(' ')

        cls3.push('opacity-0')
        st3.current.className = cls3.join(' ')
      } else if(pin.bottom < fixed.bottom && !isOpacityPin) { // панель внизу
        cls2.push('opacity-0')
        st2.current.className = cls2.join(' ')

        st3.current.className = cls3.filter((item: string) => item !== 'opacity-0').join(' ')
      }
    }
  }

  useEffect(() => {
    // window.requestAnimationFrame(() => {
    //   appRef.current.addEventListener("scroll", onScroll);
    // })

    function watchScroll() {
      props.parentRefExt.current.addEventListener("scroll", onScroll);
    }
    watchScroll();

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    console.log(333, 'select', selected)
    onSelect(selected)
  }, [selected])

  return (
    <>

      {/*{items.length ?*/}
        <div className={'relative'}>
          <Table tableRef={tableRef} >

            <div className='flex flex-col pl-3 py-3'>
              <div className={'flex'}>
                <TableHeader cellWidth={cellWidth} setCellWidth={setCellWidth} minWidthTrRef={minWidthTrRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} table={table} ref={headerRef} selected={selected} setSelectAll={setSelectAll} scrollWidth={scrollWidth} />
              </div>

              <TableBody rowVirtualizer={rowVirtualizer}>
                {items && items.length ?
                  <div ref={st1} className={''}
                       style={{
                         position: 'absolute',
                         top: 0,
                         left: 0,
                         width: '100%',
                         transform: `translateY(${items[0].start - rowVirtualizer.options.scrollMargin}px)`,
                       }}
                  >
                    {items.map((virtualRow, index) => {
                      const isLoaderRow = virtualRow.index + 1 > rows.length
                      const rowData = rows[virtualRow.index] as Row<any> // as Row<IOrder>
                      // console.log('---', rowData , rowData?.original?.title_rus, virtualRow )
                      // console.log('---', id2 )
                      let checked = false
                      if(rowData) {
                        checked = !!selected.include.find((item: any) => item._id === rowData.original._id) || selected.all || !!(selected.exclude.length && !selected.exclude.find((item: any) => item._id === rowData.original._id))
                      }
                      return (
                        rowData ?
                          <div
                            key={rowData.original._id + virtualRow.index + id2}
                            data-index={virtualRow.index}
                            ref={rowVirtualizer.measureElement}
                            {...{
                              style: {
                                minHeight: `${virtualRow.size}px`,
                              }
                            }}
                            className={classNames('flex',)}
                          >
                            <div {...{
                              style: {
                                ...style
                              }
                            }}
                                 className={classNames(
                                   'flex flex-1 justify-between cursor-pointer',
                                   // 'border border-blue-500',
                                   index > 0 && 'border border-t-slate-200 border-x-0 border-b-0',
                                   checked ? 'bg-sky-100' : 'hover:bg-sky-50')}
                                 onClick={() => onClickRow(rowData.getAllCells()[0].getContext())}
                            >
                              {rowData && <TrMultiColumns cellWidth={cellWidth} setCellWidth={setCellWidth} data={props.data} minWidthTrRef={minWidthTrRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} isLoaderRow={isLoaderRow} hasNextPage={hasNextPage} rowData={rowData} checked={checked} selected={selected} setSelect={setSelect} rowContextMenu={rowContextMenu} tableContextMenu={tableContextMenu} table={table} />}
                            </div>

                            <div className={'min-w-[12px]'} />
                          </div> :
                          isLoaderRow
                            ? hasNextPage ?
                              <div key={index} className={'flex '} >
                                <div className={'flex-1'} {...{
                                  style: {
                                    minWidth: minWidthHeader,
                                    minHeight: 48
                                  }
                                }}>
                                  <CellPreloader />
                                </div>

                                <div className={'min-w-[12px] '} />
                              </div> : <div key={index}>Больше ничего нет</div>
                            : ''
                      )
                    })}

                  </div> : '' }
              </TableBody>

              {/*<ActionGroupIndex selected={selected} className={'sticky bottom-3 '} cleanSelected={() => setSelectAll(false)}>{actionGroup}</ActionGroupIndex>*/}

            </div>

            <div className={classNames('opacity-0')}
                 {...{
                   style: {
                     minWidth: selected.count ? tableRef?.current?.offsetWidth : 0,
                     maxWidth: selected.count ? tableRef?.current?.offsetWidth : 0,
                   }
                 }}>

              <ActionGroupIndex selected={selected} className={''} cleanSelected={() => setSelectAll(false)}>{actionGroup}</ActionGroupIndex>

            </div>

          </Table>


          <>
            <div ref={st2} className={classNames('fixed bottom-3 px-3 ', selected.count ? isActionTop ? 'opacity-0' : '' : 'opacity-0')}
                 {...{
                   style: {
                     minWidth: selected.count ? isActionTop ? 0 : tableRef?.current?.offsetWidth : 0,
                     maxWidth: selected.count ? isActionTop ? 0 : tableRef?.current?.offsetWidth : 0,
                   }
                 }}>
              <ActionGroupIndex selected={selected} className={''} cleanSelected={() => setSelectAll(false)}>{actionGroup}</ActionGroupIndex>
            </div>

            <div ref={st3} className={classNames('absolute bottom-5 px-3 ', selected.count ? isActionTop ? '' : 'opacity-0' : 'opacity-0')}
                 {...{
                   style: {
                     minWidth: selected.count ? tableRef?.current?.offsetWidth : 0,
                     maxWidth: selected.count ? tableRef?.current?.offsetWidth : 0,
                   }
                 }}>
              <ActionGroupIndex selected={selected} className={''} cleanSelected={() => setSelectAll(false)}>{actionGroup}</ActionGroupIndex>
            </div>
          </>

        </div>

      {/*  :*/}
      {/*  <div className='flex items-center justify-center h-20'>*/}
      {/*    <div className='text-slate-400'>Пусто</div>*/}
      {/*  </div>*/}
      {/*}*/}
    </>
  )
})

export default TableFlexMultiColumns;