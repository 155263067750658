import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  fetchABCPSettings,
  loadClientOrdersFromABCP,
  fetchResults,
  fetchOrders,
  fetchResultShort,
  fetchOrder
} from "./AbcpThunk";
import {ISettingsABCP} from "../../../models/ISettingsABCP";
import {IOrder} from "../../../models/IOrder";

interface IResult {
  _id?: string,
  status?: string,
  dateStart?: string,
  dateEnd?: string,
  count?: string,
  updateCount?: string,
  updateOrders?: string,
  createCount?: string,
  countAll?: string
}

interface SettingsABCPState {
  data: ISettingsABCP | null;
  isLoading: boolean;
  error: string;
}

interface AbcpState {
  loadResult: {
    data: {};
    isLoading: boolean;
    error: string;
  },
  moreResults: {
    data: any;
    isLoading: boolean;
    error: string;
  },
  moreResultShort: {
    data: any;
    isLoading: boolean;
    error: string;
  },
  settings: SettingsABCPState,
  orders: {
    data: IOrder[];
    isLoading: boolean;
    error: string;
    pages: any[];
    hasNextPage: boolean;
  },
  order: {
    data: Partial<IOrder>;
    isLoading: boolean;
    error: string;
  }

}

const initialState: AbcpState = {
  loadResult: {
    data: {
      status: '',
      dateStart: '',
      dateEnd: '',
      count: '',
      updateCount: '',
      updateOrders: '',
      createCount: '',
      countAll: '',
    },
    isLoading: false,
    error: '',
  },
  settings: {
    data: null,
    isLoading: false,
    error: ''
  },
  moreResults: {
    data: null,
    isLoading: false,
    error: ''
  },
  moreResultShort: {
    data: [],
    isLoading: false,
    error: '',
  },
  orders: {
    data: [],
    isLoading: false,
    error: '',
    pages: [],
    hasNextPage: true,
  },
  order: {
    data: {},
    isLoading: false,
    error: '',
  }
}



export const AbcpSlice = createSlice({
  name: 'abcp',
  initialState,
  reducers: {
    addOrders(state, action: PayloadAction<any>) {
      console.log('addOrders', action.payload)
      // const last = action.payload.meta?.last;
      // const pages = state.orders.pages;
      // console.log('slice', 'pages', pages.join(','), 'last', last)
      // if(last) {
      //   console.log('last true')
      //   if(!pages.some( item => String(item) === String(last))) {
      //     console.log('1')
      //     state.orders.data = [...state.orders.data, ...action.payload.data];
      //   }
      //   state.orders.pages = [ ...pages, last ];
      // } else {
      //   console.log('last false')
      //   state.orders.data = action.payload.data;
      //   state.orders.pages = [];
      // }
    },
  },
  extraReducers: {
    [loadClientOrdersFromABCP.fulfilled.type]: (state, action: PayloadAction<IResult>) => {
      state.loadResult.isLoading = false;
      state.loadResult.error = '';
      state.loadResult.data = action.payload;
    },
    [loadClientOrdersFromABCP.pending.type]: (state) => {
      state.loadResult.isLoading = true;
    },
    [loadClientOrdersFromABCP.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadResult.isLoading = false;
      state.loadResult.error = action.payload;
    },

    //settings
    [fetchABCPSettings.fulfilled.type]: (state, action: PayloadAction<ISettingsABCP>) => {
      state.settings.isLoading = false;
      state.settings.error = '';
      state.settings.data = action.payload;
    },
    [fetchABCPSettings.pending.type]: (state) => {
      state.settings.isLoading = true;
    },
    [fetchABCPSettings.rejected.type]: (state, action: PayloadAction<string>) => {
      state.settings.isLoading = false;
      state.settings.error = action.payload;
    },

    //moreResults
    [fetchResults.fulfilled.type]: (state, action: PayloadAction<[]>) => {
      state.moreResults.isLoading = false;
      state.moreResults.error = '';
      state.moreResults.data = action.payload;
    },
    [fetchResults.pending.type]: (state) => {
      state.moreResults.isLoading = true;
    },
    [fetchResults.rejected.type]: (state, action: PayloadAction<string>) => {
      state.moreResults.isLoading = false;
      state.moreResults.error = action.payload;
    },

    //moreResultShort
    [fetchResultShort.fulfilled.type]: (state, action: PayloadAction<[]>) => {
      state.moreResultShort.isLoading = false;
      state.moreResultShort.error = '';
      state.moreResultShort.data = action.payload;
    },
    [fetchResultShort.pending.type]: (state) => {
      state.moreResultShort.isLoading = true;
    },
    [fetchResultShort.rejected.type]: (state, action: PayloadAction<string>) => {
      state.moreResultShort.isLoading = false;
      state.moreResultShort.error = action.payload;
    },

    //fetchOrders
    [fetchOrders.fulfilled.type]: (state, action: PayloadAction<{data:IOrder[], meta:any}>) => {
      state.orders.isLoading = false;
      state.orders.error = '';
      state.orders.hasNextPage = !!action.payload.data.length;
      const last = action.payload.meta?.last;
      const pages = state.orders.pages;
      // console.log('slice', 'pages', pages.join(','), 'last', last)
      if(last) {
        // console.log('last true')
        if(!pages.some( item => String(item) === String(last))) {
          // console.log('1')
          state.orders.data = [...state.orders.data, ...action.payload.data];
        }
        state.orders.pages = [ ...pages, last ];
      } else {
        // console.log('last false')
        state.orders.data = action.payload.data;
        state.orders.pages = [];
      }


      // state.orders.data = [...state.orders.data, ...action.payload];
    },
    [fetchOrders.pending.type]: (state, action: any) => {
      console.log('pending', state.orders, action?.meta?.arg.last)
      if(!action?.meta?.arg.last) {
        state.orders.data = []
      }
      state.orders.isLoading = true;
    },
    [fetchOrders.rejected.type]: (state, action: PayloadAction<string>) => {
      state.orders.isLoading = false;
      state.orders.error = action.payload;
      state.orders.hasNextPage = false;
    },

    //fetchOrder
    [fetchOrder.fulfilled.type]: (state, action: PayloadAction<IOrder>) => {
      state.order.isLoading = false;
      state.order.error = '';
      state.order.data = action.payload;
    },
    [fetchOrder.pending.type]: (state) => {
      state.order.isLoading = true;
    },
    [fetchOrder.rejected.type]: (state, action: PayloadAction<string>) => {
      state.order.isLoading = false;
      state.order.error = action.payload;
    },
  }
})

export const { addOrders } = AbcpSlice.actions;
export default AbcpSlice.reducer;