import * as React from 'react';
import {Fragment, useEffect, useRef, useState} from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import Input from "components/ui/Input/Input";
import SelectOptionsFlexHeight from "../options/SelectOptionsFlexHeight";

type Props = {
  children?: React.ReactNode
  param?: string
  label?: string
  data?: any
  isLoading?: any
  getData?: (arg0: any) => void
  hasNextPage?: any
  lastId?: string
  onChange?: (optionObject: {} | null) => void // событие при выборе option
  initSelected?: {_id: string, [type: string]: string} | any // текущий выбранный объект
  optionKey: string // ключ в data, по которому будет отображаться текст в options
  initStatus?: string
  size?: string | number
  sort?: string

  isError?: boolean
  errorText?: string
  helpText?: string

  isSearchInput?: boolean

  isDisabled?: boolean
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const SelectFlexHeight: React.FC<Props> = (props: Props) => {
  const {
    label,
    data,
    isLoading: isFetchingNextPage,
    getData=()=>{},
    hasNextPage,
    lastId='_id',
    onChange=()=>{},
    initSelected,
    optionKey,
    initStatus,
    size=100, // по-умолчанию
    sort='_id', // по-умолчанию
    isError,
    errorText,
    helpText,
    isSearchInput = false,
    isDisabled = false
  } = props;

  const [currentOption, setCurrentOption] = useState<any>()
  const [filter, setFilter] = useState('');
  const [init, setInit] = useState(true);
  const refInput = useRef<any>()
  useEffect(() => {
    if(initStatus === 'fulfilled') {
      setCurrentOption(initSelected)
      setInit(false)
    }
  }, [initStatus])

  useEffect(() => {
    onChange(currentOption);
  }, [currentOption])

  const change = (value: any) => {
    setCurrentOption(value)
  }

  // фокус на поиске
  useEffect(() => {
    if(refInput.current) {
      refInput.current.focus()
    }
  }, [refInput.current])

  return (
    <>
      {init ?
        <div>
          {label && <div className="block text-sm font-medium text-gray-700">{label}</div>}
          <div className={classNames("relative w-full ", label ? 'mt-1' : '')}>
            <div className="relative w-full h-9.5 min-h-[38px] text-sm rounded-md text-slate-900 border border-slate-300 py-2 pl-3 pr-10 placeholder:text-slate-400 focus:ring-0 focus:border-sky-500 cursor-pointer shadow-sm  bg-white text-left">
              <span className="block truncate">Загрузка...</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-slate-400" aria-hidden="true"/>
              </span>
            </div>
          </div>
        </div>
        :
        <Listbox value={currentOption ? currentOption : null} onChange={change} by={(a, z) => {return a?._id === z._id}}>
          {({open}) => (
            <>
              <div>
                {label && <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>}
                <div className={classNames("relative w-full ", label ? 'mt-1' : '')}>
                  <Listbox.Button
                    className={classNames("relative w-full h-9.5 min-h-[38px] text-sm rounded-md text-slate-900 py-2 pl-3 pr-10 placeholder:text-slate-400 focus:ring-0 focus:border-sky-500 shadow-sm  bg-white text-left",
                      isError ? 'border border-rose-400' : 'border border-slate-300',
                      isDisabled ? 'border border-slate-100' : '',
                      isDisabled ? 'pointer-events-none' : 'cursor-pointer',
                    )}>
                    <span className={classNames("block truncate", isDisabled ? 'text-slate-300' : 'text-gray-700')}>{currentOption?.[optionKey]}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon className={classNames("h-5 w-5 ", isDisabled ? 'text-slate-100' : 'text-slate-400')} aria-hidden="true"/>
                    </span>
                  </Listbox.Button>

                  {open &&
                   <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                   >
                     <Listbox.Options
                      className="absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                     >

                       {isSearchInput && <div className='pt-1 pb-2 px-2 border-b'>
                         <Input ref={refInput} placeholder='Поиск...'
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}/>
                       </div>}
                       
                       <SelectOptionsFlexHeight data={data ? data : []}
                                                    optionKey={optionKey}
                                                    isLoading={isFetchingNextPage}
                                                    hasNextPage={hasNextPage}
                                                    getData={getData}
                                                    filter={filter}
                                                    size={size}
                                                    sort={sort}
                       />
                     </Listbox.Options>
                   </Transition>}

                </div>
              </div>
            </>
          )}
        </Listbox>
      }

      { helpText && <div className='text-slate-500 line-clamp-6 text-xs'>{helpText}</div> }
      { isError && <div className='text-rose-400 line-clamp-6 text-xs'>{errorText}</div> }
    </>
  );
};

export default SelectFlexHeight;