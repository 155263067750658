import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import {TooltipPlacement} from "../../components/ui/tooltips/TooltipPanel";

interface ITooltip {
  id?: string
  target: any
  text: string,
  placement?: TooltipPlacement
}

interface TooltipsState {
  tooltips: ITooltip[]
}

const initialState: TooltipsState = {
  tooltips: []
}

export const tooltipSlice = createSlice({
  name: 'tooltip',
  initialState,
  reducers: {
    showTooltip(state, action: PayloadAction<ITooltip>) {
      // state.dialogs.push({title: action.payload.title, text: action.payload.text });
      console.log('showTooltip', action.payload)
      const newTooltip = action.payload;
      newTooltip.id = uuidv4();
      if(!state.tooltips.some(t => t.target === newTooltip.target)) {
        state.tooltips = [...state.tooltips, newTooltip]
      } else {
        console.log('такой tooltip уже есть')
      }

    },
    closeTooltip(state, action: PayloadAction<string>) {
      console.log('closeTooltip', action.payload)
      const tooltips = state.tooltips.filter( tooltip => tooltip.target !== action.payload);
      state.tooltips = [...tooltips]
    },
    // setData(state, action: PayloadAction<any>) {
    //   const dialog = state.dialogs.find(item => item.id === action.payload.id);
    //   const dialogs = state.dialogs.filter(item => item.id !== action.payload.id);
    //   if(dialog) {
    //     dialog.data = action.payload.data;
    //     state.dialogs = [...dialogs, dialog]
    //   }
    // }
  },
  extraReducers: {}
})

export const { showTooltip, closeTooltip } = tooltipSlice.actions;
export default tooltipSlice.reducer;