import React, {Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {CellContext, createColumnHelper, RowData} from "@tanstack/react-table";
import {useAppDispatch} from "hooks/redux";
import {Link, useNavigate} from "react-router-dom";
import {useNotification} from "hooks/notification";
import {useSlide} from "hooks/slide";
import {useContextMenu} from "hooks/contextMenu";
import {
  productApi,
  useAddProductMutation,
  useDelProductMutation,
  useDelProductsMutation,
  useDownloadXlsMutation,
  useLazyGetProductsFiltersQuery, useLazyGetProductsQuery, useOnlineProductsMutation, usePoolProductsMutation,
} from "api/product";
import {IProduct} from "models/IProduct";
import {MainContext} from "components/Main";
import ProductSlide from "components/ui/slide/tmpl/ProductSlide";
import {useGetProducts} from "hooks/useGetProducts";
import {ArrowLeftIcon, ChevronRightIcon, PencilIcon, PlusIcon, PlusSmallIcon} from "@heroicons/react/20/solid";
import ProductCategorySlide from "components/ui/slide/tmpl/ProductCategorySlide";
import Checkbox from "components/ui/checkbox/Checkbox";
import {formatCurrencyView, formatSumSeparator} from "utils/helpers";
import {useDialog} from "hooks/dialog";
import DialogImportProducts from "components/ui/dialogs/tmpl/DialogImportProducts";
import {DialogContext, EnumDialogIcon, EnumDialogWidth} from "components/ui/dialogs/DialogWrap";
import ActionGroup1 from "components/ui/table3/action-groups/tmpl/ActionGroup1";
import DialogCategorySelection from "components/ui/dialogs/tmpl/DialogCategorySelection";
import {useTooltip} from "hooks/tootip";
import PopoverIndex, {PopoverContext} from "components/ui/popovers/PopoverIndex";
import SelectConstantHeight from "components/ui/select/selectConstantHeight/SelectConstantHeight";
import Input from "components/ui/Input/Input";
import Button from "components/ui/buttons/Button";
import {AppContext} from "App";
import RadioGroupIndex from "components/ui/radio-groups/RadioGroupIndex";
import {TooltipPlacement} from "components/ui/tooltips/TooltipPanel";
import TableFlexOneColumn from "components/ui/table/tmpl/TableFlexOneColumn";
import TableConstantMultiColumns from "components/ui/table/tmpl/TableConstantMultiColumns";
import TableFlexMultiColumns from "components/ui/table/tmpl/TableFlexMultiColumns";
import {ColumnsWidth} from "components/ui/table/Table";
import {useProducts} from "../../../../hooks/useProducts";
import {DialogServiceType} from "../Dialogs";
import {useGetCursor} from "../../../../hooks/useGetCursor";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
const FilterName: React.FC<{name: string, count: string}> = (props: {name: string, count: string}) => {
  return (
      <Fragment>
        {props.name} <span className='text-gray-400 text-sm'>{props.count}</span>
      </Fragment>)
}

const OnlyRootLink: React.FC<{cat: string, item: any, onlyRoot: boolean, toOnlyRootCategory: any}> = ({cat, item, onlyRoot, toOnlyRootCategory}) => {
  const tooltip = useTooltip();
  return (
    <Fragment>
      <span className={classNames('cursor-pointer rounded-full  px-2 text-sm leading-5 hover:bg-amber-400 hover:text-white', cat===item._id && onlyRoot ? 'bg-amber-400 text-white' : 'text-amber-500')}
            onMouseEnter={ (event: any) => { tooltip.show('Товары без подкатегорий', event.currentTarget) } }
            onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }
            onClick={ (event: any) => { event.stopPropagation(); tooltip.close(event.currentTarget); toOnlyRootCategory(item._id) } }>{item?.productsCountOnlyRoot}</span>
    </Fragment>)
}

const CategoryLink: React.FC<{cat: string, item: any, onlyRoot: boolean, toOnlyRootCategory: any, clickProductCategoryEdit: any, toCategory: any}> = ({cat, item, onlyRoot, toOnlyRootCategory, clickProductCategoryEdit, toCategory}) => {
  return (
    <Fragment>
      <div className='group/category flex items-center space-x-3'>
        <div className={ classNames('cursor-pointer', cat===item._id ? onlyRoot ? 'text-amber-500' : 'text-red-500' : '', 'group-hover/category:text-red-500') } onClick={()=>toCategory(item._id)}>
          {item.categoryName_rus} <span className='text-gray-400 text-sm'>{item.productsCount}</span> { item?.productsCountOnlyRoot ? (<OnlyRootLink cat={cat} item={item} onlyRoot={onlyRoot} toOnlyRootCategory={toOnlyRootCategory} />) : '' }
        </div>

        <div className='group/edit' onClick={() => clickProductCategoryEdit(item._id)}>
          <PencilIcon className='h-4 w-4 invisible cursor-pointer group-hover/category:visible group-hover/category:text-slate-200 group-hover/edit:text-slate-400' />
        </div>
      </div>
    </Fragment>)
}

const getThumb200x200 = (fileName: string) => {
  const ext = fileName.split('.').pop();
  return fileName.replace('.' + ext, '-200x200.' + ext)
}

const ImagePreview: React.FC<any> = ({info}) => {
  const [images, setImages] = useState<string[]>([])
  useEffect(() => {
    const arr = info.getValue()
    if(Array.isArray(arr)) {
      setImages(arr)
    } else {
      setImages([])
    }
  }, [info])
  return (
    images.length > 0 ?
        <PopoverIndex content={<ImagesPopover images={images} />}>
          {/*<div className={'w-10 h-10 bg-cover bg-center rounded border'} style={{backgroundImage: `url('${'https://s3.aaabox.ru/'+getThumb200x200(images[0])}')`}} />*/}
          <div className={'w-10 h-10 bg-cover bg-center rounded border'} style={{backgroundImage: `url('${'https://s3.aaabox.ru/'+getThumb200x200(images[0])}')`}} />
        </PopoverIndex>
        :
        <div className={'w-10 h-10 rounded border'}></div>
  )
}

const ImagePreview16x16: React.FC<any> = ({info}) => {
  const [images, setImages] = useState<string[]>([])
  useEffect(() => {
    const arr = info.row.original.images
    if(Array.isArray(arr)) {
      setImages(arr)
    } else {
      setImages([])
    }
  }, [info])
  return (
    images.length > 0 ?
      <PopoverIndex content={<ImagesPopover images={images} />}>
        <div className={'w-16 h-16 bg-cover bg-center rounded border'} style={{backgroundImage: `url('${'https://s3.aaabox.ru/'+getThumb200x200(images[0])}')`}} />
      </PopoverIndex>
      :
      <div className={'w-16 h-16 rounded border'}></div>
  )
}

const ImagesPopover: React.FC<any> = ({images}) => {
  const {maxWidth} = useContext(PopoverContext)
  return (
    <>
      {/*<div style={{maxWidth: 'inherit'}}>*/}
      <div className={'inline-grid gap-2 z-50 rounded-md p-2 shadow bg-black/50'} style={{gridTemplateColumns: maxWidth > 850 && images.length >=5 ? 'repeat(5, 160px)' : 'repeat(auto-fit, 160px)', maxWidth: 'inherit'}}>
        {/*<div className={'flex flex-wrap gap-3 z-10 rounded-md p-2 shadow bg-black/50'}>*/}
          {images.map((item: string) => <img key={item} className={'w-40 h-40 object-cover border rounded'} src={'https://s3.aaabox.ru/'+getThumb200x200(item)} alt=""/>)}
          {/*{images.map((item: string) => <div key={item} className={'w-40 h-40 bg-cover bg-center rounded border'} style={{backgroundImage: `url('${'https://s3.aaabox.ru/'+item}')`}} />)}*/}
        </div>
      {/*</div>*/}


    </>

  )
}

const ProductPhoneRow: React.FC<{info: CellContext<IProduct, string> & {setSelected2: any, checked2: boolean}, clickProduct: any}> = ({ info, clickProduct }: {info: CellContext<IProduct, string> & {setSelected2: any, checked2: boolean}, clickProduct: any}) => {
  const [checked, setChecked] = useState<boolean>(false)
  const tooltip = useTooltip();

  useEffect(() => {
    setChecked(info.checked2)
  }, [info])

  return (
    <Fragment>
      <div className={'flex gap-3 px-4 py-3'}>
        <div className={'relative'} onClick={(event)=>{event.stopPropagation()}}>
          <Checkbox
            checked={checked ? checked : false}
            onChange={(value) => {
            // console.log('info', info);
            if(info?.setSelected2)
              info?.setSelected2(value)
            }} className={'absolute top-1 left-1'} />
          <ImagePreview16x16 info={info} />
        </div>
        <div className='flex flex-col gap-3 w-full'>

          <div className='flex flex-col'>
            <div className='flex justify-between text-xs'>
              <div>
                <span className='text-slate-400'>{info.row.original.brandName}</span> {info.row.original.number ? <span className='text-slate-400'>– {info.row.original.number}</span> : ''}
              </div>
              <div className={'flex gap-1'}>
                {info.row.original?.isOnline ?
                  <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400"
                       onMouseEnter={ (event: any) => { tooltip.show('Online', event.currentTarget, TooltipPlacement.TOP) } }
                       onMouseLeave={ (event: any) => { tooltip.close(event.currentTarget) } }>
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div> :
                  <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400 opacity-0">
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div>
                }
                <div className={'text-xs text-slate-400'}>#{info.row.index+1}</div>
              </div>

            </div>

            <div className='text-sm line-clamp-2 whitespace-normal'>{info.row.original.title_rus}</div>
            {/*<div className={'flex'}>*/}
            {/*  <Link*/}
            {/*    to="/"*/}
            {/*    onClick={(e) => {e.preventDefault();}}*/}
            {/*    className={classNames("text-sm line-clamp-2 whitespace-normal",*/}
            {/*      // "text-sky-600 hover:text-sky-500 underline decoration-dashed"*/}
            {/*    )}>*/}
            {/*    {info.row.original.title_rus || 'не задано'}*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
          <div className={'flex text-sm justify-between'}>
            {info.row.original.price_sale || info.row.original.price_sale === 0 ?
              <div className='text-sky-600'>{formatSumSeparator(formatCurrencyView(info.row.original.price_sale)) + ' ₽'}</div>
              : <div></div>
            }
            {info.row.original.qty && info.row.original.qty != 0 ?
              <div className={'text-slate-400'}>В наличии: {info.row.original.qty} шт</div>
              :
              info.row.original.qty == 0 ? <div className={'text-amber-500'}>Нет в наличии</div> : <div></div>
            }

          </div>


        </div>
      </div>
    </Fragment>)
}

const ProductHeaderCheck: React.FC<{info: CellContext<IProduct, string> & {setSelectedAll2: any, checkedAll2: boolean}}> = ({ info }: {info: CellContext<IProduct, string> & {setSelectedAll2: any, checkedAll2: boolean}}) => {
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    setChecked(info.checkedAll2)
  }, [info])

  return (
    <Fragment>
      <div className={'flex gap-3 px-1 py-3'}>
        <div className={'relative'} onClick={(event)=>{event.stopPropagation()}}>
          <Checkbox
            label={'Выбрать всё'}
            checked={checked ? checked : false}
            onChange={(value) => {
              // console.log('info', info);
              if(info?.setSelectedAll2)
                info?.setSelectedAll2(value)
            }} className={''} />
        </div>
      </div>
    </Fragment>)
}

type Props = {
  _dialog?: DialogServiceType
  width?: EnumDialogWidth
}

const DialogAddProducts: React.FC<Props> = ({_dialog}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState<string>('');
  const [cat, setCat] = useState<string>('');
  const [onlyRoot, setOnlyRoot] = useState<boolean>(false);
  const [defFilters, setDefFilters] = useState<any>({ isPool: ['true'] })
  const [filter2, setFilter2] = useState<any>({...defFilters})
  const [getProducts, {data: products, isFetching, isLoading, isError: isError3, error: error3}] = useGetProducts()
  // const [getData, obj, lastArgs] = useLazyGetProductsQuery()
  // const [getProducts, {data: products, isFetching, isLoading, isError: isError3, error: error3}] = useGetCursor(getData, obj)

  const [params, setParams] = useState<any>()
  // const {data: categories} = useGetProductCategoriesQuery()
  const [addProduct] = useAddProductMutation();
  const [delProduct] = useDelProductMutation();
  const [delProducts, {}] = useDelProductsMutation();
  const [getFilters, { data: productsFilters, isFetching: isFetchingFilters }] = useLazyGetProductsFiltersQuery();
  const [downloadXls] = useDownloadXlsMutation();
  const [poolSelected] = usePoolProductsMutation()
  const [onlineSelected] = useOnlineProductsMutation()
  const dialog = useDialog()
  const tooltip = useTooltip();
  const {title, setIsSearchInput, setPreloader} = useContext(MainContext)
  const {dialogRef} = useContext(DialogContext)
  const [searchText, setSearchText] = useState('')
  const [sortOption, setSortOption] = useState<any>([
    {option: {_id: 'id:desc', value: 'По дате создания'}},
    {option: {_id: 'price_sale:asc', value: 'Дешевле'}},
    {option: {_id: 'price_sale:desc', value: 'Дороже'}},
    {option: {_id: 'qty:asc', value: 'Наличие мало'}},
    {option: {_id: 'qty:desc', value: 'Наличие много'}},
    {option: {_id: 'title_rus:asc', value: 'Наименование (А-Я)'}},
    {option: {_id: 'title_rus:desc', value: 'Наименование (Я-А)'}},
  ])
  const [sort, setSort] = useState<string>('id:desc')
  const [is2xl, set2xl] = useState<boolean | null>(null)
  const tableRef = React.useRef<any>()
  const [selected, setSelected] = useState<{ all: boolean, count: number, include: any[], exclude: any[] }>()
  // const { data: productsFilters} = useGetProductsFiltersQuery();
  const notify = useNotification();
  const slides = useSlide();
  const contextMenu = useContextMenu();
  // const {create} = useProducts()

  const tableContextMenu = [
    [
      {text: 'Создать товар', onClick:({meta}: any) => { create(meta) }},
    ],
    [{text: 'Обновить', onClick:() => { dispatch(productApi.util.invalidateTags(['Products']));}}],
    [
      {text: 'Импорт xls', onClick:({ row, meta}: any) => { importProducts(meta) }},
      // {text: 'Скачать xls', onClick:() => { getXls() }}
    ],
  ]
  const rowContextMenu = [
    [
      {
        text: 'Копировать',
        onClick: ({ row, meta }: any) => {
          console.log('copy', row, meta)
          copy(meta, row.original)
        }
      },
      // {text: 'Печать'}
    ],
    [{text: 'Удалить', onClick:({ row, meta }: any) => {
      delProduct(row.original._id).then(
        () => {
          setParams({ cat: meta?.cat, ...meta?.filter2, filterText: meta?.filterText, onlyRoot: meta?.onlyRoot, sort: meta?.sort })
          updateCategories(meta?.cat, meta?.filter2, meta?.filterText, meta?.onlyRoot)
        }
      )
    }}]
  ]

  const onOk = useCallback((): Promise<void> => {
    // срабатывает, когда происходит accept диалогового окна.
    // если этого обработчика нет, тогда диалоговое окно закрывается по-умолчанию при событии закрытия в dialogWrap
    // и ничего не возвращает
    // если этот обработчик есть, но нет accept(result), тогда окно не закрывается.
    // из любого места компонента можно закрыть диалоговое окно (accept() или decline()),
    // минуя регистрацию onOk и onCancel

    return new Promise((resolve, reject) => {
      console.log('promise')
      resolve()
      _dialog?.accept({selected, params})
    })

  }, [selected])

  useEffect(() => {
    _dialog?.acceptHandler(onOk)
    _dialog?.setTitle('Справочник Товары')
    _dialog?.setIcon(EnumDialogIcon.FORM)
    _dialog?.acceptButton('Выбрать ' + selected?.count)
    _dialog?.declineButton('Закрыть')
  }, [onOk])

  const onMedia = useCallback(() => {
    const matches = window.matchMedia("(min-width: 1536px)").matches
    // console.log('onMedia set2xl', matches)
    set2xl(matches)
  }, [])

  useEffect(() => {
    const m = window.matchMedia("(min-width: 1536px)")
    window.requestAnimationFrame(() => {
      onMedia();
      m.addEventListener('change', onMedia);
    })
    return () => m.removeEventListener('change', onMedia)
  }, [])

  useEffect(() => {
    setPreloader(isFetching)
  }, [isFetching])

  useEffect(() => {
    setIsSearchInput(true)
    return () => setIsSearchInput(false)
  }, [])

  const importProducts = (meta: any) => {
    dialog.show(<DialogImportProducts width={EnumDialogWidth.MAXW7XL}/>).then(
      () => {
        setParams({ cat: meta?.cat, ...meta?.filter2, filterText: meta?.filterText, onlyRoot: meta?.onlyRoot, sort: meta?.sort })
        updateCategories(meta.cat, meta?.filter2, meta?.filterText, meta?.onlyRoot)
      }
    )
  }

  const clickProduct = (info: any) => {
    console.log('row2', info)
    const meta = info.table.options.meta
    slides.show(<ProductSlide idProduct={info.row.original._id} />).then(
      (result) => {
        setParams({ cat: meta?.cat, ...meta?.filter2, filterText: meta?.filterText, onlyRoot: meta?.onlyRoot, sort: meta?.sort })
        updateCategories(meta.cat, meta?.filter2, meta?.filterText, meta?.onlyRoot)

        // dispatch(productApi.util.resetApiState())
        // dispatch(productApi.util.selectInvalidatedBy(products, ['Products']))
        // dispatch(productApi.util.prefetch('getProducts', {size: countPerPage, sort: '_id', last: '', filter}, { force: true }))
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const columnHelper = createColumnHelper<IProduct>()
  const columns = [
    columnHelper.accessor<any, any>('brandName', {
      header: (info: any) => <ProductHeaderCheck info={info} />,
      cell: (info: CellContext<any, any> & any) => <ProductPhoneRow info={info} clickProduct={() => clickProduct(info)} />,
      // cell: '123',
      size: 300, // нужно для того, чтобы не было слишком узкой колонки, а потом она раздвигается с перерисовкой контента
      minSize: 150,
      // maxSize: 200,
      meta: {
        width: ColumnsWidth.STRETCH,
        padding_not: true
      }
    }),
  ]

  const columns2xl = useMemo(() => {
    return [
      // columnHelper.accessor('_id', {
      //   cell: info => <Link to="/" onClick={(e) => clickProduct(e, info)} className="text-sky-600 hover:text-sky-500">${info.getValue()}</Link>,
      //   size: 100,
      // }),
      // columnHelper.accessor('Brand.name', {
      columnHelper.accessor('images', {
        header: '',
        cell: info => <ImagePreview info={info} />,
        // size: 73,
        minSize: 73,
        maxSize: 73,
        // meta: { width: 'max-content' }
      }),
      columnHelper.accessor('brandName', {
        header: 'Бренд',
        // size: 50,
        // minSize: 200,
        maxSize: 200,
        meta: {
          // width: ColumnsWidth.MAX_CONTENT
        }
      }),
      columnHelper.accessor('number', {
        header: 'Артикул',
        // cell: info => <Link to="/" onClick={(e) => {e.preventDefault(); e.stopPropagation(); clickProduct(info)}} className="text-sky-600 hover:text-sky-500 border-b border-dashed border-sky-600 hover:border-sky-500">{info.getValue() || 'не задано'}</Link>,
        // size: 150,
        // minSize: 100,
        maxSize: 200,
        meta: {
          // width: ColumnsWidth.MAX_CONTENT,
        }
      }),
      columnHelper.accessor('title_rus', {
        header: 'Наименование',
        cell: info => <div className='' title={info.getValue()}>{info.getValue()}</div>,
        // size: 400,
        minSize: 250,
        // maxSize: 400,
        meta: {
          width: ColumnsWidth.STRETCH
        },
      }),
      columnHelper.accessor('qty', {
        header: 'Наличие',
        // size: 200,
        // minSize: 100,
        // maxSize: 200,
        meta: {
          // width: ColumnsWidth.MAX_CONTENT,
          side: 'justify-center'
        }
      }),
      columnHelper.accessor('price_sale', {
        header: 'Цена продажная',
        cell: info => info.getValue() ? formatSumSeparator(formatCurrencyView(info.getValue())) + ' ₽' : '',
        // size: 170,
        // minSize: 170,
        // maxSize: 400,
        meta: {
          // width: ColumnsWidth.MAX_CONTENT_OR_HEADER,
          // addWidth: 'OO', // поправка к ширине (разделители тысячных, знак рубля) -- костыль, который нужно исправить: сложность в том, что мы не смогли получить доступ к отформатированным данным (cell: info => ...), только к сырым данным от сервера
          side: 'justify-end'
        }
      }),
    ]
  }, [])

  const updateCategories = (cat: string = '', filter2: {[key: string]: any} & {} = {}, filterText: string, onlyRoot: boolean) => {
    console.log('updateCategories', { cat, ...filter2, filterText, onlyRoot })
    const refreshWhenDelBrand = (result: any) => {
      // нужно для случаев:
      // 1. когда удалили все товары одного бренда, а этот бренд выбран в фильтре -
      // 2. когда переместили все товары одного бренда, а этот бренд выбран в фильтре -
      // в таком случае нужно удалить из фильтрации этот бренд, т.к. его уже нет в списке и сбросить фильтр без
      // перезагрузки не получится
      if(result?.data?.general) {
        // const brands = result.data.general.find((item:any) => item.id === 'brandId') // список брендов от сервера
        // let f = false
        // console.log(13, 'brands', brands, 'filter2.brandId', filter2.brandId)
        // if(Array.isArray(brands?.list) && filter2?.brandId) {
        //   const brandIdNew: any[] = []
        //   filter2.brandId.map((id: any) => {
        //     const find = brands.list.find((item: any) => item.id === id) // ищем бренд из фильтра в списке от сервера
        //
        //     if(!find || find.count == 0) {
        //       f = true // если не находим или кол-во равно 0, то это наш случай - удаляем бренд из фильтрации
        //     } else {
        //       brandIdNew.push(id)
        //     }
        //   })
        //
        //   if(f) {
        //     setFilter({id: 'brandId'}, brandIdNew) // заново фильтруем без удаленных брендов
        //   }
        // }

      }
    }

    if(filterText) {
      getFilters({ cat, ...filter2, filterText, onlyRoot }).then(
        (result: any) => {
          refreshWhenDelBrand(result)
        }
      )
    } else {
      getFilters({ cat, ...filter2, onlyRoot }).then(
        (result: any) => {
          refreshWhenDelBrand(result)
        }
      )
    }
  }

  const create = (meta: any) => {
    slides.show(<ProductSlide idProduct={'new'} categoryForNew={meta.cat} />).then(
      (result) => {
        console.log(1,'close slide', meta.cat)
        // setParams({ cat: meta?.cat, ...meta?.filter2, filterText: meta?.filterText, onlyRoot: meta?.onlyRoot, sort: meta?.sort })
        // updateCategories(meta.cat, meta?.filter2, meta?.filterText, meta?.onlyRoot)
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const copy = (meta: any, productData: any) => {
    slides.show(<ProductSlide idProduct={'copy'} categoryForNew={meta.cat} productData={productData} />).then(
      (result) => {
        console.log(1,'close slide', meta.cat)
        setParams({ cat: meta?.cat, ...meta?.filter2, filterText: meta?.filterText, onlyRoot: meta?.onlyRoot, sort: meta?.sort })
        updateCategories(meta.cat, meta?.filter2, meta?.filterText, meta?.onlyRoot)
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const createCategory = () => {
    slides.show(<ProductCategorySlide idProductCategory={'new'} />).then(
      (result) => {
        console.log(2,'close slide', result)
        updateCategories(cat, filter2, filterText, onlyRoot)
        // getProducts({filterText, last: ''})
      },
      (reason) => {
        console.log(reason)
      }
    )
  }

  const clickProductCategoryEdit = (categoryId: string) => {
    slides.show(<ProductCategorySlide idProductCategory={categoryId} />).then(
      (result) => {
        console.log(555, result)
        updateCategories(cat, filter2, filterText, onlyRoot)
      },
      (reason) => {
        console.log(333, reason)
      }
    )
  }

  const toCategory = (categoryId: string) => {
    console.log('toCategory', categoryId);
    setCat(categoryId);
    setOnlyRoot(false)
  }

  const getProductsGap = useCallback((params: any) => {
    // if(filterText) params.filterText = filterText
    // if(cat) params.cat = cat;
    console.log('gap', params)
    // setParams(params)
    return getProducts({...params})
  }, [params])

  useEffect(() => {
    // console.log(111, searchText, searchText.length)
    if(searchText.length >= 3 || searchText.length === 0) {
      setFilterText(searchText)
    }

  }, [searchText])

  useEffect(() => {
    updateCategories(cat, filter2, filterText, onlyRoot)
  }, [filter2, cat, filterText, onlyRoot])

  const setFilter = (filterObj: any, valueNew: any) => {
    console.log('------------setFilter', filterObj, valueNew)
    setFilter2({...filter2, [filterObj.id]:valueNew})
  }

  useEffect(() => {
    if(is2xl != null) {
      console.log('setParams2', params, { cat, ...filter2, filterText, onlyRoot, sort })
      setParams({ cat, ...filter2, filterText, onlyRoot, sort })
    }

  }, [cat, filter2, filterText, onlyRoot, sort, is2xl])

  const changeTheCategoryOfSelected = (selected: { all: boolean, count: number, include: any[], exclude: any[] }) => {
    console.log('changeTheCategoryOfSelected', selected, params)
    dialog.show(<DialogCategorySelection selected={selected} params={params}/>).then(
      () => {
        setParams({ cat, ...filter2, filterText, onlyRoot, sort })
        updateCategories(cat, filter2, filterText, onlyRoot)
      }
    )
  }

  const toOnlyRootCategory = (categoryId: string) => {
    setOnlyRoot(true)
    setCat(categoryId)
  }

  const deleteProducts = (selected: { all: boolean, count: number, include: any[], exclude: any[] }) => {
    console.log('deleteProducts', selected)
    delProducts({selected, params}).then(
      () => {
        setParams({ cat, ...filter2, filterText, onlyRoot, sort })
        updateCategories(cat, filter2, filterText, onlyRoot)
      }
    )
  }

  const onPoolSelected = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, isPool: boolean) => {
    console.log('poolSelected', selected)
    poolSelected({selected, isPool, params}).then(
      () => {
        setParams({ cat, ...filter2, filterText, onlyRoot, sort })
        updateCategories(cat, filter2, filterText, onlyRoot)
      }
    )
  }

  const onOnlineSelected = (selected: { all: boolean, count: number, include: any[], exclude: any[] }, isOnline: boolean) => {
    console.log('onlineSelected', selected)
    onlineSelected({selected, isOnline, params}).then(
      () => {
        setParams({ cat, ...filter2, filterText, onlyRoot, sort })
        updateCategories(cat, filter2, filterText, onlyRoot)
      }
    )
  }


  const [catView, setCatView] = useState<any>({ breadcrumbs: [], categories: [] })

  useEffect(() => {
    const getLevel = (catsEx?: any): number => {
      let i = 1
      let level = 1
      const cats = catsEx ? catsEx : productsFilters.categories.data
      let success = false
      while (i <= cats.length) {
        const c = cats[i-1]
        if (c._id === cat) {
          success = true
          break
        } else {
          const subLevel = getLevel(c.subCategories)
          if(subLevel) {
            level = level + subLevel
            success = true
            break
          }
        }
        i += 1;
      }

      if(success) {
        return level
      } else {
        return 0
      }
    }

    const getCategories = (catsEx?: any, pre?: any, prePre?: any): [] => {
      let i = 1
      const cats = catsEx ? catsEx : productsFilters.categories.data
      while (i <= cats.length) {
        const c = cats[i-1]

        if (c._id === cat) {
          console.log(8, cat, c)
          if(c.subCategories.length && c.subCategories.some((item: any) => item.productsCount)) {
            // console.log(1)

            const arr = c.subCategories.filter((item: any) => item.productsCount).map((item: any) => {
              return { _id: item._id, catName: item.categoryName_rus, productsCount: item.productsCount, isSub: (!!item.subCategories.length && item.subCategories.some((item: any) => item.productsCount)) }
            })
            c.productsCountOnlyRoot && arr.unshift({ _id: c._id+'onlyRoot', productsCountOnlyRoot: c.productsCountOnlyRoot, onlyRoot: true })
            arr.unshift({ _id: c._id, catName: c.categoryName_rus, productsCount: c.productsCount, productsCountOnlyRoot: c.productsCountOnlyRoot, parent: true })
            arr.unshift({ _id: pre ? pre._id : '', catName: 'Назад' })
            // console.log(9, arr)
            return arr
          } else if(c.productsCount > 0) {
            console.log(2, 'pre', pre, 'c._id', c._id, cats)
            const arr = cats.filter((item: any) => item.productsCount).map((item: any) => {
              return { _id: item._id, catName: item.categoryName_rus, productsCount: item.productsCount, isSub: (!!item.subCategories.length && item.subCategories.some((item: any) => item.productsCount)) }
            })
            const all = cats.find((item: any) => item._id === '')
            console.log('all', all)
            if(!all) {
              console.log(3, pre, prePre)
              pre.productsCountOnlyRoot && arr.unshift({ _id: pre._id+'onlyRoot', productsCountOnlyRoot: pre.productsCountOnlyRoot, onlyRoot: true })
              arr.unshift({ _id: pre ? pre._id : '', catName: pre.catName, productsCount: pre.productsCount, productsCountOnlyRoot: pre.productsCountOnlyRoot, parent: true })
              arr.unshift({ _id: prePre ? prePre._id : '', catName: 'Назад' })
            } else {
              all.productsCountOnlyRoot && arr.splice(1, 0, { _id: 'onlyRoot', productsCountOnlyRoot: all.productsCountOnlyRoot, onlyRoot: true })
            }

            return arr
          }

        } else {
          const subLevel = getCategories(c.subCategories, {_id: c._id, catName: c.categoryName_rus, productsCount: c.productsCount, productsCountOnlyRoot: c.productsCountOnlyRoot}, pre ? {_id: pre._id, catName: pre.categoryName_rus, productsCount: pre.productsCount} : {_id: '', catName: 'Все', productsCount: 1})
          if(subLevel.length) {
            return subLevel
          }
        }
        i += 1;
      }

      return []
    }

    const getBreadcrumbs = (catsEx?: any): any[] => {
      let breadcrumbs: any[] = []
      let i = 1
      let level = 1
      const cats = catsEx ? catsEx : productsFilters.categories.data
      const all = cats.find((item: any) => item._id === '')
      while (i <= cats.length) {
        const c = cats[i-1]
        if (c._id === cat && cat !== '') {
          // console.log(7, cat, c)
          breadcrumbs.push({ _id: c._id, catName: c.categoryName_rus, productsCount: c.productsCount, last: false })
          break
        }
        else {
          const sub = getBreadcrumbs(c.subCategories)
          // console.log('sub', sub)
          if(sub.length) {
            breadcrumbs.push({ _id: c._id, catName: c.categoryName_rus, productsCount: c.productsCount, last: false }, ...sub)
            break
          }
        }
        i += 1;
      }

      if(all) {
        breadcrumbs.unshift({ _id: all._id, catName: all.categoryName_rus, productsCount: all.productsCount, last: false })
      }
      return breadcrumbs
    }

    if(productsFilters) {

      let level = getLevel()
      const breadcrumbs = getBreadcrumbs()

      if(breadcrumbs.length) {
        const last = breadcrumbs[breadcrumbs.length-1]
        last.last = true
      }

      const categories = getCategories()

      console.log('123', level, categories, breadcrumbs)

      setCatView({ categories, breadcrumbs })
    }

  }, [productsFilters]);

  return (
    <>
      <div className="flex w-full h-full items-start gap-x-4 mt-10">
          <aside className={'hidden shrink-0 min-h-[30rem] lg:block'}>

            <div className={'mb-2'}>
              <Button text={'Добавить категорию'} onClick={createCategory} style={'TERTIARY'}/>
            </div>
            <div className="p-3 w-80 shrink-0 bg-white drop-shadow-lg rounded-lg space-y-5">
              <div className={''}>
                <div className='flex space-x-3 items-center text-slate-400'>
                  <div>Категории</div>
                  {/*<PencilIcon className='h-4 w-4 text-slate-200 hover:text-slate-400 cursor-pointer' />*/}
                  <PlusIcon className='h-6 w-6 text-slate-200 hover:text-slate-400 cursor-pointer' onClick={createCategory} />
                </div>
                <div className='mt-3 max-h-96 overflow-y-auto'>

                  {/*<div className='group/category flex items-center space-x-3 text-sm text-slate-500'>*/}
                  {/*  <div className={ classNames('cursor-pointer', cat==='1' ? 'text-red-500' : '', 'group-hover/category:text-red-500') } onClick={()=>toOnlyRootCategory('')}>{'Без категории'} <span className='text-gray-400 text-xs'>{10}</span></div>*/}
                  {/*  <div className='group/edit' onClick={() => clickProductCategoryEdit('1')}>*/}
                  {/*    <PencilIcon className='h-4 w-4 invisible cursor-pointer group-hover/category:visible group-hover/category:text-slate-200 group-hover/edit:text-slate-400' />*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {productsFilters?.categories?.data.length ?
                    (productsFilters.categories.data.map((item: any) =>
                      <Fragment key={item._id}>
                        <CategoryLink cat={cat} item={item} toCategory={toCategory} clickProductCategoryEdit={clickProductCategoryEdit} onlyRoot={onlyRoot} toOnlyRootCategory={toOnlyRootCategory} />

                        {item.subCategories.map( (subItem1: any) =>
                          <Fragment key={subItem1._id}>
                            <div className='ml-3'>
                              <CategoryLink cat={cat} item={subItem1} toCategory={toCategory} clickProductCategoryEdit={clickProductCategoryEdit} onlyRoot={onlyRoot} toOnlyRootCategory={toOnlyRootCategory} />
                            </div>

                            {subItem1.subCategories.map( (subItem2: any) =>
                              <Fragment key={subItem2._id}>
                                <div className='ml-6'>
                                  <CategoryLink cat={cat} item={subItem2} toCategory={toCategory} clickProductCategoryEdit={clickProductCategoryEdit} onlyRoot={onlyRoot} toOnlyRootCategory={toOnlyRootCategory} />
                                </div>

                                {subItem2.subCategories.map( (subItem3: any) =>
                                  <Fragment key={subItem3._id}>
                                    <div className='ml-9'>
                                      <CategoryLink cat={cat} item={subItem3} toCategory={toCategory} clickProductCategoryEdit={clickProductCategoryEdit} onlyRoot={onlyRoot} toOnlyRootCategory={toOnlyRootCategory} />
                                    </div>

                                    {subItem3.subCategories.map( (subItem4: any) =>
                                      <Fragment key={subItem4._id}>
                                        <div className='ml-12'>
                                          <CategoryLink cat={cat} item={subItem4} toCategory={toCategory} clickProductCategoryEdit={clickProductCategoryEdit} onlyRoot={onlyRoot} toOnlyRootCategory={toOnlyRootCategory} />
                                        </div>
                                      </Fragment>
                                    )}

                                  </Fragment>
                                )}

                              </Fragment>

                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ))
                    : 'Категорий нет'}
                </div>
              </div>

              {productsFilters?.general.map((item: any) => (
                <div key={item.id}>

                  {item.type === 'boolean' &&
                    <Fragment>
                      <div className='flex space-x-3 items-center text-slate-400'>
                        <div>{item.title}</div>
                      </div>
                      <div className="mt-3 flex items-center">
                        <Checkbox
                          id={item.id}
                          label={<FilterName name={item.name} count={item.count} />}
                          checked={filter2?.[item.id] ? filter2?.[item.id] : false}
                          onChange={(value) => setFilter(item,value)} />
                      </div>
                    </Fragment>
                  }


                  {item.type === 'list' &&
                    <Fragment>
                      <div className='flex space-x-3 items-center text-slate-400'>
                        <div>{item.title} {}</div>
                      </div>
                      <div className='min-h-0 max-h-64 overflow-y-auto p-1'>
                        {item.list.map((item2: any) => {
                          const checked = Array.isArray(filter2?.[item.id]) ? filter2?.[item.id].some((b: any) => b === item2.id) : false
                          // console.log(44444, checked)
                          return (
                            <Fragment key={item2.id + '-brand'}>
                              <Checkbox
                                id={item2.id + '-brand'}
                                label={<FilterName name={item2.name} count={item2.count} />}
                                checked={checked}
                                disabled={!item2.count && !checked}
                                onChange={(value) => {
                                  let b: any = []
                                  if(value) {
                                    b = filter2?.[item.id] ? [...filter2[item.id], item2.id] : [item2.id]
                                  } else {
                                    b = filter2?.[item.id].filter((id: any) => id !== item2.id)
                                  }
                                  // setBrandsFilter(b)
                                  setFilter(item, b)
                                }}/>
                            </Fragment>
                          )
                        })
                        }
                      </div>
                    </Fragment>

                  }

                  {item.type === 'list_radio' &&
                    <Fragment>
                      <div className='flex space-x-3 items-center text-slate-400'>
                        <div>{item.title} {}</div>
                      </div>
                      <div className='min-h-0 max-h-64 overflow-y-auto p-1'>
                        <RadioGroupIndex values={item.list.map((item2: {id: string, name: string, count: string}) => ({id: item2.id, title: <FilterName name={item2.name} count={item2.count} />, disabled: !item2.count}))}
                                         onChange={(value) => setFilter(item, value.id)}
                                         checked={{id: item.def}}/>
                      </div>
                    </Fragment>

                  }

                  {item.type === 'list_checkbox' &&
                    <Fragment>
                      <div className='flex space-x-3 items-center text-slate-400'>
                        <div>{item.title} {}</div>
                      </div>
                      <div className='min-h-0 max-h-64 overflow-y-auto p-1'>
                        {item.list.map((item2: any) => {
                          const checked = Array.isArray(filter2?.[item.id]) ? filter2?.[item.id].some((b: any) => b === item2.id) : false
                          return (
                            <Fragment key={item2.id}>
                              <Checkbox
                                label={<FilterName name={item2.name} count={item2.count} />}
                                checked={checked}
                                disabled={!item2.count && !checked}
                                onChange={(value) => {
                                  let b: any = []
                                  if(value) {
                                    b = filter2?.[item.id] ? [...filter2[item.id], item2.id] : [item2.id]
                                  } else {
                                    b = filter2?.[item.id].filter((id: any) => id !== item2.id)
                                  }
                                  setFilter(item, b)
                                }}/>
                            </Fragment>
                          )
                        })
                        }
                      </div>
                    </Fragment>

                  }


                </div>
              ))}
              {/*<div>*/}
              {/*  <div className='flex space-x-3 items-center text-slate-400'>*/}
              {/*    <div>Бренды</div>*/}
              {/*  </div>*/}
              {/*  {brandsList.map((item2: any) => {*/}
              {/*    const a = []*/}
              {/*    return (*/}
              {/*      <Fragment key={item2.id}>*/}
              {/*        <Checkbox*/}
              {/*          id={item2.id}*/}
              {/*          label={item2.name + ' ' + item2.count}*/}
              {/*          checked={brandsFilter.some(b => b === item2.id)}*/}
              {/*          onChange={(event) => {*/}
              {/*            let b: any = []*/}
              {/*            if(event.target.checked) {*/}
              {/*              b = [...brandsFilter, item2.id]*/}
              {/*            } else {*/}
              {/*              b = brandsFilter.filter(id => id !== item2.id)*/}
              {/*            }*/}
              {/*            setBrandsFilter(b)*/}
              {/*            setFilter({id: 'brandId'}, b)*/}
              {/*          }}/>*/}
              {/*      </Fragment>*/}
              {/*    )*/}
              {/*  })*/}
              {/*  }*/}
              {/*</div>*/}

            </div>
          </aside>

          <div className="flex-1"
               {...{
                 style: {
                   // height: `calc(100vh - 64px - ${title?.current?.offsetHeight}px - 24px - 8px - 20px)`,
                 },
               }}
          >
            <div className=''
                 {...{
                   style: {
                     // height: `calc(100% - 25px - 50px)`,
                   },
                 }}>

              <div className={'flex gap-3 flex-wrap lg:hidden'}>
                {catView.categories.length ?
                  (catView.categories.map((item: any) =>
                    <Fragment key={item._id}>
                      {item.catName === 'Назад' ?
                        <Fragment>
                          <div className={classNames(
                            'group/category flex items-center border rounded p-3 cursor-pointer text-sky-600 border-sky-600 hover:border-red-500 hover:text-red-500')} onClick={()=>toCategory(item._id)}>
                            <div className={ classNames('flex items-center ') } >
                              <ArrowLeftIcon className={'w-5 h-5'} /> <span>Назад</span>
                            </div>

                          </div>
                        </Fragment>

                        :
                        item.onlyRoot ?
                          <div className={classNames(
                            'group/category flex items-center border rounded p-3 hover:border-red-500 hover:text-red-500',
                            cat+'onlyRoot'===item._id && onlyRoot ? 'text-red-500 border-red-500 pointer-events-none' : item.productsCountOnlyRoot ? 'cursor-pointer text-amber-600 border-amber-600' : 'pointer-events-none text-gray-400 border-gray-400' )} onClick={()=>toOnlyRootCategory(item._id.replace('onlyRoot',''))}>

                            <div className={ classNames('flex items-center ') } >
                              <div>
                                <span className={classNames('text-sm', cat+'onlyRoot'===item._id ? 'text-slate-400' : 'text-slate-400 group-hover/category:text-slate-400')}>{item.productsCountOnlyRoot}</span>
                              </div>
                            </div>

                          </div>
                          :
                          <div className={classNames(
                            'group/category flex items-center border rounded p-3 hover:border-red-500 hover:text-red-500',
                            cat===item._id && !onlyRoot ? 'text-red-500 border-red-500 pointer-events-none' : item.productsCount ? 'cursor-pointer text-sky-600 border-sky-600' : 'pointer-events-none text-gray-400 border-gray-400' )} onClick={()=>toCategory(item._id)}>

                            <div className={ classNames('flex items-center ') } >
                              {item.isSub && <PlusSmallIcon className={'w-5 h-5'} />}
                              <div>
                                {item.catName} <span className={classNames('text-sm', cat===item._id ? 'text-slate-400' : 'text-slate-400 group-hover/category:text-slate-400')}>{item.productsCount}</span>
                              </div>
                              {item?.parent && <ChevronRightIcon className={'w-5 h-5'} /> }
                            </div>

                          </div>
                      }

                    </Fragment>

                  ))
                  : ''}
              </div>


              <div className={'h-16 flex items-center justify-end lg:hidden'}>
                <Link to="/" onClick={(e) => {e.preventDefault(); }} className="text-sky-600 hover:text-sky-500 border-b border-dashed border-sky-600 hover:border-sky-500">Фильтры</Link>
              </div>

              <div className={'flex flex-col-reverse gap-y-3 sm:flex-row sm:justify-between sm:gap-x-3 mb-2'}>
                <div>
                  <Button text={'Создать товар'} style={'SECONDARY'} onClick={() => create({cat, filter2, filterText, totalElements: products?.meta.totalElements, onlyRoot, sort})} />
                </div>
                <div className={'flex flex-col gap-y-2 sm:gap-x-3 sm:flex-row'}>
                  {/* preloader */}
                  {isFetching && <div className={classNames("hidden md:flex md:justify-center md:items-center")}>
                    <svg className="animate-spin h-5 w-5 self-center text-slate-500" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" fillOpacity="0%" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>}

                  {/* Сортировка */}
                  <div className={'flex items-center min-w-[320px] h-full'}>
                    <span className={'hidden sm:block sm:mr-2'}>Сортировка:</span>
                    <SelectConstantHeight
                      data={sortOption}
                      initSelected={sortOption[0].option}
                      optionKey={'value'}
                      isLoading={true}
                      initStatus={'fulfilled'}
                      onChange={(option) => setSort(option._id)}
                    />
                  </div>

                  {/* Поиск */}
                  <div className='flex items-center sm:max-w-[250px] min-w-0'>
                    <Input placeholder='Поиск' value={searchText}
                           onChange={(e) => setSearchText(e.target.value)}
                           onClean={() => setSearchText('')}
                           type={'search'}
                    />
                  </div>
                </div>
              </div>

              <TableFlexMultiColumns
                columns={columns}
                data={products.data}
                isLoading={isLoading}
                isFetching={isFetching}
                hasNextPage={isLoading ? isLoading : products?.meta.hasNextPage}
                getData={getProductsGap}
                meta={ {cat, filter2, filterText, totalElements: products?.meta.totalElements, onlyRoot, sort} }
                params={params}
                isError={isError3}
                error={error3}
                parentRefExt={dialogRef}
                onClickRow={(info) => clickProduct(info)}
                onSelect={(selected: any) => setSelected(selected)}
              />
            </div>

          </div>

        </div>

    </>
  )
}

export default DialogAddProducts;