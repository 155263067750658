import * as React from 'react';
import {forwardRef, Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import {CellContext, flexRender, Row} from "@tanstack/react-table";
import ActionGroupIndex from "../../table3/action-groups/ActionGroupIndex";
import {useTable} from "../../../../hooks/useTable";
import TableHeader from "../headers/TableHeader";
import TableBodyScroll from "../TableBodyScroll";
import {AppContext} from "../../../../App";
import TableScroll from "../TableScroll";
import TrMultiColumns from "../rows/TrMultiColumns";
import CellPreloader from "../rows/cells/CellPreloader";
import TableFooter from "../footer/TableFooter";

type Props = {
  columns?: any
  data?: any // принимать объект data = { data: [], meta: {} }
  isLoading?: any // true при начальной загрузке
  isFetching?: any //
  getData?: (arg0: any) => void | Promise<any>
  hasNextPage?: any
  onClickRow?: (firstCell: CellContext<any, unknown>) => void
  onDoubleClickRow?: (rowData: Record<string, unknown>) => void
  size?: string | number
  // sort?: string
  tableContextMenu?: any
  rowContextMenu?: any
  meta?: any
  params?: {[key: string]: any} // параметры фильтрации, категории или любые другие параметры, которые будут добавлены к запросу
  isError?: any
  error?: any
  isRowNumber?: boolean // отображать номер строки
  isCheckbox?: boolean // отображать чекбоксы - возможность одиночного/множественного выбора
  actionGroup?: any
  parentRefExt?: any
  isFooter?: boolean
  onSelect?: (selected: any) => void
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Row2 = React.memo(TrMultiColumns)

const TableConstantMultiColumns: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {

  const {
    actionGroup,
    onClickRow,
    onSelect=() => {},
  } = props;
  const {appRef} = useContext(AppContext)
  const {
    table,
    rows,
    rowVirtualizer,
    tableRef,
    parentRef,
    headerRef,
    minWidthTable,
    scrollWidth,
    selected,
    setSelect,
    setSelectAll,
    hasNextPage,
    tableContextMenu,
    rowContextMenu } = useTable({  ...props })
  const [cellWidth, setCellWidth] = useState({width: 0})

  const widthBodyRef = useRef<any>({})
  const widthHeaderRef = useRef<any>({})
  const widthFooterRef = useRef<any>({})
  const minWidthTrRef = useRef<any>(0)
  // const style = useMemo(() => ({ minWidth: minWidthTrRef.current}), [cellWidth])
  const [style, setStyle] = useState<any>({minWidth: 0})
  const minWidthHeader = useMemo(() => headerRef.current ? window.getComputedStyle(headerRef.current).getPropertyValue("min-width") : 0, [headerRef?.current?.style?.['min-width']])

  const headerRef2 = useRef<any>(0)
  const headerRef3 = useRef<any>(null)

  useEffect(() => {
    console.log(333, 'select', selected)
    onSelect(selected)
  }, [selected])

  const onScroll = () => {
    // const cls = parentRef.current.className.split(' ')
    // const stl = parentRef.current.style
    // const cls2 = headerRef2.current.className.split(' ')
    // const stl2 = headerRef2.current.style
    // headerRef2.current.style.position = "sticky";
    // headerRef2.current.style.top = '0px';

    // const size1 = parentRef.current.getBoundingClientRect();
    // const size2 = headerRef2.current.getBoundingClientRect();
    // const size3 = headerRef3.current.getBoundingClientRect();
    // headerRef2.current.style.top = `${12}px`;
    // if(size1.top > size3.top) {
    //   headerRef2.current.style.top = `${size1.top - size3.top + 12}px`;
    // } else {
    //   headerRef2.current.style.top = `${0}px`;
    // }
    // console.log(222, 'onScroll', size1.top, size3.top, size1.top - size3.top)
  }

  useEffect(() => {
    setStyle({minWidth: minWidthTrRef.current})

  }, [cellWidth])

  useEffect(() => {
    // window.requestAnimationFrame(() => {
    //   appRef.current.addEventListener("scroll", onScroll);
    // })

    function watchScroll() {
      parentRef?.current.addEventListener("scroll", onScroll);
    }

    if(parentRef?.current) {
      watchScroll();
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [parentRef?.current]);

  const id2: number = useMemo(() => Math.random(), [props.data])
  return (
    <>
      {rowVirtualizer.getVirtualItems().length ?

          <TableScroll parentRef={parentRef} minWidthTable={minWidthTable} >

            <div ref={headerRef2} className={'sticky top-0 flex w-full z-10 '}>
              <TableHeader minWidthTrRef={minWidthTrRef} cellWidth={cellWidth} setCellWidth={setCellWidth} data={props.meta?.changeData} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} widthFooterRef={widthFooterRef} table={table} ref={headerRef} selected={selected} setSelectAll={setSelectAll} scrollWidth={scrollWidth} />
            </div>
            {/*<div ref={headerRef3} className={'flex w-full opacity-0'}>*/}
            {/*  <TableHeader widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} table={table} ref={headerRef} selected={selected} setSelectAll={setSelectAll} scrollWidth={scrollWidth} />*/}
            {/*</div>*/}
            {/*<div className={'flex absolute top-3'}>*/}
            {/*  <TableHeader widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} table={table} ref={headerRef} selected={selected} setSelectAll={setSelectAll} scrollWidth={scrollWidth} />*/}
            {/*</div>*/}

            <TableBodyScroll rowVirtualizer={rowVirtualizer} >

              {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                const isLoaderRow = virtualRow.index + 1 > rows.length
                const rowData = rows[virtualRow.index] as Row<any> // as Row<IOrder>
                // console.log('------', rowData)
                let checked = false
                if(rowData) {
                  checked = !!selected.include.find((item: any) => item._id === rowData.original._id) || !!(selected.exclude.length && !selected.exclude.find((item: any) => item._id === rowData.original._id) && !rowData.original?.move)
                }
                return (
                  rowData ? <div /*tr*/
                      key={(rowData.original._id ? rowData.original._id : virtualRow.index) + id2}
                      data-index={(rowData.original._id ? rowData.original._id : virtualRow.index) + id2}
                      ref={rowVirtualizer.measureElement}
                      {...{
                        style: {
                          minHeight: `${virtualRow.size}px`,
                          // ...style,
                        }
                      }}
                      className={classNames('flex')}
                    >

                      {/*<TrMultiColumns isLoaderRow={isLoaderRow} hasNextPage={hasNextPage} rowData={rowData} checked={checked} selected={selected} setSelect={setSelect} rowContextMenu={rowContextMenu} tableContextMenu={tableContextMenu} table={table} />*/}

                      {/*{rowData && <TrMultiColumns minWidthTrRef={minWidthTrRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} isLoaderRow={isLoaderRow} hasNextPage={hasNextPage} rowData={rowData} checked={checked} selected={selected} setSelect={setSelect} rowContextMenu={rowContextMenu} tableContextMenu={tableContextMenu} table={table} />}*/}

                      <div {...{
                        style: {
                          ...style
                        }
                      }}
                           className={classNames(
                             'flex flex-1 justify-between',
                             onClickRow && 'cursor-pointer',
                             // 'border border-blue-500',
                             index > 0 && 'border border-t-slate-200 border-x-0 border-b-0',
                             checked ? 'bg-sky-100' : 'hover:bg-sky-50')}
                           onClick={() => onClickRow && onClickRow(rowData.getAllCells()[0].getContext())}
                      >
                        <TrMultiColumns cellWidth={cellWidth} setCellWidth={setCellWidth} data={props.meta?.changeData} minWidthTrRef={minWidthTrRef} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} widthFooterRef={widthFooterRef} isLoaderRow={isLoaderRow} hasNextPage={hasNextPage} rowData={rowData} checked={checked} selected={selected} setSelect={setSelect} rowContextMenu={rowContextMenu} tableContextMenu={tableContextMenu} table={table} />
                      </div>
                      <div className={'min-w-[12px]'} />
                    </div>
                    :
                    isLoaderRow
                      ? hasNextPage ?
                        <div key={index} className={'flex'} >
                          <div className={'flex-1'} {...{
                            style: {
                              minWidth: minWidthHeader
                            }
                          }}>
                            <CellPreloader />
                          </div>

                          <div className={'min-w-[12px]'} />
                        </div> : <div key={index}>Больше ничего нет</div>
                      : ''
                )
              })}

            </TableBodyScroll>


            <ActionGroupIndex selected={selected} className={'sticky bottom-3 '} cleanSelected={() => setSelectAll(false)}>{actionGroup}</ActionGroupIndex>

            {props.data.length && props.isFooter &&
            <div ref={headerRef3} className={'sticky bottom-0 flex w-full  z-10 '}>
              <TableFooter minWidthTrRef={minWidthTrRef} cellWidth={cellWidth} setCellWidth={setCellWidth} data={props.meta?.changeData} widthHeaderRef={widthHeaderRef} widthBodyRef={widthBodyRef} widthFooterRef={widthFooterRef} table={table} ref={headerRef} selected={selected} setSelectAll={setSelectAll} scrollWidth={scrollWidth} />
            </div>}

          </TableScroll>

        :
        <div className='flex items-center justify-center h-20'>
          <div className='text-slate-400'>Пусто</div>
        </div>
      }
    </>
  )
})

export default TableConstantMultiColumns;